import styles from './disable.module.scss';

interface IDisable {
	children: JSX.Element[] | JSX.Element | null;
	is?: boolean;
}

export const Disable = (props: IDisable) => {
	const { children, is } = props;

	return <div className={`${is ? styles.is : styles.without}`}>{children}</div>;
};
