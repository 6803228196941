import { TestModel } from '../../models/test.model';
import { TestsApi } from '../../apis/tests-api';
import { makeObservable, observable } from 'mobx';
import { TestCrudStore } from './test-crud.store';
import { PerformancesColumnsSnakeCase } from '../../hooks/apis/use-reports-list';
import { PerformanceStore } from '../performance.store';
import { TestSegment } from '../../hooks/tools/use-segments';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { filterPrototypesByProp, testFilterPrototypes } from '../../components/filters-menu/filters/index';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { FilterStore, FiltersMap } from '@monorepo/controlled/src/stores/filter.store';
import { PerformanceModel } from '../../models/performance.model';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { Statuses } from '../../enums/status';
import { StringActions } from '@monorepo/tools/src/lib/utils/string';

export enum TestFiltersStoreKeys {
	Tests = 'tests',
}
export class TestStore {
	crud = new TestCrudStore();
	list = new BaseListStore<TestModel>({
		listFunc: TestsApi.list,
		model: TestModel,
	});
	performance = new PerformanceStore<TestModel>({
		keys: new Set([
			PerformancesColumnsSnakeCase.TestId,
			PerformancesColumnsSnakeCase.TestName,
			PerformancesColumnsSnakeCase.TestGroupName,
		]),
		segments: new Set<ISegment<TestModel, PerformanceModel, PerformancesColumnsSnakeCase>>([TestSegment<TestModel>()]),
	});
	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeObservable(this, {
			crud: observable,
			list: observable,
			performance: observable,
		});
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		const prototype = testFilterPrototypes.find(filter => filter.MenuComponent === 'Status');
		let defaultFilters: FiltersMap;
		if (prototype) {
			defaultFilters = new Map([
				[
					1,
					new FilterModel({
						index: 1,
						label: `Status is ${Statuses.Active}`,
						action: StringActions.Equals,
						value: [Statuses.Active],
						prototype,
					}),
				],
			]);
		}

		Object.values(TestFiltersStoreKeys).map((key: TestFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const testFilterByKey = new FilterStore({
				key,
				currentFilters: defaultFilters,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, testFilterByKey);
		});
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getPerformance() {
		return this.performance;
	}
}
