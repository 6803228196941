import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useCampaignTableOfContents } from '../../../modules/hooks/toc/campaign.toc';
import { Nav, NavGroup, NavItem, NavPage } from '@monorepo/base/src/components/nav/nav';
import { Page } from '@monorepo/base/src/components/page/page';
import { TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import routes from '../../../modules/routes/index.routes';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

export const CampaignGroupView = () => {
	const { campaignGroupId } = useParams();
	const navigate = useNavigateParams();
	const { currentRoute } = useRoute();
	const { isTableOfContents, tableOfContents, setIsTableOfContents } = useCampaignTableOfContents();

	useNestedSidebar();

	if (!campaignGroupId) {
		navigate(`${routes.campaignGroups.index()}`, { replace: true });
		return null;
	}

	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);
	useEffect(() => {
		setActiveRoute(currentRoute);
	}, [currentRoute]);

	const renderLastSection = () => {
		if (isTableOfContents) {
			return <TableOfContents title={'Campaign'} divider={true} heads={tableOfContents} />;
		}
		return (
			<NavGroup title={'Actions'}>
				<NavItem to={campaignGroupId ? routes.campaignGroups.edit(campaignGroupId) : ''}>Edit Campaign Group</NavItem>
			</NavGroup>
		);
	};

	return (
		<NavPage>
			<Nav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				<NavItem to={campaignGroupId ? routes.campaignGroups.view(campaignGroupId) : ''}>Overview</NavItem>
				<NavItem to={campaignGroupId ? routes.campaignGroups.campaigns(campaignGroupId) : ''}>Campaigns</NavItem>
				<NavItem to={campaignGroupId ? routes.campaignGroups.creatives(campaignGroupId) : ''}>Creatives</NavItem>
				<NavGroup title={'Placements'}>
					<NavItem to={campaignGroupId ? routes.campaignGroups.domains(campaignGroupId) : ''}>Domains</NavItem>
					<NavItem to={campaignGroupId ? routes.campaignGroups.pages(campaignGroupId) : ''}>Pages</NavItem>
					<NavItem to={campaignGroupId ? routes.campaignGroups.bidKeys(campaignGroupId) : ''}>BidKeys</NavItem>
				</NavGroup>
				{renderLastSection()}
			</Nav>
			<Page unstyled={true}>
				<Outlet context={{ setIsTableOfContents }} />
			</Page>
		</NavPage>
	);
};
