import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';

export type dispatchLogType =
	| DispatchLogsTypes.Success
	| DispatchLogsTypes.Error
	| DispatchLogsTypes.Info
	| DispatchLogsTypes.PublishingProgress;

export enum DispatchLogsTypes {
	Success = 'success',
	Error = 'error',
	Info = 'info',
	PublishingProgress = 'publishingProgress',
}

export interface ILog {
	msg: string;
	type: dispatchLogType;
}

// TODO - take from db or logs provider
// TODO - add log types enums
export function useLogs() {
	const { toastsStore, notificationsStore } = useStores<IControlledStore>();
	const logs = notificationsStore.getNotifications();

	const dispatchLog = (props: ILog): void => {
		const { msg, type } = props;

		toastsStore.addToast({
			msg,
			type,
		});
		notificationsStore.addNotification({
			msg,
			type,
			date: new Date(),
			extraData: {},
		});
	};

	return { dispatchLog, logs };
}
