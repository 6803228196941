import { useLocation } from 'react-router-dom';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';

// !Use this hook if you need SiderSlider with navigation
// TODO - bug when isBlockRequest true in create
export function useStateBetweenPages(predicate?: () => boolean) {
	const locationState = useLocation().state as BetweenPagesStateProps;
	const stateBetweenPages = {} as BetweenPagesStateProps;
	if (locationState && predicate && predicate()) {
		stateBetweenPages.isBlockRequest = locationState.isBlockRequest;
		stateBetweenPages.isResetList = !locationState.isBlockRequest;
	}

	return stateBetweenPages;
}
