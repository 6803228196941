import { observer } from 'mobx-react';
import { useState } from 'react';
import { useCreativeGet } from '../../hooks/apis/use-creative-get';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CreativeModel } from '../../models/creative.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import styles from './main-breadcrumbs.module.scss';
import { AdvertiserBreadcrumb } from './advertiser-page.breadcrumbs';
import { CreativeGroupBreadcrumb } from './creative-group-page.breadcrumbs';
import { IAdminxStore } from '../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { SelectNew, SelectOptions } from '@monorepo/base/src/components/select-new/select-new';

interface ICreativeBreadcrumbs {
	creativeId: id;
}

export const CreativePageBreadcrumbs = observer((props: ICreativeBreadcrumbs) => {
	const { creativeId } = props;
	const { creativeStore } = useStores<IAdminxStore>();
	const navigate = useNavigateParams();
	useCreativeGet({ id: creativeId });
	const [advertiserCreatives, setAdvertisercreatives] = useState<CreativeModel[]>([]);

	const creative = creativeStore.getCrud().getData();
	const creativeGroup = creative.getCreativeGroup();
	const advertiser = creativeGroup?.getAdvertiser();
	const creativeName = creative?.getBrand();
	const isLoadingCreatives = creativeStore.getListStore().getIsLoading();

	const onListItemCreative = (creativesId: (string | number)[]) => {
		const creativeId = creativesId[0];
		if (!creativeId) {
			return null;
		}

		navigate(routes.creatives.campaigns(creativeId));
	};

	const onBreadcrumbcreative = () => {
		creativeStore
			.getListStore()
			.fetch()
			.then(_creatives => {
				if (!Array.isArray(_creatives)) {
					return null;
				}
				setAdvertisercreatives(_creatives.filter(creative => creative.getCreativeGroup()?.getId() === creativeGroup?.getId()));
			});
	};

	const onBreadcrumbLink = () => {
		const creativeId = creative.getId();

		if (creativeId) {
			navigate(`${routes.creatives.campaigns(creativeId)}`);
		}
	};

	return (
		<div className={styles.wrapper}>
			{advertiser ? <AdvertiserBreadcrumb advertiser={advertiser} /> : null}
			{creativeGroup ? <CreativeGroupBreadcrumb creativeGroup={creativeGroup} advertiser={advertiser} /> : null}
			{creativeName ? (
				<BreadcrumbMenu
					label={'Creative'}
					title={creativeName}
					onArrowClick={onBreadcrumbcreative}
					navigateToModel={onBreadcrumbLink}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_breadcrumbs')] }}
					isBarLoader={isLoadingCreatives}
					isLoading={isLoadingCreatives && advertiserCreatives.length === 0}
					skeletonRectanglesAmount={12}>
					<div className={styles.menuWrapper}>
						{advertiserCreatives ? (
							<SelectNew
								classNames={{ list: styles.list }}
								placeholder={'Search creative'}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_breadcrumbs_select')] }}
								options={advertiserCreatives.map(_advertiserCreatives => {
									return {
										label: `${_advertiserCreatives.getBrand()} - ${_advertiserCreatives.getId()}` as string,
										value: _advertiserCreatives.getId() as string,
										to: routes.creatives.campaigns(_advertiserCreatives.getId() as string),
									};
								})}
								sortBy={(a: SelectOptions, b: SelectOptions) => (a.label > b.label ? 1 : -1)}
								required
								onSelect={onListItemCreative}
								disabled={isLoadingCreatives}
								defaultValues={[creativeId]}
							/>
						) : null}
					</div>
				</BreadcrumbMenu>
			) : null}
		</div>
	);
});
