import { observer } from 'mobx-react';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';

import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../modules/stores';
import { useParams } from 'react-router-dom';
import ReportTable from '../../../../modules/components/report-table/table-component';
import { ReportFilterType } from '../../../../modules/components/report-table/stores/data-stores/old-report.types';
import { useTableStore } from '../../../../modules/components/report-table/stores/use-table-store.hook';

export const CampaignTrashedBidKeys = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const { campaignId } = useParams();
	const hiddenFilters = campaignId
		? [{ column: 'campaign_id', filterType: ReportFilterType.EQUALS, value: [Number(campaignId)], inverted: false }]
		: undefined;

	const { campaignTrashedBidKeys: reportTableStore } = campaignStore;

	useTableStore({ reportTableStore, hiddenFilters });

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Trashed Bid Keys</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<ReportTable tableStore={reportTableStore} />
		</Page>
	);
});
