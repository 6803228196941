import { Fragment } from 'react';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { CampaignGroupModel } from '../../models/campaign-group.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, IUseEntityActions, useDefaultActions } from './default.actions';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IAdminxStore } from '../../stores';
import { DataAttribute, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { campaignGroupsFilterPrototypes } from '../../components/filters-menu/filters';

const theme = getTheme();
export function useCampaignGroupActions(props: IUseEntityActions = {}) {
	const { onEdit, createRoute, debugProps } = props;
	const { campaignGroupsStore, settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { dataAttrs } = debugProps || {};

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [...campaignGroupsFilterPrototypes];

	const onEditRoute = (campaignGroupId: id) => (onEdit ? onEdit(campaignGroupId) : routes.campaignGroups.edit(campaignGroupId));
	const onDelete = (ids: id[]) => campaignGroupsStore.getCrud().delete(ids[0]);

	const funcActions = {
		onEditRoute,
		onDelete,
	};

	const SelectedActions = (props: ITableActions<CampaignGroupModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new CampaignGroupModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities, debugProps, ...funcActions });

		return (
			<Fragment>
				<SelectedLinerButton>
					<EditAction
						state={{ isBlockRequest: true } as BetweenPagesStateProps}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_edit', dataAttrs) }}
					/>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DeleteAction debugProps={{ dataAttrs: suffixToDataAttr('_selected_delete', dataAttrs) }} />
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	const EntityActions = (props: IEntityActions<CampaignGroupModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction } = useDefaultActions({ entities: [entity], ...funcActions });

		return (
			<Fragment>
				<QuickActionButton>
					<EditAction
						iconColor={theme.titleColor}
						textColor={theme.titleColor}
						state={{ isBlockRequest: true } as BetweenPagesStateProps}
					/>
				</QuickActionButton>
				<QuickActionButton>
					<DeleteAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} />
				</QuickActionButton>
			</Fragment>
		);
	};

	const IndexActions = () => {
		return (
			<PrimaryLink
				debugProps={{ dataAttrs: [new DataAttribute('id', 'create_campaign_group')] }}
				icon={'plus'}
				to={createRoute || `${routes.campaignGroups.create()}`}
				width={'100px'}
				state={{ isBlockRequest: true }}>
				Create
			</PrimaryLink>
		);
	};

	return {
		IndexActions,
		SelectedActions,
		EntityActions,
		FiltersActions,
	};
}
