import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { Divider } from '../divider/divider';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { option } from '../dropdown/dropdown';
import { Input } from '../form/input/input';
import { HighlightStatuses } from '../highlight/highlight';
import { FormError } from '../form/form-error/form-error';
import { Skeleton } from '../skeleton/skeleton';
import { Tag } from '../tag/tag';
import styles from './selection-list.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

interface ISelectionListItem {
	name: string;
	description?: string;
	disabled?: boolean;
	id?: string | number;
}

interface ISelectionList<T> {
	label: string;
	options: T[];
	onSelect: (option: T[]) => void;
	defaultOptions?: T[];
	isLoadingOptions?: boolean;
	renderOption: (option: T) => ReactNode;
	isSelectionList?: boolean;
	debugProps?: IDebugProps;
	isSingleSelection?: boolean;
	error?: string;
}

export const SelectionList = <T extends ISelectionListItem>(props: ISelectionList<T>) => {
	const {
		label,
		defaultOptions,
		options,
		onSelect,
		isLoadingOptions,
		renderOption,
		isSelectionList,
		debugProps,
		isSingleSelection,
		error,
	} = props;
	const [activeOptions, setActiveOptions] = useState<T[]>([]);
	const [searchBarOption, setSearchBarOption] = useState<option | undefined>('');
	const [filteredOptions, setFilteredOptions] = useState<T[]>(options);
	const searchBarRef = useRef<HTMLInputElement | null>(null);
	const [isOpenSelectionList, setSelectionList] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);
	const { dataAttrs } = debugProps || {};

	const prevOptions = useRef<T[]>();
	const prevDefaultOptions = useRef<T[]>();

	const dropdownRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const isDefaultOptionsChanged = () => (defaultOptions && prevDefaultOptions?.current) || defaultOptions !== prevDefaultOptions?.current;

	const isOptionsChanged = () =>
		Array.isArray(options) && Array.isArray(prevOptions.current) && options.join('') !== prevOptions.current.join('');

	useOnClickOutside(dropdownRef, () => setSelectionList(false));

	useEffect(() => {
		// Initalize active option in case of options or defaultOption or defaultOptions were changed from the last render
		// for example why we need this use case, see Create campaign page select an advertiser and select campaign group
		if (defaultOptions || isDefaultOptionsChanged() || isOptionsChanged()) {
			setActiveOptions(defaultOptions || []);
		}
		prevOptions.current = options;
		prevDefaultOptions.current = defaultOptions;
	}, [options, defaultOptions]);

	useEffect(() => {
		setFilteredOptions(options);
	}, [options]);

	useEffect(() => {
		if (!isSelectionList) {
			searchBarRef.current?.focus();
		} else if (isSelectionList && isOpenSelectionList) {
			searchBarRef.current?.focus();
		}
	}, [searchBarRef, isOpenSelectionList]);

	const addOrRemoveOption = (option: T) => {
		const isActivatedOption = activeOptions.find(_option => _option.name === option.name);
		let newSet: T[] = [];

		if (isActivatedOption) {
			newSet = activeOptions.filter(_option => {
				return _option.name !== option.name;
			});
		} else {
			if (isSingleSelection) {
				newSet = [option];
			} else {
				newSet = [...activeOptions];
				newSet.push(option);
			}
		}

		setActiveOptions(newSet);
		onSelect && onSelect(newSet);
	};

	const onSearchBarChange = (value: option) => {
		setSearchBarOption(value);

		setFilteredOptions(
			options.filter(
				option =>
					(option.name || '').toLowerCase().includes(value.toLowerCase()) ||
					(option.description || '').toLowerCase().includes(value.toLowerCase()) ||
					(option.id || '').toString().toLowerCase().includes(value.toLowerCase())
			)
		);
	};

	const _renderOption = (option: T) => {
		return renderOption(option);
	};

	const renderOptions = () => {
		const selectedOptions: JSX.Element[] = [];
		const unselectedOptions: JSX.Element[] = [];

		filteredOptions.forEach(option => {
			if (Array.from(activeOptions).find((_option: T) => _option.name === option.name)) {
				selectedOptions.push(
					<li className={styles.highlight} value={option.name} key={option.name} onClick={() => addOrRemoveOption(option)}>
						{_renderOption(option)}
					</li>
				);
			} else {
				unselectedOptions.push(
					<li
						className={`${option.disabled ? styles.disabled : ''}`}
						value={option.name}
						key={option.name}
						onClick={() => {
							if (!option.disabled) {
								addOrRemoveOption(option);
							}
						}}>
						{_renderOption(option)}
					</li>
				);
			}
		});

		return (
			<Fragment>
				{selectedOptions.map(option => option)}
				<div className={styles.separatorLine}>
					<Divider />
				</div>
				{unselectedOptions.map(option => option)}
			</Fragment>
		);
	};

	const renderSelectionList = () => {
		return (
			<div className={styles.wrapper} {...generateDataAttrs(dataAttrs)}>
				<div className={styles.searchWrapper}>
					<Input
						className={styles.searchbar}
						inputClassName={styles.searchInput}
						inputWrapperClassName={styles.searchInputWrapper}
						value={searchBarOption}
						placeholder={'Search'}
						onChange={e => onSearchBarChange(e.target.value)}
						ref={searchBarRef}
					/>
					{isSelectionList ? (
						<div onClick={() => setSelectionList(false)}>
							<Icon
								className={styles.toggleArrow}
								isMFP={true}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'expend_button')] }}>
								chevron-down
							</Icon>
						</div>
					) : null}
				</div>
				<Divider />
				{isLoadingOptions ? (
					<div className={styles.skeletonWrapper}>
						<Skeleton is={true} rectanglesAmount={isSelectionList ? 14 : 22} />
					</div>
				) : (
					<div className={`${isSelectionList ? styles.addLayer : ''}`}>
						{!isSelectionList && (
							<Fragment>
								<div className={styles.sumWrapper}>{`Selected ${
									defaultOptions?.length || activeOptions?.length || 0
								} ${label} out of ${options.length}`}</div>
								<Divider />
							</Fragment>
						)}

						{filteredOptions.length > 0 && (
							<Fragment>
								<div className={styles.resultsCounter}>About {filteredOptions.length} results:</div>
								<div className={`${styles.optionsWrapper} ${isSelectionList ? styles.decreasedHeight : ''}`}>
									<ul>{renderOptions()}</ul>
								</div>
							</Fragment>
						)}
						{filteredOptions.length === 0 && searchBarOption && searchBarOption.length > 0 && (
							<div className={styles.noResults}>No results for: "{searchBarOption}"</div>
						)}
					</div>
				)}
			</div>
		);
	};

	const renderChips = () => (
		<div
			className={`${styles.selectionListWrapper} ${styles.closed}`}
			onClick={() => setSelectionList(true)}
			{...generateDataAttrs(dataAttrs)}>
			{activeOptions.length > 0 ? (
				<Fragment>
					<div className={`${styles.title} ${styles.notEmpty}`}>
						{label} <span className={styles.labelActiveOptions}>({activeOptions.length} selected)</span>
					</div>
					{activeOptions.map(option => (
						<Tag key={option.name} type={HighlightStatuses.New}>
							{option.name}
							<Icon
								className={styles.closeIcon}
								isMFP={true}
								onClick={e => {
									e.stopPropagation();
									addOrRemoveOption(option);
								}}>
								x-close
							</Icon>
						</Tag>
					))}
					<Icon
						className={styles.toggleArrow}
						isMFP={true}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'expend_button')] }}>
						chevron-down
					</Icon>
				</Fragment>
			) : (
				<Fragment>
					<div className={`${styles.title} ${styles.empty}`}>{label}</div>
					<Icon
						className={styles.toggleArrow}
						isMFP={true}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'expend_button')] }}>
						chevron-down
					</Icon>
				</Fragment>
			)}
		</div>
	);

	const renderSelection = () => (
		<div className={styles.selectionListAndError}>
			<div
				className={`${styles.selectionListWrapper} ${styles.closed}`}
				onClick={() => setSelectionList(true)}
				{...generateDataAttrs(dataAttrs)}>
				{activeOptions.length > 0 ? (
					<Fragment>
						{activeOptions.map(o => (
							<div key={o.name} className={styles.activeSingleOption}>
								{o.name}
							</div>
						))}
						<Icon
							className={styles.toggleArrow}
							isMFP={true}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'expend_button')] }}>
							chevron-down
						</Icon>
					</Fragment>
				) : (
					<Fragment>
						<div className={`${styles.title} ${styles.empty}`}>{label}</div>
						<Icon
							className={styles.toggleArrow}
							isMFP={true}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'expend_button')] }}>
							chevron-down
						</Icon>
					</Fragment>
				)}
			</div>
			<FormError msg={errorMsg} />
		</div>
	);

	if (isSelectionList) {
		if (isOpenSelectionList) {
			return (
				<div className={`${styles.selectionListWrapper}`} ref={dropdownRef}>
					{renderSelectionList()}
				</div>
			);
		}

		return isSingleSelection ? renderSelection() : renderChips();
	}

	return renderSelectionList();
};
