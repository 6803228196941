import { Fragment } from 'react';
import { useTableContext } from '../table-component';
import { TableRawActionButton } from '../table-elements/table-actions';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { EnablePauseCampaignsModal } from '../../modals/enable-pause-campaigns';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import routes from '../../../routes/index.routes';
import { DeleteModal } from '../../modals/delete-modal';
import { observer } from 'mobx-react';

export const CampaignRowActions = observer(() => {
	const { tableStore } = useTableContext();
	const { campaignStore, modalsStore, toastsStore } = useStores<IAdminxStore>();
	const campaignsCrudStore = campaignStore.getCrud();
	const { dispatchLog } = useLogs();

	const selectedIds = tableStore.getSelectedRows().map(r => r.campaign_id) as string[];
	const campaignSingleAction = tableStore.getSelectedRows()[0];

	const pauseCampaigns = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={() => {
						campaignsCrudStore.pauseCampaigns(selectedIds).then(() => {
							toastsStore.addToast({ msg: `Campaigns ${selectedIds} Paused`, type: DispatchLogsTypes.Success });
							tableStore.fetchReport({}, false);
						});
					}}>
					<EnablePauseCampaignsModal action={'Paused'} campaignIds={selectedIds} />
				</ActionModal>
			),
		});
	};

	const enableCampaigns = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={() => {
						campaignsCrudStore.enableCampaigns(selectedIds).then(() => {
							toastsStore.addToast({ msg: `Campaigns ${selectedIds} Enabled`, type: DispatchLogsTypes.Success });
							tableStore.fetchReport({}, false);
						});
					}}>
					<EnablePauseCampaignsModal action={'Enable'} campaignIds={selectedIds} />
				</ActionModal>
			),
		});
	};

	const deleteCampaigns = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Delete'}
					onAction={() => {
						const deleteId = selectedIds[0];
						campaignStore
							.getCrud()
							.delete(deleteId)
							.then(() => {
								dispatchLog({
									msg: `campaign: ${campaignSingleAction['campaign_name']}${deleteId} ${'Deleted'}`,
									type: DispatchLogsTypes.Success,
								});
								tableStore.fetchReport({}, false);
							})
							.catch(() => {
								dispatchLog({
									msg: `Delete ${deleteId} Failed`,
									type: DispatchLogsTypes.Error,
								});
							});
					}}>
					<DeleteModal label={`Are you sure you want to delete '${campaignSingleAction['campaign_name']}' campaign?`} />
				</ActionModal>
			),
		});
	};

	return (
		<Fragment>
			<TableRawActionButton text={'Enable'} onClick={enableCampaigns} icon={'play'} />
			<TableRawActionButton text={'Pause'} onClick={pauseCampaigns} icon={'pause-square'} />
			<TableRawActionButton
				text={'Edit'}
				to={routes.campaigns.edit(selectedIds[0])}
				icon={'edit-02'}
				disabled={selectedIds.length !== 1}
			/>

			<TableRawActionButton
				text={'Duplicate'}
				to={routes.campaigns.duplicate(selectedIds[0])}
				icon={'file-plus-01'}
				disabled={selectedIds.length !== 1}
			/>
			<TableRawActionButton text={'Delete'} onClick={deleteCampaigns} icon={'trash-02'} disabled={selectedIds.length !== 1} />
		</Fragment>
	);
});
