import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CampaignPageBreadcrumbs } from './campaign-page.breadcrumbs';
import { IAdminxStore } from '../../stores';

export const TestPageBreadcrumbs = observer(() => {
	const { testStore } = useStores<IAdminxStore>();
	const testCrudStore = testStore.getCrud();
	const campaignId = testCrudStore.getData().getCampaign()?.getId();

	return campaignId ? <CampaignPageBreadcrumbs campaignId={campaignId} /> : null;
});
