import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../stores';
import { TargetingModel } from '../../../../models/targeting.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Environment, Environments } from '../../../../enums/environment';
import { DeviceType } from '@monorepo/tools/src/lib/enums/device-types';
import { TargetingListOption } from './targeting-list.option';
import styles from './select-targeting.module.scss';
import { CampaignModel } from '../../../../models/campaign.model';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { SelectionList } from '@monorepo/base/src/components/selection-list/selection-list';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { PrimaryText } from '@monorepo/base/src/components/buttons/buttons';

interface ITargetOption {
	name: string;
	description: string;
	id: id;
	environment?: Environment;
	device_types?: Set<DeviceType>;
	op_systems?: Set<string>;
	browsers?: Set<string>;
	geos?: string[];
}
export const SelectTargeting: FC = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();

	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const formStore = campaignCrudStore.getFormStore();
	const targeting = campaign.getTargeting();
	const [targetList, setTargetList] = useState<TargetingModel[]>([]);
	const [sharedCampaigns, setSharedCampaigns] = useState<CampaignModel[]>([]);
	const targetingId = targeting?.getId();

	useEffect(() => {
		const getExistingTargeting = async () => {
			campaignCrudStore.getTargetsList().then(targeting => setTargetList(targeting.map(t => new TargetingModel(t))));
		};

		if (!targeting?.id) {
			formStore.clearErrors(['device_types', 'operating_systems', 'browsers', 'geos', 'targeting_id']);
		}

		getExistingTargeting();
	}, []);

	useEffect(() => {
		formStore.clearErrors(['device_types', 'operating_systems', 'browsers', 'geos', 'targeting_id']);
	}, [targetingId]);

	useEffect(() => {
		const fn = async () => {
			if (targeting?.id) {
				campaignCrudStore
					.getAmountOfSharedTarget(targeting.id)
					.then(campaigns => {
						setSharedCampaigns(campaigns);
					})
					.catch(() => null);
			}
		};
		fn();
	}, [targeting?.getId()]);

	const targetingOptions: ITargetOption[] = targetList.map(t => {
		const option = {
			...t,
			id: t?.id || -1,
			name: '' + (t?.id || ''),
			description: '' + (t?.id || ''),
		};

		return option;
	});

	const getDefault = function (): ITargetOption[] {
		const trgt = campaign.getTargeting();
		if (!trgt || !trgt?.getId()) {
			return [];
		}
		return [
			{
				...trgt,
				id: trgt?.id || -1,
				name: '' + (trgt?.id || ''),
				description: '' + (trgt?.id || ''),
			},
		];
	};

	const onTargetSelect = (targets: ITargetOption[]) => {
		const trgt = targetList.find(t => t.id === targets[0]?.id) || null;
		if (trgt) {
			targeting?.setBrowsers(trgt.getBrowsers());
			targeting?.setDeviceTypes((trgt.getDeviceTypes() as Set<DeviceType>) || new Set());
			targeting?.setEnvironment(trgt.getEnvironment() || Environments.All);
			targeting?.setGeos(trgt.getGeos());
			targeting?.setOperatingSystems(trgt.getOperatingSystems());
			const id = trgt?.getId();
			if (id) {
				targeting?.setId(id);
			}
		} else {
			campaign.setTargeting(new TargetingModel());
			setSharedCampaigns([]);
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.dropdown}>
				<SelectionList<ITargetOption>
					label={'Select targeting'}
					options={targetingOptions}
					onSelect={onTargetSelect}
					defaultOptions={getDefault()}
					isLoadingOptions={campaignStore.getListStore().getIsLoading()}
					isSelectionList={true}
					renderOption={option => <TargetingListOption option={option} />}
					isSingleSelection={true}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_targeting_select')] }}
					error={formStore.getErrors().get('targeting_id')?.getMsg()}
				/>
			</div>
			{sharedCampaigns.length ? (
				<div className={styles.sharedAmountWrapper}>
					<Tooltip
						className={styles.sharedAmountTooltip}
						content={
							sharedCampaigns.length
								? sharedCampaigns.map(c => c.name).join(', ')
								: campaign.getTargeting()?.getId()
								? 'No Campaigns are sharing this targeting'
								: 'Select a targeting to view campaigns that are sharing it'
						}
						isFullWidth={true}>
						<PrimaryText icon={'link-03'} onClick={() => null} className={`${styles.sharedText}`}>
							Shared by {sharedCampaigns.length} campaigns
						</PrimaryText>
					</Tooltip>
				</div>
			) : (
				''
			)}
		</div>
	);
});
