import { makeAutoObservable } from 'mobx';

export interface IRules {
	max_loss_per_key?: number;
	max_wins_per_click?: number;
	max_wins_per_conversion?: number;
	max_clicks_per_conversion?: number;
	use_optimal_gain_for_max_loss_calculation?: boolean;
	max_cpm?: number;
	use_domain_budget_limit?: boolean;
	allow_multiple_sales?: boolean;
}

export const defaultCampaignRuleValues = {
	maxLoss: 0,
	maxWinsPerClick: 500,
	maxWinsPerConversion: 1000,
	maxClicksPerConversion: 1000,
	maxCpm: 1000,
	allow_multiple_sales: true,
};

export class RulesModel implements IRules {
	max_loss_per_key?: number;
	max_wins_per_click?: number;
	max_wins_per_conversion?: number;
	max_clicks_per_conversion?: number;
	use_optimal_gain_for_max_loss_calculation?: boolean;
	use_domain_budget_limit?: boolean;
	max_cpm?: number;
	allow_multiple_sales?: boolean;

	constructor(rules?: IRules) {
		this.max_loss_per_key = rules?.max_loss_per_key || defaultCampaignRuleValues.maxLoss;
		this.max_wins_per_click = rules?.max_wins_per_click || defaultCampaignRuleValues.maxWinsPerClick;
		this.max_wins_per_conversion = rules?.max_wins_per_conversion || defaultCampaignRuleValues.maxClicksPerConversion;
		this.max_clicks_per_conversion = rules?.max_clicks_per_conversion || defaultCampaignRuleValues.maxClicksPerConversion;
		this.use_optimal_gain_for_max_loss_calculation = rules?.use_optimal_gain_for_max_loss_calculation;
		this.use_domain_budget_limit = rules?.use_domain_budget_limit;
		this.max_cpm = rules?.max_cpm || defaultCampaignRuleValues.maxCpm;
		this.allow_multiple_sales = rules?.allow_multiple_sales ?? defaultCampaignRuleValues.allow_multiple_sales;

		makeAutoObservable(this);
	}

	public getFormData(): IRules {
		return {
			max_loss_per_key: this.max_loss_per_key,
			max_wins_per_click: this.max_wins_per_click,
			max_wins_per_conversion: this.max_wins_per_conversion,
			max_clicks_per_conversion: this.max_clicks_per_conversion,
			use_optimal_gain_for_max_loss_calculation: this.use_optimal_gain_for_max_loss_calculation,
			use_domain_budget_limit: this.use_domain_budget_limit,
			max_cpm: this.max_cpm,
			allow_multiple_sales: this.allow_multiple_sales,
		};
	}

	public getMaxLoss(): number | undefined {
		return this.max_loss_per_key;
	}

	public setMaxLoss(max_loss_per_key: number): void {
		this.max_loss_per_key = max_loss_per_key;
	}

	public getMaxWinsPerClick(): number | undefined {
		return this.max_wins_per_click;
	}

	public setMaxWinsPerClick(max_wins_per_click: number): void {
		this.max_wins_per_click = max_wins_per_click;
	}

	public getMaxWinsPerConversion(): number | undefined {
		return this.max_wins_per_conversion;
	}

	public setMaxWinsPerConversion(max_wins_per_conversion: number): void {
		this.max_wins_per_conversion = max_wins_per_conversion;
	}

	public getMaxClicksPerConversion(): number | undefined {
		return this.max_clicks_per_conversion;
	}

	public setMaxClicksPerConversion(max_clicks_per_conversion: number): void {
		this.max_clicks_per_conversion = max_clicks_per_conversion;
	}

	public getMaxCPM(): number | undefined {
		return this.max_cpm;
	}

	public setMaxCPM(max_cpm: number): void {
		this.max_cpm = max_cpm;
	}

	public getAllowMultipleSales(): boolean | undefined {
		return this.allow_multiple_sales;
	}

	public setAllowMultipleSales(allow_multiple_sales: boolean): void {
		this.allow_multiple_sales = allow_multiple_sales;
	}

	public getUseOptimalGainForMaxLossCalculation(): boolean | undefined {
		return this.use_optimal_gain_for_max_loss_calculation;
	}

	public setUseOptimalGainForMaxLossCalculation(use_optimal_gain_for_max_loss_calculation: boolean): void {
		this.use_optimal_gain_for_max_loss_calculation = use_optimal_gain_for_max_loss_calculation;
	}

	public getUseDomainBudgetLimit(): boolean | undefined {
		return this.use_domain_budget_limit;
	}

	public setUseDomainBudgetLimit(use_domain_budget_limit: boolean): void {
		this.use_domain_budget_limit = use_domain_budget_limit;
	}
}
