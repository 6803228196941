import { ICid, CidModel } from '../models/cid.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';

export const CidsApi = {
	create: async (cid: ICid, options?: IRequestOptions): Promise<CidModel> => {
		return ask.post<ICid>(endpoints.cids.create(), cid, options);
	},

	edit: (): Promise<null> => {
		throw new Error('Not implemented');
	},

	list: async (): Promise<CidModel[]> => {
		throw new Error('Not implemented');
	},

	get: async (): Promise<CidModel> => {
		throw new Error('Not implemented');
	},

	delete: async () => {
		throw new Error('Not implemented');
	},
};
