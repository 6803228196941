import { ReactNode, useRef, useState } from 'react';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import styles from './quick-actions-menu.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

interface IEntityActionsMenu {
	children: ReactNode;
	toggler?: ReactNode;
	iconSize?: string;
	icon?: string;
	forceShow?: boolean;
	classes?: {
		wrapper?: string;
		actionsMenu?: string;
	};
}

export { QuickActionButton } from './quick-action-button';
export function QuickActionsMenu(props: IEntityActionsMenu): JSX.Element {
	const { children, toggler, iconSize = '22px', icon = 'arrow-dropdown', forceShow, classes } = props;
	const [isQuickActionsMenuOpen, setEntityActionsMenuOpen] = useState<boolean>(false);
	const entityActionsRef = useRef(null);

	useOnClickOutside(entityActionsRef, () => {
		setEntityActionsMenuOpen(false);
	});

	return (
		<div ref={entityActionsRef} className={styles.wrapper}>
			<div onClick={() => setEntityActionsMenuOpen(!isQuickActionsMenuOpen)} className={styles.quickActionsTrigger}>
				{toggler || (
					<Icon
						className={`tableQuickActions ${forceShow ? 'forceShow' : ''}`}
						isMFP={true}
						size={iconSize}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'quick_action_button')] }}>
						{icon}
					</Icon>
				)}
			</div>
			<Menu isOpen={isQuickActionsMenuOpen} unstyled={true}>
				<div className={`${styles.actionsMenu} ${classes?.actionsMenu}`}>{children}</div>
			</Menu>
		</div>
	);
}
