import { CreateJob } from '../../pages/jobs/create/create-job';
import { EditJob } from '../../pages/jobs/edit/edit-job';
import { Jobs } from '../../pages/jobs/index/jobs.page';
import routes from '../routes/index.routes';

export const JobsRouter = [
	{
		path: routes.jobs.index(),
		element: <Jobs />,
	},
	{
		path: routes.jobs.create(),
		element: <CreateJob />,
	},
	{
		path: routes.jobs.edit(':jobId'),
		element: <EditJob />,
	},
];
