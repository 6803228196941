import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '@monorepo/tools/src/lib/hooks/utils/use-debounce';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { IndexLinerButton } from '../action-liner/liner-buttons';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import styles from './search-filter.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

const theme = getTheme();

interface ISearchFilter {
	onSetGlobalFilter: (arg0: string) => void;
	globalFilter: string;
	debugProps?: IDebugProps;
}

export const SearchFilter = (props: ISearchFilter) => {
	const { onSetGlobalFilter, globalFilter, debugProps } = props;
	const [isSearchOpen, setSearchOpen] = useState<boolean>(Boolean(globalFilter));
	const inputRef = useRef<HTMLInputElement>(null);
	const [term, setTerm] = useState<string>(globalFilter);
	const debounceTerm = useDebounce<string>(term, 250);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		if (debounceTerm !== undefined) {
			onSetGlobalFilter(debounceTerm);
		}
	}, [debounceTerm]);

	const onSearchButton = () => {
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.INDEX_PAGE.ACTION_LINER.SEARCH.TOGGLE,
			label: 'Action liner search button',
			value: window.location.href,
		});
		setSearchOpen(!isSearchOpen);
	};

	return (
		<div className={styles.wrapper}>
			{isSearchOpen ? (
				<div className={styles.searchBox}>
					<Icon isMFP={true} className={styles.searchIcon} color={theme.primaryColor} size={'18px'}>
						search-sm
					</Icon>
					<input
						ref={inputRef}
						value={term || ''}
						placeholder={'Search'}
						className={styles.input}
						autoFocus
						{...generateDataAttrs(suffixToDataAttr('_search_input', dataAttrs))}
						onChange={e => {
							setTerm(e.target.value);
						}}
					/>
					<Icon
						className={styles.closeIcon}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'close_search_button')] }}
						color={'gray'}
						size={'16px'}
						onClick={() => {
							sendGtagEvent({
								action: TrackingActions.Click,
								category: EVENTS.CLICK.INDEX_PAGE.ACTION_LINER.SEARCH.CLOSE,
								label: 'Action liner close search',
								value: window.location.href,
							});
							setTerm('');
							setSearchOpen(false);
						}}
						isMFP={true}>
						x-close
					</Icon>
				</div>
			) : (
				<IndexLinerButton>
					<SecondaryText
						icon={'search-sm'}
						iconColor={theme.actionLinerActionIconsColor}
						iconSize={'18px'}
						debugProps={{ dataAttrs: suffixToDataAttr('_search', dataAttrs) }}
						onClick={onSearchButton}>
						Search
					</SecondaryText>
				</IndexLinerButton>
			)}
		</div>
	);
};
