import { Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ReportFilterType } from '../../../../modules/components/report-table/stores/data-stores/old-report.types';
import ReportTable from '../../../../modules/components/report-table/table-component';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../modules/stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { useTableStore } from '../../../../modules/components/report-table/stores/use-table-store.hook';
import { useTableStoreMetrics } from '../../../../modules/hooks/tools/use-repr-metrics';

export const DomainsCampaignsPage = observer(() => {
	const { domainsStore } = useStores<IAdminxStore>();
	const { domainName } = useParams();
	const { campaignsPerformance } = domainsStore;

	const hiddenFilters = domainName
		? [{ column: 'domain', filterType: ReportFilterType.EQUALS, value: [domainName], inverted: false }]
		: undefined;

	useTableStore({ reportTableStore: campaignsPerformance, hiddenFilters });

	const chartProps = useTableStoreMetrics(campaignsPerformance);

	return (
		<div>
			<Pagebar>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full>
				<Chart isLoading={campaignsPerformance.dataStore.getIsLoading()} {...chartProps} />
			</Card>
			<ReportTable tableStore={campaignsPerformance} />
		</div>
	);
});
