import { FilterStore } from './filter.store';
import { VisibilityState, SortingState } from '@tanstack/react-table';
// import { observable, action, makeObservable } from 'mobx';
import { makeAutoObservable } from 'mobx';

interface ITableStore {
	filterTerm: string;
	pageIndex: number;
	pageSize: number;
	columnsVisibility: VisibilityState;
	filters: Map<string, FilterStore>;
}

// {
// 	theme: '123',
// 	dates: [['primary']: ['13/06/34']],
// 	pageSettings: {
// 		'moshe/123': {
// 			pageIndex: 1,
// 			pageSize: 30,
// 		},
// 	}
// }

export class TableStore implements ITableStore {
	filterTerm = '';
	pageIndex = 0;
	pageSize = 100;
	columnsVisibility: VisibilityState = {};
	defaultColumnsVisibility: VisibilityState = {};
	filters: Map<string, FilterStore> = new Map();
	sortingBy: SortingState = [];

	constructor() {
		// makeObservable(this, {
		// 	pageSize: observable,
		// 	pageIndex: observable,
		// 	filterTerm: observable,
		// 	columnsVisibility: observable,
		// 	defaultColumnsVisibility: observable,
		// 	filters: observable,
		// 	setPageSize: action,
		// 	setPageIndex: action,
		// 	setFilterTerm: action,
		// 	setColumnVisibility: action,
		// 	setDefaultColumnVisibility: action,
		// 	setFiltersStore: action,
		// });
		makeAutoObservable(this);
		// this.updateInStorage();
	}

	setFilterTerm(filterTerm: string): void {
		this.filterTerm = filterTerm;
		// this.updateInStorage({ filterTerm });
	}

	getFilterTerm(): string {
		return this.filterTerm;
	}

	setPageIndex(pageIndex: number): void {
		this.pageIndex = pageIndex;
		// this.updateInStorage({ pageIndex });
	}

	getPageIndex(): number {
		return this.pageIndex;
	}

	resetTable(): void {
		this.setFilterTerm('');
		this.setPageIndex(0);
		this.setPageSize(20);
		// this.updateInStorage({ pageIndex: 0, filterTerm: '', pageSize: 20 });
	}

	setPageSize(pageSize: number): void {
		this.pageSize = pageSize;
		// this.updateInStorage({ pageSize });
	}

	getPageSize(): number {
		return this.pageSize;
	}

	setColumnVisibility(columnsVisibility: VisibilityState): void {
		this.columnsVisibility = columnsVisibility;
		// this.updateInStorage({ pageSize });
	}

	getColumnVisibility(): VisibilityState {
		return this.columnsVisibility;
	}

	setDefaultColumnVisibility(defaultColumnsVisibility: VisibilityState): void {
		this.defaultColumnsVisibility = defaultColumnsVisibility;
	}

	getDefaultColumnVisibility(): VisibilityState {
		return this.defaultColumnsVisibility;
	}

	getFiltersStore(key: string) {
		return this.filters.get(key);
	}

	setFiltersStore(key: string, filterStore: FilterStore) {
		return this.filters.set(key, filterStore);
	}

	setSortingBy(sortingBy: SortingState) {
		this.sortingBy = sortingBy;
	}

	getSortingBy() {
		return this.sortingBy;
	}
}
