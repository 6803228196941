export class BidderRoutes {
	private baseRoute = 'bidder';

	campaigns() {
		return `/${this.baseRoute}/campaigns`;
	}

	createCampaign() {
		return `/${this.baseRoute}/campaigns/create`;
	}

	editCampaign(id: string) {
		return `/${this.baseRoute}/campaigns/${id}/edit`;
	}
}
