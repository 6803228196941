import { Fragment, useEffect, useState } from 'react';
import { FilterApplyAndCancelButtons, FilterOptionProps } from './table-filters';
import styles from './table-filters.module.scss';
import { Dropdown } from '@monorepo/base/src/components/dropdown/dropdown';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import dayjs from 'dayjs';
import { FilterOptions } from './filter-options';
import { IFilterDefinition, IFilterOption } from './filters.types';

export const SelectFromDataSourceFilterOption = ({ column, onApply, onCanceled, initialState }: FilterOptionProps) => {
	const filter: IFilterDefinition | undefined = column.availableFilters;
	if (!filter?.selectFromDataSource) {
		console.error('TableFilters: selectFromDataSource are not defined');
		return null;
	}
	const [selectedAction, setAction] = useState(
		filter?.actions.find(action => action.value === initialState?.filterType) || filter?.actions[0]
	);
	const [options, setOptions] = useState<(string | number)[]>(initialState?.value || []);
	const availableFilterActions = filter.actions || [];
	const [availableFilterOptions, setAvailableFilterOptions] = useState<IFilterOption[]>([]);

	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const store = filter.selectFromDataSource?.store;

	useEffect(() => {
		const callback = filter.selectFromDataSource?.cb;
		if (!store || !callback) {
			return;
		}
		store
			?.fetchReport({
				params: {
					periodEnd: primaryToDate,
					periodStart: primaryFromDate,
				},
			})
			.then(() => {
				const listOptions = store.getReportData().map(callback);
				setAvailableFilterOptions(listOptions);
			})
			.catch(() => {
				console.error('fetching options faild');
			});
	}, []);
	const handleApply = () => {
		if (!selectedAction) {
			return;
		}
		const filterValue = options || [''];
		onApply({ filterType: selectedAction.value, value: filterValue, column: column.name });
	};

	return (
		<Fragment>
			<div className={styles.filterTitle}>{column.header}</div>
			<div className={styles.filterActionPicker}>
				<Dropdown
					required={true}
					options={availableFilterActions.map(x => x.label)}
					onSelect={val =>
						val
							? setAction(prev => {
									const action = availableFilterActions.find(action => action.label === Array.from(val)[0]);
									if (!action) {
										return prev;
									}
									return action;
							  })
							: val
					}
					autocomplete={availableFilterActions.length > 10}
					disabled={availableFilterActions.length === 1}
					defaultOption={selectedAction?.label || ''}
					autocompletePlaceholder={'Search action'}
				/>
			</div>

			<FilterOptions
				isLoading={store.getIsLoading()}
				options={availableFilterOptions}
				placeholder={`Search ${column.header}`}
				value={options}
				onEnumFilterOption={setOptions}
			/>

			<FilterApplyAndCancelButtons
				onApply={handleApply}
				onCanceled={onCanceled}
				disableApply={
					!selectedAction ||
					!options ||
					(selectedAction.value === initialState?.filterType && JSON.stringify(options) === JSON.stringify(initialState.value))
				}
			/>
		</Fragment>
	);
};
