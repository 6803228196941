import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { CreativeGroupModel, ICreativeGroup } from '../../models/creative-group.model';
import routes from '../../routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityHeaders, IMergedHeadersReturn } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { IPerformanceItem } from '../../models/performance.model';
import { IAdminxStore } from '../../stores';
import { useEffect } from 'react';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { createColumnHelper } from '@tanstack/react-table';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';

const columnHelper = createColumnHelper<ICreativeGroup & IPerformanceItem>();

export const useCreativeGroupHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<CreativeGroupModel> => {
	const { includeColumns = {}, EntityActions, onEdit, summary } = props;
	const { advertiser = true } = includeColumns;
	const { sidebarStore, settingsStore } = useStores<IAdminxStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;

	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				design: true,
				id: true,
				advertiserName: true,
				advertiserId: true,
				auctions: true,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: true,
				roas: true,
				ctr: true,
				lpCvr: true,
				impressions: false,
				optCost: false,
				optGain: false,
				gain: false,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	const renderHierachyColumns = () => {
		const columns = [];

		if (advertiser) {
			columns.push(
				columnHelper.accessor('advertiserName', {
					header: 'Advertiser',
					cell: info => {
						const { row } = info;
						if (!row.getValue('advertiserId')) {
							return <span>{row.getValue('advertiserName')}</span>;
						}
						return (
							<TableLink
								to={routes.advertisers.campaigns(row.getValue('advertiserId'))}
								onClick={() => {
									sidebarStore.setSidebarOpen(false);
								}}>
								{row.getValue('advertiserName')}
							</TableLink>
						);
					},
				})
			);
			columns.push(
				columnHelper.accessor('advertiserId', {
					header: 'Advertiser ID',
				})
			);
		}

		return columns;
	};

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const creativeGroup = new CreativeGroupModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<QuickActionsMenu>
								<EntityActions entity={creativeGroup} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('design', {
				header: 'Design',
				cell: info => {
					const { row } = info;
					const toEdit = (() => {
						if (onEdit) {
							return onEdit(row.getValue('id'));
						}
						return routes.creativeGroups.edit(row.getValue('id'));
					})();
					return (
						<TableLink
							to={routes.creativeGroups.creatives(row.getValue('id'))}
							toEdit={toEdit}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}
							onClick={() => {
								if (!toEdit) {
									sidebarStore.setSidebarOpen(false);
								}
							}}>
							{row.getValue('design')}
						</TableLink>
					);
				},
			}),
			...renderHierachyColumns(),
			...mergedPerforamnceHeaders,
		],
	};
};
