import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './edit-job.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { JobForm } from '../../../modules/components/forms/job/job.form';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { useJobGet } from '../../../modules/hooks/apis/use-job-get';
import { IAdminxStore } from '../../../modules/stores';
import { Statuses } from '../../../modules/enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

export const EditJob = observer(() => {
	const { jobStore } = useStores<IAdminxStore>();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const { dispatchLog } = useLogs();

	const jobCrudStore = jobStore.getCrud();
	const job = jobCrudStore.getData();

	const navigate = useNavigateParams();
	const location = useLocation();
	const params = useParams();

	useEffect(() => {
		jobCrudStore.reset();
	}, [location]);

	if (params.jobId) {
		useJobGet({ id: params.jobId });
	}

	const name = jobCrudStore.getData().getName();
	const formStore = jobCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = jobCrudStore.getIsLoading();
	const isSuccess = jobCrudStore.getIsSuccess();
	const error = jobCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(-1);
			dispatchLog({
				msg: `Job ${name} was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			jobCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onEditJob = () => {
		setFormClicked(true);
		jobStore.getCrud().edit();
	};

	return (
		<Fragment>
			<Disable is={isLoading}>
				<Page className={isLoading ? styles.loadingPage : ''}>
					<Pagebar>
						<PagebarTitle>
							<div className={styles.title}>Edit job</div>
							<SpacerX />
							<Toggle
								label={job.getIsActive() === true ? Statuses.Active : Statuses.Paused}
								onChange={status => job.setIsActive(status ? true : false)}
								defaultValue={job.getIsActive() === true}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'job_status')] }}
							/>
						</PagebarTitle>
					</Pagebar>
					<JobForm />
				</Page>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					<BottomBarActions>
						<PrimaryButton
							onClick={() => onEditJob()}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'edit_job_button')] }}
							isRotateIcon={isLoading}
							icon={isLoading ? 'loading-01' : 'send-03'}>
							Edit
						</PrimaryButton>
					</BottomBarActions>
				</BottomBar>
			</Disable>
		</Fragment>
	);
});
