import { ReportFilterType } from './old-report.types';

export enum SortOrderEnum {
	ASC = 'asc',
	DESC = 'desc',
}

export enum FilterTypeEnum {
	// Built-in filters
	EQUALS = 'EQUALS',
	INCLUDES = 'INCLUDES',
	CONTAINS = 'CONTAINS',
	GREATER_THAN = 'GREATER_THAN',
	LESS_THAN = 'LESS_THAN',
	// inverted filters
	NOT_EQUALS = 'NOT_EQUALS',
	NOT_INCLUDES = 'NOT_INCLUDES',
	NOT_CONTAINS = 'NOT_CONTAINS',
	NOT_GREATER_THAN = 'NOT_GREATER_THAN',
	NOT_LESS_THAN = 'NOT_LESS_THAN',
}

export const FilterTypeOld2NewMap = new Map<ReportFilterType, FilterTypeEnum>([
	[ReportFilterType.CONTAINS, FilterTypeEnum.CONTAINS],
	[ReportFilterType.EQUALS, FilterTypeEnum.EQUALS],
	[ReportFilterType.NOT_EQUALS, FilterTypeEnum.NOT_EQUALS],
	[ReportFilterType.INCLUDE, FilterTypeEnum.INCLUDES],
	[ReportFilterType.GREATER, FilterTypeEnum.GREATER_THAN],
	[ReportFilterType.LESS, FilterTypeEnum.LESS_THAN],
]);

export const FilterTypeNew2OldMap = new Map<FilterTypeEnum, ReportFilterType>([
	[FilterTypeEnum.CONTAINS, ReportFilterType.CONTAINS],
	[FilterTypeEnum.EQUALS, ReportFilterType.EQUALS],
	[FilterTypeEnum.NOT_EQUALS, ReportFilterType.NOT_EQUALS],
	[FilterTypeEnum.INCLUDES, ReportFilterType.INCLUDE],
	[FilterTypeEnum.GREATER_THAN, ReportFilterType.GREATER],
	[FilterTypeEnum.LESS_THAN, ReportFilterType.LESS],
]);

export const InvertedReportFilterTypesMap = new Map<FilterTypeEnum, FilterTypeEnum>([
	[FilterTypeEnum.NOT_CONTAINS, FilterTypeEnum.CONTAINS],
	[FilterTypeEnum.NOT_EQUALS, FilterTypeEnum.EQUALS],
	[FilterTypeEnum.NOT_INCLUDES, FilterTypeEnum.INCLUDES],
	[FilterTypeEnum.NOT_GREATER_THAN, FilterTypeEnum.GREATER_THAN],
	[FilterTypeEnum.NOT_LESS_THAN, FilterTypeEnum.LESS_THAN],
]);

export const InvertedReportFilterTypesBackMap = new Map<FilterTypeEnum, FilterTypeEnum>([
	[FilterTypeEnum.CONTAINS, FilterTypeEnum.NOT_CONTAINS],
	[FilterTypeEnum.EQUALS, FilterTypeEnum.NOT_EQUALS],
	[FilterTypeEnum.INCLUDES, FilterTypeEnum.NOT_INCLUDES],
	[FilterTypeEnum.GREATER_THAN, FilterTypeEnum.NOT_GREATER_THAN],
	[FilterTypeEnum.LESS_THAN, FilterTypeEnum.NOT_LESS_THAN],
]);

export interface ReportInputFilterItemInterface {
	column: string;
	filterType: FilterTypeEnum;
	value: string[];
	inverted: boolean;
}

export interface ReportInputOrderItemInterface {
	column: string;
	sortOrder: SortOrderEnum;
}

export interface IAdminxReporterParams {
	reportName: string;
	periodStart: string;
	periodEnd: string;
	groupBys: string[];
	metrics: string[];
	filters: ReportInputFilterItemInterface[];
	start: number;
	limit: number;
	bypassCache: boolean;
	sort: ReportInputOrderItemInterface[];
	segments?: string[];
	includeDateGraph?: boolean;
	exportSchema?: 'CSV';
}

export const generateReportParams = (): IAdminxReporterParams => ({
	reportName: '',
	periodStart: '',
	periodEnd: '',
	groupBys: [],
	metrics: [],
	filters: [],
	segments: [],
	start: 0,
	limit: 100,
	bypassCache: false,
	sort: [],
});

export interface IAdminxReporterResponse<T> {
	reportName: string;
	total: T;
	graphData: T[];
	rows: T[];
	segments: T[];
	isCached: boolean;
	missingRows: boolean;
}
