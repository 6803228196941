import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { SelectionList } from '@monorepo/base/src/components/selection-list/selection-list';
import { CreativeModel } from '../../../../models/creative.model';
import { IAdminxStore } from '../../../../stores';
import { SelectionListOption } from '../../../selection-list-option/selection-list-option';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import styles from './campaign-creative.module.scss';
import { ButtonGroup, GButton } from '@monorepo/base/src/components/buttons/group/button-group';
import { DropdownNew } from '@monorepo/base/src/components/dropdown-new/dropdown-new';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BannerSize } from '../../../../models/campaign.model';
import { WhiteBlackLists } from '../../../../enums/white-black-list-enum';

interface IAttachCreativeOption {
	name: string;
	description: string;
}

export const CampaignCreative: FC = observer(() => {
	const { campaignStore, creativeStore } = useStores<IAdminxStore>();
	const campaign = campaignStore.getCrud().getData();
	const creativeList = creativeStore.getListStore().getList();
	const creatives = campaign.getCreatives() || [];
	const bannerSizesOptions = campaignStore
		.getInputOptions()
		.getBannerSizes()
		.map(size => {
			return {
				label: `${size.width}x${size.height}`,
				value: size.id as id,
			};
		});
	const blackListedSizes = campaign.getBlacklistedSizes()?.map(black_list_size => {
		return black_list_size.id;
	}) as id[] | undefined;

	const whiteListedSizes = campaign.getWhitelistedSizes()?.map(white_list_size => {
		return white_list_size.id;
	}) as id[] | undefined;

	const selectedCreatives: IAttachCreativeOption[] = creatives?.map(_creative => {
		return {
			name: `${_creative?.getBrand()} - ${_creative?.getId()}` || '',
			description: `${_creative?.getCreativeGroup()?.getAdvertiser()?.getName()} - ${_creative?.getCreativeGroup()?.getDesign()}`,
		};
	});

	const creativeOptions = useMemo(
		() =>
			creativeList
				.filter(
					creative =>
						creative.getCreativeGroup()?.getAdvertiser()?.getId() === campaignStore.getCrud().getData().getAdvertiser()?.getId()
				)
				.map(creative => {
					return {
						name: `${creative?.getBrand()} - ${creative?.getId()}` || '',
						description: `${creative?.getCreativeGroup()?.getAdvertiser()?.getName()} - ${creative
							?.getCreativeGroup()
							?.getDesign()}`,
					};
				}),
		[creatives, creativeList]
	);

	const onSizeSelect = (sizesId: id[]) => {
		const selectedSizes: BannerSize[] = [];

		sizesId.forEach(sizeId => {
			const selectedSize = campaignStore
				.getInputOptions()
				.getBannerSizes()
				.find(bannerSize => bannerSize.id === sizeId);
			if (selectedSize) {
				selectedSizes.push(selectedSize);
			}
		});

		return selectedSizes;
	};

	const onCreativeSelect = (options: IAttachCreativeOption[]) => {
		const selectedCreatives = options.map(option =>
			creativeList.find(creative => option.name === `${creative.getBrand()} - ${creative.getId()}`)
		);

		const creatives: CreativeModel[] = [];
		selectedCreatives.forEach(creative => {
			creatives.push(new CreativeModel(creative));
		});

		campaignStore.getCrud().getData().setCreatives(creatives);
		campaignStore.getCrud().setCreativeListEdited();
	};

	return (
		<Card
			id={'campaignCreatives'}
			title={'Creatives'}
			icon={'layers-three-02'}
			className={styles.cardSpacer}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_creatives_section')] }}>
			<ButtonGroup
				defaultValue={campaign.getBannerListType()}
				title={'Banner sizes black/white list'}
				onChange={(setValues: Set<string>) => campaign.setBannerListType(setValues.values().next().value)}>
				<GButton
					icon={'shield-off'}
					text={WhiteBlackLists.Blacklist}
					value={WhiteBlackLists.Blacklist}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'size_black_list')] }}
				/>
				<GButton
					icon={'shield-plus'}
					text={WhiteBlackLists.Whitelist}
					value={WhiteBlackLists.Whitelist}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'size_white_list')] }}
				/>
			</ButtonGroup>
			{campaign.getBannerListType() === WhiteBlackLists.Blacklist && (
				<DropdownNew
					classNames={{ dropdownWrapper: styles.dropdownWrapper, parentSelectWrapper: styles.parentSelectWrapper }}
					options={bannerSizesOptions}
					defaultValues={blackListedSizes}
					multi={true}
					autocomplete={true}
					autocompletePlaceholder={'Search Size'}
					label={WhiteBlackLists.Blacklist}
					onSelect={blacklistedSizesId => {
						const blacklistedSizes = onSizeSelect(blacklistedSizesId);
						campaign.setBlacklistedSizes(blacklistedSizes);
					}}
				/>
			)}
			{campaign.getBannerListType() === WhiteBlackLists.Whitelist && (
				<DropdownNew
					classNames={{ dropdownWrapper: styles.dropdownWrapper, parentSelectWrapper: styles.parentSelectWrapper }}
					options={bannerSizesOptions}
					defaultValues={whiteListedSizes}
					multi={true}
					autocomplete={true}
					autocompletePlaceholder={'Search Size'}
					label={WhiteBlackLists.Whitelist}
					onSelect={whitelistedSizesId => {
						const whitelistedSizes = onSizeSelect(whitelistedSizesId);
						campaign.setWhitelistedSizes(whitelistedSizes);
					}}
				/>
			)}
			<SelectionList<IAttachCreativeOption>
				label={'Creatives'}
				options={creativeOptions}
				defaultOptions={selectedCreatives}
				onSelect={(creative: IAttachCreativeOption[]) => onCreativeSelect(creative)}
				isLoadingOptions={campaignStore.getListStore().getIsLoading()}
				isSelectionList={true}
				renderOption={option => <SelectionListOption option={option} />}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_creatives_select')] }}
			/>
		</Card>
	);
});
