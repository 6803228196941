export enum StringActions {
	Contains = 'contains',
	DoesNotContain = 'does not contain',
	Equals = 'equal to',
	DoesNotEqual = 'does not equal',
	Include = 'include',
}
export type stringAction =
	| StringActions.Contains
	| StringActions.DoesNotContain
	| StringActions.Equals
	| StringActions.DoesNotEqual
	| StringActions.Include;

export type enumAction = StringActions.Equals | StringActions.DoesNotEqual;

export const toLowerCaseAndTrim = (word: string | undefined) => {
	if (!word) {
		return '';
	}

	// TODO
	word = word.replaceAll('-', '').replace(/\s+/g, '-').toLowerCase().trim();
	return word;
};

export const randomString = (length: number) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const getFirstStringUrl = () => {
	return window.location.pathname.replace(/^\/([^/]*).*$/, '$1');
};
export const validateEmail = (email: string): boolean => {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return regex.test(email);
};

export const passwordValidation = (password: string) => {
	return {
		lengthError: password.length < 8,
		integerError: !/[0-9]/.test(password),
		letterCaseError: !/[a-z]/.test(password) || !/[A-Z]/.test(password),
		specialCharacterError: !/[!@#$%^&*()_+\-={};':"|,.<>\/?]+/.test(password),
	};
};

export const stringToTitleCase = (value: string) =>
	value
		.replace(/([A-Z][a-z]|[A-Z]+(?=[A-Z]|$))/g, ' $1')
		.replace(/./, m => m.toUpperCase())
		.trim();

export const stringFuncs = {
	[StringActions.Contains]: (string: string, searchValue: string) =>
		string.toString().toLowerCase().includes(searchValue.toString().toLowerCase()),
	[StringActions.DoesNotContain]: (string: string, searchValue: string) =>
		!string.toString().toLowerCase().includes(searchValue.toString().toLowerCase()),
	[StringActions.DoesNotEqual]: (string: string, searchValue: string) =>
		string.toString().toLowerCase() !== searchValue.toString().toLowerCase(),
	[StringActions.Equals]: (string: string, searchValue: string) =>
		string.toString().toLowerCase() === searchValue.toString().toLowerCase(),
	[StringActions.Include]: (string: string, searchValue: string) =>
		string.toString().toLowerCase().includes(searchValue.toString().toLowerCase()),
};

export const enumFuncs = {
	[StringActions.Equals]: (options: string[], searchValue: string) =>
		options.map(option => option.toString().toLowerCase()).includes(searchValue.toString().toLowerCase()),
	[StringActions.DoesNotEqual]: (options: string[], searchValue: string) =>
		!options.map(option => option.toString().toLowerCase()).includes(searchValue.toString().toLowerCase()),
};

export const getStringAfterLastSlash = (inputString: string) => {
	const parts = inputString.split('/');
	if (parts.length > 1) {
		return parts[parts.length - 1];
	} else {
		return inputString;
	}
};

export const getStringBetweenParentheses = (inputString: string) => {
	const startIndex = inputString.indexOf('(');
	if (startIndex === -1) {
		throw new Error('No opening brace found.');
	}

	let braceCount = 1;
	let endIndex = startIndex + 1;

	while (endIndex < inputString.length && braceCount > 0) {
		if (inputString[endIndex] === '(') {
			braceCount++;
		} else if (inputString[endIndex] === ')') {
			braceCount--;
		}
		endIndex++;
	}

	if (braceCount !== 0) {
		throw new Error('Mismatched braces.');
	}

	return inputString.substring(startIndex + 1, endIndex - 1);
};

export const removeParenthesesAndTrim = (inputString: string) => {
	let outputString = inputString.replace(/\([^)]*\)/g, ''); // Remove parentheses and their contents
	outputString = outputString.trim(); // Trim whitespace from the beginning and end
	return outputString;
};

export const capitalizeFirstLetter = (input: string): string => {
	return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};
