import { generateStorageKey } from './helper';
import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { ReactElement } from 'react';
import { dispatchLogType, ILog } from '../hooks/use-logs';

interface IToastAdd {
	onClose?: () => void;
	children?: ReactElement | string;
	msg?: string;
	type?: dispatchLogType;
}

export interface IToastItem extends IToastAdd {
	id: number;
}

interface IToasts {
	toasts: IToastAdd[];
	toastsOnNav: ILog[];
}

export class ToastsStore implements IToasts {
	toasts: IToastItem[] = [];
	toastsOnNav: ILog[] = [];
	id = 1;

	constructor() {
		makeAutoObservable(this, {
			toasts: observable,
			setToasts: action,
			removeToast: action,
			addToastOnNav: action,
		});

		makePersistable(this, {
			name: generateStorageKey('toastsstore'),
			properties: ['toastsOnNav'],
			storage: window.sessionStorage,
		});
	}

	getToasts(): IToastItem[] {
		return this.toasts;
	}

	addToast(toast: IToastAdd) {
		if (!toast) {
			throw new Error('No toast added to addToast function');
		}
		// Render toasts in the next render cycle
		setTimeout(() => {
			const toasts = [
				...this.toasts,
				{
					id: this.id++,
					...toast,
				},
			];
			this.setToasts(toasts);
		});
	}

	setToasts(toasts: IToastItem[]) {
		this.toasts = toasts;
	}

	removeToast(id: number) {
		this.toasts = this.toasts.filter(toast => toast.id !== id);
	}

	addToastOnNav(toastOnNav: ILog): void {
		this.toastsOnNav.push(toastOnNav);
	}

	getToastOnNav(): ILog[] {
		return this.toastsOnNav;
	}

	resetToastsOnNav(): void {
		this.toastsOnNav = [];
	}

	getId(): number {
		return this.id;
	}
}
