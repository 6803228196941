import { IJobCreateForm, IJobEditForm, JobModel } from '../models/job.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';
import { URLSearchParams } from 'url';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export const JobsApi = {
	list: (params?: URLSearchParams): Promise<JobModel[]> => {
		return ask.get(endpoints.jobs.list(), params);
	},
	create: (job: IJobCreateForm, options?: IRequestOptions): Promise<{ id: id }> => {
		return ask.post<IJobCreateForm>(endpoints.jobs.create(), job, options);
	},
	get: (id: id, options?: URLSearchParams): Promise<JobModel> => {
		return ask.get(endpoints.jobs.get(id), options);
	},
	edit: (id: id, job: IJobEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<IJobEditForm>(endpoints.jobs.edit(id), job, options);
	},
	delete: (id: id) => {
		return ask.delete(endpoints.jobs.delete(id));
	},
};
