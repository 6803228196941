import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { UserModel } from '../../models/user.model';
import { IAdminxStore } from '../../stores';

export function useUser() {
	const { userStore } = useStores<IAdminxStore>();
	const user = userStore.getUser();
	const userCrudStore = userStore.getCrud();

	useEffect(() => {
		const userId = user?.getId();
		if (!userId) {
			return;
		}
		userStore
			.getCrud()
			.profile()
			.then(user => {
				if (!user?.id) {
					return;
				}
				userStore.setUser(new UserModel(user));
			});
	}, []);

	return {
		error: userCrudStore.getHttpError(),
		isLoading: userCrudStore.getIsLoading(),
		isSuccess: userCrudStore.getIsSuccess(),
	};
}
