import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './create-advertiser.module.scss';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { AdvertiserForm } from '../../../modules/components/forms/advertiser/advertiser.form';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import routes from '../../../modules/routes/index.routes';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import { IAdminxStore } from '../../../modules/stores';
import { Statuses } from '../../../modules/enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

export const CreateAdvertiser = observer(() => {
	const { advertiserStore } = useStores<IAdminxStore>();
	const { dispatchLog } = useLogs();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const advertiserCrudStore = advertiserStore.getCrud();
	const advertiser = advertiserCrudStore.getData();

	const navigate = useNavigateParams();
	const location = useLocation();

	useEffect(() => {
		advertiserCrudStore.reset();
	}, [location]);

	const name = advertiser.getName();
	const formStore = advertiserCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = advertiserCrudStore.getIsLoading();
	const isSuccess = advertiserCrudStore.getIsSuccess();
	const error = advertiserCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(`${routes.advertisers.index()}`);
			dispatchLog({
				msg: `Advertiser ${name} was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			advertiserCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onCreateAdvertiser = () => {
		setFormClicked(true);
		advertiserStore.getCrud().create();
	};

	return (
		<SiderSlider
			onClose={() => navigate(`${routes.advertisers.index()}`, { state: { isBlockRequest: true } as BetweenPagesStateProps })}
			isOpen={true}>
			<Disable is={isLoading}>
				<Page>
					<Pagebar>
						<PagebarTitle unstyled={true}>
							<div className={styles.title}>Create Advertiser</div>
							<SpacerX />
							<Toggle
								label={advertiser.getStatus() === Statuses.Active ? Statuses.Active : Statuses.Paused}
								onChange={status => advertiser.setStatus(status ? Statuses.Active : Statuses.Paused)}
								defaultValue={advertiser.getStatus() === Statuses.Active}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'create_advertiser_status_toggle')] }}
							/>
						</PagebarTitle>
					</Pagebar>
					<AdvertiserForm />
				</Page>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					<BottomBarActions>
						<PrimaryButton
							debugProps={{ dataAttrs: [new DataAttribute('id', 'create_advertiser_button')] }}
							onClick={() => onCreateAdvertiser()}
							isRotateIcon={isLoading}
							icon={isLoading ? 'loading-01' : 'send-03'}>
							Create
						</PrimaryButton>
					</BottomBarActions>
				</BottomBar>
			</Disable>
		</SiderSlider>
	);
});
