import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { Outlet, useParams } from 'react-router-dom';
import { Nav, NavItem, NavPage } from '@monorepo/base/src/components/nav/nav';
import routes from '../../../modules/routes/index.routes';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';
import styles from './domains-view.module.scss';

export const DomainsView: FC = observer(() => {
	const { currentRoute } = useRoute();
	const { domainName } = useParams();
	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);
	useNestedSidebar();

	const formattedDomainName =
		domainName?.startsWith('http://') || domainName?.startsWith('https://') ? domainName : `http://${domainName}`;
	return (
		<NavPage>
			<Nav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				<NavItem to={domainName ? `${routes.domains.view(domainName)}` : ''}>Overview</NavItem>
				<NavItem to={domainName ? `${routes.domains.campaigns(domainName)}` : ''}>Campaigns</NavItem>
				<NavItem to={domainName ? `${routes.domains.pages(domainName)}` : ''}>pages</NavItem>
				<NavItem to={domainName ? `${routes.domains.bidKeys(domainName)}` : ''}>bidkeys</NavItem>
			</Nav>
			<Page>
				<Pagebar>
					<PagebarTitle>
						<a href={formattedDomainName} target="_blank" className={styles.domainLink}>
							{domainName}
						</a>
					</PagebarTitle>
				</Pagebar>
				<Outlet />
			</Page>
		</NavPage>
	);
});
