import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
// import { useEffect, useState } from 'react';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { CreativeGroupForm } from '../../../modules/components/forms/creative-group/creative-group.form';
import { observer } from 'mobx-react';
import { generatePath /*, useLocation,*/, useParams } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import styles from './edit-creative-group.module.scss';
import { useCreativeGroupGet } from '../../../modules/hooks/apis/use-creative-group-get';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import routes from '../../../modules/routes/index.routes';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { route } from '@monorepo/tools/src/lib/types/url';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { useState } from 'react';

interface IEditCreativeGroups {
	parentRoute?: route;
}

export const EditCreativeGroups = observer((props: IEditCreativeGroups) => {
	const { parentRoute } = props;
	const { creativeGroupsStore } = useStores<IAdminxStore>();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);

	const { advertiserId } = useParams();

	const { dispatchLog } = useLogs();
	const navigate = useNavigateParams();
	// const location = useLocation();
	const params = useParams();

	// useEffect(() => {
	// 	creativeGroupsStore.resetCrud();
	// }, [location]);

	if (params.creativeGroupId) {
		useCreativeGroupGet({ id: params.creativeGroupId });
	}

	const creativeGroupCrudStore = creativeGroupsStore.getCrud();
	const design = creativeGroupCrudStore.getData().getDesign();
	const formStore = creativeGroupCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = creativeGroupCrudStore.getIsLoading();
	const isSuccess = creativeGroupCrudStore.getIsSuccess();
	const error = creativeGroupCrudStore.getHttpError();

	if (!isValidForm && isFormClicked) {
		dispatchLog({
			msg: 'Please complete all mandatory fields',
			type: DispatchLogsTypes.Error,
		});
	}

	if (isSuccess) {
		navigate(generatePath(parentRoute || routes.creativeGroups.index(), { advertiserId }), {
			state: { isBlockRequest: true } as BetweenPagesStateProps,
		});
		dispatchLog({
			msg: `Creative Group ${design} was successfully Edited`,
			type: DispatchLogsTypes.Success,
		});
		creativeGroupCrudStore.setIsSuccess(false);
	}

	const onEditCreativeGroup = () => {
		setFormClicked(true);
		creativeGroupsStore.getCrud().edit();
	};

	const onCloseSiderSlider = () => {
		navigate(generatePath(parentRoute || routes.creativeGroups.index(), { advertiserId }), {
			state: { isBlockRequest: true } as BetweenPagesStateProps,
		});
	};

	return (
		<SiderSlider onClose={onCloseSiderSlider} isOpen={true}>
			<BarLoader is={isLoading} />
			<Disable is={isLoading}>
				<Page>
					<Pagebar>
						<PagebarTitle unstyled={true}>
							<div className={styles.title}>Edit Creative Group</div>
						</PagebarTitle>
					</Pagebar>
					<CreativeGroupForm />
				</Page>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					<BottomBarActions>
						<PrimaryButton
							onClick={() => onEditCreativeGroup()}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'save_creative_groups_button')] }}
							isRotateIcon={isLoading}
							icon={isLoading ? 'loading-01' : 'send-03'}>
							Save
						</PrimaryButton>
					</BottomBarActions>
				</BottomBar>
			</Disable>
		</SiderSlider>
	);
});
