import { CreateCampaign } from '../../pages/campaigns/create/create-campaign.page';
import { DuplicateCampaign } from '../../pages/campaigns/duplicate/duplicate-campaign.page';
import { EditCampaign } from '../../pages/campaigns/edit/edit-campaign.page';
import { Campaigns } from '../../pages/campaigns/index/campaigns.page';
import { CampaignBidKeys } from '../../pages/campaigns/view/bid-keys/bid-keys.campaign';
import { CampaignView } from '../../pages/campaigns/view/campaign-view.page';
import { CampaignCreatives } from '../../pages/campaigns/view/creatives/creatives.campaign';
import { CampaignDailyReport } from '../../pages/campaigns/view/daily-report/daily-report.campaign';
import { CampaignDeals } from '../../pages/campaigns/view/deals/deals.campaign';
import { CampaignDomains } from '../../pages/campaigns/view/domains/domains.campaign';
import { CampaignPages } from '../../pages/campaigns/view/pages/pages.campaign';
import { CampaignTrashedBidKeys } from '../../pages/campaigns/view/trashed-bidkeys/trashed-bid-keys.campaign';
import routes from '../routes/index.routes';
import { Soon } from '@monorepo/base/src/components/soon/soon';

export const CampaignsRouter = [
	{
		path: routes.campaigns.index(),
		element: <Campaigns />,
	},
	{
		path: routes.campaigns.create(),
		element: <CreateCampaign />,
	},
	{
		element: <CampaignView />,
		children: [
			{
				path: routes.campaigns.edit(':campaignId'),
				element: <EditCampaign />,
			},
			{
				path: routes.campaigns.duplicate(':campaignId'),
				element: <DuplicateCampaign />,
			},
			{
				path: routes.campaigns.view(':campaignId'),
				element: <Soon />,
			},
			{
				path: routes.campaigns.creatives(':campaignId'),
				element: <CampaignCreatives />,
			},
			{
				path: routes.campaigns.deals(':campaignId'),
				element: <CampaignDeals />,
			},
			{
				path: routes.campaigns.dailyReport(':campaignId'),
				element: <CampaignDailyReport />,
			},
			{
				path: routes.campaigns.domains(':campaignId'),
				element: <CampaignDomains />,
			},
			{
				path: routes.campaigns.pages(':campaignId'),
				element: <CampaignPages />,
			},
			{
				path: routes.campaigns.bidKeys(':campaignId'),
				element: <CampaignBidKeys />,
			},
			{
				path: routes.campaigns.trashedBidKeys(':campaignId'),
				element: <CampaignTrashedBidKeys />,
			},
		],
	},
];
