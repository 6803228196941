import { useLocation } from 'react-router-dom';
import { route } from '@monorepo/tools/src/lib/types/url';

interface IUseRoute {
	currentRoute: route;
	indexRoute: route;
	currentRouteWithoutSpecialChars: string;
	entityRoute: route;
}

export function useRoute(): IUseRoute {
	const location = useLocation();

	const calcEntityRoute = (_currentRoute: route) => {
		const currentRouteSplit = _currentRoute.split('/');
		return `/${currentRouteSplit?.[1]}/${currentRouteSplit?.[2]}`;
	};

	const useRoutes = {
		currentRoute: location.pathname,
		indexRoute: `/${location.pathname.split('/')?.[1]}`,
		currentRouteWithoutSpecialChars: location.pathname.replace(/\d/g, '').replaceAll('/', '').replaceAll('-', ''),
		entityRoute: calcEntityRoute(location.pathname),
	};

	return useRoutes;
}
