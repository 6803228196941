import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { To } from 'react-router-dom';
import { route } from '@monorepo/tools/src/lib/types/url';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import styles from './table-link.module.scss';
import { ReactNode } from 'react';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';
import { generateDataAttrs, DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

interface ITableLink {
	toEdit?: route;
	toEditState?: BetweenPagesStateProps;
	to?: To;
	toState?: BetweenPagesStateProps;
	onClick?: () => void;
	children: ReactNode;
}

export const TableLink = (props: ITableLink) => {
	const { children, to, toEdit, toEditState, toState, ...rest } = props;

	return (
		<div className={styles.wrapper} style={{ whiteSpace: 'pre' }}>
			{to ? (
				<LinkWithParams
					className={`tableLink ${styles.link}`}
					to={to}
					state={toState}
					{...rest}
					{...generateDataAttrs([new DataAttribute('id', 'table_entity_name_button')])}>
					{children}
				</LinkWithParams>
			) : (
				children
			)}
			{toEdit ? (
				<LinkWithParams className={`tableLinkEditIcon ${styles.editLink}`} to={toEdit} state={toEditState} {...rest}>
					<Icon
						isMFP={true}
						size={'14px'}
						font={IconFonts.Filled}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'table_edit_icon')] }}>
						edit-02
					</Icon>
				</LinkWithParams>
			) : null}
		</div>
	);
};
