import { observer } from 'mobx-react';
import { FC } from 'react';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Outlet } from 'react-router-dom';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Card } from '@monorepo/base/src/components/card/card';
import { useTableStore } from '../../../modules/components/report-table/stores/use-table-store.hook';
import { useTableStoreMetrics } from '../../../modules/hooks/tools/use-repr-metrics';
import ReportTable from '../../../modules/components/report-table/table-component';
import { TableDefaultButton } from '../../../modules/components/report-table/table-elements/table-buttons';
import { TableActionsBar } from '../../../modules/components/report-table/table-elements/table-actions';
import { CampaignGroupRowActions } from '../../../modules/components/report-table/actions/campaign-group.action';
import routes from '../../../modules/routes/index.routes';

const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'pagebar')] };

export const CampaignGroups: FC = observer(() => {
	const { campaignGroupsStore } = useStores<IAdminxStore>();
	const { campaignGroupsPerformance } = campaignGroupsStore;

	useTableStore({ reportTableStore: campaignGroupsPerformance });

	const chartProps = useTableStoreMetrics(campaignGroupsPerformance);

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Campaign Groups</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full debugProps={{ dataAttrs: [new DataAttribute('id', 'chart')] }}>
				<Chart isLoading={campaignGroupsPerformance.dataStore.getIsLoading()} {...chartProps} />
			</Card>
			<SpacerY y={2} />
			<ReportTable tableStore={campaignGroupsPerformance}>
				<TableDefaultButton to={routes.campaignGroups.create()} />
				<TableActionsBar>
					<CampaignGroupRowActions />
				</TableActionsBar>
			</ReportTable>
			<Outlet />
		</Page>
	);
});
