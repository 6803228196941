import {
	DomainFormatColumnConfig,
	DomainSalesColumnConfig,
	DomainScoreColumnConfig,
	DomainScorerClassColumnConfig,
} from '../../../columns/domains.columns';
import { WinsColumnConfig } from '../../../columns/metrics.columns';
import {
	CampaignIdColumnConfig,
	CampaignNameColumnConfig,
	CreativeGroupIdColumnConfig,
	CreativeGroupNameColumnConfig,
} from '../../../columns/adminx.columns';
import { ReportSortOrder } from '../../../stores/data-stores/old-report.types';
import { CreatedAtColumnConfig, CreatedByColumnConfig, UpdatedAtColumnConfig } from '../../../columns/data.columns';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const domainScoresTableConfig = {
	reportParams: {
		reportName: 'adxDomainsScore',
		sortBy: 'wins',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new CampaignNameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new CampaignIdColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CreativeGroupNameColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CreativeGroupIdColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DomainScoreColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DomainFormatColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DomainScorerClassColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new WinsColumnConfig({ visibility: ColumnVisibilityOptions.Visible, type: 'data' }),
		new DomainSalesColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CreatedByColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CreatedAtColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new UpdatedAtColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
	],
	tableState: { pagination: { pageIndex: 0, pageSize: 5 } },
	isGraphAvailable: false,
	tableName: 'domainsScoreTable',
};
