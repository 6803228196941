import { observer } from 'mobx-react';
import { Fragment, useEffect } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useCampaignInputOptions } from '../../../hooks/apis/use-campaign-input-options';
import { AdvertiserModel } from '../../../models/advertiser.model';
import { IAdminxStore } from '../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const CampaignGroupForm = observer(() => {
	useCampaignInputOptions();

	const { campaignGroupsStore, campaignStore, advertiserStore } = useStores<IAdminxStore>();
	const advertiserCrudStore = advertiserStore.getCrud();
	const advertiserView = advertiserCrudStore.getData();
	const advertiserViewName = advertiserView.getName();

	const campaignGroupCrudStore = campaignGroupsStore.getCrud();
	const formStore = campaignGroupCrudStore.getFormStore();
	const campaignGroup = campaignGroupCrudStore.getData();
	const advertiser = campaignGroup.getAdvertiser();
	const advertisers = campaignStore.getInputOptions().getAdvertisers();
	const advertisersNames = advertisers.map(_advertiser => _advertiser.getName() || '');

	const onAdvertiserSelect = (advertiserName: option) => {
		const _advertiser = advertisers.find(_advertiser => _advertiser.getName() === advertiserName);
		if (_advertiser) {
			campaignGroup.setAdvertiser(new AdvertiserModel(_advertiser));
		}
	};

	useEffect(() => {
		if (advertiserViewName) {
			onAdvertiserSelect(advertiserViewName);
		}
	}, [advertiserViewName, advertisers]);

	return (
		<Fragment>
			<Card title={'Campaign Group Information'} icon={'dataflow-04'} border={false}>
				<Input
					value={campaignGroup.getName()}
					placeholder={'Campaign Group Name'}
					onValue={value => {
						campaignGroup.setName(value);
						formStore.clearError('campaign_group_name');
					}}
					required={true}
					error={formStore.getErrors().get('campaign_group_name')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_group_name')] }}
				/>
				<Dropdown
					required={true}
					disabled={Boolean(advertiserView?.getName())}
					defaultOption={advertiser?.getName()}
					label={'Select an Advertiser'}
					options={advertisersNames}
					onSelect={(advertiserName: Set<option> | undefined) => onAdvertiserSelect(advertiserName?.values().next().value)}
					error={formStore.getErrors().get('advertiser')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_group_advertiser')] }}
				/>
			</Card>
		</Fragment>
	);
});
