import { Fragment } from 'react';
import { useTableContext } from '../table-component';
import { TableRawActionButton } from '../table-elements/table-actions';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { EnablePauseCampaignsModal } from '../../modals/enable-pause-campaigns';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import routes from '../../../routes/index.routes';
import { observer } from 'mobx-react';

export const BidderCampaignsRowActions = observer(() => {
	const { tableStore } = useTableContext();
	const { bidderStore, modalsStore, toastsStore } = useStores<IAdminxStore>();
	const bidderCrudStore = bidderStore.getCrud();
	const isLoading = bidderCrudStore.getIsLoading();

	const selectedIds = tableStore.getSelectedRows().map(r => r.id) as string[];

	const toastTheResult = (msg: string, type: DispatchLogsTypes) => {
		toastsStore.addToast({ msg, type });
	};

	const pauseCampaigns = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={async () => {
						await bidderCrudStore.pauseCampaign(selectedIds[0]);
						toastTheResult(`Campaign ${selectedIds[0]} Paused`, DispatchLogsTypes.Success);
						tableStore.fetchReport({}, false);
					}}>
					<EnablePauseCampaignsModal action={'Paused'} campaignIds={[selectedIds[0]]} />
				</ActionModal>
			),
		});
	};

	const enableCampaigns = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={async () => {
						await bidderCrudStore.enableCampaign(selectedIds[0]);
						toastTheResult(`Campaign ${selectedIds[0]} Enabled`, DispatchLogsTypes.Success);
						tableStore.fetchReport({}, false);
					}}>
					<EnablePauseCampaignsModal action={'Enable'} campaignIds={[selectedIds[0]]} />
				</ActionModal>
			),
		});
	};

	return (
		<Fragment>
			<TableRawActionButton
				text={'Enable'}
				isLoading={isLoading}
				onClick={enableCampaigns}
				icon={'play'}
				disabled={selectedIds.length !== 1}
			/>
			<TableRawActionButton
				text={'Pause'}
				isLoading={isLoading}
				onClick={pauseCampaigns}
				icon={'pause-square'}
				disabled={selectedIds.length !== 1}
			/>
			<TableRawActionButton
				text={'Edit'}
				to={routes.bidder.editCampaign(selectedIds[0])}
				icon={'edit-02'}
				disabled={selectedIds.length !== 1}
			/>
		</Fragment>
	);
});
