import { AdvertiserNameColumnConfig, AdvertiserIdColumnConfig, AdvertiserStatusColumnConfig } from '../../../columns/adx-repr.columns';
import { generateAdxReprPerformanceColumns } from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { FilterTypeEnum, SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const advertisersTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'wins',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [
			{
				column: 'advertiser_status',
				filterType: FilterTypeEnum.EQUALS,
				value: ['ACTIVE'],
				inverted: false,
			},
		],
	},
	columns: [
		new AdvertiserNameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new AdvertiserIdColumnConfig({ visibility: ColumnVisibilityOptions.Visible, header: 'ID' }),
		new AdvertiserStatusColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),

		...generateAdxReprPerformanceColumns(),
	],
	includeChart: true,
	tableName: 'adxReprAdvertiserPerformance',
};
