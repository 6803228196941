import { TableStore } from './table.store';
import { makeAutoObservable } from 'mobx';

interface ISettingsStore {
	pages: Map<string, IPageSettings>;
	darkMode: boolean;
}

interface IPageSettings {
	tableStore: TableStore;
}

export class SettingsStore implements ISettingsStore {
	darkMode: boolean;
	pages: Map<string, IPageSettings>;

	constructor() {
		makeAutoObservable(this);
		this.darkMode = false;
		this.pages = new Map<string, IPageSettings>([]);
	}

	getPages(): Map<string, IPageSettings> {
		return this.pages;
	}

	getDarkMode(): boolean {
		return this.darkMode;
	}
}
