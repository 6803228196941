import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './debug-creative.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IAdminxStore } from '../../../modules/stores';
import { BannerSize } from '../../../modules/models/campaign.model';
import { useParams } from 'react-router-dom';
import { Page } from '@monorepo/base/src/components/page/page';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { Icon } from '@monorepo/base/src/components/icon/icon';

export const DebugCreative = observer(() => {
	const { creativeStore, campaignStore } = useStores<IAdminxStore>();
	const resourceUrl = creativeStore.getCrud().getData().getResourceUrl();

	const { creativeId } = useParams();

	useEffect(() => {
		if (creativeId) {
			creativeStore.getCrud().get(creativeId);
		}
	}, [creativeId]);

	const [cleanedHtml, setCleanedHtml] = useState('');
	const [rawHtmlContent, setRowHtmlContent] = useState('');
	const [creativeTextPlaceholders, setCreativeTextPlaceholders] = useState<{ name: string; value: string }[]>([]);
	const [isLoading, setIsLoading] = useState(
		creativeStore.getCrud().getIsLoading() && !creativeStore.getCrud().getData().getResourceUrl()
	);
	const [displayCode, setDisplayCode] = useState(false);
	const [showCreativeBorder, setShowCreativeBorder] = useState(true);
	const [showResponsive, setShowResponsive] = useState(false);
	const [color, setColor] = useState('#ffffff');

	// helpers functions
	const getBannerWithSize = (banners?: BannerSize[]) => {
		return (
			banners?.map(({ height = 1, width = 1, id }) => ({
				size: height * width,
				direction: height === width ? 'square' : height < width ? 'horizontal' : 'vertical',
				height,
				width,
				id,
			})) || []
		);
	};

	const sortBannerBySizes = (banners: (BannerSize & { size: number })[]) => {
		return [
			...banners.filter(({ height = 1, width = 1 }) => height < width).sort((a, b) => a.size - b.size),
			...banners.filter(({ height = 1, width = 1 }) => height >= width).sort((a, b) => a.size - b.size),
		];
	};

	const creativeBannerSizes = sortBannerBySizes(getBannerWithSize(creativeStore.getCrud().getData().getBannerSizes()));
	const bannerSizesNotInCreative = sortBannerBySizes(
		getBannerWithSize(
			campaignStore
				.getInputOptions()
				.getBannerSizes()
				?.filter(banner => !creativeBannerSizes.find(item => item.id === banner.id))
		)
	);

	useEffect(() => {
		if (!creativeTextPlaceholders.length) {
			return;
		}
		setCleanedHtml(() => {
			let newHtml = rawHtmlContent;
			creativeTextPlaceholders.forEach(item => {
				newHtml = newHtml.replace(new RegExp(item.name, 'g'), item.value);
			});
			return newHtml;
		});
	}, [creativeTextPlaceholders]);

	useEffect(() => {
		if (!resourceUrl) {
			return;
		}
		const fetchHtml = async () => {
			try {
				const response = await fetch(resourceUrl);
				const rowHtml = await response.text();
				const htmlCleanedFromHrefPlaceholders = rowHtml.replace(/href="[^"]*"/g, 'href="#"');
				const placeholdersMatches = htmlCleanedFromHrefPlaceholders.match(/@@[^@]+@@/g) || [];
				setCreativeTextPlaceholders(placeholdersMatches.map(name => ({ name, value: name })));
				setRowHtmlContent(rowHtml);
				setCleanedHtml(htmlCleanedFromHrefPlaceholders);
				setIsLoading(false);
			} catch (error) {
				console.error('Error fetching HTML:', error);
			}
			setIsLoading(false);
		};
		fetchHtml();
	}, [resourceUrl]);

	return (
		<Page className={styles.debugPageWrappaer}>
			<div className={styles.listAndTools}>
				<div className={styles.titleAndToolBarWrapper}>
					<div className={styles.titleAndToolBar}>
						<div className={styles.title}>Debug Creative</div>
						<div className={styles.toolBar}>
							{creativeTextPlaceholders.map((placeholder, i) => (
								<input
									key={i}
									type={'text'}
									className={styles.tagTextInput}
									value={placeholder.value}
									placeholder={placeholder.name}
									onChange={e =>
										setCreativeTextPlaceholders(prev => {
											const newList = [...prev];
											const index = newList.findIndex(item => item.name === placeholder.name);
											newList[index].value = e.target.value;
											return newList;
										})
									}
								/>
							))}
							<button
								className={`${styles.showBorderButton} ${showCreativeBorder ? styles.active : ''}`}
								onClick={() => setShowCreativeBorder(!showCreativeBorder)}>
								{`${showCreativeBorder ? 'Hide' : 'Show'} Border`}
							</button>
							<button
								className={`${styles.showBorderButton} ${showResponsive ? styles.active : ''}`}
								onClick={() => setShowResponsive(!showResponsive)}>
								{`${showResponsive ? 'Hide' : 'Show'} Responsive`}
							</button>
							<button
								className={`${styles.displayCodeButton} ${displayCode ? styles.active : ''}`}
								onClick={() => setDisplayCode(!displayCode)}>
								<Icon className={styles.icon} isMFP={true} color={'#fff'} size={'18px'}>
									code-02
								</Icon>
							</button>
							<input className={styles.colorPicker} type={'color'} value={color} onChange={e => setColor(e.target.value)} />
						</div>
					</div>
					<BarLoader is={isLoading} />
					{displayCode && (
						<textarea className={styles.codeInput} value={cleanedHtml} onChange={e => setCleanedHtml(e.target.value)} />
					)}
				</div>
				{showResponsive && (
					<Fragment>
						<div className={styles.divider}>
							<div className={styles.title}>Responsive Size</div>
						</div>
						<div style={{ backgroundColor: color }} className={styles.responsiveWrapper}>
							<div className={styles.creativeResizeableWrapper}>
								<iframe
									sandbox=""
									allow=""
									referrerPolicy="no-referrer"
									className={`${styles.creativeResponsiveIframe} ${showCreativeBorder ? styles.showBorder : ''}`}
									srcDoc={cleanedHtml}
								/>
							</div>
						</div>
					</Fragment>
				)}
				<div className={styles.divider}>
					<div className={styles.title}>Current Creative Sizes</div>
				</div>
				<div className={styles.creativeOptions}>
					{!isLoading &&
						creativeBannerSizes.map(size => (
							<CreativeListItem
								key={size.id}
								bannerHtmlText={cleanedHtml}
								size={size}
								color={color}
								showBorder={showCreativeBorder}
							/>
						))}
				</div>

				{!isLoading && bannerSizesNotInCreative.length > 0 && (
					<Fragment>
						<div className={styles.divider}>
							<div className={styles.title}>Another options</div>
						</div>
						<div className={styles.creativeOptions}>
							{bannerSizesNotInCreative.map(size => (
								<CreativeListItem
									key={size.id}
									bannerHtmlText={cleanedHtml}
									size={size}
									color={color}
									showBorder={showCreativeBorder}
								/>
							))}
						</div>
					</Fragment>
				)}
			</div>
		</Page>
	);
});

function getOppositeColor(hexColor: string) {
	hexColor = hexColor.replace('#', '');

	const r = parseInt(hexColor.substr(0, 2), 16);
	const g = parseInt(hexColor.substr(2, 2), 16);
	const b = parseInt(hexColor.substr(4, 2), 16);

	const oppositeR = 255 - r;
	const oppositeG = 255 - g;
	const oppositeB = 255 - b;

	const oppositeHex =
		'#' + oppositeR.toString(16).padStart(2, '0') + oppositeG.toString(16).padStart(2, '0') + oppositeB.toString(16).padStart(2, '0');

	return oppositeHex;
}

const CreativeListItem = observer(
	({ bannerHtmlText, size, color, showBorder }: { bannerHtmlText: string; size: BannerSize; color: string; showBorder: boolean }) => {
		return (
			<div className={styles.creativeListItem} style={{ backgroundColor: color }}>
				<div className={styles.creativeIframeWrapperColumn}>
					<div className={styles.creativeListItemTitleTop}>{size.width}</div>
					<div className={styles.creativeIframeWrapperRow}>
						<div className={styles.creativeListItemTitleLeft}>{size.height}</div>
						<iframe
							key={size.id}
							sandbox="allow-same-origin"
							referrerPolicy="no-referrer"
							style={{
								height: `${size.height}px`,
								width: `${size.width}px`,
								outlineColor: getOppositeColor(color),
							}}
							className={`${styles.creativeIframe} ${showBorder ? styles.showBorder : ''}`}
							srcDoc={bannerHtmlText}
						/>
					</div>
				</div>
			</div>
		);
	}
);
