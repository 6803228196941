import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Decisions } from '../../../../enums/decisions';
import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const Rules = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const rules = campaignStore.getCrud().getData().getRules();
	const maxLoss = rules?.getMaxLoss();
	const maxWinsPerClick = rules?.getMaxWinsPerClick();
	const maxWinsPerConversion = rules?.getMaxWinsPerConversion();
	const maxClicksPerConversion = rules?.getMaxClicksPerConversion();
	const maxCPM = rules?.getMaxCPM();
	const dailyMaxSales = campaignStore.getCrud().getData()?.getDailyMaxSales();

	return (
		<Card id={'rules'} title={'Rules'} icon={'gavel'} debugProps={{ dataAttrs: [new DataAttribute('id', 'rules_section')] }}>
			<Spacer />
			<Input
				type={'number'}
				icon={'currency-dollar'}
				value={maxLoss?.toString()}
				min={0}
				placeholder={'Max Loss (per key)'}
				onValue={value => rules?.setMaxLoss(parseInt(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'max_loss')] }}
			/>
			<Input
				type={'number'}
				value={maxWinsPerClick?.toString()}
				placeholder={'Max wins per click'}
				onValue={value => rules?.setMaxWinsPerClick(parseInt(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'max_wins_per_click')] }}
			/>
			<Input
				type={'number'}
				value={maxWinsPerConversion?.toString()}
				placeholder={'Max wins per conversion'}
				onValue={value => rules?.setMaxWinsPerConversion(parseInt(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'max_wins_per_conversion')] }}
			/>
			<Input
				type={'number'}
				value={maxClicksPerConversion?.toString()}
				placeholder={'Max clicks per conversion'}
				onValue={value => rules?.setMaxClicksPerConversion(parseInt(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'max_clicks_per_conversion')] }}
			/>
			<Input
				type={'number'}
				value={maxCPM?.toString()}
				placeholder={'Max CPM'}
				onValue={value => rules?.setMaxCPM(parseFloat(value))}
				step={'0.1'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'max_cpm')] }}
			/>
			<Input
				type={'number'}
				value={dailyMaxSales?.toString()}
				placeholder={'Daily Max Sales'}
				onValue={value => campaignStore.getCrud().getData().setDailyMaxSales(parseInt(value))}
				step={'1'}
				min={0}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'daily_max_sales')] }}
			/>
			<Spacer />
			<Toggle
				title={'Use Optimal Gain For Max Loss Calculation?'}
				label={rules?.getUseOptimalGainForMaxLossCalculation() ? Decisions.Yes : Decisions.No}
				onChange={status => rules?.setUseOptimalGainForMaxLossCalculation(status)}
				defaultValue={rules?.getUseOptimalGainForMaxLossCalculation()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'use_optimal_gain_for_max_loss_calculation')] }}
			/>
			<Spacer />
			<Toggle
				title={'Use Domain Budget Limit?'}
				label={rules?.getUseDomainBudgetLimit() ? Decisions.Yes : Decisions.No}
				onChange={status => rules?.setUseDomainBudgetLimit(status)}
				defaultValue={rules?.getUseDomainBudgetLimit()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'domain_budget_limit')] }}
			/>
			<Spacer />
			<Toggle
				title={'Allow Multiple Sales?'}
				label={rules?.getAllowMultipleSales() ? Decisions.Yes : Decisions.No}
				onChange={status => rules?.setAllowMultipleSales(status)}
				defaultValue={rules?.getAllowMultipleSales()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'allow_multiple_sales')] }}
			/>
		</Card>
	);
});
