import { DealModel } from '../../models/deal.model';
import { DealsApi } from '../../apis/deals.api';
import { makeAutoObservable } from 'mobx';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { DealCrudStore } from './deal-crud.store';
import { PerformanceStore } from '../performance.store';
import { PerformancesColumnsSnakeCase } from '../../hooks/apis/use-reports-list';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { filterPrototypesByProp } from '../../components/filters-menu/filters';

export enum DealFiltersStoreKeys {
	Deals = 'deals',
	Campaigns = 'campaignsdeals',
}

export class DealStore {
	private crud = new DealCrudStore();
	private list = new BaseListStore<DealModel>({
		listFunc: DealsApi.list,
		model: DealModel,
	});
	performance = new PerformanceStore<DealModel>({
		keys: new Set([PerformancesColumnsSnakeCase.PreferredDealId]),
	});
	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(DealFiltersStoreKeys).map((key: DealFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const dealFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, dealFilterByKey);
		});
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getPerformance() {
		return this.performance;
	}
}
