import { ElementType, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { CreativesApi } from '../../apis/creatives.api';
import { IButton, PrimaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { CreativeModel } from '../../models/creative.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { PerformancesColumnsSnakeCase } from '../apis/use-reports-list';
import { useRoute } from '../tools/use-route';
import { useSegments } from '../tools/use-segments';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, useDefaultActions } from './default.actions';
import { Segments } from '@monorepo/base/src/components/segments/segments';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { AttachCreativeCampaigns } from '../../components/modals/attach-creative-campaigns/attach-creative-campaigns';
import { DetachCampaignCreativeModal } from '../../components/modals/detach-campaign-creative-modal';
import { SendToReviewModal } from '../../components/modals/send-to-review-modal';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IAdminxStore } from '../../stores';
import { ISegmentActions } from '@monorepo/base/src/components/table/table';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { creativeFilterPrototypes } from '../../components/filters-menu/filters';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { CreativesBulkCSVCreate } from '../../components/creatives-bulk-create-csv/creatives-bulk-create-csv';

const theme = getTheme();

interface creativeActionsProps {
	hasDetach?: boolean;
}

export function useCreativeActions(props: creativeActionsProps = {}) {
	const { hasDetach = false } = props;
	const { creativeStore, modalsStore, toastsStore, settingsStore } = useStores<IAdminxStore>();
	const navigate = useNavigateParams();
	const { campaignId } = useParams();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { dispatchLog } = useLogs();
	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars);
	const currentFilters = filterStore?.getCurrentFilters();
	const performanceStore = creativeStore.getPerformance();
	const onEditRoute = (id: id) => routes.creatives.edit(id);
	const onDelete = (ids: id[]) => creativeStore.getCrud().delete(ids[0]);
	const onDownload = (ids: id[]) => creativeStore.downloadCreativeHtmlFiles(ids);

	const funcActions = {
		onEditRoute,
		onDelete,
		onDownload,
	};

	const sendToReview = (creativeIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Send to review'}
					onAction={() => {
						CreativesApi.sendToReview(creativeIds)
							.then(() => {
								toastsStore.addToastOnNav({ msg: `Sent to review successfully`, type: DispatchLogsTypes.Success });
								navigate(0);
							})
							.catch(() => {
								toastsStore.addToast({ msg: `Failed to send to review`, type: DispatchLogsTypes.Error });
							});
					}}>
					<SendToReviewModal creativeIds={creativeIds} />
				</ActionModal>
			),
		});
	};

	const attachCampaigns = (creativeIds: id[]) => {
		const creatives = creativeIds.map(id =>
			creativeStore
				.getListStore()
				.getList()
				.find(creative => creative.getId() === id)
		);
		modalsStore.addModal({
			children: (
				<ActionModal
					primaryBtnTitle={'Attach Campaigns'}
					onAction={() => {
						const campaignIds =
							creativeStore
								.getCrud()
								.getData()
								.getCampaigns()
								?.map(campaign => campaign.getId()) || [];
						const promises = creativeIds.map(creativeId => {
							return CreativesApi.attachCampaigns(creativeId, campaignIds);
						});
						Promise.allSettled(promises).then(results => {
							const successIds: id[] = [];
							const failedIds: id[] = [];
							results.forEach((result, index) => {
								if (result.status === 'fulfilled') {
									successIds.push(creativeIds[index]);
								} else {
									failedIds.push(creativeIds[index]);
								}
							});
							if (successIds.length > 0) {
								dispatchLog({
									msg: `${successIds.length > 1 ? `Creatives` : `Creative`} ${successIds} Attached`,
									type: DispatchLogsTypes.Success,
								});
							}
							if (failedIds.length > 0) {
								dispatchLog({
									msg: `${failedIds.length > 1 ? `Creatives` : `Creative`} ${failedIds} Failed to attach`,
									type: DispatchLogsTypes.Error,
								});
							}
						});
					}}>
					<AttachCreativeCampaigns creatives={creatives as CreativeModel[]} />
				</ActionModal>
			),
		});
	};

	const onDetach = (creativeIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Detach'}
					onAction={() => {
						if (!campaignId) {
							return null;
						}
						CreativesApi.detachCampaignCreatives(creativeIds, campaignId).then(() => {
							toastsStore.addToastOnNav({
								msg: `${creativeIds.length > 1 ? `Creatives` : `Creative`} Detached`,
								type: DispatchLogsTypes.Success,
							});
							navigate(0);
						});
					}}>
					<DetachCampaignCreativeModal creativeIds={creativeIds} />
				</ActionModal>
			),
		});
	};

	const SelectedActions = (props: ITableActions<CreativeModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new CreativeModel(row.original));
		const { EditAction, DeleteAction, DownloadAction } = useDefaultActions({ entities, ...funcActions });
		const entitiesIds: id[] = [];
		entities.forEach(e => {
			const id = e.getId();
			if (id) {
				entitiesIds.push(id);
			}
		});

		const creatives = entitiesIds.map(id =>
			creativeStore
				.getListStore()
				.getList()
				.find(creative => creative.getId() === id)
		);

		const isSameAdvertiser = creatives.every(creative => {
			return creative?.getCreativeGroup()?.getAdvertiser()?.getId() === creatives[0]?.getCreativeGroup()?.getAdvertiser()?.getId();
		});

		const DetachAction: ElementType = (props: IButton<HTMLButtonElement>) => {
			const { textColor = 'white', iconColor = 'white' } = props;
			return (
				<SecondaryText
					icon={'link-off'}
					onClick={() => onDetach(entitiesIds)}
					textColor={textColor}
					iconColor={iconColor}
					{...props}>
					Detach
				</SecondaryText>
			);
		};
		const DebugAction: ElementType = (props: IButton<HTMLButtonElement>) => {
			const { textColor = 'white', iconColor = 'white' } = props;
			return (
				<SecondaryText
					disabled={!entitiesIds || entitiesIds.length > 1}
					icon={'code-02'}
					onClick={() => navigate(routes.creatives.debug(entitiesIds[0]))}
					textColor={textColor}
					iconColor={iconColor}
					{...props}>
					Debug
				</SecondaryText>
			);
		};

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						disabled={!isSameAdvertiser}
						iconSize={'22px'}
						icon={'link-02'}
						onClick={() => attachCampaigns(entitiesIds)}
						textColor={'white'}>
						Attach
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText icon={'eye'} onClick={() => sendToReview(entitiesIds)} textColor={'white'}>
						Review
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<EditAction />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<Tooltip content={`Deleting a creative has been blocked and you should do it manually`}>
						<DeleteAction />
					</Tooltip>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<Tooltip content={`Preview and debug the creative content`}>
						<DebugAction />
					</Tooltip>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<Tooltip content={`Download creative html file`}>
						<DownloadAction />
					</Tooltip>
				</SelectedLinerButton>
				{hasDetach ? (
					<SelectedLinerButton>
						<Tooltip content={`Detach from campaign`}>
							<DetachAction />
						</Tooltip>
					</SelectedLinerButton>
				) : null}
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<CreativeModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction, DownloadAction } = useDefaultActions({ entities: [entity], ...funcActions });
		const entityId = entity.getId();
		if (!entityId) {
			// log error
			return null;
		}

		const DetachAction: ElementType = (props: IButton<HTMLButtonElement>) => {
			const { textColor = 'white', iconColor = 'white' } = props;
			return (
				<SecondaryText
					icon={'link-off'}
					onClick={() => onDetach([entityId])}
					textColor={textColor}
					iconColor={iconColor}
					{...props}>
					Detach
				</SecondaryText>
			);
		};
		return (
			<Fragment>
				<QuickActionButton>
					<SecondaryText
						iconSize={'16px'}
						iconColor={theme.titleColor}
						textColor={theme.titleColor}
						icon={'link-02'}
						onClick={() => attachCampaigns([entityId])}>
						Attach
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<SecondaryText
						icon={'eye'}
						onClick={() => sendToReview([entityId])}
						iconColor={theme.titleColor}
						textColor={theme.titleColor}
						iconSize={'14px'}>
						Review
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<EditAction iconColor={theme.titleColor} textColor={theme.titleColor} />
				</QuickActionButton>
				<QuickActionButton>
					<Tooltip content={`Deleting a creative has been blocked and you should do it manually`}>
						<DeleteAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} />
					</Tooltip>
				</QuickActionButton>
				<QuickActionButton>
					<Tooltip content={`download creative html file`}>
						<DownloadAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} />
					</Tooltip>
				</QuickActionButton>
				{hasDetach ? (
					<QuickActionButton>
						<DetachAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} />
					</QuickActionButton>
				) : null}
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={[...creativeFilterPrototypes]}
				currentFilters={currentFilters}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
			/>
		);
	};

	const SegmentActions = (props: ISegmentActions) => {
		const { segments, onSegment } = useSegments<CreativeModel>({
			performanceStore,
			disableSegments: [PerformancesColumnsSnakeCase.CreativeId, PerformancesColumnsSnakeCase.CreativeGroupId],
		});

		return (
			<Segments
				currentSegments={performanceStore.getSegments()}
				availableSegments={segments}
				onClick={segment => onSegment(segment)}
				{...props}
			/>
		);
	};

	const IndexActions = () => {
		return (
			<Fragment>
				<PrimaryLink
					icon={'plus'}
					to={routes.creatives.create()}
					width={'100px'}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'creatives_table_create')] }}>
					Create
				</PrimaryLink>
				<CreativesBulkCSVCreate />
			</Fragment>
		);
	};

	return {
		IndexActions,
		SelectedActions,
		EntityActions,
		FiltersActions,
		SegmentActions,
	};
}
