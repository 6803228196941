import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { observer } from 'mobx-react';
import { IAdminxStore } from '../../../../modules/stores';
import ReportTable from '../../../../modules/components/report-table/table-component';
import { useTableStore } from '../../../../modules/components/report-table/stores/use-table-store.hook';
import { BidderCampaignsRowActions } from '../../../../modules/components/report-table/actions/bidder-campaigns.action';
import { TableActionsBar } from '../../../../modules/components/report-table/table-elements/table-actions';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';

export const BidderCampaign = observer(() => {
	const { bidderStore } = useStores<IAdminxStore>();
	const { campaignsPerformance } = bidderStore;

	useTableStore({ reportTableStore: campaignsPerformance });

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Campaigns</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<ReportTable tableStore={campaignsPerformance}>
				<TableActionsBar>
					<BidderCampaignsRowActions />
				</TableActionsBar>
			</ReportTable>
		</Page>
	);
});
