import React, { useEffect, useState } from 'react';
import { capitalCase } from 'change-case';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Textarea } from '@monorepo/base/src/components/form/textarea/textarea';
import { setToString } from '@monorepo/tools/src/lib/utils/set';
import styles from './white-black-list.module.scss';
import { PrimaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { WhiteBlackList as WhiteBlackListOption, WhiteBlackLists } from '../../enums/white-black-list-enum';
import { useLocation } from 'react-router-dom';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';

const getListingType = (whitelist: Set<string>, blacklist: Set<string>): WhiteBlackLists | null => {
	if (whitelist.size) {
		return WhiteBlackLists.Whitelist;
	} else if (blacklist.size) {
		return WhiteBlackLists.Blacklist;
	}
	return null;
};

export interface IWhiteBlackListChangeData {
	list: Set<string>;
	listType: WhiteBlackListOption;
}

interface IWhiteBlackList {
	whitelist: Set<string>;
	blacklist: Set<string>;
	textareaPlaceholder?: string;
	defaultListType?: WhiteBlackListOption;
	onListChange?: (data: IWhiteBlackListChangeData) => void;
	isValidItem?: (item: string) => boolean;
	error?: string;
	debugProps?: IDebugProps;
}

export const WhiteBlackList = (props: IWhiteBlackList) => {
	const { dispatchLog } = useLogs();
	const { whitelist, blacklist, textareaPlaceholder = 'List', defaultListType, onListChange, error, debugProps } = props;
	const location = useLocation();

	const [listType, setListType] = useState<WhiteBlackListOption>(defaultListType || WhiteBlackLists.Blacklist);
	const [textareaValue, setTextareaValue] = useState<string>('');
	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);

	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	useEffect(() => {
		// init the list type by checking the lists
		setListType(getListingType(whitelist, blacklist) ?? defaultListType ?? WhiteBlackLists.Blacklist);
	}, [location, whitelist, blacklist]);

	useEffect(() => {
		// init current list by loading from the parent on change list type (or parent)
		let newList;
		if (listType === WhiteBlackLists.Whitelist) {
			newList = new Set(whitelist || []);
		} else {
			newList = new Set(blacklist || []);
		}
		setTextareaValue(setToString(newList));
	}, [listType, whitelist, blacklist]);

	useEffect(() => {
		const list = new Set<string>();
		const listArr = textareaValue.trim().split('\n');
		for (const item of listArr) {
			const val = item.trim();
			if (val) {
				list.add(val);
			}
		}
		if (typeof onListChange === 'function') {
			onListChange({
				list,
				listType,
			});
		}
	}, [textareaValue, listType]);

	const onCopyToClipboard = () => {
		if (textareaValue) {
			navigator.clipboard.writeText(textareaValue).then(() => {
				dispatchLog({
					msg: 'Copied to clipboard',
					type: DispatchLogsTypes.Success,
				});
			});
		}
	};

	const onClearList = () => {
		setTextareaValue('');
	};

	const onTextChange = (value: string) => {
		setTextareaValue(value);
	};

	const renderGButton = (type: WhiteBlackListOption) => {
		const oppositeType = type === WhiteBlackLists.Whitelist ? WhiteBlackLists.Blacklist : WhiteBlackLists.Whitelist;
		const typeIcon = type === WhiteBlackLists.Whitelist ? 'shield-plus' : 'shield-off';
		const isDisabled: boolean = !!textareaValue.length && listType !== type;

		if (isDisabled) {
			return (
				<div className={styles.disabledWrapper}>
					<Tooltip content={`The ${oppositeType.toLowerCase()} must be empty for switching type`}>
						<GButton icon={typeIcon} text={capitalCase(type)} value={type} disabled={isDisabled} />
					</Tooltip>
				</div>
			);
		}
		return (
			<GButton
				icon={typeIcon}
				text={capitalCase(type)}
				value={type}
				disabled={isDisabled}
				debugProps={{ dataAttrs: suffixToDataAttr(`_${type.toLowerCase()}_button`, dataAttrs) }}
			/>
		);
	};

	return (
		<div className={styles.wrapper}>
			<ButtonGroup
				defaultValue={listType}
				title={'Choose Listing Type'}
				onChange={(setValues: Set<string>) => setListType(setValues.values().next().value as WhiteBlackListOption)}>
				{renderGButton(WhiteBlackLists.Blacklist)}
				{renderGButton(WhiteBlackLists.Whitelist)}
			</ButtonGroup>
			<Spacer />
			{!textareaValue.length ? null : (
				<div className={styles.textareaActions}>
					<PrimaryText onClick={onClearList} debugProps={{ dataAttrs: [new DataAttribute('id', 'clear_all_button')] }}>
						Clear All
					</PrimaryText>
					<Spacer x={0.5} />|<Spacer x={0.5} />
					<PrimaryText onClick={onCopyToClipboard} debugProps={{ dataAttrs: [new DataAttribute('id', 'copy_all_button')] }}>
						Copy All
					</PrimaryText>
				</div>
			)}
			<Textarea
				required={true}
				value={textareaValue}
				placeholder={textareaPlaceholder}
				onValue={onTextChange}
				rows={6}
				error={errorMsg}
				debugProps={debugProps}
			/>
			<Spacer />
		</div>
	);
};
