import { generateStorageKey } from '@monorepo/controlled/src/stores/helper';
import { makeAutoObservable } from 'mobx';
import dayjs from 'dayjs';
import { makePersistable } from 'mobx-persist-store';
import {
	convertClassInstanceToObj,
	createObjectFromQueryParams,
	isObjectWithAllValues,
	queryParamsToObject,
} from '@monorepo/tools/src/lib/utils/object';

export interface IDateSelection {
	key: string;
	startDate?: Date;
	endDate?: Date;
	label?: string;
}

export class DateSelection {
	startDate: Date;
	endDate: Date;
	key: string;
	label?: string;

	constructor(props: IDateSelection) {
		this.startDate = props?.startDate || new Date();
		this.endDate = props?.endDate || new Date();
		this.label = props?.label;
		this.key = props.key;

		makeAutoObservable(this);
	}

	setEndDate(endDate: Date) {
		this.endDate = endDate;
	}

	setStartDate(startDate: Date) {
		this.startDate = startDate;
	}

	getEndDate(): Date {
		return this.endDate;
	}

	getStartDate(): Date {
		return this.startDate;
	}

	getLabel(): string | undefined {
		return this.label;
	}
}

interface IDateStore {
	primarySelection: IDateSelection;
}

const initialStartDate = dayjs().toDate();
const initialEndDate = dayjs().subtract(7, 'day').toDate();

export const setDateSelectionInQueryParams = (dateSelection: DateSelection, hashParams?: string) => {
	let dateSelectionObj = convertClassInstanceToObj(dateSelection);
	const currentSearchParams = window.location.search.length > 0 ? queryParamsToObject(window.location.search) : {}; // neccessary for keeping old query params
	dateSelectionObj = { ...currentSearchParams, ...dateSelectionObj };

	if (!isObjectWithAllValues(dateSelectionObj) && !dateSelectionObj.label) {
		dateSelectionObj = {
			...dateSelectionObj,
			key: 'initial',
			startDate: initialStartDate,
			endDate: initialEndDate,
			label: 'Last 7 Days',
		};
	}

	const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
	dateSelectionObj.startDate = dateSelectionObj?.startDate?.toLocaleString(undefined, dateOptions).replace(/\//g, '-');
	dateSelectionObj.endDate = dateSelectionObj?.endDate?.toLocaleString(undefined, dateOptions).replace(/\//g, '-');

	delete dateSelectionObj.key;

	if (dateSelectionObj.label !== 'Custom' && dateSelectionObj.label !== 'Days up to yesterday') {
		delete dateSelectionObj.startDate;
		delete dateSelectionObj.endDate;
	}
	// dateSelectionObj.startDate = dateSelectionObj?.startDate?.toISOString()?.substring(0, 10);
	// dateSelectionObj.endDate = dateSelectionObj?.endDate?.toISOString()?.substring(0, 10);

	const newSearchParams = new URLSearchParams(dateSelectionObj);
	window.history.replaceState(null, '', `?${newSearchParams.toString()}${hashParams || ''}`);
};

export class DateStore implements IDateStore {
	primarySelection: DateSelection;

	constructor() {
		const hashParams = window.location.hash; //neccessary for google redirect
		const dateSelectionFromQueryParams = createObjectFromQueryParams(['startDate', 'endDate', 'label']);
		if (
			dateSelectionFromQueryParams !== null &&
			typeof dateSelectionFromQueryParams.startDate === 'string' &&
			typeof dateSelectionFromQueryParams.endDate === 'string'
		) {
			dateSelectionFromQueryParams.startDate = new Date(dateSelectionFromQueryParams.startDate);
			dateSelectionFromQueryParams.endDate = new Date(dateSelectionFromQueryParams.endDate);
			dateSelectionFromQueryParams.key = 'primary';
		}
		this.primarySelection = dateSelectionFromQueryParams?.label
			? new DateSelection(dateSelectionFromQueryParams as { key: string }) //for some reason i can't use "as IDateSelection"
			: new DateSelection(
					JSON.parse(window.localStorage.getItem(generateStorageKey('datestore')) || '{}')?.primarySelection || {
						key: 'initial',
						startDate: initialStartDate,
						endDate: initialEndDate,
						label: 'Last 7 Days',
					}
			  );

		setDateSelectionInQueryParams(this.primarySelection, hashParams);

		makeAutoObservable(this);

		makePersistable(this, {
			name: generateStorageKey('datestore'),
			properties: ['primarySelection'],
			storage: {
				setItem: (key, data) => {
					// dont save initial because of hydration problems with dater component, send only key primary dater
					if (JSON.parse(data)?.primarySelection?.key === 'initial') {
						return;
					}
					window.localStorage.setItem(key, data);
				},
				removeItem: key => window.localStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');
					if (!data.primarySelection) {
						data.primarySelection = {};
					}
					const queryParams = queryParamsToObject(window.location.search);
					if (queryParams.endDate && queryParams.startDate) {
						data.primarySelection.startDate = queryParams.startDate;
						data.primarySelection.endDate = queryParams.endDate;
					}

					if (queryParams.label) {
						data.primarySelection.label = queryParams.label;
					}
					const primary = new DateSelection({
						startDate: new Date(data?.primarySelection?.startDate),
						endDate: new Date(data?.primarySelection?.endDate),
						key: data.primarySelection?.key,
						label: data.primarySelection?.label,
					});

					const hashParams = window.location.hash; //neccessary for google redirect
					setDateSelectionInQueryParams(primary, hashParams);

					return {
						...data,
						primarySelection: data.primarySelection ? primary : undefined,
					};
				},
			},
		});
	}

	setPrimarySelection(date: DateSelection): void {
		this.primarySelection = date;
	}

	getPrimarySelection(): DateSelection {
		return this.primarySelection;
	}
}
