import { DividerX } from '@monorepo/base/src/components/divider/divider';
import { NotificationsMenu } from '../notifications-menu/notifications-menu';
import { UserMenu } from '@monorepo/controlled/src/components/user-menu/user-menu';
import { observer } from 'mobx-react';
import { MainBreadcrumbs } from '../main-breadcrumbs/main-breadcrumbs';
import styles from './main-navbar.module.scss';
import { generateDataAttrs, DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import routes from '../../routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../stores';

export const MainNavbar = observer(() => {
	const { authStore, userStore } = useStores<IAdminxStore>();
	return (
		<nav className={styles.navBar} {...generateDataAttrs([new DataAttribute('id', 'main_navbar')])}>
			<div className={styles.begin}>
				<MainBreadcrumbs />
			</div>
			<div className={styles.end}>
				<NotificationsMenu />
				<DividerX alignSelf="center" height="25px" />
				<UserMenu {...{ authStore, userStore }} loginPageRoute={routes.general.home()} />
			</div>
		</nav>
	);
});
