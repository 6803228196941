import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useCampaignHeaders } from '../../../../modules/hooks/theaders/campaigns.headers';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import {
	IPerformanceOptionsFilterType,
	IPerformanceOptionsSortBy,
	PerformancesColumnsSnakeCase,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';

//no need for now - DEPRECATED
export const CreativeCampaigns = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const { creativeId } = useParams();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const reportList = campaignStore.getListStore();

	if (creativeId) {
		const creativeFilter = [
			{
				column: PerformancesColumnsSnakeCase.CreativeId,
				filterType: IPerformanceOptionsFilterType.Include,
				value: [creativeId],
			},
		];

		useReportsList(reportList, {
			groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
			reportName: 'adxCampaigns',
			sortBy: IPerformanceOptionsSortBy.Id,
			filters: creativeFilter,
		});
	}
	const { columns } = useCampaignHeaders();

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Campaigns</PagebarTitle>
			</Pagebar>
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'creatives_campaign_table')] }}
				columns={columns}
				data={reportList.getData()?.getData() || []}
			/>
		</Page>
	);
});
