import { useEffect, useState } from 'react';
import cn from 'classnames';
import { IMetric } from '../chart';
import { PerformanceEnumarableLabels, PerformanceMetricsSymbols } from '@monorepo/tools/src/lib/enums/performance-labels';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Select } from '../../select/select';
import { Menu } from '../../menu/menu';
import { Skeleton } from '../../skeleton/skeleton';
import { ChartColors } from '../chart';
import styles from './chart-legend.module.scss';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { isStringInfinityOrNaN } from '@monorepo/tools/src/lib/utils/number';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

const theme = getTheme();
interface ILegendItem {
	metric: IMetric;
	color: string;
	index: number;
	toggleMetric: (index: number) => void;
	replaceMetric: (index: number, afterMetric: PerformanceEnumarableLabels) => void;
	isLoading?: boolean;
	legendOptions?: string[];
	debugProps?: IDebugProps;
	isDisableDropdown?: boolean;
}
const toFixedWithoutZeros = (num: number, precision: number) => `${Number.parseFloat(num.toFixed(precision))}`;

const LegendItem = (props: ILegendItem) => {
	const { isLoading, color, toggleMetric, metric, replaceMetric, index, legendOptions, isDisableDropdown } = props;

	const { label, sum, selected, formatter } = metric;
	const [isMetricOpen, setIsMetricOpen] = useState<boolean>(false);
	const formattedSum = formatter ? formatter(sum) + '' : `${sum}`;
	const isFormattedWithSymbol = formattedSum.includes('$') || formattedSum.includes('%');
	const symbol = isFormattedWithSymbol ? '' : PerformanceMetricsSymbols.get(label as PerformanceEnumarableLabels);
	let parsedSum: string = sum + '';

	if (typeof sum !== 'undefined' && symbol === '%') {
		parsedSum = toFixedWithoutZeros(sum * 100, 2);
	}
	const sumIsNan = typeof sum === 'undefined' || typeof sum === 'object' ? true : isNaN(sum);

	return (
		<div className={cn(styles.itemWrapper, { [styles.isLoading]: isLoading })}>
			<div className={styles.item} {...generateDataAttrs([new DataAttribute('id', `chart_legend_${index + 1}`)])}>
				<div
					className={`${styles.marker} ${selected ? '' : styles.hide}`}
					style={{ backgroundColor: color }}
					{...generateDataAttrs([new DataAttribute('id', `chart_legend_${index + 1}_${selected ? 'show' : 'hide'}`)])}></div>
				<div className={styles.content}>
					<div
						className={styles.labelWrapper}
						onClick={() => {
							sendGtagEvent({
								action: TrackingActions.Click,
								category: EVENTS.CLICK.INDEX_PAGE.CHART.LEGEND.TOGGLE,
								value: !metric.selected ? 'Enable legend' : 'Disable legend',
							});
							toggleMetric(index);
						}}
					>
						<div className={styles.labelContainer}>
							<span className={styles.label}>{label}</span>
						</div>
						{isLoading ? (
							<div className={styles.skeletonSum}>
								{
									<Skeleton
										is={isLoading}
										fullWidth
										debugProps={{ dataAttrs: [new DataAttribute('id', `chart_legend_${index + 1}_skeleton`)] }}
									/>
								}
							</div>
						) : !sumIsNan ? (
							symbol ? (
								<div
									className={styles.sum}
									{...generateDataAttrs([new DataAttribute('id', `chart_legend_${index + 1}_sum`)])}>
									{symbol === '$' ? symbol : ''}
									{formatter
										? !isStringInfinityOrNaN(Number.parseFloat(parsedSum))
											? formatter(Number.parseFloat(parsedSum))
											: 0
										: parsedSum}
									{symbol === '%' ? symbol : ''}
								</div>
							) : (
								<div
									className={styles.sum}
									{...generateDataAttrs([new DataAttribute('id', `chart_legend_${index + 1}_sum`)])}>
									{formatter ? formatter(sum) : sum}
								</div>
							)
						) : formatter ? (
							formatter(metric?.dataset?.data?.reduce((acc, v) => acc + Number.parseFloat(v.toFixed(2)), 0)) ?? '-'
						) : (
							'-'
						)}
					</div>
					{!isDisableDropdown && (
						<Icon
							isMFP={true}
							onClick={() => setIsMetricOpen(!isMetricOpen)}
							color={theme.titleColor}
							radius={false}
							className={styles.arrow}
							debugProps={{ dataAttrs: [new DataAttribute('id', `chart_legend_${index + 1}_dropdown_expend`)] }}>
							chevron-down
						</Icon>
					)}
				</div>
			</div>
			<Menu
				debugProps={{ dataAttrs: [new DataAttribute('id', `chart_legend_menu_${index + 1}`)] }}
				className={styles.menuMinWidth}
				unstyled={true}
				isOpen={isMetricOpen}
				alignToLeft={true}
				onClose={() => setIsMetricOpen(false)}>
				<Select
					isSearch
					placeholder={'Search Metric'}
					values={legendOptions || []}
					onClick={value => {
						replaceMetric(index, value as PerformanceEnumarableLabels);
						setIsMetricOpen(false);
					}}
					defaultValue={label}
				/>
			</Menu>
		</div>
	);
};

interface ILegend {
	metrics: IMetric[];
	toggleMetric: (index: number) => void;
	replaceMetric: (index: number, afterMetric: PerformanceEnumarableLabels) => void;
	isLoading?: boolean;
	legendOptions?: string[];
	debugProps?: IDebugProps;
}

export const Legend = (props: ILegend) => {
	const { metrics, toggleMetric, replaceMetric, isLoading, legendOptions } = props;
	const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
	useEffect(() => {
		setSelectedMetrics(metrics.map(x => x.label));
	}, [metrics]);
	return (
		<div className={`${styles.legend}`} {...generateDataAttrs([new DataAttribute('id', 'chart_legend')])}>
			{Array.from(metrics.values()).map((metricValue, index) => {
				if (!metricValue.visible) {
					return null;
				}

				if (metricValue.sum === -1) {
					metricValue.sum = 0;
				}

				return (
					<LegendItem
						legendOptions={legendOptions?.filter(v => !selectedMetrics.includes(v))}
						isLoading={isLoading}
						key={ChartColors[index]}
						metric={metricValue}
						index={index}
						color={ChartColors[index]}
						toggleMetric={toggleMetric}
						replaceMetric={replaceMetric}
						isDisableDropdown={legendOptions?.length === Object.keys(metrics).length}
					/>
				);
			})}
		</div>
	);
};
