import { ICidCreateForm, ICidEditForm, CidModel } from './../../../models/cid.model';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { CidsApi } from '../../../apis/cids.api';
import { HttpError } from '../../../models/http-error.model';

export class CidsCrudStore extends BaseCrudStore<CidModel, ICidCreateForm, ICidEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: CidsApi,
			model: CidModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidCid before calling this function
	 * @returns
	 */

	public getCreateFormData(): ICidCreateForm {
		return {
			source: {
				current_cid: this.getData().getCurrentCid(),
				new_cid: this.getData().getNewCid(),
			},
			targets: this.getData().getTargetCids(),
		};
	}

	public getEditFormData(): ICidEditForm {
		return {
			source: {
				current_cid: this.getData().getCurrentCid(),
				new_cid: this.getData().getNewCid(),
			},
			targets: this.getData().getTargetCids(),
		};
	}

	public isValid(): boolean {
		this.formStore.reset();
		const current_cid = this.getData().getCurrentCid();
		const new_cid = this.getData().getNewCid();
		const targets = this.getData().getTargetCids();

		if (!current_cid) {
			this.formStore.addError(new FormError('current_cid', 'Please provide a Current CID'));
		} else {
			if (current_cid === new_cid) {
				this.formStore.addError(new FormError('new_cid', 'Please provide a New CID that is different from Current CID'));
			}
			if (current_cid.length < 4 || current_cid.length > 5) {
				this.formStore.addError(new FormError('current_cid', 'Please provide a current CID that is 4 or 5 characters long'));
			}
		}

		if (!new_cid) {
			this.formStore.addError(new FormError('new_cid', 'Please provide a New CID'));
		} else {
			if (new_cid.length < 4 || new_cid.length > 5) {
				this.formStore.addError(new FormError('new_cid', 'Please provide a New CID that is 4 or 5 characters long'));
			}
		}

		if (targets.length > 0) {
			targets.forEach((target, index) => {
				if (!target.getCurrentTargetCid()) {
					this.formStore.addError(new FormError(`targets[${index}].current_cid`, 'Please provide a Current CID'));
				} else {
					if (target.getCurrentTargetCid() === target.getNewTargetCid()) {
						this.formStore.addError(
							new FormError(`targets[${index}].new_cid`, 'Please provide a New CID that is different from Current CID')
						);
					}

					if ((target.getCurrentTargetCid() as string).length < 4 || (target.getCurrentTargetCid() as string).length > 5) {
						this.formStore.addError(
							new FormError(`targets[${index}].current_cid`, 'Please provide a Current CID that is 4 or 5 characters long')
						);
					}
				}

				if (!target.getNewTargetCid()) {
					this.formStore.addError(new FormError(`targets[${index}].new_cid`, 'Please provide a New CID'));
				} else {
					if ((target.getNewTargetCid() as string).length < 4 || (target.getNewTargetCid() as string).length > 5) {
						this.formStore.addError(
							new FormError(`targets[${index}].new_cid`, 'Please provide a New CID that is 4 or 5 characters long')
						);
					}
				}
			});
		}

		return this.formStore.getIsValid();
	}

	public clearData(): void {
		this.data = new CidModel();
	}
}
