import { primitives } from '../types/primitives';
import { toJS, isObservableObject, isObservableArray, isObservableSet, isObservableMap } from 'mobx';

// Swap key to value and value to key
export const objectFlip = (obj: { [key: string]: string }) => {
	return Object.keys(obj).reduce((ret: { [key: string]: string }, key: string) => {
		ret[obj[key]] = key;
		return ret;
	}, {});
};

export const objectToSelectOptions = (obj: Record<string, string>) => {
	return Object.entries(obj).map(([key, value]) => ({
		label: value,
		value: key,
	}));
};

export const isExists = (obj: primitives): boolean => {
	return obj !== null && obj !== undefined;
};

export function shallowEqualObjects(objA: Record<string, unknown>, objB: Record<string, unknown>) {
	if (objA === objB) {
		return true;
	}

	if (!objA || !objB) {
		return false;
	}

	const aKeys = Object.keys(objA);
	const bKeys = Object.keys(objB);
	const len = aKeys.length;

	if (bKeys.length !== len) {
		return false;
	}

	for (let i = 0; i < len; i++) {
		const key = aKeys[i];

		if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
			return false;
		}
	}

	return true;
}

export const getKeyByValue = (object: any, value: string | number) => {
	return Object.keys(object).find(key => object[key] === value);
};

export const getObjectValue = (object: any, keysArray: string[]) => {
	let result = object;

	for (const key of keysArray) {
		// eslint-disable-next-line no-prototype-builtins
		if (result && result.hasOwnProperty(key)) {
			result = result[key];
		} else {
			return undefined; // Key not found, return undefined
		}
	}

	return result;
};

export const convertClassInstanceToObj = (classInstance: any) => {
	const obj: any = {};
	for (const key in classInstance) {
		// eslint-disable-next-line no-prototype-builtins
		if (classInstance.hasOwnProperty(key)) {
			obj[key] = classInstance[key];
		}
	}
	return obj;
};

export const createInstanceFromQueryParams = <T>(MyClass: new (...args: any[]) => T, propertyNames: (keyof T)[]): T | null => {
	const properties = createObjectFromQueryParams(propertyNames);
	if (!isObjectWithAllValues(properties)) {
		return null;
	}
	// Create an instance of the class with the extracted properties
	const instance = new MyClass(properties) as T;

	return instance;
};

export const createObjectFromQueryParams = (propertyNames: (string | number | symbol)[]) => {
	const params = new URLSearchParams(window.location.search);
	const properties: Record<string, any> = {} as Record<string, string | null>;

	// Extract the specified properties from the query parameters
	propertyNames.forEach(propName => {
		if (typeof propName !== 'symbol') {
			properties[propName] = params.get(propName as string);
		}
	});

	if (Object.entries(properties).length === 0) {
		return null;
	}

	return properties;
};

export const queryParamsToObject = (queryString: string) => {
	const searchParams = new URLSearchParams(queryString);
	const obj: any = {};

	for (const [key, value] of searchParams) {
		if (obj[key]) {
			if (!Array.isArray(obj[key])) {
				obj[key] = [obj[key]];
			}
			obj[key].push(value);
		} else {
			obj[key] = value;
		}
	}

	if (!isObjectWithAllValues(obj)) {
		console.log('return null');
		return null;
	}

	return obj;
};

export const isObjectWithAllValues = (obj: any) => {
	if (obj === null) {
		return false;
	}

	if (Object.entries(obj).length === 0) {
		return false;
	}

	for (const key in obj) {
		// eslint-disable-next-line no-prototype-builtins
		if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === undefined || obj[key] === 'null')) {
			return false;
		}
	}
	return true;
};

export const isArrayOfObjectHasAllProp = (array: Record<string, any>[]): boolean => {
	if (array.length === 0) {
		return false;
	}

	for (let i = 0; i < array.length; i++) {
		const obj = array[i];
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				// Check for undefined, null, or empty string
				if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
					return false;
				}
			}
		}
	}
	return true;
};

export type Difference = { key: string; old: any; new: any };

export const deepObjectDiff = (obj1: Record<string, any>, obj2: Record<string, any>, parentKey: string | null = null): Difference[] => {
	let differences: Difference[] = [];
	obj1 = JSON.parse(JSON.stringify(obj1));
	obj2 = JSON.parse(JSON.stringify(obj2));

	const deepEqual = (a: any, b: any): boolean => {
		if (Array.isArray(a) && Array.isArray(b)) {
			return a.length === b.length && a.every((value, index) => deepEqual(value, b[index]));
		} else if (typeof a === 'object' && typeof b === 'object') {
			const keysA = Object.keys(a);
			const keysB = Object.keys(b);
			return keysA.length === keysB.length && keysA.every(key => deepEqual(a[key], b[key]));
		} else {
			return a === b;
		}
	};

	for (const key in obj1) {
		const currentKey = parentKey ? `${parentKey}.${key}` : key;

		if (obj1.hasOwnProperty(key)) {
			if (obj2.hasOwnProperty(key)) {
				if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
					if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
						if (!deepEqual(obj1[key], obj2[key])) {
							differences.push({
								key: currentKey,
								old: obj1[key],
								new: obj2[key],
							});
						}
					} else {
						differences = differences.concat(deepObjectDiff(obj1[key], obj2[key], currentKey));
					}
				} else if (!deepEqual(obj1[key], obj2[key])) {
					differences.push({
						key: currentKey,
						old: obj1[key],
						new: obj2[key],
					});
				}
			} else {
				differences.push({
					key: currentKey,
					old: obj1[key],
					new: undefined,
				});
			}
		}
	}

	for (const key in obj2) {
		const currentKey = parentKey ? `${parentKey}.${key}` : key;

		if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
			differences.push({
				key: currentKey,
				old: undefined,
				new: obj2[key],
			});
		}
	}

	return differences;
};
