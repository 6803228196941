import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Card } from '@monorepo/base/src/components/card/card';
import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { CreateTargeting } from './create-targeting';
import { SelectTargeting } from './select-targeting';
import { TargetingModel } from '../../../../models/targeting.model';
import { Target } from '../../../../enums/target';
import styles from './targeting.module.scss';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export const Targeting: FC<{ isDuplicate?: boolean }> = observer(({ isDuplicate }) => {
	const { campaignStore } = useStores<IAdminxStore>();

	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const currentTarget = campaign.getTargeting();
	const [target, setTarget] = useState(currentTarget?.getId() && !isDuplicate ? Target.Shared : Target.Custom);
	const [originalCampaignTargetingId, setOriginalCampaignTargetingId] = useState<undefined | id>(undefined);
	const [isFirstRender, setIsFirstRender] = useState(true);

	useEffect(() => {
		if (isDuplicate && campaign?.getTargeting()?.getId() && isFirstRender) {
			setOriginalCampaignTargetingId(campaign?.getTargeting()?.getId());
			campaign?.getTargeting()?.setId(undefined as any);
			setIsFirstRender(false);
		} else if (campaign?.getTargeting()?.getId() && !isDuplicate) {
			setTarget(Target.Shared);
		}
	}, [campaign?.getTargeting()?.getId()]);

	useEffect(() => {
		if (target === Target.Custom) {
			campaign.setTargeting(new TargetingModel());
		}
	}, [target]);

	return (
		<Card
			id={'targeting'}
			title={'Targeting'}
			icon={'target-05'}
			className={styles.spaceBetween}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'targeting_section')] }}>
			{originalCampaignTargetingId ? <div>Previously selected targeting was {originalCampaignTargetingId}</div> : ''}
			<ButtonGroup defaultValue={target} onChange={(setValues: Set<string>) => setTarget(setValues.values().next().value)}>
				<GButton
					icon={'link-03'}
					text={Target.Shared}
					value={Target.Shared}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'shared_targeting')] }}
				/>
				<GButton
					icon={'tool-02'}
					text={Target.Custom}
					value={Target.Custom}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'custom_targeting')] }}
				/>
			</ButtonGroup>
			{target === Target.Custom ? '' : <SelectTargeting />}
			{!currentTarget?.getId() && target !== Target.Custom ? '' : <CreateTargeting />}
		</Card>
	);
});
