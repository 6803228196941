export enum PermissionActions {
	ReportRead = 'report:read',
	AccountRead = 'account:read',
	UsersRead = 'user:read',
	UsersWrite = 'user:write',
	UsersOnline = 'user:online',
	UserInvite = 'user:invite',
	Public = 'user:public',
	Internal = 'user:internal',
}
