import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { url } from '@monorepo/tools/src/lib/types/url';
import { PerformanceEnumarableLabels } from '@monorepo/tools/src/lib/enums/performance-labels';
import { Status } from '../enums/status';

export const availablePerformanceToMetrics = {
	[PerformanceEnumarableLabels.Auctions]: 'auctions',
	[PerformanceEnumarableLabels.Wins]: 'wins',
	[PerformanceEnumarableLabels.Impressions]: 'impressions',
	[PerformanceEnumarableLabels.Clicks]: 'clicks',
	[PerformanceEnumarableLabels.Conversions]: 'conversions',
	[PerformanceEnumarableLabels.Cost]: 'cost',
	[PerformanceEnumarableLabels.Revenue]: 'conversionValue',
	[PerformanceEnumarableLabels.WinRate]: 'winRate',
	[PerformanceEnumarableLabels.ROAS]: 'roas',
	[PerformanceEnumarableLabels.CTR]: 'ctr',
	[PerformanceEnumarableLabels.CVR]: 'lpCvr',
	[PerformanceEnumarableLabels.Gain]: 'gain',
	[PerformanceEnumarableLabels.OptimalGain]: 'optGain',
	[PerformanceEnumarableLabels.OptimalCost]: 'optCost',
	[PerformanceEnumarableLabels.CPA]: 'cpa',
	[PerformanceEnumarableLabels.AvgBidFloor]: 'avgBidFloor',
	[PerformanceEnumarableLabels.AvgBidToWin]: 'avgBidToWin',
	[PerformanceEnumarableLabels.CPC]: 'cpc',
	[PerformanceEnumarableLabels.CPM]: 'cpm',
	[PerformanceEnumarableLabels.MaxBidToWin]: 'maxBidToWin',
	[PerformanceEnumarableLabels.MinBidToWin]: 'minBidToWin',
	[PerformanceEnumarableLabels.MinBidFloor]: 'minBidFloor',
	[PerformanceEnumarableLabels.MaxBidFloor]: 'maxBidFloor',
};

export type TPerformanceEnumerable = string; // should be one of availablePerformanceToMetrics

export interface IPerformanceItem {
	id?: id;
	name?: string;
	campaign?: string; // campaign name
	campaignName?: string; // campaign name
	domain?: url;
	page?: url;
	auctions?: number;
	avgBidFloor?: number;
	avgBidToWin?: number;
	clicks?: number;
	conversionValue?: number;
	conversions?: number;
	cost?: number;
	cpa?: number;
	cpc?: number;
	cpm?: number;
	ctr?: number;
	impressions?: number;
	lpCvr?: number;
	maxBidFloor?: number;
	maxBidToWin?: number;
	minBidFloor?: number;
	minBidToWin?: number;
	roas?: number;
	winRate?: number;
	wins?: number;
	campaignId?: id;
	campaignGroupId?: id;
	campaignGroupName?: string;
	advertiserId?: id;
	advertiser?: string;
	creativeId?: id;
	creativeGroupId?: id;
	creativeImageUrl?: url;
	creativeGroupDesign?: string;
	testId?: id;
	testName?: string;
	testGroupName?: string;
	date?: string;
	hour?: string;
	publisherId?: id;
	browserName?: string;
	state?: string;
	city?: string;
	osName?: string;
	auctionType?: string;
	preferredDealId?: string;
	preferredDealName?: string;
	geo?: string;
	bid?: string;
	bidKey?: string;
	bidKeyId?: id;
	gain?: number;
	optGain?: number;
	optCost?: number;
	bidIsEditable?: boolean;
	creativeBrandName?: string;
	creativeSize?: string;
	responses?: string[]; // ?
	dailyBudget?: number;
	status?: Status;
}

interface IPerformanceResponse {
	success: boolean;
	errorMessage: string | null;
	data: PerformanceModel[];
	total: number;
	limited: boolean;
	footer: PerformanceModel;
	columns: string[][]; // no needed
	graphData: object; // no needed
}

export class PerformanceResponse {
	public success: boolean;
	public errorMessage: string | null;
	public data: PerformanceModel[];
	public total: number;
	public limited: boolean;
	public footer: PerformanceModel;
	public columns: string[][]; // no needed
	public graphData: object; // no needed

	constructor(performanceResponse: IPerformanceResponse) {
		this.success = performanceResponse.success;
		this.errorMessage = performanceResponse.errorMessage;
		this.data = performanceResponse.data;
		this.total = performanceResponse.total;
		this.limited = performanceResponse.limited;
		this.footer = performanceResponse.footer;
		this.columns = performanceResponse.columns;
		this.graphData = performanceResponse.graphData;
		makeAutoObservable(this);
	}

	public getFooter(): PerformanceModel {
		return this.footer;
	}

	public getData(): PerformanceModel[] {
		return this.data;
	}
}

export class PerformanceModel implements IPerformanceItem {
	public id?: id;
	public name?: string;
	public design?: string;
	public campaign?: string; // ?
	public campaignName?: string;
	public domain?: url;
	public page?: url;
	public auctions?: number;
	public avgBidFloor?: number;
	public avgBidToWin?: number;
	public clicks?: number;
	public conversionValue?: number;
	public conversions?: number;
	public cost?: number;
	public cpa?: number;
	public cpc?: number;
	public cpm?: number;
	public ctr?: number;
	public impressions?: number;
	public lpCvr?: number;
	public maxBidFloor?: number;
	public maxBidToWin?: number;
	public minBidFloor?: number;
	public minBidToWin?: number;
	public roas?: number;
	public winRate?: number;
	public wins?: number;
	public campaignId?: id;
	public campaignGroupId?: id;
	public campaignGroup?: string;
	public advertiserId?: id;
	public advertiserName?: string;
	public advertiser?: string;
	public creativeId?: id;
	public creativeBrandName?: string;
	public creativeImageUrl?: url;
	public creative_resource_type?: string; // DEPRECATED in new reporting api
	public creativeSize?: string;
	public creativeGroupId?: id;
	public creativeGroupDesign?: string;
	public testId?: id;
	public testName?: string;
	public testGroupName?: string;
	public date?: string;
	public hour?: string;
	public publisherId?: id;
	public browserName?: string;
	public state?: string;
	public city?: string;
	public auctionType?: string;
	public geo?: string;
	public osName?: string;
	public bid?: string;
	public bidKey?: string;
	public bidKeyId?: id;
	public preferredDealId?: string;
	public preferredDealName?: string;
	public gain?: number;
	public optGain?: number;
	public optCost?: number;
	public deal_id?: id;
	public bidIsEditable?: boolean;
	public responses?: string[];
	public dailyBudget?: number;
	public campaignGroupName?: string;
	public status?: Status;

	constructor(performance: IPerformanceItem) {
		this.id = performance.id;
		this.campaign = performance.campaign;
		this.campaignName = performance.campaignName;
		this.page = performance.page;
		this.domain = performance.domain;
		this.auctions = performance.auctions;
		this.avgBidFloor = performance.avgBidFloor;
		this.avgBidToWin = performance.avgBidToWin;
		this.clicks = performance.clicks;
		this.conversionValue = performance.conversionValue;
		this.conversions = performance.conversions;
		this.cost = performance.cost;
		this.cpa = performance.cpa;
		this.cpc = performance.cpc;
		this.cpm = performance.cpm;
		this.ctr = performance.ctr;
		this.impressions = performance.impressions;
		this.lpCvr = performance.lpCvr;
		this.maxBidFloor = performance.maxBidFloor;
		this.maxBidToWin = performance.maxBidToWin;
		this.minBidFloor = performance.minBidFloor;
		this.minBidToWin = performance.minBidToWin;
		this.roas = performance.roas;
		this.winRate = performance.winRate;
		this.wins = performance.wins;
		this.campaignId = performance.campaignId;
		this.campaignGroupId = performance.campaignGroupId;
		this.advertiserId = performance.advertiserId;
		this.creativeId = performance.creativeId;
		this.creativeGroupId = performance.creativeGroupId;
		this.creativeGroupDesign = performance.creativeGroupDesign;
		this.testId = performance.testId;
		this.testName = performance.testName;
		this.testGroupName = performance.testGroupName;
		this.date = performance.date;
		this.browserName = performance.browserName;
		this.state = performance.state;
		this.city = performance.city;
		this.auctionType = performance.auctionType;
		this.geo = performance.geo;
		this.osName = performance.osName;
		this.publisherId = performance.publisherId;
		this.bid = performance.bid;
		this.bidKey = performance.bidKey;
		this.bidKeyId = performance.bidKeyId;
		this.preferredDealId = performance.preferredDealId;
		this.preferredDealName = performance.preferredDealName;
		this.optCost = performance.optCost;
		this.optGain = performance.optGain;
		this.cost = performance.cost;
		this.bidIsEditable = performance.bidIsEditable;
		this.creativeBrandName = performance.creativeBrandName;
		this.creativeSize = performance.creativeSize;
		this.responses = performance.responses;
		this.hour = performance.hour;
		this.creativeImageUrl = performance.creativeImageUrl;
		this.advertiser = performance.advertiser;
		this.campaignGroupName = performance.campaignGroupName;
		this.dailyBudget = performance.dailyBudget;
		this.creativeGroupId = performance.creativeGroupId;
		this.campaignGroupName = performance.campaignGroupName;
		this.campaignGroupId = performance.campaignGroupId;
		this.status = performance.status?.trim() as Status;
	}
}
