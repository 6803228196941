import { useEffect } from 'react';
import { ReportFilter } from './data-stores/old-report.types';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import dayjs from 'dayjs';
import { TableStore } from './table-stores/table.store';

interface IuseTableStore<T> {
	hiddenFilters?: ReportFilter[];
	reportTableStore: TableStore<T>;
}

export const useTableStore = <T>({ hiddenFilters, reportTableStore }: IuseTableStore<T>) => {
	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');

	useEffect(() => {
		const reportParams = {
			periodStart: primaryFromDate,
			periodEnd: primaryToDate,
		};
		if (hiddenFilters) {
			reportTableStore.setHiddenFilters(hiddenFilters);
		}
		reportTableStore.fetchReport(reportParams);
	}, [startDate, endDate, JSON.stringify(hiddenFilters)]);
};
