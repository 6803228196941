import { observer } from 'mobx-react';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useJobActions } from '../../../modules/hooks/actions/job.actions';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useJobHeaders } from '../../../modules/hooks/theaders/job.headers';
import { useList } from '../../../modules/hooks/tools/use-list';
import { useStateBetweenPages } from '../../../modules/hooks/tools/use-state-between-pages';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { JobModel } from '../../../modules/models/job.model';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '../../../modules/hooks/tools/use-route';

const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'jobs_pagebar')] };
const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'jobs_table')] };
const actionsDebugProps = { dataAttrs: [new DataAttribute('id', 'jobs_actions')] };

export const Jobs: FC = observer(() => {
	const { jobStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const jobListStore = jobStore.getListStore();
	const stateBetweenPages = useStateBetweenPages(() => jobListStore.getList().length !== 0);

	useList<JobModel>(jobStore.getListStore(), undefined, stateBetweenPages);

	const { columns } = useJobHeaders();
	const { SelectedActions, IndexActions, FiltersActions } = useJobActions({ debugProps: actionsDebugProps });

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Jobs</PagebarTitle>
			</Pagebar>
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				columns={columns}
				debugProps={tableDebugProps}
				data={jobStore.getListStore().getList()}
				isLoading={jobStore.getListStore().getIsLoading()}>
				{() => {
					return {
						SelectedActions,
						IndexActions,
						FiltersActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
