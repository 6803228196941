import { TagTypes } from '@monorepo/base/src/components/tag/tag';
// import { Colors } from './colors';

export interface IStatus {
	status?: Status;
}

export enum Statuses {
	Active = 'ACTIVE',
	Paused = 'PAUSED',
	NoBalance = 'NO BALANCE',
	New = 'NEW',
	Ended = 'ENDED',
}

export type Status = Statuses.Active | Statuses.Paused | Statuses.NoBalance | Statuses.New | Statuses.Ended;

// export const mapToColor = (status?: Status) => {
// 	if (status === Statuses.Active) {
// 		return Colors.Success;
// 	}
// 	if (status === Statuses.New) {
// 		return Colors.New;
// 	}
// 	if (status === Statuses.NoBalance) {
// 		return Colors.Warning;
// 	}
// 	if (status === Statuses.Paused) {
// 		return Colors.Error;
// 	}
// 	return Colors.Info;
// };

export const mapToTagStatus = (status?: Status) => {
	if (status === Statuses.Active) {
		return TagTypes.Success;
	}
	if (status === Statuses.New) {
		return TagTypes.New;
	}
	if (status === Statuses.NoBalance) {
		return TagTypes.Warning;
	}
	if (status === Statuses.Paused) {
		return TagTypes.Paused;
	}
	return TagTypes.Info;
};

export const mapToIcon = (status: Status) => {
	if (status === Statuses.Active) {
		return 'play';
	}
	if (status === Statuses.New) {
		return 'message-plus-circle';
	}
	if (status === Statuses.NoBalance) {
		return 'credit-card-x';
	}
	if (status === Statuses.Paused) {
		return 'pause-square';
	}
	return 'info-circle';
};
