import { useRef, useState } from 'react';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { Nav, NavItem } from '@monorepo/base/src/components/nav/nav';
import styles from './segments.module.scss';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IconFonts } from '../icon/icon';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';

const theme = getTheme();
export interface ISegmentCell<TModel, TPerformance> {
	model: TModel;
	performanceItem: TPerformance;
}

export interface ISegment<TModel, TPerformance, TColumns> {
	title: string;
	performanceGroupBy: TColumns | null;
	renderCell?: (obj: ISegmentCell<TModel, TPerformance>) => JSX.Element;
	sortFn?: (a: TPerformance, b: TPerformance) => number;
}

interface ISegmentsComponent<TModel, TPerformance, TColumns> {
	availableSegments: ISegment<TModel, TPerformance, TColumns>[];
	onClick: (segment: ISegment<TModel, TPerformance, TColumns> | null) => void;
	currentSegments?: Set<ISegment<TModel, TPerformance, TColumns>>;
	isComingSoon?: ISegment<TModel, TPerformance, TColumns>[];
	debugProps?: IDebugProps;
}

export function Segments<TModel, TPerformance, TColumns>(props: ISegmentsComponent<TModel, TPerformance, TColumns>) {
	const { availableSegments, onClick, currentSegments, isComingSoon, debugProps } = props;
	const [isSegmentsMenu, setSegmentsMenu] = useState<boolean>(false);
	const segmentsMenu = useRef<HTMLDivElement>(null);
	const currentSegmentTitle = currentSegments && currentSegments?.size > 0 ? currentSegments?.values().next()?.value?.title : '';
	const { dataAttrs } = debugProps || {};

	const onSegmentMenu = () => {
		setSegmentsMenu(!isSegmentsMenu);
	};

	const isCurrentSegment = (segment: ISegment<TModel, TPerformance, TColumns> | { title: string; performanceGroupBy: null }) => {
		return currentSegments && segment.performanceGroupBy === currentSegments?.values().next()?.value?.performanceGroupBy;
	};

	const _availableSegments: ISegment<TModel, TPerformance, TColumns>[] = [
		{
			title: 'None',
			performanceGroupBy: null,
		},
		...availableSegments,
	];

	const onSegmentClick = (segment: ISegment<TModel, TPerformance, TColumns> | null) => {
		setSegmentsMenu(false);
		onClick(segment?.performanceGroupBy ? segment : null);
	};

	return (
		<div className={styles.wrapper} ref={segmentsMenu}>
			<SecondaryText
				className={`${styles.segmentBtn} ${currentSegments && currentSegments?.size > 0 ? styles.active : styles.notActive}`}
				icon={'layers-three-01'}
				font={IconFonts.Outlined}
				iconColor={theme.actionLinerActionIconsColor}
				iconSize={'18px'}
				debugProps={{ dataAttrs: suffixToDataAttr('_segment', dataAttrs) }}
				onClick={onSegmentMenu}>
				<div className={styles.segmentText}>Segment</div>
				{currentSegmentTitle ? <div className={styles.currentSegmentTitle}>{currentSegmentTitle}</div> : null}
			</SecondaryText>
			<Menu
				isOpen={isSegmentsMenu}
				alignToRight={true}
				className={styles.menu}
				onClose={() => setSegmentsMenu(false)}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'segment_menu')] }}>
				<Nav list>
					{_availableSegments.map(segment => {
						return (
							<NavItem
								disabled={Boolean(isComingSoon?.find(_segment => _segment.title === segment.title))}
								key={segment.title}
								debugProps={{ dataAttrs: suffixToDataAttr(`_${segment.title.toLowerCase()}`, dataAttrs) }}
								className={`${isCurrentSegment(segment) ? styles.highlight : ''}`}
								onNavItem={() => onSegmentClick(segment?.performanceGroupBy ? segment : null)}>
								<div className={`${styles.item}`}>
									<div>{segment.title}</div>
								</div>
							</NavItem>
						);
					})}
				</Nav>
			</Menu>
		</div>
	);
}
