import { ColumnDef, createColumnHelper, RowData, sortingFns } from '@tanstack/react-table';
import { currencyFormat, percents } from '@monorepo/tools/src/lib/utils/number';
import { IPerformanceItem, PerformanceModel } from '../../models/performance.model';
import { IMergedHeadersReturn } from './index.headers';

// TODO - think maybe in meta object should be column type and not the isPerformance
// TODO - meta interface with total line

interface IuseWithPerformanceHeaders {
	summaryItem?: IPerformanceItem;
	customColumns?: { id: string; header: string }[];
}

const columnHelper = createColumnHelper<PerformanceModel>();

declare module '@tanstack/table-core' {
	// eslint-disable-next-line
	interface ColumnMeta<TData extends RowData, TValue> {
		isPerformance?: boolean;
		colSpan?: number;
		// TODO add total line here
	}
}
export const useWithPerformanceHeaders = (props: IuseWithPerformanceHeaders = {}): IMergedHeadersReturn<PerformanceModel> => {
	const { summaryItem = {}, customColumns = [] } = props;

	const columns = [
		columnHelper.accessor('date', {
			header: 'Date',
			cell: ({ row }) => <span>{row.getValue('date')}</span>,
			footer: () => <span></span>,
		}),
		...customColumns.map(column =>
			columnHelper.accessor(column.id, {
				header: column.header,
				cell: ({ row }) => <span>{row.getValue(column.id)}</span>,
				// footer: () => <span>{summaryItem[column.id]}</span>,
				sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
				meta: {
					isPerformance: true,
				},
			})
		),
		columnHelper.accessor('auctions', {
			header: 'Auctions',
			cell: ({ row }) => <span>{(row.getValue('auctions') as number)?.toLocaleString() ?? '-'}</span>,
			footer: () => <span>{summaryItem?.auctions?.toLocaleString()}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('wins', {
			header: 'Wins',
			cell: ({ row }) => <span>{(row.getValue('wins') as number)?.toLocaleString() ?? '-'}</span>,
			footer: () => <span>{summaryItem?.wins?.toLocaleString()}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('impressions', {
			header: 'Impressions',
			cell: ({ row }) => <span>{(row.getValue('impressions') as number)?.toLocaleString() ?? '-'}</span>,
			footer: () => <span>{summaryItem?.impressions?.toLocaleString()}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('clicks', {
			header: 'Clicks',
			cell: ({ row }) => <span>{(row.getValue('clicks') as number)?.toLocaleString() ?? '-'}</span>,
			footer: () => <span>{summaryItem?.clicks?.toLocaleString()}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('conversions', {
			header: 'Conversions',
			cell: ({ row }) => <span>{(row.getValue('conversions') as number)?.toLocaleString() ?? '-'}</span>,
			footer: () => <span>{summaryItem?.conversions?.toLocaleString()}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('cost', {
			header: 'Cost',
			cell: ({ row }) => <span>{currencyFormat(row.getValue('cost')) ?? '-'}</span>,
			footer: () => <span>{currencyFormat(summaryItem?.cost)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('conversionValue', {
			header: 'Revenue',
			cell: ({ row }) => <span>{currencyFormat(row.getValue('conversionValue')) ?? '-'}</span>,
			footer: () => <span>{currencyFormat(summaryItem?.conversionValue)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('winRate', {
			header: 'Win rate',
			cell: ({ row }) => <span>{percents(row.getValue('winRate')) ?? '0%'}</span>,
			footer: () => <span>{percents(summaryItem?.winRate)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('roas', {
			header: 'ROAS',
			cell: ({ row }) => <span>{percents(row.getValue('roas')) ?? '0%'}</span>,
			footer: () => <span>{percents(summaryItem?.roas)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('ctr', {
			header: 'CTR',
			cell: ({ row }) => <span>{percents(row.getValue('ctr')) ?? '0%'}</span>,
			footer: () => <span>{percents(summaryItem?.ctr)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('lpCvr', {
			header: 'CVR',
			cell: ({ row }) => <span>{percents(row.getValue('lpCvr')) ?? '0%'}</span>,
			footer: () => <span>{percents(summaryItem?.lpCvr)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('gain', {
			header: 'Gain',
			cell: ({ row }) => <span>{currencyFormat(row.getValue('gain')) ?? '-'}</span>,
			footer: () => <span>{currencyFormat(summaryItem?.gain)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('optGain', {
			header: 'Optimal Gain',
			cell: ({ row }) => <span>{currencyFormat(row.getValue('optGain')) ?? '-'}</span>,
			footer: () => <span>{currencyFormat(summaryItem?.optGain)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
		columnHelper.accessor('optCost', {
			header: 'Optimal Cost',
			cell: ({ row }) => <span>{currencyFormat(row.getValue('optCost')) ?? '-'}</span>,
			footer: () => <span>{currencyFormat(summaryItem?.optCost)}</span>,
			sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			meta: {
				isPerformance: true,
			},
		}),
	] as ColumnDef<PerformanceModel>[];

	return {
		columns,
	};
};
