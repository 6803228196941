import { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './filter-entity-menu.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { SelectOptions } from '../../select-new/select-new';
import { FilterOptions } from '../filter-options/filter-options';
import { ReportFilterActionEnum } from '@monorepo/controlled/src/models/filter.model';

interface IFilterEntityMenu {
	title: string;
	onApply: (_value: SelectOptions[]) => void;
	onCancel: () => void;
	defaultAction?: string;
	defaultValues?: string[];
	debugProps?: IDebugProps;
	entityOptions: SelectOptions[];
	multi?: boolean;
	isSelectAll?: boolean;
	selectedOptions?: SelectOptions[];
	isLoadingOptions?: boolean;
}

export const FilterEntityMenu = (props: IFilterEntityMenu) => {
	const {
		title,
		onApply,
		onCancel,
		defaultAction = ReportFilterActionEnum.INCLUDE,
		debugProps,
		defaultValues = [],
		entityOptions,
		isLoadingOptions,
		isSelectAll,
		multi,
	} = props;

	const [action, setAction] = useState<option>(defaultAction);
	const [filterValue, setFilterValue] = useState<(string | number)[]>(defaultValues);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setAction(defaultAction);
	}, [defaultAction]);

	return (
		<div className={styles.wrapper} {...generateDataAttrs(dataAttrs)}>
			<div className={styles.title}>
				{title}
				<Icon isMFP={true} className={styles.closeButton} onClick={onCancel}>
					x-close
				</Icon>
			</div>
			<div className={styles.content}>
				<FilterOptions
					placeholder={`Search ${title}`}
					value={filterValue}
					onEnumFilterOption={setFilterValue}
					onCancel={onCancel}
					options={entityOptions}
					isSelectAll={isSelectAll}
					isMultiple={multi}
					isLoading={isLoadingOptions}
				/>
			</div>
			<div className={styles.actions}>
				<div className={styles.action}>
					<SecondaryButton
						className={`${styles.actionButton} ${styles.cancelButton}`}
						onClick={onCancel}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_menu_cancel_button')] }}>
						Cancel
					</SecondaryButton>
				</div>
				<div className={styles.action}>
					<PrimaryButton
						className={styles.actionButton}
						disabled={filterValue.length === 0}
						onClick={() => {
							if (filterValue && action) {
								onApply(filterValue as any);
							}
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_menu_apply_button')] }}>
						Apply
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};
