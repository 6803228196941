import { observer } from 'mobx-react';
import { Fragment, useEffect } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useCampaignInputOptions } from '../../../hooks/apis/use-campaign-input-options';
import { AdvertiserModel } from '../../../models/advertiser.model';
import { IAdminxStore } from '../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const CreativeGroupForm = observer(() => {
	useCampaignInputOptions();

	const { creativeGroupsStore, campaignStore, advertiserStore } = useStores<IAdminxStore>();
	const advertiserCrudStore = advertiserStore.getCrud();
	const advertiserView = advertiserCrudStore.getData();

	const creativeGroupCrudStore = creativeGroupsStore.getCrud();
	const formStore = creativeGroupCrudStore.getFormStore();
	const creativeGroup = creativeGroupCrudStore.getData();
	const advertiser = creativeGroup.getAdvertiser();
	const advertisers = campaignStore.getInputOptions().getAdvertisers();
	const advertisersNames = advertisers.map(_advertiser => _advertiser.getName() || '');

	const onAdvertiserSelect = (advertiserName: option) => {
		const _advertiser = advertisers.find(_advertiser => _advertiser.name === advertiserName);
		if (_advertiser) {
			creativeGroup.setAdvertiser(new AdvertiserModel(_advertiser));
		}
	};

	useEffect(() => {
		const advertiserName = advertiserView.getName();
		if (advertiserName) {
			onAdvertiserSelect(advertiserName);
			// setAdvertiserDropDownDisable(true);
		}
	}, [creativeGroup]);

	return (
		<Fragment>
			<Card
				title={'Creative Group Information'}
				icon={'layers-three-02'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_groups_form')] }}>
				<Fragment>
					<Input
						value={creativeGroup.getDesign()}
						placeholder={'Creative Group Name'}
						onValue={value => {
							creativeGroup.setDesign(value);
							formStore.clearError('design');
						}}
						required={true}
						error={formStore.getErrors().get('design')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_groups_name')] }}
					/>
					<Dropdown
						required={true}
						defaultOption={advertiser?.getName()}
						label={'Select an Advertiser'}
						options={advertisersNames}
						onSelect={(advertiserName: Set<option> | undefined) => onAdvertiserSelect(advertiserName?.values().next().value)}
						error={formStore.getErrors().get('advertiser')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_groups_advertiser')] }}
					/>
				</Fragment>
			</Card>
		</Fragment>
	);
});
