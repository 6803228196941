import { FilterModel, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { FilterNumberMenu } from '@monorepo/base/src/components/filters/filter-number-menu/filter-number-menu';
import { number, numberAction, numberFuncs } from '@monorepo/tools/src/lib/utils/number';
import styles from './filters.module.scss';
import { observer } from 'mobx-react';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { snakeCase } from 'change-case';
import { getObjectValue } from '@monorepo/tools/src/lib/utils/object';

interface INumberFilterProto {
	cellKey: string | string[];
	columnName: string;
	isPercentage?: boolean;
	entity?: string;
}

interface INumberFilterComponentProps extends IFilterComponentProps {
	cellKey: string | string[];
	columnName: string;
	isPercentage?: boolean;
	entity?: string;
}

const NumbersFilter = (props: INumberFilterComponentProps) => {
	const { filter, onCancel, onApply, addFilter, editFilter, columnName, isPercentage, cellKey, entity } = props;
	const protoObj = NumberFilterPrototype({ columnName, isPercentage, cellKey, entity });

	if (typeof filter?.value === 'string') {
		return <div></div>;
	}

	const onAddNumbersFilter = (_action: numberAction, _value: number) => {
		const filterLabel = `${columnName} ${_action} ${number(_value, false, false, true)}`;
		const numbersFilter = new FilterModel({
			label: filterLabel,
			action: _action,
			value: _value,
			prototype: protoObj,
		});

		if (filter?.index && editFilter) {
			editFilter(filter.index, numbersFilter);
		} else {
			if (protoObj?.multi && addFilter) {
				addFilter(numbersFilter);
			}
		}

		onApply();
	};

	return (
		<FilterNumberMenu
			title={columnName}
			defaultValue={typeof filter?.value === 'number' ? filter?.value : undefined}
			defaultAction={filter?.action}
			onApply={(_action: numberAction, _value: number) => onAddNumbersFilter(_action, _value)}
			onCancel={onCancel}
			inputClassNames={styles.numberFilterInputField}
			debugProps={{ dataAttrs: [new DataAttribute('id', `${snakeCase(columnName)}_menu`)] }}
		/>
	);
};

export const NumberFilterPrototype = (props: INumberFilterProto): IFilterProto => {
	const { columnName, isPercentage, cellKey, entity } = props;

	return {
		MenuComponent: columnName,
		FilterComponent: observer((props: IFilterComponentProps) => NumbersFilter({ ...props, columnName, isPercentage, cellKey, entity })),
		prop: `${entity}${cellKey}`,
		deletable: true,
		multi: true,
		filterFunc<T>(models: Array<T>, filter: FilterModel): Array<T> {
			const { value, action } = filter;
			let _value = value as number;

			if (isPercentage) {
				_value = _value / 100;
			}

			return models.filter(model => {
				if (!action) {
					return true;
				}
				const actionFunc = numberFuncs[action as numberAction];
				if (!actionFunc) {
					return true;
				}

				if (Array.isArray(cellKey)) {
					const extractedValue = getObjectValue(model, cellKey);
					return actionFunc(extractedValue || 0, _value);
				}

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				return actionFunc(model[cellKey] || 0, _value);
			});
		},
	};
};
