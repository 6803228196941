import { ReactNode, useEffect, useRef, useState } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './sider-slider.module.scss';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';

interface ISiderSlider {
	children: ReactNode;
	isOpen: boolean;
	onClose?: () => void;
	className?: string;
	isOverlay?: boolean;
	isScroll?: boolean;
	isDisableCloseAnimation?: boolean; // when components render and the siderslider is closed, there is a unwanted close animation
	debugProps?: IDebugProps;
}

export const SiderSlider = (props: ISiderSlider) => {
	const { children, isOpen, onClose, className, isOverlay = true, isScroll, isDisableCloseAnimation, debugProps } = props;
	const greyScreen = useRef(null);
	const [isSiderSlider, setIsSiderSlider] = useState<boolean>(isOpen);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setIsSiderSlider(isOpen);
	}, [isOpen]);

	const handleKeyDownEvent = (e: KeyboardEvent) => {
		if (e.key === 'Escape' && onClose) {
			onCloseSiderSlider();
		}
	};

	const onCloseSiderSlider = () => {
		setTimeout(() => {
			if (onClose) {
				onClose();
			}
		}, 300);
		setIsSiderSlider(false);
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDownEvent);
		if (!isScroll) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			window.removeEventListener('keydown', handleKeyDownEvent);
			document.body.style.overflow = 'auto';
		};
	}, [location]);

	return (
		<div
			ref={greyScreen}
			className={`${styles.greyScreen} ${isSiderSlider ? styles.open : styles.close} ${!isOverlay ? styles.noGreyScreen : ''}`}>
			<aside
				className={`${styles.wrapper} ${styles.right} 
			${isSiderSlider ? styles.open : styles.close} ${className} ${isDisableCloseAnimation ? styles.disableAnimation : ''}`}
				{...generateDataAttrs(dataAttrs || [new DataAttribute('id', 'sider_slider')])}>
				<Icon className={styles.closeIcon} isMFP={true} onClick={onCloseSiderSlider}>
					x-close
				</Icon>
				{children}
			</aside>
		</div>
	);
};
