import { ReactNode } from 'react';
import { SecondaryButton, PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { IModalInjectProps } from '../../modal/modal';
import styles from './action-modal.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Card } from '@monorepo/base/src/components/card/card';
import { snakeCase } from 'change-case';

interface IActionModal extends IModalInjectProps {
	onAction?: () => void;
	onClose?: () => void;
	type?: ModalTypes;
	primaryBtnTitle?: string;
	children: ReactNode;
	debugProps?: IDebugProps;
}

export enum ModalTypes {
	Warning = 'warning',
	Info = 'info',
	Error = 'error',
	None = 'none',
}

export const ActionModal = (props: Partial<IActionModal>) => {
	const { children, onAction, onClose, type, primaryBtnTitle = '', closeModal } = props;

	const onActionClick = () => {
		if (onAction) {
			onAction();
		}

		if (onClose) {
			onClose();
		}

		// close modal of modal.tsx
		if (closeModal) {
			closeModal();
		}
	};

	const modalType = type || ModalTypes.None;
	const icons = {
		[ModalTypes.Warning]: 'alert-triangle',
		[ModalTypes.Info]: 'info-circle',
		[ModalTypes.Error]: 'alert-circle',
		[ModalTypes.None]: 'none',
	};

	return (
		<Card>
			<div className={styles.content}>
				{modalType !== ModalTypes.None ? (
					<div className={`${styles.iconWrapper}`}>
						<Icon className={`${styles[modalType]} ${styles.icon}`} isMFP={true} size={'26px'}>
							{icons[modalType]}
						</Icon>
					</div>
				) : null}
				<div className={styles.childrenWrapper}>{children}</div>
			</div>
			<div className={styles.actions}>
				<SecondaryButton
					className={styles.cancelBtn}
					onClick={closeModal}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'action_modal_cancel')] }}>
					Cancel
				</SecondaryButton>
				<PrimaryButton
					className={`${type && styles[type]} ${styles.actionBtn}`}
					onClick={onActionClick}
					debugProps={{
						dataAttrs: [new DataAttribute('id', `action_modal_${snakeCase(primaryBtnTitle)}`)],
					}}>
					{primaryBtnTitle}
				</PrimaryButton>
			</div>
		</Card>
	);
};
