import { useEffect, useState } from 'react';

export function useDidMount() {
	const [isDidMount, setDidMount] = useState<boolean>(false);

	useEffect(() => {
		setDidMount(true);
	}, []);
	return isDidMount;
}
