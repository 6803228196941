import { Context, createContext, useContext } from 'react';
import { SidebarStore } from '../stores/sidebar.store';
import { AuthStore } from '../stores/auth.store';
import { FormStore } from '../stores/form.store';
import { ToastsStore } from '../stores/toasts.store';
import { ModalsStore } from '../stores/modals.store';
import { DateStore } from '../stores/date.store';
import { NotificationsStore } from '../stores/notifications.store';
import { AnnouncementsStore } from '../stores/announcements.store';
import { SettingsStore } from '../stores/settings.store';
import { Constructable } from '@monorepo/tools/src/lib/interfaces/class';
import { TooltipsStore } from '../stores/tooltips.store';

let StoreContext: Context<any>;
export function initStore<T>(store: Constructable<T>) {
	StoreContext = createContext<T>(new store()) as Context<T>;
}

export function useStores<T>() {
	return useContext<T>(StoreContext as Context<T>);
}

export interface IControlledStore {
	sidebarStore: SidebarStore;
	authStore: AuthStore;
	formStore: FormStore;
	toastsStore: ToastsStore;
	modalsStore: ModalsStore;
	tooltipsStore: TooltipsStore;
	dateStore: DateStore;
	notificationsStore: NotificationsStore;
	announcementsStore: AnnouncementsStore;
	settingsStore: SettingsStore;
}
