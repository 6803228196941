import { AdminxReporterEndpoint } from './adminx-reporter.endpoint';
import { BidKeysManagementEndpoint } from './bidkeys-management.endpoint';
import { BidderEndpoints } from './bidder.endpoints';
import { CampaignSegmentationEndpoint } from './campaign-segmentation.endpoint';
import { CampaignsEndpoint } from './campaigns.endpoint';
import { CreativesEndpoint } from './creatives.endpoint';
import { CrudEndpoint } from './crud.endpoint';
import { DealsEndpoint } from './deals.endpoint';
import { PreferencesEndpoint } from './preferences.endpoint';
import { TestsEndpoint } from './tests.endpoint';
import { UsersEndpoint } from './users.endpoint';

const endpoints = {
	campaigns: new CampaignsEndpoint(),
	advertisers: new CrudEndpoint('advertisers'),
	preferences: new PreferencesEndpoint(),
	creatives: new CreativesEndpoint(),
	campaignGroups: new CrudEndpoint('campaign-groups'),
	creativeGroups: new CrudEndpoint('creative-groups'),
	tests: new TestsEndpoint(),
	deals: new DealsEndpoint(),
	jobs: new CrudEndpoint('jobs'),
	cids: new CrudEndpoint('campaigns/webapps-cid-replacer'),
	campaignSegmentations: new CampaignSegmentationEndpoint(),
	report: new AdminxReporterEndpoint(),
	users: new UsersEndpoint(),
	bidKeysManagment: new BidKeysManagementEndpoint(),
	bidder: new BidderEndpoints(),
};

export default endpoints;
