import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { queryParamsToSearchParams } from '@monorepo/tools/src/lib/utils/url';
import { IAdminxStore } from '../../stores';

/**
 * @param options
 * @returns
 */
export const useCampaignInputOptions = (options?: IRequestOptions) => {
	const { campaignStore } = useStores<IAdminxStore>();
	const location = useLocation();

	useEffect(() => {
		const params = queryParamsToSearchParams(options?.queryParams);

		campaignStore.getInputOptions().fetch(params);
	}, [location]);

	return { error: campaignStore.getInputOptions().getError(), isLoading: campaignStore.getInputOptions().getIsLoading() };
};
