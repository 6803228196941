export interface IConfig<T> {
	env: IEnv<T>;
	theme: ITheme;
	scripts: IScripts;
}

export type Adminx = 'adminx';
export type ManifestoPlatform = 'manifesto-platform';
export type Publifesto = 'publifesto';

interface IEnv<T> {
	projectEnv: string;
	rootApi: string;
	sheriffRootApi: string;
	sheriffRoot: string;
	projectName: Adminx | ManifestoPlatform | Publifesto;
	project: T;
	platform: string;
}

interface ITheme {
	gray500: string;
	primary600: string;
	primary700: string;
	primaryColor: string;
	titleColor: string;
	subtitleColor: string;
	actionLinerIconTextColor: string;
	actionLinerIconColor: string;
	actionLinerActionIconsColor: string;
	toggleSliderDefaultBackgroundColor: string;
}

interface IScripts {
	hotjar: { id: string; version: string };
	ga4: { gtagIds: string };
}

function getConfig<T>(): IConfig<T> {
	// typescript think process.env can be only string
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return process.env.CONFIG;
}

function getTheme(): ITheme {
	return getConfig().theme;
}

export { getConfig, getTheme };
