import { Dropdown } from '@monorepo/base/src/components/dropdown/dropdown';
import { Fragment, useState } from 'react';
import { FilterApplyAndCancelButtons, FilterOptionProps } from './table-filters';
import { Input } from '@monorepo/base/src/components/form/input/input';
import styles from './table-filters.module.scss';

export const TablePercentageFilter = ({ column, onApply, onCanceled, initialState }: FilterOptionProps) => {
	const filter = column.availableFilters;
	const [action, setAction] = useState(filter?.actions.find(action => action.value === initialState?.filterType) || filter?.actions[0]);
	const [value, setValue] = useState(initialState?.value[0] || '');
	const availableFilterAction = filter?.actions.map(action => ({ label: action.label, value: action.value })) || [];

	const handleApply = () => {
		if (!action || !value) {
			return;
		}

		onApply({ filterType: action.value, value: [`${value}%`], column: column.name });
	};

	return (
		<Fragment>
			<div className={styles.filterTitle}>{column.header}</div>
			<div className={styles.filterActionPicker}>
				<Dropdown
					required={true}
					options={availableFilterAction.map(x => x.label)}
					onSelect={val => setAction(val ? availableFilterAction.find(action => action.label === Array.from(val)[0]) : val)}
					autocomplete={availableFilterAction.length > 10}
					defaultOption={action?.label || ''}
					autocompletePlaceholder={'Search action'}
				/>
			</div>
			<Input
				type={filter?.type}
				value={value.toString().replace('%', '')}
				placeholder={'Value'}
				title={'Value'}
				onChange={e => setValue(e.target.value || '')}
				after="%"
			/>
			<FilterApplyAndCancelButtons
				onApply={handleApply}
				onCanceled={onCanceled}
				disableApply={!action || !value || (action.value === initialState?.filterType && value === initialState.value[0])}
			/>
		</Fragment>
	);
};
