import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CampaignModel } from './campaign.model';
import { TestGroupModel } from './test-group.model';
import { Status, Statuses } from '../enums/status';

/**
 * The props the test crud page uses
 */
export interface ITest {
	id?: id;
	name?: string;
	description?: string;
	status?: Status;
	campaign?: CampaignModel;
	test_groups?: TestGroupModel[];
	percentage?: number;
}

/**
 * The props to send to server
 */
export interface ITestCreateForm {
	id?: id;
	name?: string;
	description?: string;
	status?: Status;
	campaign?: {
		id?: id;
	};
	test_groups?: TestGroupModel[];
	percentage?: number;
}

export interface ITestEditForm {
	id?: id;
	name?: string;
	description?: string;
	status?: Status;
	campaign?: {
		id?: id;
	};
	test_groups?: TestGroupModel[];
	percentage?: number;
}

export class TestModel implements ITest {
	id?: id;
	name?: string;
	description?: string;
	status?: Status;
	campaign?: CampaignModel;
	test_groups?: TestGroupModel[];
	percentage?: number;

	constructor(props?: ITest) {
		this.id = props?.id;
		this.name = props?.name;
		this.description = props?.description;
		this.campaign = props?.campaign ? new CampaignModel(props.campaign) : undefined;
		this.test_groups = props?.test_groups?.map(test_group => new TestGroupModel(test_group)) || [new TestGroupModel()];
		this.status = props?.status || Statuses.Active;
		this.percentage = props?.percentage || 50;

		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getName() {
		return this.name;
	}

	public setName(name: string) {
		this.name = name;
	}

	public getDescription() {
		return this.description;
	}

	public setDescription(description: string) {
		this.description = description;
	}

	public getCampaign(): CampaignModel | undefined {
		return this.campaign;
	}

	public setCampaign(campaign: CampaignModel) {
		this.campaign = campaign;
	}

	public getTestGroups() {
		return this.test_groups;
	}

	public setTestGroup(test_groups: TestGroupModel[]) {
		this.test_groups = test_groups;
	}

	public addTestGroup() {
		this.test_groups?.push(new TestGroupModel());
	}

	public removeTestGroup(index: number) {
		this.test_groups?.splice(index, 1);
	}

	public getStatus(): Status | undefined {
		return this.status;
	}

	public setStatus(status: Status): void {
		this.status = status;
	}

	public setPercentage(percentage: number): void {
		this.percentage = percentage;
	}

	public getPercentage(): number | undefined {
		return this.percentage;
	}
}
