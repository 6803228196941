import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { UserModel } from '../../modules/models/user.model';
import { IAdminxStore } from '../../modules/stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../modules/routes/index.routes';
import { sessionStorageHelper } from '@monorepo/tools/src/lib/tools/storage';
import { URL_TO_RETURN_AFTER_LOGOUT } from '../../lib/consts/storage';
import { redirectHandler } from '../../modules/components/bouncer-login/login-with';
import { OAuthProviderEnum } from '../../modules/enums/oauth-providers';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

interface IOAuthRedirect {
	provider: OAuthProviderEnum;
}

export const OAuthLoginRedirect = observer(({ provider }: IOAuthRedirect) => {
	const { authStore, userStore } = useStores<IAdminxStore>();
	const navigate = useNavigateParams();
	useEffect(() => {
		const initiator = async () => {
			const hash_params = window?.location?.hash;
			window.location.hash = '';
			if (hash_params && typeof hash_params === 'string') {
				try {
					const accessToken = new URLSearchParams(hash_params.slice(1)).get('access_token') ?? '';
					const { userAuth, user, use2FA, qrCode } = await redirectHandler({ accessToken, provider });

					const userModel = new UserModel(user);

					if (use2FA) {
						const qrEncoded = qrCode ? encodeURIComponent(qrCode) : null;
						return navigate(`${routes.auth.twoFactor()}?${qrCode ? `qrCode=${qrEncoded}&` : ''}userId=${userModel.id}`);
					}

					userStore.setUser(userModel);
					authStore.setToken(userAuth?.token);

					const urlToReturnTo = sessionStorageHelper.getItem(URL_TO_RETURN_AFTER_LOGOUT);
					navigate(urlToReturnTo || routes.general.home());
				} catch (e) {
					navigate(routes.auth.login());
				}
			}
		};
		initiator();
	}, []);

	return null;
});
