import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDevices } from '@monorepo/tools/src/lib/hooks/tools/use-devices';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { SidebarButton } from '@monorepo/base/src/components/sidebar/sidebar';
import styles from './auth-layout.module.scss';
import { useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { MainNavbar } from '../main-navbar/main-navbar';
import { IAdminxStore } from '../../stores';
import { ControlledSidebar } from '../sidebar/sidebar';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';

export const AuthLayout: FC = observer(() => {
	const { sidebarStore, toastsStore } = useStores<IAdminxStore>();
	const location = useLocation();
	const didMount = useDidMount();
	const { dispatchLog } = useLogs();
	const toastsOnNav = toastsStore.getToastOnNav();
	const [, setIsTableOfContents] = useState<boolean>(false);

	useEffect(() => {
		if (toastsOnNav.length > 0 && didMount) {
			toastsOnNav.forEach(toastOnNav => {
				dispatchLog(toastOnNav);
			});
			toastsStore.resetToastsOnNav();
		}

		return () => {
			// tableStore.resetTable();
		};
	}, [location, didMount]);

	const devices = useDevices();

	const onClickSidebarButton = () => {
		if (devices.isBigRes) {
			sidebarStore.setSidebarOpen(!sidebarStore.getIsSideBarOpen());
		} else {
			sidebarStore.setSidebarVisibility(!sidebarStore.getIsSideBarVisible());
		}
	};

	return (
		<main className={styles.main}>
			<ControlledSidebar />
			{!devices.isBigRes ? <SidebarButton onClick={onClickSidebarButton} isOpen={sidebarStore.getIsSideBarOpen()} /> : null}
			<div className={sidebarStore.getIsSideBarOpen() ? styles.minimize : styles.maximize}>
				<MainNavbar />
				<Outlet context={{ setIsTableOfContents }} />
			</div>
		</main>
	);
});
