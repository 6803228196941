import { getConfig } from '@monorepo/tools/src/lib/get-config';

export class AdminxReporterEndpoint {
	report() {
		return `${getConfig().env.sheriffRoot}/adminx-reporting/report`;
	}

	newReport() {
		return `${this.report()}/repr`;
	}
}
