import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CrudEndpoint } from './crud.endpoint';
import { getConfig } from '@monorepo/tools/src/lib/get-config';

const CREATIVE_ID = ':creativeId';

// TODO - RONT
export class CreativesEndpoint extends CrudEndpoint {
	private creativeGroupsUrl: string;
	private attachCampaignsUrl: string;

	constructor() {
		super('creatives');
		this.creativeGroupsUrl = `${this.rootApi}/creative-groups`;
		this.attachCampaignsUrl = `${this.rootApi}/creatives/${CREATIVE_ID}/bulk-attach-to-campaigns`;
	}

	postBanner() {
		if (!process.env.REACT_APP_UPLOAD_BANNER) {
			throw Error('No REACT_APP_UPLOAD_BANNER prop found');
		}
		return `${getConfig().env.sheriffRoot}${process.env.REACT_APP_UPLOAD_BANNER}`;
	}

	creativeGroupsList() {
		return this.creativeGroupsUrl;
	}

	// TODO
	sendToReview() {
		if (!process.env.REACT_APP_SEND_TO_REVIEW_API) {
			throw Error('No REACT_APP_SEND_TO_REVIEW_API prop found');
		}
		return process.env.REACT_APP_SEND_TO_REVIEW_API;
	}

	attachCampaigns(id: id) {
		return this.attachCampaignsUrl.replace(`${CREATIVE_ID}`, id.toString());
	}

	multiCreate() {
		return `${this.baseUrl}/bulk-create`;
	}

	bulkCreateCSV() {
		return `${this.baseUrl}/bulk-create-csv`;
	}

	getCampaignCreatives(campaignId: id, creativeIds: id[]) {
		const ids = creativeIds.map(i => `ids=${i}`);
		return `${this.rootApi}/campaigns/${campaignId}/creatives/detach?${ids.join('&')}`;
	}
}
