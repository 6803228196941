import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';

export interface ICacheBidKeys {
	[key: string]: {
		data: {
			TOTAL_WINS: number;
			TOTAL_OPTIMAL_COST: number;
			TOTAL_CLICKS: number;
			TOTAL_CALCULATE_GAIN: number;
			TOTAL_CONVERSIONS: number;
			TOTAL_MONEY_GAINED: number;
			TOTAL_BIDS: number;
			CALCULATED_Q1_GAIN: number;
			TOTAL_MONEY_SPENT_WINS: number;
			TOTAL_MONEY_BID: number;
			CALCULATED_OPTIMAL_GAIN: number;
		};
		shouldBeClosedDate: string | null;
		isReopened?: boolean;
	};
}

export interface IListStatus {
	[key: string]: string;
}

export const KeysManagementApi = {
	listStatus(bidKeys: string[]): Promise<IListStatus> {
		return ask.post(endpoints.bidKeysManagment.listStatus(), bidKeys);
	},

	reopenBidKeys(bidKey: string[]): Promise<void> {
		return ask.delete(endpoints.bidKeysManagment.reopenBidKey(), { body: bidKey, headers: { 'Content-Type': 'application/json' } });
	},
};
