import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Card } from '@monorepo/base/src/components/card/card';
import { WhiteBlackLists } from '../../../../enums/white-black-list-enum';
import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ButtonGroup, GButton } from '@monorepo/base/src/components/buttons/group/button-group';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Fragment } from 'react';

export const BidKeysAndSitesInfo = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();

	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();

	const isBlacklist = (campaign.campaign_satellites_details?.black_sites_counter || 0) > 0;
	const sitesInTargetCouter =
		campaign.campaign_satellites_details?.black_sites_counter || campaign.campaign_satellites_details?.white_sites_counter || 0;
	const bidKeyscounter = campaign.campaign_satellites_details?.bid_keys_counter || 0;
	return (
		<Fragment>
			<Card
				id={'sitesInTargetInfo'}
				title={'Sites In Target'}
				icon={'globe-03'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'sites_in_target_section')] }}>
				<Spacer />
				<ButtonGroup
					defaultValue={isBlacklist ? WhiteBlackLists.Blacklist : WhiteBlackLists.Whitelist}
					disabled
					onChange={() => null}>
					<GButton disabled icon={'shield-plus'} text={WhiteBlackLists.Blacklist} value={WhiteBlackLists.Blacklist} />
					<GButton disabled icon={'shield-off'} text={WhiteBlackLists.Whitelist} value={WhiteBlackLists.Whitelist} />
				</ButtonGroup>
				<Spacer />
				<Input disabled value={`Total of [${sitesInTargetCouter}] keys in target`} title={`Listed Sites`} />
			</Card>
			<Card id={'bidKeysInfo'} title={'BidKeys'} icon={'key-01'}>
				<Spacer />
				<Input disabled value={`Total of [${bidKeyscounter}] bidkeys`} title={`Listed Sites`} />
			</Card>
		</Fragment>
	);
});
