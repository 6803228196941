import { observer } from 'mobx-react';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './tooltips-container.module.scss';

import { IndependentTooltip } from '../independent-tooltip/independent-tooltip';

export const TooltipsContainer = observer(() => {
	const { tooltipsStore } = useStores<IControlledStore>();
	const tooltips = tooltipsStore?.getTooltips();

	return (
		<div className={styles.wrapper}>
			{tooltips?.map((tooltip, index) => {
				if (tooltip.hoverRef.current !== null) {
					return <IndependentTooltip tooltip={tooltip} key={index} />;
				}
			})}
		</div>
	);
});
