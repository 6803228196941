import { ITestEditForm } from '../models/test.model';
import { ITestCreateForm, TestModel } from '../models/test.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';
import { URLSearchParams } from 'url';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export const TestsApi = {
	list: (params?: URLSearchParams): Promise<TestModel[]> => {
		return ask.get(endpoints.tests.list(), params);
	},
	create: (test: ITestCreateForm, options?: IRequestOptions): Promise<{ id: number }> => {
		return ask.post<ITestCreateForm>(endpoints.tests.create(), test, options);
	},
	get: (id: id, options?: URLSearchParams): Promise<TestModel> => {
		return ask.get(endpoints.tests.get(id), options);
	},
	edit: (id: id, test: ITestEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.put<ITestEditForm>(endpoints.tests.edit(id), test, options);
	},
	delete: (id: id) => {
		return ask.delete(endpoints.tests.delete(id));
	},
	enableTests(testIds: id[]): Promise<null> {
		return ask.patch(endpoints.tests.enableTests(), testIds);
	},
	pauseTests(testIds: id[]): Promise<null> {
		return ask.patch(endpoints.tests.pauseTests(), testIds);
	},
};
