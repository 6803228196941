import { CreateAdvertiser } from '../../pages/advertisers/create/create-advertiser.page';
import { EditAdvertiser } from '../../pages/advertisers/edit/edit-advertiser.page';
import { Advertisers } from '../../pages/advertisers/index/advertisers.page';
import { AdvertiserView } from '../../pages/advertisers/view/advertiser-view.page';
import { AdvertiserBidKeys } from '../../pages/advertisers/view/bid-keys/bid-keys.advertiser';
import { AdvertiserCampaignGroups } from '../../pages/advertisers/view/campaign-groups/campaign-groups.advertiser';
import { AdvertiserCampaigns } from '../../pages/advertisers/view/campaigns/campaigns.advertiser';
import { AdvertiserCreativeGroups } from '../../pages/advertisers/view/creative-groups/creative-groups.advertiser';
import { AdvertiserCreatives } from '../../pages/advertisers/view/creatives/creatives.advertiser';
import { AdvertiserDomains } from '../../pages/advertisers/view/domains/domains.advertiser';
import { AdvertiserPages } from '../../pages/advertisers/view/pages/pages.advertiser';
import { CreateCampaignGroups } from '../../pages/campaign-groups/create/create-campaign-group.page';
import { EditCampaignGroups } from '../../pages/campaign-groups/edit/edit-campaign-group.page';
import { CreateCampaign } from '../../pages/campaigns/create/create-campaign.page';
import { CreateCreativeGroups } from '../../pages/creative-groups/create/create-creative-group.page';
import { EditCreativeGroups } from '../../pages/creative-groups/edit/edit-creative-group.page';
import routes from '../routes/index.routes';
import { Soon } from '@monorepo/base/src/components/soon/soon';

export const AdvertisersRouter = [
	{
		path: routes.advertisers.index(),
		element: <Advertisers />,
		children: [
			{
				path: routes.advertisers.create(),
				element: <CreateAdvertiser />,
			},
			{
				path: routes.advertisers.edit(':advertiserId'),
				element: <EditAdvertiser />,
			},
		],
	},
	{
		element: <AdvertiserView />,
		children: [
			{
				path: routes.advertisers.view(':advertiserId'),
				element: <Soon />,
			},
			{
				path: routes.advertisers.campaignGroups(':advertiserId'),
				element: <AdvertiserCampaignGroups />,
				children: [
					{
						path: routes.advertisers.createCampaignGroup(':advertiserId'),
						element: <CreateCampaignGroups parentRoute={routes.advertisers.campaignGroups(':advertiserId')} />,
					},
					{
						path: routes.advertisers.editCampaignGroup(':advertiserId', ':campaignGroupId'),
						element: <EditCampaignGroups parentRoute={routes.advertisers.campaignGroups(':advertiserId')} />,
					},
				],
			},
			{
				path: routes.advertisers.creativeGroups(':advertiserId'),
				element: <AdvertiserCreativeGroups />,
				children: [
					{
						path: routes.advertisers.createCreativeGroup(':advertiserId'),
						element: <CreateCreativeGroups parentRoute={routes.advertisers.creativeGroups(':advertiserId')} />,
					},
					{
						path: routes.advertisers.editCreativeGroup(':advertiserId', ':creativeGroupId'),
						element: <EditCreativeGroups parentRoute={routes.advertisers.creativeGroups(':advertiserId')} />,
					},
				],
			},
			{
				path: routes.advertisers.campaigns(':advertiserId'),
				element: <AdvertiserCampaigns />,
			},
			{
				path: routes.advertisers.createCampaign(':advertiserId'),
				element: <CreateCampaign parentRoute={routes.advertisers.createCampaign(':advertiserId')} />,
			},
			{
				path: routes.advertisers.creatives(':advertiserId'),
				element: <AdvertiserCreatives />,
			},

			{
				path: routes.advertisers.domains(':advertiserId'),
				element: <AdvertiserDomains />,
			},
			{
				path: routes.advertisers.pages(':advertiserId'),
				element: <AdvertiserPages />,
			},
			{
				path: routes.advertisers.bidKeys(':advertiserId'),
				element: <AdvertiserBidKeys />,
			},
		],
	},
];
