import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter } from '../../components/filters-menu/filters';
import { performanceFilters } from '../../components/filters-menu/filters/perfomance.filter';
import { Statuses } from '../../enums/status';

export const dealFilters = new Map<FilterTypeKey, FilterType>([
	['numberFilters', { ...performanceFilters.numberFilters } as INumberFilter],
	[
		'stringFilters',
		{
			...performanceFilters.stringFilters,
			formats: { columnName: 'Format', cellKey: 'formats' },
			name: { columnName: 'Deal Name', cellKey: 'name' },
			value: { columnName: 'Value', cellKey: 'value' },
			comment: { columnName: 'Comment', cellKey: 'comment' },
			proposal_name: { columnName: 'Proposal Name', cellKey: 'proposal_name' },
			proposal_id: { columnName: 'Proposal Id', cellKey: 'proposal_id' },
			publisher_profile: { columnName: 'Publisher Profile', cellKey: 'publisher_profile' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
			serving_status: {
				columnName: 'Serving Status',
				cellKey: 'serving_status',
				enumOptions: [
					{ value: Statuses.Active, label: 'Active' },
					{ value: Statuses.Paused, label: 'Paused' },
				],
				deletable: true,
			},
		} as IEnumFilter,
	],
]);
