import { makeAutoObservable } from 'mobx';
import { BidderCampaignModel, IBidderCampaign } from './bidder-camapign.model';
import { IBidderCampaignDetailsMetaData } from './bidder-campaign.types';

export interface IBidderCampaignDetailt {
	campaign: IBidderCampaign;
	MetaData: IBidderCampaignDetailsMetaData;
	success: boolean;
}

export class BidderCampaignResponseModel implements Partial<IBidderCampaignDetailt> {
	private _campaign: BidderCampaignModel;
	private _MetaData: IBidderCampaignDetailsMetaData;
	private _success: boolean;

	constructor(data?: Partial<IBidderCampaignDetailt>) {
		this._campaign = new BidderCampaignModel(data?.campaign);
		this._MetaData = data?.MetaData || {
			campaign_group_id: '',
			campaign_id: '',
			advertiser_id: '',
		};
		this._success = data?.success ?? false;

		makeAutoObservable(this);
	}

	get campaign(): BidderCampaignModel {
		return this._campaign;
	}

	set campaign(value: IBidderCampaign) {
		this._campaign = new BidderCampaignModel(value);
	}

	get MetaData() {
		return this._MetaData;
	}

	set MetaData(value: IBidderCampaignDetailsMetaData) {
		this._MetaData = value;
	}

	get success() {
		return this._success;
	}

	set success(value: boolean) {
		this._success = value;
	}

	getId() {
		return this.campaign?.campaign_id;
	}

	toObject() {
		return {
			campaign: this._campaign.toObject(),
			MetaData: this.MetaData,
			success: this.success,
		};
	}
}
