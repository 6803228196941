import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { PerformanceResponse } from '../models/performance.model';
import { getConfig } from '@monorepo/tools/src/lib/get-config';
// import { hash } from '@monorepo/tools/src/lib/tools/hash';

export const PerformanceApi = {
	performance: (params: IPerformanceOptions): Promise<PerformanceResponse> => {
		// const paramsHash = hash.encrypt(JSON.stringify(params));
		// let queryParams = '';
		// if (paramsHash) {
		// 	queryParams = `?ck=${paramsHash}`;
		// }
		// return ask.post<IPerformanceOptions>(`${getConfig().env.sheriffRoot}/adx/reporting/reportsApi/api/rp/repr`, params);
		return ask.post<IPerformanceOptions>(`${getConfig().env.sheriffRoot}/adminx-reporting/report`, params).then(res => res.payload);
	},
	downloadCsv: (params?: IPerformanceOptions): Promise<File | null> => {
		return ask.downloadPost<IPerformanceOptions>(`${getConfig().env.sheriffRoot}/adminx-reporting/report/download`, params || {});
	},
	generateCsv: (params?: IPerformanceOptions): Promise<File | null> => {
		return ask.post<IPerformanceOptions>(`${getConfig().env.sheriffRoot}/adminx-reporting/report/generate-csv`, params || {});
	},
};
