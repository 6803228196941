import { Icon } from '@monorepo/base/src/components/icon/icon';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './notifications-menu.module.scss';
import { observer } from 'mobx-react';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { useEffect, useState } from 'react';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { INotificationItem } from '@monorepo/controlled/src/stores/notifications.store';
import { AvatarDetails, AvatarTitle, AvatarSubtitle } from '@monorepo/base/src/components/avatar-details/avatar-details';
import dayjs from 'dayjs';
import { IAdminxStore } from '../../stores';

export const NotificationsMenu = observer(() => {
	const { notificationsStore, userStore } = useStores<IAdminxStore>();
	const notifications = notificationsStore.getNotifications();
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
	const [, setIsBadge] = useState<boolean>(false);

	useEffect(() => {
		if (Array.isArray(notifications) && notifications.length > 0) {
			setIsBadge(true);
		}
	}, [notifications]);

	useEffect(() => {
		if (isOpenMenu) {
			setIsBadge(false);
		}
	}, [isOpenMenu]);

	const NotificationBar = () => {
		return (
			<Icon
				className={styles.notificationBell}
				color={'#8a8a8a'}
				size={'20px'}
				isMFP={true}
				onClick={() => setIsOpenMenu(!isOpenMenu)}>
				bell-03
			</Icon>
		);
	};

	return (
		<div className={styles.wrapper}>
			<NotificationBar />
			<Menu isOpen={isOpenMenu} onClose={() => setIsOpenMenu(false)} alignToRight={true}>
				<div className={styles.notificationsMenu}>
					<div className={styles.title}>Notifications</div>
					<Spacer y={2} />
					{!notifications || notifications.length === 0 ? (
						<div>Nothing new here</div>
					) : (
						notifications.map((notification: INotificationItem, index) => {
							const { msg, children, date } = notification;
							return (
								<div key={date.toString() + msg}>
									<AvatarDetails url={userStore.getUser()?.getPicture()}>
										<AvatarTitle>{msg || children}</AvatarTitle>
										<AvatarSubtitle>{dayjs(date).format('YYYY-MM-DD HH:MM')}</AvatarSubtitle>
									</AvatarDetails>
									{index + 1 !== notifications.length ? <Spacer y={2} /> : null}
								</div>
							);
						})
					)}
				</div>
			</Menu>
		</div>
	);
});
