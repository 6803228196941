import { ReactNode } from 'react';
import styles from './table-merge-column.module.scss';

interface ITableMergeColumn {
	children: ReactNode;
}

export const TableMergeColumn = (props: ITableMergeColumn) => {
	const { children } = props;
	return (
		<div className={styles.groupColumns} key={'headers'}>
			{children}
		</div>
	);
};
