import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { CampaignGroupModel, ICampaignGroup } from '../../models/campaign-group.model';
import { IPerformanceItem } from '../../models/performance.model';
import routes from '../../routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { IAdminxStore } from '../../stores';
import { mapToTagStatus, Status, Statuses } from '../../enums/status';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { createColumnHelper } from '@tanstack/react-table';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { TableStatusCell } from '../../components/table-status-cell/table-status-cell';

const columnHelper = createColumnHelper<ICampaignGroup & IPerformanceItem>();

export const useCampaignGroupHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<CampaignGroupModel> => {
	const { includeColumns = {}, EntityActions, onEdit, summary } = props;
	const { advertiser = true } = includeColumns;
	const { sidebarStore, settingsStore } = useStores<IAdminxStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;

	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				name: true,
				id: true,
				status: true,
				advertiserName: true,
				date: false,
				auctions: true,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: true,
				roas: true,
				ctr: true,
				lpCvr: true,
				impressions: false,
				optCost: false,
				optGain: false,
				gain: false,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	const renderHierachyColumns = () => {
		const columns = [];

		if (advertiser) {
			columns.push(
				columnHelper.accessor('advertiserName', {
					header: 'Advertiser',
					cell: info => {
						const { row } = info;
						if (!row.getValue('advertiserId')) {
							return <span>{row.getValue('advertiserName')}</span>;
						}
						return (
							<TableLink
								to={routes.advertisers.campaigns(row.getValue('advertiserId'))}
								onClick={() => {
									sidebarStore.setSidebarOpen(false);
								}}>
								{row.getValue('advertiserName')}
							</TableLink>
						);
					},
				})
			);
			columns.push(
				columnHelper.accessor('advertiserId', {
					header: 'Advertiser ID',
				})
			);
		}

		return columns;
	};

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => (
					<TableMergeColumn>
						<TableSelectionHeader headerProps={headerProps} />
						<SpacerX x={0.7} />
						<Icon size={'14px'} isMFP={true} color={'#c5c5c5'}>
							circle
						</Icon>
						<SpacerX x={0.7} />
					</TableMergeColumn>
				),
				cell: cellProps => {
					const { row } = cellProps;
					const campaignGroup = new CampaignGroupModel(row.original);
					const status = campaignGroup.getStatus() as Statuses;

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<SpacerX x={0.7} />
							<TableStatusCell status={status} />
							<QuickActionsMenu>
								<EntityActions entity={campaignGroup} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					const toEdit = (() => {
						if (onEdit) {
							return onEdit(row.getValue('id'));
						}
						return routes.campaignGroups.edit(row.getValue('id'));
					})();
					return (
						<TableLink
							to={routes.campaignGroups.campaigns(row.getValue('id'))}
							toEdit={toEdit}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}
							onClick={() => {
								if (!toEdit) {
									sidebarStore.setSidebarOpen(false);
								}
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			// TODO - generalize columns
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string;
					return <Tag type={mapToTagStatus(status.toUpperCase() as Status)}>{row.getValue('status')}</Tag>;
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			...renderHierachyColumns(),
			...mergedPerforamnceHeaders,
		],
	};
};
