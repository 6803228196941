interface IDeleteModal {
	label?: string;
}
export const DeleteModal = (props: IDeleteModal) => {
	const { label } = props;
	if (label) {
		return <div>{label}</div>;
	}
	return <div>Are you sure you want to delete this row?</div>;
};
