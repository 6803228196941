import { makeObservable, observable } from 'mobx';
import { CampaignInputOptionsStore } from './campaign-input-options.store';
import { CampaignCrudStore } from './campaign-crud.store';
import { IPerformanceOptions } from '../../hooks/apis/use-reports-list';
import { FilterStore, FiltersMap } from '@monorepo/controlled/src/stores/filter.store';
import { campaignFilterPrototypes, filterPrototypesByProp } from '../../components/filters-menu/filters/index';
import { PerformanceResponse } from '../../models/performance.model';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { PerformanceApi } from '../../apis/performance.api';
import { Statuses } from '../../enums/status';
import { StringActions } from '@monorepo/tools/src/lib/utils/string';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';

import { campaignsTableConfig } from '../../components/report-table/configurations/adx-repr/campaigns/campaigns.config';
import { NewAdminxReportTableStore } from '../../components/report-table/stores/table-stores/adx-repr-table.store';
import { advertiserCampaignsPerformanceTableConfig } from '../../components/report-table/configurations/adx-repr/campaigns/advertiser-campaign.config';
import { campaignGroupCampaignsPerformanceTableConfig } from '../../components/report-table/configurations/adx-repr/campaigns/campaign-group-campaigns.config';
import { campaignDailyReportPerformanceTableConfig } from '../../components/report-table/configurations/adx-repr/campaigns/campaign-daily-report.config';
import { campaignTrashedBidKeysTableConfig } from '../../components/report-table/configurations/adx-repr/campaigns/campaign-trashed-bidkeys.config';
import { campaignDomainsTableConfig } from '../../components/report-table/configurations/adx-repr/campaigns/campaigns-domains.config';

// every enum here is the path withtout / and ids
// for example, advertiser/1/campaigns will transform to CampaignFiltersStoreKeys.Advertiser
export enum CampaignFiltersStoreKeys {
	Campaigns = 'campaigns',
	Advertiser = 'advertiserscampaigns',
	CampaignGroup = 'campaigngroupscampaigns',
	// CampaignsDailyReport = 'campaignsdailyreport',
}

export class CampaignStore {
	crud = new CampaignCrudStore();
	list = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});

	metrics = new HttpStore<IPerformanceOptions, PerformanceResponse>({
		httpFunc: PerformanceApi.performance,
		model: PerformanceResponse,
	});

	campaignsReportTable = new NewAdminxReportTableStore(campaignsTableConfig);
	advertiserCampaignsReportTable = new NewAdminxReportTableStore(advertiserCampaignsPerformanceTableConfig);
	campaignGroupsCampaignsReportTable = new NewAdminxReportTableStore(campaignGroupCampaignsPerformanceTableConfig);
	campaignDailyReport = new NewAdminxReportTableStore(campaignDailyReportPerformanceTableConfig);
	campaignTrashedBidKeys = new NewAdminxReportTableStore(campaignTrashedBidKeysTableConfig);
	campaignDomainsPerformance = new NewAdminxReportTableStore(campaignDomainsTableConfig);

	inputOptions = new CampaignInputOptionsStore();
	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeObservable(this, {
			crud: observable,
			list: observable,
			// performance: observable,
			metrics: observable,
			campaignsReportTable: observable,
			advertiserCampaignsReportTable: observable,
			campaignGroupsCampaignsReportTable: observable,
			campaignDailyReport: observable,
		});
		this.settingsStore = settingsStore;

		this.createDefaultFilters();
	}

	createDefaultFilters() {
		// set filters for all campaigns pages
		const prototype = campaignFilterPrototypes.find(filter => filter.MenuComponent === 'Status');
		let defaultFilters: FiltersMap;
		if (prototype) {
			defaultFilters = new Map([
				[
					1,
					new FilterModel({
						index: 1,
						label: `Status is ${Statuses.Active}`,
						action: StringActions.Equals,
						value: [Statuses.Active],
						prototype,
					}),
				],
			]);
		}

		Object.values(CampaignFiltersStoreKeys).map((key: CampaignFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const campaignFilterByKey = new FilterStore({
				key,
				currentFilters: defaultFilters,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, campaignFilterByKey);
		});
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}

	getInputOptions() {
		return this.inputOptions;
	}

	// getPerformance() {
	// 	return this.performance;
	// }

	getMetrics() {
		return this.metrics;
	}
}
