import {
	CampaignGroupIdColumnConfig,
	CampaignGroupNameColumnConfig,
	CampaignDailyBudgetColumnConfig,
	CampaignGroupStatusColumnConfig,
} from '../../../columns/adx-repr.columns';
import { generateAdxReprPerformanceColumns } from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { FilterTypeEnum, SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const campaignGroupsTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'wins',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [
			{
				column: 'campaign_group_status',
				filterType: FilterTypeEnum.EQUALS,
				value: ['ACTIVE'],
				inverted: false,
			},
		],
	},
	columns: [
		new CampaignGroupNameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new CampaignGroupIdColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CampaignGroupStatusColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CampaignDailyBudgetColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		...generateAdxReprPerformanceColumns(),
	],
	includeChart: true,
	tableName: 'adxReprCampaignGroupsPerformance',
};
