import { CampaignNameColumnConfig, DateColumnConfig } from '../../../columns/adx-repr.columns';
import { generateAdxReprPerformanceColumns } from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const campaignDailyReportPerformanceTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'dt',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new CampaignNameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new DateColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		...generateAdxReprPerformanceColumns(),
	],
	includeChart: true,
	tableName: 'adxReprCampaignDailyReportPerformance',
};
