import { makeAutoObservable } from 'mobx';
import { url } from '@monorepo/tools/src/lib/types/url';
import { CampaignGroupModel } from './campaign-group.model';
import { AdvertiserModel } from './advertiser.model';
import { IStrategy, StrategyModel } from './strategy.model';
import { ITargetingForm, TargetingModel } from './targeting.model';
import { DailyBudgetModel, IDailyBudget } from './daily-budget.model';
import { IRules, RulesModel } from './rules.model';
import { capitalCase } from 'change-case';
import { CreativeSelectionMode } from '../enums/creative-selection-modes';
import { CampaignType } from '../enums/campaign-types';
import { DealType, DealTypes } from '../enums/deal-types';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { DealModel } from './deal.model';
import { CreativeModel } from './creative.model';
import { Status } from '../enums/status';
import { WhiteBlackLists } from '../enums/white-black-list-enum';
import { AccountsModel, IAccount } from './account.model';

export type BannerSize = {
	id?: id;
	width?: number;
	height?: number;
};

export interface ICampaignSatellitesDetails {
	black_sites_counter: number;
	white_sites_counter: number;
	bid_keys_counter: number;
	trash_counter: number;
	models_params: number;
}

export interface ICampaignDeepCloneOptions {
	source_campaign_id?: id;
	clone_sites_in_target: boolean;
	clone_bid_keys: boolean;
	clone_trash_keys: boolean;
	clone_models_params: boolean;
}

/**
 * The campaign the campaign crud page uses
 */
export interface ICampaign {
	id?: id;
	name?: string;
	advertiser?: AdvertiserModel;
	account?: AccountsModel;
	campaign_group?: CampaignGroupModel;
	status?: Status;
	parent?: CampaignModel;
	priority?: number;
	secondary_priority?: number;
	cache_key_generator?: string;
	campaign_type?: CampaignType;
	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: StrategyModel;
	dest_url?: url;
	targeting?: TargetingModel;
	daily_budget?: DailyBudgetModel;
	default_payout?: number;
	rules?: RulesModel;
	black_listed_sites?: Set<string>;
	white_listed_sites?: Set<string>;
	black_listed_keys?: Set<string>;
	white_listed_keys?: Set<string>;
	creatives?: CreativeModel[];
	creative_selection_mode?: CreativeSelectionMode;
	black_listed_sizes?: BannerSize[];
	white_listed_sizes?: BannerSize[];
	daily_domain_max_loss?: number | null;
	daily_max_sales?: number | null;
	campaign_satellites_details?: ICampaignSatellitesDetails;
	deep_clone_campaign?: ICampaignDeepCloneOptions;
}

/**
 * The campaign the campaign edit page uses
 */
export interface ICampaignEdit {
	id?: id;
	name?: string;
	advertiser?: AdvertiserModel;
	account?: IAccount;
	campaign_group?: CampaignGroupModel;
	status?: Status;
	parent?: CampaignModel;
	priority?: number;
	secondary_priority?: number;
	cache_key_generator?: string;
	campaign_type?: CampaignType;
	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: IStrategy;
	dest_url?: url;
	targeting?: TargetingModel;
	daily_budget?: DailyBudgetModel;
	default_payout?: number;
	rules?: RulesModel;
	creative_selection_mode?: CreativeSelectionMode;
	black_listed_sizes?: BannerSize[];
	white_listed_sizes?: BannerSize[];
	daily_domain_max_loss?: number | null;
	daily_max_sales?: number | null;
}

/**
 * The campaign to send to server
 */
export interface ICampaignCreateForm {
	id?: id;
	name?: string;
	advertiser?: { id?: id }; // Advertiser id
	campaign_group?: { id?: id } | null; // Campaign group id
	account?: IAccount;
	status?: Status;
	parent?: { id?: id };
	priority?: number;
	secondary_priority?: number;
	cache_key_generator?: string;
	campaign_type?: CampaignType;
	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: { id: id } | null;
	dest_url?: url;
	targeting?: ITargetingForm;
	daily_budget?: IDailyBudget;
	default_payout?: number;
	rules?: IRules;
	creatives?: number[];
	creative_selection_mode?: CreativeSelectionMode;
	black_listed_sizes?: BannerSize[];
	white_listed_sizes?: BannerSize[];
	daily_domain_max_loss?: number | null;
	daily_max_sales?: number | null;
	deep_clone_campaign?: ICampaignDeepCloneOptions;
}

export interface ICampaignEditForm {
	id?: id;
	name?: string;
	advertiser?: { id?: id }; // Advertiser id
	account?: IAccount;
	campaign_group?: { id?: id } | null; // Campaign group id
	status?: Status;
	parent?: { id?: id };
	priority?: number;
	secondary_priority?: number;
	cache_key_generator?: string;
	campaign_type?: CampaignType;
	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: { id?: id } | null;
	dest_url?: url;
	targeting?: ITargetingForm;
	daily_budget?: IDailyBudget;
	default_payout?: number;
	rules?: IRules;
	creatives?: number[];
	creative_selection_mode?: CreativeSelectionMode;
	black_listed_sizes?: BannerSize[];
	white_listed_sizes?: BannerSize[];
	daily_domain_max_loss?: number | null;
	daily_max_sales?: number | null;
}

export interface ICampaignTargetingListing {
	black_listed_sites?: string[];
	white_listed_sites?: string[];
	black_listed_keys?: string[];
	white_listed_keys?: string[];
}

export class CampaignModel implements ICampaign {
	id?: id;
	name?: string;
	advertiser?: AdvertiserModel;
	campaign_group?: CampaignGroupModel;
	account?: AccountsModel;
	status?: Status;
	parent?: CampaignModel;
	priority?: number;
	secondary_priority?: number;
	cache_key_generator?: string;
	campaign_type?: CampaignType;
	deal_type?: DealType;
	preferred_deals?: DealModel[];
	billing_id?: id;
	strategy?: StrategyModel;
	dest_url?: url;
	targeting?: TargetingModel;
	daily_budget?: DailyBudgetModel;
	default_payout?: number;
	rules?: RulesModel;
	black_listed_sites?: Set<string>;
	white_listed_sites?: Set<string>;
	black_listed_keys?: Set<string>;
	white_listed_keys?: Set<string>;
	creatives?: CreativeModel[];
	black_listed_sizes?: BannerSize[];
	white_listed_sizes?: BannerSize[];
	banner_list_type?: WhiteBlackLists;
	creative_selection_mode?: CreativeSelectionMode;
	daily_domain_max_loss?: number | null;
	daily_max_sales?: number | null;
	campaign_satellites_details?: ICampaignSatellitesDetails;
	deep_clone_campaign?: ICampaignDeepCloneOptions;

	constructor(campaign?: ICampaign) {
		this.id = campaign?.id;
		this.name = campaign?.name;
		this.advertiser = campaign?.advertiser ? new AdvertiserModel(campaign.advertiser) : undefined;
		this.account = campaign?.account ? new AccountsModel(campaign.account) : undefined;
		this.campaign_group = campaign?.campaign_group ? new CampaignGroupModel(campaign.campaign_group) : undefined;
		this.status = campaign?.status;
		this.parent = campaign?.parent ? new CampaignModel(campaign?.parent) : undefined;
		this.priority = campaign?.priority;
		this.secondary_priority = campaign?.secondary_priority;
		this.cache_key_generator = campaign?.cache_key_generator;
		this.daily_domain_max_loss = campaign?.daily_domain_max_loss;

		this.campaign_type = campaign?.campaign_type;
		this.deal_type = campaign?.deal_type ? (capitalCase(campaign?.deal_type) as DealType) : DealTypes.OpenAuction;
		this.preferred_deals = campaign?.preferred_deals?.map(deal => new DealModel(deal));
		this.billing_id = campaign?.billing_id;
		this.strategy = campaign?.strategy ? new StrategyModel(campaign.strategy) : undefined;
		this.dest_url = campaign?.dest_url;
		this.targeting = campaign?.targeting ? new TargetingModel(campaign.targeting) : undefined;
		this.daily_budget = campaign?.daily_budget ? new DailyBudgetModel(campaign.daily_budget) : undefined;
		this.default_payout = campaign?.default_payout;
		this.rules = campaign?.rules ? new RulesModel(campaign.rules) : undefined;
		this.black_listed_sites = new Set<string>(campaign?.black_listed_sites ?? []);
		this.white_listed_sites = new Set<string>(campaign?.white_listed_sites ?? []);
		this.black_listed_keys = new Set<string>(campaign?.black_listed_keys ?? []);
		this.white_listed_keys = new Set<string>(campaign?.white_listed_keys ?? []);
		this.black_listed_sizes = campaign?.black_listed_sizes || [];
		this.white_listed_sizes = campaign?.white_listed_sizes || [];
		this.banner_list_type = (campaign?.white_listed_sizes || [])?.length > 0 ? WhiteBlackLists.Whitelist : WhiteBlackLists.Blacklist;
		this.creatives = campaign?.creatives?.map(campaignCreative => {
			// happens because it returns the pivot table instead of creative
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore: Unreachable code error
			return new CreativeModel(campaignCreative.creative);
		});
		this.creative_selection_mode = campaign?.creative_selection_mode;
		this.daily_domain_max_loss = campaign?.daily_domain_max_loss;
		this.daily_max_sales = campaign?.daily_max_sales;
		this.campaign_satellites_details = campaign?.campaign_satellites_details ?? {
			black_sites_counter: 0,
			white_sites_counter: 0,
			bid_keys_counter: 0,
			trash_counter: 0,
			models_params: 0,
		};

		// initialize only for duplicate flow
		if (campaign?.id || campaign?.deep_clone_campaign) {
			this.deep_clone_campaign = campaign?.deep_clone_campaign ?? {
				source_campaign_id: campaign?.id,
				clone_sites_in_target: false,
				clone_bid_keys: false,
				clone_trash_keys: false,
				clone_models_params: false,
			};
		}

		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getName() {
		return this.name;
	}

	public setName(name: string) {
		this.name = name;
	}

	public getAdvertiser(): AdvertiserModel | undefined {
		return this.advertiser;
	}

	public getAccount(): AccountsModel | undefined {
		return this.account;
	}

	public setAccount(account: AccountsModel): void {
		this.account = account;
	}

	public setAdvertiser(advertiser: AdvertiserModel): void {
		this.advertiser = advertiser;
	}

	public getCampaignGroup(): CampaignGroupModel | undefined {
		return this.campaign_group;
	}

	public setCampaignGroup(campaignGroup: CampaignGroupModel | undefined): void {
		this.campaign_group = campaignGroup;
	}

	public getStatus(): Status | undefined {
		return this.status;
	}

	public setParentCampaign(parent: CampaignModel | undefined): void {
		this.parent = parent;
	}

	public getParentCampaign(): CampaignModel | undefined {
		return this.parent;
	}

	public setStatus(status: Status): void {
		this.status = status;
	}

	public getPriority(): number | undefined {
		return this.priority;
	}

	public setPriority(priority: number): void {
		this.priority = priority;
	}

	public getSecondaryPriority(): number | undefined {
		return this.secondary_priority;
	}

	public setSecondaryPriority(secondary_priority: number): void {
		this.secondary_priority = secondary_priority;
	}

	public getCacheKeyGenerator(): string | undefined {
		return this.cache_key_generator;
	}

	public setCacheKeyGenerator(cache_key_generator: string): void {
		this.cache_key_generator = cache_key_generator;
	}

	public getCampaignType(): CampaignType | undefined {
		return this.campaign_type;
	}

	public setCampaignType(campaign_type: CampaignType): void {
		this.campaign_type = campaign_type;
	}

	public getDealType(): DealType | undefined {
		return this.deal_type;
	}

	public setDealType(deal_type: DealType): void {
		this.deal_type = deal_type;
	}

	public setBillingId(billingId: id | undefined) {
		this.billing_id = billingId;
	}

	public getBillingId(): id | undefined {
		return this.billing_id;
	}

	public getStrategy(): StrategyModel | undefined {
		return this.strategy;
	}

	public setStrategy(strategy: StrategyModel | undefined): void {
		this.strategy = strategy;
	}

	public getDestinationUrl(): url | undefined {
		return this.dest_url;
	}

	public setDestinationUrl(dest_url: url): void {
		this.dest_url = dest_url;
	}

	public getTargeting(): TargetingModel | undefined {
		return this.targeting;
	}

	public setTargeting(targeting: TargetingModel): void {
		this.targeting = targeting;
	}

	public getDailyBudget(): DailyBudgetModel | undefined {
		return this.daily_budget;
	}

	public setDailyBudget(dailyBudget: DailyBudgetModel): void {
		this.daily_budget = dailyBudget;
	}

	public getDefaultPayout(): number | undefined {
		return this.default_payout;
	}

	public setDefaultPayout(defaultPayout: number): void {
		this.default_payout = defaultPayout;
	}

	public getRules(): RulesModel | undefined {
		return this.rules;
	}

	public setRules(rules: RulesModel): void {
		this.rules = rules;
	}

	public getBlackListedSites(): Set<string> | undefined {
		return this.black_listed_sites;
	}
	public getWhiteListedSites(): Set<string> | undefined {
		return this.white_listed_sites;
	}

	public setBlackListedSites(black_listed_sites: Set<string>): void {
		this.black_listed_sites = black_listed_sites;
	}
	public setWhiteListedSites(white_listed_sites: Set<string>): void {
		this.white_listed_sites = white_listed_sites;
	}

	public getBlacklistedKeys(): Set<string> | undefined {
		return this.black_listed_keys;
	}
	public getWhitelistedKeys(): Set<string> | undefined {
		return this.white_listed_keys;
	}

	public getCreatives(): CreativeModel[] | undefined {
		return this.creatives;
	}
	public setCreatives(creative: CreativeModel[]): void {
		this.creatives = creative;
	}
	public getCreativeSelectionMode(): CreativeSelectionMode | undefined {
		return this.creative_selection_mode;
	}
	public setCreativeSelectionMode(creativeSelectionMode: CreativeSelectionMode): void {
		this.creative_selection_mode = creativeSelectionMode;
	}
	public getPreferredDeals(): DealModel[] | undefined {
		return this.preferred_deals;
	}
	public setPreferredDeals(preferred_deals: DealModel[]): void {
		this.preferred_deals = preferred_deals;
	}
	public setBlacklistedSizes(black_listed_sizes: BannerSize[]): void {
		this.black_listed_sizes = black_listed_sizes;
	}
	public setWhitelistedSizes(white_listed_sizes: BannerSize[]): void {
		this.white_listed_sizes = white_listed_sizes;
	}
	public getBlacklistedSizes(): BannerSize[] | undefined {
		return this.black_listed_sizes;
	}
	public getWhitelistedSizes(): BannerSize[] | undefined {
		return this.white_listed_sizes;
	}
	public getBannerListType(): WhiteBlackLists | undefined {
		return this.banner_list_type;
	}
	public setBannerListType(banner_list_type: WhiteBlackLists): void {
		this.banner_list_type = banner_list_type;
	}
	public getDailyDomainMaxLoss(): number | undefined | null {
		return this.daily_domain_max_loss;
	}
	public setDailyDomainMaxLoss(daily_domain_max_loss: number | null): void {
		this.daily_domain_max_loss = daily_domain_max_loss;
	}
	public getDailyMaxSales(): number | undefined | null {
		return this.daily_max_sales;
	}
	public setDailyMaxSales(daily_max_sales: number | null): void {
		this.daily_max_sales = daily_max_sales;
	}
}
