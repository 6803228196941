import { Fragment } from 'react';
import { id } from '@monorepo/tools/src/lib/types/primitives';

type IToggleCampaignStatuses = {
	campaignIds: id[];
	action: string;
};
export const EnablePauseCampaignsModal = (props: IToggleCampaignStatuses) => {
	const { campaignIds, action } = props;
	return (
		<Fragment>
			<div>{action} the following campaigns:</div>
			<span style={{ color: '#4f46e5' }}>
				{campaignIds.length < 6
					? campaignIds.map((campaignId, index) => `${index !== campaignIds.length - 1 ? campaignId + ', ' : campaignId + '.'}`)
					: `${campaignIds.length} campaigns`}
			</span>
		</Fragment>
	);
};
