interface IFormError {
	property: string;
	msg: string;
}

export class FormError implements IFormError {
	property: string;
	msg: string;

	constructor(property: string, msg: string) {
		this.property = property;
		this.msg = msg;
	}

	getMsg(): string {
		return this.msg;
	}

	getProperty(): string {
		return this.property;
	}
}
