import { HttpError } from '../../models/http-error.model';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './error-msg.module.scss';

interface IErrorMsg {
	error: HttpError | string;
	classes?: { error: string };
	isIcon?: boolean;
}

export const ErrorMsg = (props: IErrorMsg) => {
	const { error, classes, isIcon = true } = props;

	if (typeof error === 'string') {
		return (
			<div className={`${styles.error} ${classes?.error}`}>
				{isIcon ? <Icon isMFP={true}>alert-circle</Icon> : null}
				{error}
			</div>
		);
	}

	if (typeof error.getMessage() !== 'string') {
		return (
			<div className={`${styles.error} ${classes?.error}`}>
				{isIcon ? <Icon isMFP={true}>alert-circle</Icon> : null}
				Invalid Params
			</div>
		);
	}
	return (
		<div className={`${styles.error} ${classes?.error}`}>
			{isIcon ? <Icon isMFP={true}>alert-circle</Icon> : null}
			{error.getMessage()}
		</div>
	);
};
