import { Fragment } from 'react';
import { DealsApi } from '../../apis/deals.api';
import { Segments } from '@monorepo/base/src/components/segments/segments';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { ActionModal } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { AttachDealCampaigns } from '../../components/modals/attach-deal-campaigns/attach-deal-campaigns';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { DealModel } from '../../models/deal.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { PerformancesColumnsSnakeCase } from '../apis/use-reports-list';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useSegments } from '../tools/use-segments';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions } from './default.actions';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IAdminxStore } from '../../stores';
import { ISegmentActions } from '@monorepo/base/src/components/table/table';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { dealsFilterPrototypes } from '../../components/filters-menu/filters';

const theme = getTheme();

export function useDealActions() {
	const { dealStore, modalsStore, settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { dispatchLog } = useLogs();

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [...dealsFilterPrototypes];

	const performanceStore = dealStore.getPerformance();

	const attachCampaigns = (dealId: id, dealName: string) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					primaryBtnTitle={'Attach Campaigns'}
					onAction={() => {
						const campaignIds: id[] = [];

						dealStore
							.getCrud()
							.getData()
							.getCampaigns()
							?.forEach(campaign => {
								const id = campaign.getId();

								if (id) {
									campaignIds.push(id);
								}
							});

						DealsApi.attachCampaigns(dealId, campaignIds).then(() => {
							dispatchLog({
								msg: `Deal ${dealName} attached/detached campaigns successfully`,
								type: DispatchLogsTypes.Success,
							});
						});
					}}>
					<AttachDealCampaigns dealId={dealId} />
				</ActionModal>
			),
		});
	};

	const SelectedActions = (props: ITableActions<DealModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new DealModel(row.original));
		const firstEntity = entities[0];
		const firstEntityName = firstEntity.getDealName();
		const firstEntityId = firstEntity.getId();
		if (!firstEntityId || !firstEntityName) {
			// log error
			return null;
		}

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						iconSize={'22px'}
						icon={'link-02'}
						onClick={() => attachCampaigns(firstEntityId, firstEntityName)}
						disabled={entities.length > 1}
						textColor={'white'}>
						Attach
					</SecondaryText>
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<DealModel>) => {
		const { entity } = props;
		const entityId = entity.getId();
		const entityName = entity.getDealName();
		if (!entityId || !entityName) {
			// log error
			return null;
		}

		return (
			<Fragment>
				<QuickActionButton>
					<SecondaryText
						iconSize={'16px'}
						iconColor={theme.titleColor}
						textColor={theme.titleColor}
						icon={'link-02'}
						onClick={() => attachCampaigns(entityId, entityName)}>
						Attach
					</SecondaryText>
				</QuickActionButton>
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	const SegmentActions = (props: ISegmentActions) => {
		const { segments, onSegment } = useSegments<DealModel>({
			performanceStore,
			disableSegments: [PerformancesColumnsSnakeCase.PreferredDealId],
		});

		return (
			<Segments
				currentSegments={performanceStore.getSegments()}
				availableSegments={segments}
				onClick={segment => onSegment(segment)}
				{...props}
			/>
		);
	};

	return {
		SelectedActions,
		EntityActions,
		SegmentActions,
		FiltersActions,
	};
}
