import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useCampaignGroupGet } from '../../hooks/apis/use-campaign-group-get';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel } from '../../models/advertiser.model';
import { CampaignGroupModel } from '../../models/campaign-group.model';
import routes from '../../routes/index.routes';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import { SkeletonBreadCrumb } from './main-breadcrumbs';
import styles from './main-breadcrumbs.module.scss';
import { AdvertiserBreadcrumb } from './advertiser-page.breadcrumbs';
import { IAdminxStore } from '../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { SelectNew, SelectOptions } from '@monorepo/base/src/components/select-new/select-new';

interface ICampaignGroupBreadcrumb {
	campaignGroup: CampaignGroupModel;
	advertiser?: AdvertiserModel;
}

export const CampaignGroupBreadcrumb = observer((props: ICampaignGroupBreadcrumb) => {
	const { campaignGroup, advertiser } = props;
	const { advertiserStore } = useStores<IAdminxStore>();
	const navigate = useNavigateParams();

	const advertisersCrud = advertiserStore.getCrud();
	const campaignGroups = advertiser?.getCampaignGroups();
	const campaignGroupName = campaignGroup?.getName();
	const isLoadingAdvertiser = advertisersCrud.getIsLoading();

	const onListItemCampaignGroup = (campaignGroupsId: (string | number)[]) => {
		const campaignGroupId = campaignGroupsId[0];

		if (!campaignGroupId) {
			// TODO - log
			return null;
		}
		navigate(routes.campaignGroups.campaigns(campaignGroupId));
	};

	const onBreadcrumbCampaignGroup = () => {
		const advertiserId = advertiser?.getId();
		if (!advertiserId) {
			return null;
		}
		advertisersCrud.get(advertiserId).then(_advertiser => {
			if (!_advertiser) {
				return null;
			}
			const advertiserCampaignGroups = _advertiser.getCampaignGroups();
			if (advertiserCampaignGroups) {
				advertiser?.setCampaignGroups(advertiserCampaignGroups);
			}
		});
	};

	const onBreadcrumbLink = () => {
		const campaignGroupId = campaignGroup.getId();
		if (campaignGroupId) {
			navigate(`${routes.campaignGroups.campaigns(campaignGroupId)}`);
		}
	};

	if (!campaignGroupName) {
		return null;
	}

	return (
		<BreadcrumbMenu
			label={'Campaign Group'}
			title={campaignGroupName}
			onArrowClick={onBreadcrumbCampaignGroup}
			navigateToModel={onBreadcrumbLink}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_groups_breadcrumbs')] }}
			isBarLoader={isLoadingAdvertiser}
			isLoading={isLoadingAdvertiser && (campaignGroups?.length === 0 || !campaignGroups)}>
			<div className={styles.menuWrapper}>
				<SelectNew
					classNames={{ list: styles.list }}
					placeholder={'Search Campaign Group'}
					options={(campaignGroups || []).map(_campaignGroup => {
						return {
							label: _campaignGroup.getName() as string,
							value: _campaignGroup.getId() as string,
							to: routes.campaignGroups.campaigns(_campaignGroup.getId() as string),
						};
					})}
					sortBy={(a: SelectOptions, b: SelectOptions) => (a.label > b.label ? 1 : -1)}
					required
					onSelect={onListItemCampaignGroup}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_groups_breadcrumbs_select')] }}
					disabled={isLoadingAdvertiser}
					defaultValues={(campaignGroup.getId() ? [campaignGroup.getId()] : []) as string[]}
				/>
			</div>
		</BreadcrumbMenu>
	);
});

interface ICampaignGroupPageBreadcrumbs {
	campaignGroupId: string | number;
}

export const CampaignGroupPageBreadcrumbs = observer((props: ICampaignGroupPageBreadcrumbs) => {
	const { campaignGroupId } = props;
	const { campaignGroupsStore, advertiserStore } = useStores<IAdminxStore>();

	useCampaignGroupGet({ id: campaignGroupId });

	const campaignGroup = campaignGroupsStore.getCrud().getData();
	const advertiser = campaignGroup?.getAdvertiser();

	useEffect(() => {
		if (advertiser) {
			advertiserStore.getCrud().setData(advertiser);
		}
	}, [advertiser]);

	return (
		<div className={styles.wrapper}>
			{advertiser ? <AdvertiserBreadcrumb advertiser={advertiser} /> : <SkeletonBreadCrumb isNext={true} />}
			{campaignGroup.getName() ? (
				<CampaignGroupBreadcrumb campaignGroup={campaignGroup} advertiser={advertiser} />
			) : (
				<SkeletonBreadCrumb isNext={true} />
			)}
		</div>
	);
});
