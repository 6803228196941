import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Card } from '@monorepo/base/src/components/card/card';
import { WhiteBlackLists } from '../../../../enums/white-black-list-enum';
import { IAdminxStore } from '../../../../stores';
import { IWhiteBlackListChangeData, WhiteBlackList } from '../../../white-black-list/white-black-list';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const KeysInTarget = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();

	const campaignCrudStore = campaignStore.getCrud();
	const formStore = campaignCrudStore.getFormStore();

	const whitelist = campaignStore.getCrud().getData().getWhitelistedKeys() || new Set<string>();
	const blacklist = campaignStore.getCrud().getData().getBlacklistedKeys() || new Set<string>();

	const onListChange = (data: IWhiteBlackListChangeData) => {
		blacklist.clear();
		whitelist.clear();
		if (data?.listType === WhiteBlackLists.Whitelist) {
			data?.list.forEach(value => {
				whitelist.add(value);
			});
		} else if (data?.listType === WhiteBlackLists.Blacklist) {
			data?.list.forEach(value => {
				blacklist.add(value);
			});
		}
		campaignCrudStore.setTargetListingsEdited();
	};

	return (
		<Card
			id={'sitesInTarget'}
			title={'Keys In Target'}
			icon={'key-01'}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'keys_in_target_section')] }}>
			<Spacer />
			<WhiteBlackList
				blacklist={blacklist}
				whitelist={whitelist}
				textareaPlaceholder={'List Of Keys'}
				defaultListType={WhiteBlackLists.Blacklist}
				onListChange={onListChange}
				error={formStore.getErrors().get('black_listed_keys')?.getMsg() || formStore.getErrors().get('white_listed_keys')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'keys_in_target')] }}
			/>
			<Spacer />
		</Card>
	);
});
