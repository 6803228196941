interface ISendGtagEvent {
	action: string;
	category: string;
	label?: string;
	value?: string | number;
}

export const sendGtagEvent = (props: ISendGtagEvent): void => {
	const { action, category, label, value } = props;
	if (typeof (window as any).gtag === 'function') {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		window.gtag('event', action, {
			event_category: category,
			event_label: label,
			value,
		});
	}
};
