export const localStorageHelper = {
	setItem(key: string, item: string) {
		window.localStorage.setItem(key, item);
	},
	getItem(key: string): string | null {
		return window.localStorage.getItem(key);
	},
	removeItem(key: string): void {
		window.localStorage.removeItem(key);
	},
};

export const sessionStorageHelper = {
	setItem(key: string, item: string) {
		window.sessionStorage.setItem(key, item);
	},
	getItem(key: string): string | null {
		return window.sessionStorage.getItem(key);
	},
	removeItem(key: string): void {
		window.sessionStorage.removeItem(key);
	},
};

