import { Icon } from '../icon/icon';
import styles from './spinner.module.scss';

interface ISpinner {
	is?: boolean;
	size?: string;
}

export const Spinner = (props: ISpinner) => {
	const { is = true, size = '20px' } = props;
	if (!is) {
		return null;
	}
	return (
		<Icon size={size} className={styles.spinner} isMFP={true}>
			loading-02
		</Icon>
	);
};
