import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { observer } from 'mobx-react';
import { createContext, ReactElement, useContext, useMemo, useState } from 'react';
import styles from './table-component.module.scss';
import ColumnPicker from './column-picker/column-picker';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { SearchFilter } from '@monorepo/base/src/components/table/search-filter/search-filter';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { TableFilters } from './table-filters/table-filters';
import { TableDefaultButton, TableDefaultButtonWrapper } from './table-elements/table-buttons';
import { ExportToCSV, TableActionsBar } from './table-elements/table-actions';
import { TableStore } from './stores/table-stores/table.store';
import { ReportTableStore } from './stores/table-stores/old-report-table.store';
import { NewAdminxReportTableStore } from './stores/table-stores/adx-repr-table.store';
import { ColumnConfig } from './columns/columns';

type TableChildrens = ReactElement<typeof TableDefaultButton | typeof TableActionsBar | typeof TableDefaultButtonWrapper>;

interface TableComponentProps<T> {
	tableStore: TableStore<T>;
	showColumnPicker?: boolean;
	showSearchFilter?: boolean;
	disableCsvExport?: boolean;
	children?: TableChildrens | TableChildrens[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableContext = createContext<TableStore<any> | undefined>(undefined);

export const useTableContext = () => {
	const context = useContext(TableContext);
	if (!context) {
		throw new Error('TableContext is not provided');
	} else if (!(context instanceof TableStore)) {
		throw new Error('TableContext is not TableStore');
	}
	return { tableStore: context as TableStore };
};

export const useTableStoreContext = () => {
	const context = useContext(TableContext);
	if (!context) {
		throw new Error('TableContext is not provided');
	} else if (!(context instanceof ReportTableStore || context instanceof NewAdminxReportTableStore)) {
		throw new Error('TableContext is not ReportTableStore | NewAdminxReportTableStore');
	}
	return { tableStore: context };
};

const ReportTable = observer(
	<T,>({ tableStore, children, showColumnPicker = true, showSearchFilter = true, disableCsvExport = false }: TableComponentProps<T>) => {
		const [searchQuery, setSearchQuery] = useState('');
		const data = useMemo(() => {
			const allData = tableStore.dataStore.getReportData() || [];
			if (!searchQuery) {
				return allData;
			}
			return allData.filter(item =>
				Object.values(item as object).some(value => String(value).toLowerCase().includes(searchQuery.toLowerCase()))
			);
		}, [tableStore.dataStore.getReportData(), searchQuery]);

		const footerData = tableStore.dataStore.getReportFooter() as Record<string, number>;
		const { DefaultButtons, ActionsBar } = useMemo(() => {
			if (!children) {
				return {
					DefaultButton: null,
					TableActionsBar: null,
				};
			}
			const childrenArray = Array.isArray(children) ? children : [children];
			const DefaultButtons = childrenArray.filter(
				child => child.type === TableDefaultButton || child.type === TableDefaultButtonWrapper
			);
			const ActionsBar = childrenArray.find(child => child.type === TableActionsBar);
			return {
				DefaultButtons,
				ActionsBar,
			};
		}, [children]);

		const enableRowSelection = !!ActionsBar;

		const columns = [
			...(enableRowSelection
				? [
						{
							accessorKey: 'selection',
							id: 'selection',
							header: () => {
								return (
									<input
										className={styles.selectionCheckbox}
										type="checkbox"
										checked={table.getIsAllPageRowsSelected()}
										onChange={() => table.toggleAllPageRowsSelected()}
									/>
								);
							},
							cell: info => {
								return (
									<input
										className={styles.selectionCheckbox}
										type="checkbox"
										checked={info.row.getIsSelected()}
										onChange={() => info.row.toggleSelected()}
									/>
								);
							},
						} as ColumnDef<T>,
				  ]
				: []),
			...tableStore.columns
				.filter(col => !col.isAlwaysHidden())
				.map(column => {
					return {
						accessorKey: column.accessor,
						tooltip: column.tooltip,
						id: column.accessor,
						header: column.header,
						cell: column.cell ? column.cell : info => (info.row.original as Record<string, unknown>)[column.accessor],
						footer: () => {
							const footerValue = footerData[column.accessor];
							if (!footerValue) {
								return '';
							}
							return column.footerFormatter ? column.footerFormatter(footerValue) : footerValue.toLocaleString();
						},
					} as ColumnDef<T>;
				}),
		];

		const isTotalRow =
			Object.entries(footerData).filter(([k, v]) => typeof v === 'number' && tableStore.columns.some(c => c.name === k)).length > 0;

		const table = useReactTable({
			columns,
			data,
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
			getPaginationRowModel: getPaginationRowModel(),
			onSortingChange: tableStore.onSortingChange,
			onColumnVisibilityChange: tableStore.onColumnVisibilityChange,
			onPaginationChange: tableStore.onPaginationChange,
			onRowSelectionChange: tableStore.onRowSelectionChange,
			getRowId: tableStore.getRowId,
			autoResetPageIndex: false,
			enableRowSelection,
			enableMultiRowSelection: enableRowSelection,
			state: {
				pagination: tableStore.tableState.pagination,
				sorting: tableStore.tableState.sorting,
				columnVisibility: tableStore.tableState.columnVisibility,
				rowSelection: tableStore.tableState.rowSelection,
			},
		});

		const isTableLoading = tableStore.dataStore.getIsLoading();
		const dataError = tableStore.dataStore.getError();
		const tablePagination = tableStore.tableState.pagination;
		const columnVisibility = tableStore.tableState.columnVisibility;
		const hasPreviousPage = tablePagination.pageIndex > 0;
		const isTableHasFilters = tableStore.columns.some(col => !!col.availableFilters);
		const hasNextPage = tablePagination.pageIndex < Math.ceil(tableStore.dataStore.getTotalRows() / tablePagination.pageSize) - 1;

		return (
			<TableContext.Provider value={tableStore}>
				<div className={styles.reportTableWrapper}>
					{!isTableLoading && data.length === 0 ? (
						<div className={`${styles.noRows} ${dataError ? styles.error : ''}`}>
							<div>{dataError ? 'Error' : 'No Results Found'}</div>
							{dataError ? `${JSON.stringify(dataError)}` : null}
						</div>
					) : null}
					<div className={styles.tableActionBar}>
						<div className={styles.actions}>
							{DefaultButtons}
							{DefaultButtons?.length ? <div className={styles.divider} /> : null}
							<div className={styles.filtersWrapper}>{isTableHasFilters ? <TableFilters /> : null}</div>
						</div>
						<div className={styles.actions}>
							{showColumnPicker && <ColumnPicker />}
							{!disableCsvExport && <ExportToCSV />}
							{showSearchFilter && (
								<SearchFilter
									globalFilter={searchQuery}
									onSetGlobalFilter={qry => {
										setSearchQuery(qry);
										table.setPageIndex(0);
									}}
								/>
							)}
						</div>
						{ActionsBar}
					</div>
					<table className={styles.reportTable}>
						<thead className={`${isTableLoading ? styles.theadLoading : ''}`}>
							{table.getHeaderGroups().map(headerGroup => (
								<tr key={headerGroup.id}>
									{headerGroup.headers.map(header => (
										<th key={header.id} colSpan={header.colSpan}>
											{header.isPlaceholder ? null : (
												<Tooltip content={(header.column?.columnDef as ColumnConfig)?.tooltip || ''} delay={1000}>
													<div
														className={styles.header}
														onClick={
															header.id === 'selection' ? undefined : header.column.getToggleSortingHandler()
														}>
														{flexRender(header.column.columnDef.header, header.getContext())}
														{header.id === 'selection'
															? null
															: {
																	asc: (
																		<Icon className={styles.arrowDirection} isMFP={true} size={'1rem'}>
																			arrow-up
																		</Icon>
																	),
																	desc: (
																		<Icon className={styles.arrowDirection} isMFP={true} size={'1rem'}>
																			arrow-down
																		</Icon>
																	),
																	placeHolder: (
																		<Icon
																			className={`${styles.arrowDirection} ${styles.arrowDirectionPlaceholder}`}
																			isMFP={true}
																			size={'1rem'}>
																			arrow-up
																		</Icon>
																	),
															  }[(header.column.getIsSorted() as string) || 'placeHolder']}
													</div>
												</Tooltip>
											)}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody>
							{isTotalRow &&
								searchQuery.length === 0 &&
								table.getFooterGroups().map(footerGroup => (
									<tr key={footerGroup.id}>
										{footerGroup.headers.map(header =>
											isTableLoading && Object.keys(footerData).length === 0 ? (
												<td key={header.id}>
													<div className={styles.skeleton}>-</div>
												</td>
											) : (
												<td key={header.id}>
													{header.isPlaceholder
														? null
														: flexRender(header.column.columnDef.footer, header.getContext())}
												</td>
											)
										)}
									</tr>
								))}
							{table.getRowModel().rows.map(row => (
								<tr className={row.getIsSelected() ? styles.selected : ''} key={row.id}>
									{row.getVisibleCells().map(cell => (
										<td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
									))}
								</tr>
							))}
							{(isTableLoading || data.length === 0) &&
								table.getRowModel().rows.length < tablePagination.pageSize &&
								Array.from({ length: tablePagination.pageSize - table.getRowModel().rows.length }).map((_, i) => (
									<tr key={i}>
										{tableStore.columns
											.filter(c => columnVisibility[c.accessor] || c.isAlwaysVisible())
											.map((__, colIndex) => (
												<td key={colIndex}>
													<div className={isTableLoading ? styles.skeleton : styles.empty}>-</div>
												</td>
											))}
										{enableRowSelection && (
											<td>
												<div className={isTableLoading ? styles.skeleton : styles.empty}>-</div>
											</td>
										)}
									</tr>
								))}
						</tbody>
					</table>
					<div className={styles.paginationSettings}>
						<Dropdown
							defaultOption={`Show ${table.getState().pagination.pageSize.toLocaleString()}`}
							isCloseIcon={false}
							options={['Show 5', 'Show 10', 'Show 20', 'Show 50', 'Show 100', 'Show 200']}
							className={styles.pageSizeDropdown}
							onSelect={(size: Set<option> | undefined) => {
								const sizeNumber = Number(size?.values().next().value.replace('Show ', ''));
								sendGtagEvent({
									action: TrackingActions.Click,
									category: EVENTS.CLICK.INDEX_PAGE.TABLE_SETTINGS.SHOW_AMOUNT.CHOOSE,
									label: window.location.href,
									value: sizeNumber,
								});
								table.setPageSize(sizeNumber);
							}}
						/>
						<div className={styles.paginationNavigation}>
							<button
								className={styles.paginationNavigationButton}
								onClick={() => table.previousPage()}
								disabled={!hasPreviousPage}>
								<Icon className={styles.paginationNavigationButtonIcon} size={'1rem'} isMFP={true}>
									chevron-left
								</Icon>
							</button>
							{tablePagination.pageIndex * tablePagination.pageSize + 1} -{' '}
							{Math.min((tablePagination.pageIndex + 1) * tablePagination.pageSize, tableStore.dataStore.getTotalRows())} of{' '}
							{tableStore.dataStore.getTotalRows() === 100000 ? 'more than' : ''} {tableStore.dataStore.getTotalRows()}
							<button className={styles.paginationNavigationButton} onClick={() => table.nextPage()} disabled={!hasNextPage}>
								<Icon className={styles.paginationNavigationButtonIcon} size={'1rem'} isMFP={true}>
									chevron-right
								</Icon>
							</button>
						</div>
					</div>
				</div>
			</TableContext.Provider>
		);
	}
);

export default ReportTable;
