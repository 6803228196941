export interface IStrategy {
	id: number;
	name: string;
}

/**
 * Not observable for now
 */
export class StrategyModel implements IStrategy {
	id: number;
	name: string;

	constructor(props: IStrategy) {
		const { id, name } = props;
		this.id = id;
		this.name = name;
	}

	getId(): number {
		return this.id;
	}
}
