import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CampaignModel } from '../../../models/campaign.model';
import { SelectionList } from '@monorepo/base/src/components/selection-list/selection-list';
import styles from './attach-creative-campaigns.module.scss';
import { IAdminxStore } from '../../../stores';
import { Status, Statuses } from '../../../enums/status';
import { SelectionListOption } from '../../selection-list-option/selection-list-option';
import { CreativeModel } from '../../../models/creative.model';
import {
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsFilterType,
	IPerformanceOptionsSortBy,
	PerformancesColumnsSnakeCase,
	preparePerformanceRequest,
} from '../../../hooks/apis/use-reports-list';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import dayjs from 'dayjs';
import { AdvertiserModel } from '../../../models/advertiser.model';
import { CampaignGroupModel } from '../../../models/campaign-group.model';

interface IAttachCreativeCampaigns {
	creatives: CreativeModel[];
}

interface IAttachCreativeCampaignOption {
	name: string;
	description: string;
	status: Status;
	disabled?: boolean;
}

export const AttachCreativeCampaigns = observer((props: IAttachCreativeCampaigns) => {
	const { creatives } = props;

	const { creativeStore, campaignStore, creativeGroupsStore } = useStores<IAdminxStore>();
	const creativeCrudStore = creativeStore.getCrud();
	const { startDate, endDate } = useDates();
	const creative = creativeCrudStore.getData();
	const advertiserId = creativeGroupsStore.getCrud().getData().getAdvertiser()?.getId();
	useEffect(() => {
		campaignStore.getListStore().reset();
		if (creatives.length === 0) {
			return;
		}
		if (creatives.length === 1 && creatives[0].getId()) {
			creativeStore.getCrud().get(creatives[0].getId() as id);
		}
		campaignStore.getListStore().fetch(
			preparePerformanceRequest({
				periodStart: dayjs(startDate).format('YYYY-MM-DD'),
				periodEnd: dayjs(endDate).format('YYYY-MM-DD'),
				limit: 10000,
				filters: advertiserId
					? [
							{
								column: PerformancesColumnsSnakeCase.AdvertiserId,
								filterType: IPerformanceOptionsFilterType.Equals,
								value: [advertiserId as string],
								inverted: false,
							},
					  ]
					: [],
				reportName: 'adxCampaigns',
				type: 'STATISTICS',
				additionalColumns: [],
				dateSegment: IPerformanceOptionsDateSegments.None,
				graphLines: [],
				graphYaxis: [],
				groupBys: [
					PerformancesColumnsSnakeCase.Id,
					PerformancesColumnsSnakeCase.Name,
					PerformancesColumnsSnakeCase.AdvertiserId,
					PerformancesColumnsSnakeCase.AdvertiserName,
					PerformancesColumnsSnakeCase.CampaignGroupId,
					PerformancesColumnsSnakeCase.CampaignGroupName,
					PerformancesColumnsSnakeCase.Status,
				] as PerformancesColumnsSnakeCase[],
				sortBy: IPerformanceOptionsSortBy.Id,
				sortOrder: 1,
				start: 0,
				submit: true,
			})
		);
	}, [creatives, advertiserId]);

	const campaigns = campaignStore
		.getListStore()
		.getData()
		?.getData()
		.map(
			campaign =>
				new CampaignModel({
					id: campaign.id,
					name: campaign.name,
					advertiser: new AdvertiserModel({
						id: campaign.advertiserId,
						name: campaign.advertiserName,
					}),
					campaign_group: new CampaignGroupModel({
						id: campaign.campaignGroupId,
						name: campaign.campaignGroupName,
					}),
					status: campaign.status,
				})
		);

	//without useMemo you won't be able to filter and click without rerender also there is no point calling this again
	const campaignOptions = useMemo(() => {
		const creativesAdvertiserIds = creatives.map(creative => creative.getCreativeGroup()?.getAdvertiser()?.getId());
		return campaigns
			?.map(campaign => {
				return {
					name: campaign.getName() || '',
					description: `${campaign.getAdvertiser()?.getName()} - ${campaign.getCampaignGroup()?.getName()}`,
					status: campaign.getStatus() || Statuses.Paused,
					disabled: !creativesAdvertiserIds.includes(campaign.getAdvertiser()?.getId()),
				};
			})
			.sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));
	}, [campaigns]);

	const selectedCampaigns =
		creatives.length === 1
			? creative.getCampaigns()?.map(campaign => {
					return {
						name: campaign.getName() || '',
						description: `${campaign} - ${campaign.getCampaignGroup()?.getName()}`,
						status: campaign.getStatus() || Statuses.Paused,
					};
			  })
			: [];

	const onCampaignSelect = (options: IAttachCreativeCampaignOption[]) => {
		const selectedCampaigns = options.map(option =>
			campaigns?.find(campaign => {
				return option.name === campaign.getName();
			})
		);
		const campaignsArr: CampaignModel[] = [];
		selectedCampaigns.forEach(_campaign => {
			if (_campaign) {
				campaignsArr.push(new CampaignModel(_campaign));
			}
		});
		creative.setCampaigns(campaignsArr);
	};

	return (
		<div className={styles.wrapper}>
			<SelectionList
				label={'Campaigns'}
				options={campaignOptions || []}
				defaultOptions={selectedCampaigns}
				onSelect={campaign => onCampaignSelect(campaign)}
				isLoadingOptions={campaignStore.getListStore().getIsLoading()}
				renderOption={option => <SelectionListOption option={option} />}
			/>
		</div>
	);
});
