import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { observer } from 'mobx-react';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { Fragment, useEffect, useState } from 'react';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import styles from './add-segmentations-to-campaign.module.scss';
import { Card } from '@monorepo/base/src/components/card/card';
import { CampaignModel, ICampaign } from '../../../modules/models/campaign.model';
import { SelectOptions } from '@monorepo/base/src/components/select-new/select-new';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { DropdownNew } from '@monorepo/base/src/components/dropdown-new/dropdown-new';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { isArrayOfObjectHasAllProp } from '@monorepo/tools/src/lib/utils/object';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { IPerformanceOptionsSortBy, PerformancesColumnsSnakeCase, useReportsList } from '../../../modules/hooks/apis/use-reports-list';

export const ToolsAddSegmentationsToCampaign = observer(() => {
	const { campaignSegmentsStore, modalsStore, campaignStore } = useStores<IAdminxStore>();
	const campaignSegmentsCrudStore = campaignSegmentsStore.getCrud();
	const formStore = campaignSegmentsCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isSuccess = campaignSegmentsCrudStore.getIsSuccess();
	const error = campaignSegmentsCrudStore.getHttpError();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const webAppsCidSegments: SelectOptions[] = campaignStore
		.getInputOptions()
		.getWebappsCidSegments()
		.map(segment => ({
			label: segment.auction_package_name || '',
			value: segment.auction_package_id || '',
		}));
	const reportList = campaignStore.getListStore();
	const campaignOptions: SelectOptions[] =
		reportList
			.getData()
			?.getData()
			?.map(campaign => {
				const _campaign = new CampaignModel(campaign as ICampaign);
				return {
					label: _campaign.getName() as string,
					value: _campaign.getId() as string,
				};
			}) || [];
	const selectedCampaignId = campaignSegmentsCrudStore.getData().getCampaignId();
	const segmentations = campaignSegmentsCrudStore.getData().getSegmentations();

	useReportsList(reportList, {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxCampaigns',
		sortBy: IPerformanceOptionsSortBy.Id,
	});

	const { dispatchLog } = useLogs();

	useEffect(() => {
		campaignSegmentsCrudStore.reset();
	}, []);

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			dispatchLog({
				msg: `Campaign segmentation was created successfully`,
				type: DispatchLogsTypes.Success,
			});
			campaignSegmentsCrudStore.setIsSuccess(false);
			campaignSegmentsCrudStore.reset();
		}
	}, [isSuccess]);

	const onSubmitCids = () => {
		setFormClicked(true);
		if (formStore.getIsValid()) {
			modalsStore.addModal({
				children: (
					<ActionModal
						type={ModalTypes.Warning}
						primaryBtnTitle={'Yes, I am sure'}
						onAction={() => {
							campaignSegmentsCrudStore.createSegmentation();
						}}>
						<Fragment>Caution: You are about to create a CID which might be active, are you sure?</Fragment>
					</ActionModal>
				),
			});
		}
	};

	const onListItemCampaign = (campaignId: id[]) => {
		campaignSegmentsCrudStore.getData().setCampaignId(campaignId[0]);
		campaignSegmentsCrudStore.getData().setSegmentations([]);
		campaignSegmentsCrudStore.getData().addSegmentation();
	};

	const onSelectWebappsCidSegment = (webappsCid: id[], segmentId: string) => {
		const segment = segmentations?.find(segment => segment.getId().toString() === segmentId);
		segment?.setAuctionPackageId(webappsCid[0]);
		segment?.setAuctionPackageName(
			webAppsCidSegments
				.find(seg => {
					return seg.value == webappsCid[0];
				})
				?.label.toString() || segmentId
		);
	};

	const renderSegments = () => {
		if (!selectedCampaignId) {
			return null;
		}

		return segmentations?.map((segment, index) => {
			return (
				<Card key={index} title={'Select Webapps CID Segment'} icon={'announcement-01'}>
					<Icon
						onClick={() => campaignSegmentsCrudStore.getData().removeSegmentation(segment.getId())}
						disabled={segmentations.length <= 1}
						isMFP
						className={styles.closeIcon}>
						x-close
					</Icon>
					<DropdownNew
						label={'Select Segment'}
						classNames={{ dropdownWrapper: styles.dropdownWrapper, parentSelectWrapper: styles.parentSelectWrapper }}
						options={webAppsCidSegments}
						defaultValues={segment.auction_package_id ? [segment.auction_package_id] : ([] as id[])}
						autocomplete={true}
						autocompletePlaceholder={'Search CID Segment'}
						multi={false}
						required
						onSelect={val => onSelectWebappsCidSegment(val, segment.getId().toString())}
					/>
					<div>
						<Input
							className={styles.inputSize}
							type={'positiveNumber'}
							value={segment.getSourceCid()?.toString() || ''}
							placeholder={'Source Cid'}
							required={true}
							onValue={value => {
								segment.setSourceCid(parseInt(value));
							}}
							isId={true}
							min={10000}
							// error={formStore.getErrors().get('total_budget')?.getMsg()}
						/>
						<Input
							className={styles.inputSize}
							type={'positiveNumber'}
							value={segment.getTargetCid()?.toString() || ''}
							placeholder={'Target Cid'}
							isId={true}
							required={true}
							onValue={value => {
								segment.setTargetCid(parseInt(value));
							}}
							min={10000}
						/>
					</div>
					{segmentations.length - 1 === index ? (
						<PrimaryButton
							disabled={!isArrayOfObjectHasAllProp(segmentations)}
							className={`${styles.inputSize} ${styles.addSegment}`}
							onClick={() => campaignSegmentsCrudStore.getData().addSegmentation()}
							iconSize={'20px'}
							iconColor="rgb(99, 102, 241)"
							icon={'plus'}>
							Add Segment
						</PrimaryButton>
					) : null}
				</Card>
			);
		});
	};

	return (
		<Page>
			<div className={styles.formWrapper}>
				<div className={styles.pagebar}>
					<Pagebar>
						<PagebarTitle>Create Webapps Campaign Segmentation</PagebarTitle>
					</Pagebar>
				</div>

				<div className={styles.segmentation}>
					<Card title={'Select Campaign'} icon={'announcement-01'}>
						<DropdownNew
							classNames={{ dropdownWrapper: styles.dropdownWrapper, parentSelectWrapper: styles.parentSelectWrapper }}
							options={campaignOptions}
							defaultValues={selectedCampaignId ? [selectedCampaignId] : ([] as id[])}
							autocomplete={true}
							autocompletePlaceholder={'Search Campaign'}
							required
							onSelect={onListItemCampaign}
							disabled={reportList.getIsLoading()}
						/>
						<div className={styles.limitLoaderSize}>
							<BarLoader is={reportList.getIsLoading()} />
						</div>
					</Card>
					{renderSegments()}
				</div>
			</div>
			<BottomBar>
				{error ? <ErrorMsg error={error} /> : null}
				<BottomBarActions>
					<PrimaryButton disabled={!isArrayOfObjectHasAllProp(segmentations || [])} onClick={() => onSubmitCids()}>
						Submit
					</PrimaryButton>
				</BottomBarActions>
			</BottomBar>
		</Page>
	);
});
