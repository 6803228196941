import { IUserCreateForm, IUserEditForm, UserModel } from '../models/user.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export interface IVerify {
	token: string;
	userId: string | number;
}

export const UsersApi = {
	list: (params?: URLSearchParams): Promise<UserModel[]> => {
		return ask.get(endpoints.users.list(), params).then(res => res.payload.list);
	},
	create: (user: IUserCreateForm, options?: IRequestOptions): Promise<UserModel> => {
		return ask.post<IUserCreateForm>(endpoints.users.create(), user, options);
	},
	edit: (userId: id, user: IUserEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<IUserEditForm>(endpoints.users.edit(userId), user, options);
	},
	validate2fa: (verify: IVerify, options?: IRequestOptions): Promise<{ body: IVerify; headers: string[][] }> => {
		return ask.postWithHeaders<IVerify>(endpoints.users.validate2fa(), verify, options);
	},
	get: (userId: id, options?: URLSearchParams): Promise<UserModel> => {
		return ask.get(endpoints.users.get(userId), options).then(res => res.payload.item);
	},
	profile: (options?: URLSearchParams): Promise<UserModel> => {
		return ask.get(endpoints.users.profile(), options).then(res => res.payload.item);
	},
	editProfile: (options?: { oldPassword: string; newPassword: string }): Promise<UserModel> => {
		return ask.put(endpoints.users.profile(), options).then(res => res.payload.item);
	},
	delete: (userId: id) => {
		return ask.delete(endpoints.users.delete(userId));
	},
	getRoles: (params?: URLSearchParams): Promise<string[]> => {
		return ask.get(endpoints.users.getRoles(), params).then(res => res.payload.item);
	},
};
