import { observer } from 'mobx-react';
import { FC } from 'react';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { Outlet } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import ReportTable from '../../../modules/components/report-table/table-component';
import { useTableStore } from '../../../modules/components/report-table/stores/use-table-store.hook';
import { useTableStoreMetrics } from '../../../modules/hooks/tools/use-repr-metrics';
import { Card } from '@monorepo/base/src/components/card/card';
import { Chart } from '@monorepo/base/src/components/chart/chart';

export const Domains: FC = observer(() => {
	const { domainsStore } = useStores<IAdminxStore>();

	const { domainsReport } = domainsStore;

	useTableStore({ reportTableStore: domainsReport });
	const chartProps = useTableStoreMetrics(domainsReport);

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Domains</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full>
				<Chart isLoading={domainsReport.dataStore.getIsLoading()} {...chartProps} />
			</Card>
			<ReportTable tableStore={domainsReport} />
			<Outlet />
		</Page>
	);
});
