import { CreatePreference } from '../../pages/preferences/create/create-preference.page';
import { EditPreference } from '../../pages/preferences/edit/edit-preference.page';
import { Preferences } from '../../pages/preferences/index/preferences.page';
import routes from '../routes/index.routes';

export const PreferencesRouter = [
	{
		path: routes.preferences.index(),
		element: <Preferences />,
		children: [
			{
				path: routes.preferences.create(),
				element: <CreatePreference />,
			},
			{
				path: routes.preferences.edit(':preferenceId'),
				element: <EditPreference />,
			},
		],
	},
];
