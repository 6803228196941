import { Fragment } from 'react';
import { id } from '@monorepo/tools/src/lib/types/primitives';

type IToggleTestsStatuses = {
	testIds: id[];
	action: string;
};
export const EnablePauseTestsModal = (props: IToggleTestsStatuses) => {
	const { testIds, action } = props;
	return (
		<Fragment>
			<div>{action} the following campaigns:</div>
			<span style={{ color: '#4f46e5' }}>
				{testIds.length < 6
					? testIds.map((testId, index) => `${index !== testIds.length - 1 ? testId + ', ' : testId + '.'}`)
					: `${testIds.length} campaigns`}
			</span>
		</Fragment>
	);
};
