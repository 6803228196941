import { Component } from 'react';
import PropTypes from 'prop-types';
import DateRange from '../DateRange';
import DefinedRange from '../DefinedRange';
import { findNextRangeIndex, generateStyles } from '../../utils';
import classnames from 'classnames';
import coreStyles from '../../styles';

class DateRangePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focusedRange: [findNextRangeIndex(props.ranges), 0],
		};
		this.styles = generateStyles([coreStyles, props.classNames]);
	}

	onDefinedRangeChange = (rangesByKeys, label) => {
		const { onChange } = this.props;
		onChange(rangesByKeys, { label });
		setTimeout(() => {
			this.dateRange.calendar.focusToDate(this.props.ranges[this.state.focusedRange[0]].startDate);
		});
	};

	render() {
		const { focusedRange } = this.state;
		return (
			<div className={classnames(this.styles.dateRangePickerWrapper, this.props.className)}>
				<DefinedRange
					focusedRange={focusedRange}
					onDefinedRangeChange={this.onDefinedRangeChange}
					onPreviewChange={value =>
						this.dateRange.updatePreview(value ? this.dateRange.calcNewSelection(value, typeof value === 'string') : null)
					}
					{...this.props}
					range={this.props.ranges[focusedRange[0]]}
					className={undefined}
				/>
				<DateRange
					onRangeFocusChange={focusedRange => this.setState({ focusedRange })}
					focusedRange={focusedRange}
					{...this.props}
					ref={t => (this.dateRange = t)}
					className={undefined}
				/>
			</div>
		);
	}
}

DateRangePicker.defaultProps = {};

DateRangePicker.propTypes = {
	...DateRange.propTypes,
	...DefinedRange.propTypes,
	className: PropTypes.string,
};

export default DateRangePicker;
