import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { IAdminxStore } from '../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const AdvertiserForm = observer(() => {
	const { advertiserStore } = useStores<IAdminxStore>();
	const advertiserCrudStore = advertiserStore.getCrud();
	const formStore = advertiserCrudStore.getFormStore();
	const advertiser = advertiserCrudStore.getData();

	return (
		<Card title={'Advertiser settisngs'} icon={'settings-01'}>
			<Input
				value={advertiser.getName()}
				placeholder={'Name'}
				onValue={value => {
					advertiser.setName(value);
					formStore.clearError('name');
				}}
				required={true}
				error={formStore.getErrors().get('name')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser_name')] }}
			/>
			<Input
				type={'number'}
				icon={'currency-dollar'}
				value={advertiser.getTotalBudget()?.limit?.toString()}
				placeholder={'Limit'}
				required={true}
				onValue={value => advertiser.setTotalBudget({ limit: parseInt(value) })}
				error={formStore.getErrors().get('total_budget')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser_total_budget')] }}
			/>
			<SpacerY />
			<Toggle
				title={'User Cap'}
				label={advertiser.getUserCapEnabled() ? 'Enabled' : 'Disabled'}
				onChange={isEnabled => advertiser.setUserCapEnabled(isEnabled)}
				defaultValue={advertiser.getUserCapEnabled()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser_user_cap_enabled')] }}
			/>
		</Card>
	);
});
