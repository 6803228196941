import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { DateSelection, IDateSelection, setDateSelectionInQueryParams } from '../../stores/date.store';
import { defaultStaticRanges, defaultInputRanges } from '@monorepo/base/src/components/dater';
import { dayDiffBetweenDates } from '@monorepo/tools/src/lib/utils/date';
import { IControlledStore, useStores } from '../../hooks/use-stores';
import { Dater } from '@monorepo/base/src/components/dater/Dater/dater';
import { createObjectFromQueryParams } from '@monorepo/tools/src/lib/utils/object';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

export interface SelectionRanges {
	[key: string]: Range;
}

export interface IDaterOptions {
	label?: string;
}

const defaultMinDate = dayjs()
	.subtract(365 * 4, 'day')
	.toDate();
const defaultMaxDate = dayjs().subtract(1, 'day').toDate();
interface IControlledDater {
	minDate?: Date;
	maxDate?: Date;
}
export const ControlledDater = observer((props: IControlledDater) => {
	const { minDate = defaultMinDate, maxDate = defaultMaxDate } = props;
	const { dateStore } = useStores<IControlledStore>();
	const queryParams = createObjectFromQueryParams(['label']);
	const primarySelection = dateStore.getPrimarySelection();
	const [isOpen, setOpen] = useState<boolean>(false);
	const [dateRange, setDateRange] = useState<IDateSelection>(primarySelection);
	const label = queryParams?.label?.toString() || primarySelection?.label || 'Last 7 Days';
	const inputDateLabels = defaultInputRanges.map(r => r.label);
	const dayDiff = dayDiffBetweenDates(primarySelection.getStartDate(), primarySelection.getEndDate());

	let dateLabel = label;
	if (inputDateLabels.includes(label || '')) {
		dateLabel = `${dayDiff} ${label}`;
	}
	const fromDate = dayjs(primarySelection?.startDate).format('MMM D, YYYY');
	const toDate = dayjs(primarySelection?.endDate).format('MMM D, YYYY');

	useEffect(() => {
		setDateRange(primarySelection);
	}, [primarySelection]);

	const handleSelect = (ranges: SelectionRanges, options: IDaterOptions) => {
		const currentRange = ranges.primary || ranges.initial;
		if (currentRange) {
			setDateRange({
				...currentRange,
				key: 'primary',
				label: options?.label || 'Custom',
			});
		}
	};

	const onApply = () => {
		const dateSelection = new DateSelection({ ...dateRange, key: 'primary' });
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.DATER.APPLY,
			label: dateSelection.label,
			value: `${dateSelection.getStartDate().toLocaleDateString()} - ${dateSelection.getEndDate().toLocaleDateString()}`,
		});
		setDateSelectionInQueryParams(dateSelection);
		dateStore.setPrimarySelection(dateSelection);
		setOpen(false);
	};

	useEffect(() => {
		if (label && label !== 'Custom') {
			let value;
			if (inputDateLabels.includes(label) && dateRange?.startDate && dateRange?.endDate) {
				value = dayDiffBetweenDates(dateRange.startDate, dateRange.endDate) + 1;
			}
			const range =
				defaultStaticRanges.find((i: { label: string; range: () => { startDate: Date; endDate: Date } }) => i.label === label) ||
				defaultInputRanges.find((i: { label: string; range: () => { startDate: Date; endDate: Date } }) => i.label === label);

			const dateSelection = new DateSelection({
				...range.range(value),
				label: range.label,
				key: 'primary',
			});
			setDateSelectionInQueryParams(dateSelection);
			dateStore.setPrimarySelection(dateSelection);
		}
	}, []);

	return (
		<Dater
			dateLabel={dateLabel}
			fromDate={fromDate}
			toDate={toDate}
			isOpen={isOpen}
			setOpen={setOpen}
			dateRange={dateRange}
			handleSelect={handleSelect}
			onApply={onApply}
			minDate={minDate}
			maxDate={maxDate}
		/>
	);
});
