import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { PreferenceModel } from '../../models/preference.model';
import routes from '../../routes/index.routes';
import { usePreferenceActions } from '../actions/preference.actions';
import { IMergedHeadersReturn } from './index.headers';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { createColumnHelper } from '@tanstack/react-table';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { IAdminxStore } from '../../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';

const columnHelper = createColumnHelper<PreferenceModel>();

export const usePreferenceHeaders = (): IMergedHeadersReturn<PreferenceModel> => {
	const { settingsStore } = useStores<IAdminxStore>();
	const { EntityActions } = usePreferenceActions();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				id: true,
				name: true,
				value: true,
				comment: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const preference = new PreferenceModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<QuickActionsMenu>
								<EntityActions entity={preference} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					return (
						<TableLink
							toEdit={routes.preferences.edit(row.getValue('id'))}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('value', {
				header: 'Value',
				size: 600,
				cell: info => {
					const { row } = info;
					return <OverflowColumn maxWidth={'400px'} data={row.getValue('value')} />;
				},
			}),
			columnHelper.accessor('comment', {
				header: 'Comment',
				cell: info => {
					const { row } = info;
					return <OverflowColumn data={row.getValue('comment')} />;
				},
			}),
		],
	};
};
