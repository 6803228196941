import { url } from '@monorepo/tools/src/lib/types/url';
import { email } from '@monorepo/tools/src/lib/types/email';
import { UserStatusEnum } from '../enums/user.enum';
import { permissionsDomain } from '../hooks/tools/use-guard';
import { PermissionActions } from '../enums/permissions';
import { DomainRoleForm } from '@monorepo/controlled/src/models/domain-role.model';
import { action, makeAutoObservable } from 'mobx';

export type DomainRoleType = {
	[domain: string]: string[] | undefined;
};

export interface IUserCreateForm {
	id?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	company?: string;
}

export interface IUserEditForm {
	id?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	password?: string;
	company?: string;
}

export interface IUser {
	id: string;
	fullName: string;
	firstName: string;
	lastName: string;
	picture: url;
	email: email;
	clientId: string;
	code?: string;
	status?: UserStatusEnum;
	domainPermissions?: Record<permissionsDomain, Set<PermissionActions>>;
	permissionsSet: Set<PermissionActions>;
	domainRoles?: DomainRoleType;
	domainRolesArray: DomainRoleForm[];
}

export class UserModel implements IUser {
	id: string;
	fullName: string;
	firstName: string;
	lastName: string;
	picture: url;
	email: email;
	clientId: string;
	code?: string;
	status?: UserStatusEnum;
	domainPermissions?: Record<permissionsDomain, Set<PermissionActions>>;
	permissionsSet: Set<PermissionActions>;
	domainRoles?: DomainRoleType;
	domainRolesArray: DomainRoleForm[];

	constructor(user?: IUser);
	constructor(user: IUser) {
		this.id = user?.id;
		this.fullName = user?.fullName;
		this.firstName = user?.firstName;
		this.lastName = user?.lastName;
		this.picture = user?.picture;
		this.email = user?.email;
		this.clientId = user?.clientId;
		this.status = user?.status;
		this.domainPermissions = {};
		this.permissionsSet = new Set<PermissionActions>([PermissionActions.Public]);
		this.domainRoles = user?.domainRoles;
		this.domainRolesArray = [];

		// user?.domainRoles?.map(domainRole => ({ entityId: 'dada', roles: domainRole.roles || [], key: randomString(5) })) || [];

		if (user === Object(user)) {
			this.permissionsSet.add(PermissionActions.UsersOnline);
		}

		if (user?.domainPermissions && user.domainPermissions === Object(user.domainPermissions)) {
			const permissionsEntries = Object.entries(user.domainPermissions);
			for (const [domain, permissionsArr] of permissionsEntries) {
				const permissionsSet = new Set(Array.isArray(permissionsArr) ? permissionsArr : []);
				this.domainPermissions[domain as permissionsDomain] = permissionsSet;
				this.permissionsSet = new Set([...this.permissionsSet, ...permissionsSet]);
			}
		}

		makeAutoObservable(this, {
			editDomainRole: action,
			addDomainRole: action,
			removeDomainRole: action,
		});
	}

	setFirstName(firstName: string): void {
		this.firstName = firstName;
	}

	getFirstName(): string | undefined {
		return this.firstName;
	}

	setLastName(lastName: string): void {
		this.lastName = lastName;
	}

	getLastName(): string | undefined {
		return this.lastName;
	}

	getId(): string {
		return this.id;
	}

	getEmail(): email {
		return this.email;
	}

	getFullName(): string {
		return this.fullName;
	}

	getGivenName(): string {
		return this.firstName;
	}

	getPicture(): url {
		return this.picture;
	}

	setCode(code: string) {
		this.code = code;
	}

	getCode() {
		return this.code;
	}

	isVerified(): boolean {
		return this.status === UserStatusEnum.ENABLED;
	}

	setDomainPermissions(permissions: Record<permissionsDomain, Set<PermissionActions>>) {
		this.domainPermissions = permissions;
	}

	getDomainPermissions() {
		return this.domainPermissions;
	}

	removeDomainRole = (index: number) => {
		this.domainRolesArray.splice(index, 1);
	};

	editDomainRole = (domainRole: DomainRoleForm, index: number) => {
		this.domainRolesArray[index] = { ...domainRole };
	};

	addDomainRole = () => {
		this.domainRolesArray.push(new DomainRoleForm({ roles: [], domain: '', errorMessages: {} }));
	};
}
