import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { IPerformanceOptionsFilterType, usePerformance } from '../../../../modules/hooks/apis/use-performance';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Table } from '@monorepo/base/src/components/table/table';
import { IAdminxStore } from '../../../../modules/stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useBidKeysHeaders } from '../../../../modules/hooks/theaders/bid-keys.headers';
import {
	IPerformanceOptionsSortBy,
	IPerformanceOptionsSortOrder,
	PerformancesColumnsSnakeCase,
} from '../../../../modules/hooks/apis/use-reports-list';

export const CampaignGroupBidKeys = observer(() => {
	const { performanceStore, settingsStore } = useStores<IAdminxStore>();
	const { campaignGroupId } = useParams();
	const { columns } = useBidKeysHeaders();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	if (campaignGroupId) {
		usePerformance({
			groupBys: [PerformancesColumnsSnakeCase.BidKeys],
			filters: [
				{
					column: PerformancesColumnsSnakeCase.CampaignGroupId,
					filterType: IPerformanceOptionsFilterType.Include,
					value: [campaignGroupId],
				},
			],
			limit: 5000,
			sortBy: IPerformanceOptionsSortBy.Wins,
			sortOrder: IPerformanceOptionsSortOrder.Desc,
		});
	}

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Pages</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			{tableStore && (
				<Table
					isLoading={performanceStore.getIsLoading()}
					isBarLoader={performanceStore.getIsLoading()}
					columns={columns}
					data={performanceStore?.getData()?.getData() || []}
					columnVisibility={tableStore?.getColumnVisibility()}
					defaultSortBy={[{ id: 'wins', desc: true }]}
				/>
			)}
		</Page>
	);
});
