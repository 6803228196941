import { makeAutoObservable } from 'mobx';
import { Distribution, Distributions } from '../enums/distribution';
import { capitalCase } from 'change-case';

export interface IDailyBudget {
	distribution?: Distribution;
	limit?: number;
}

export class DailyBudgetModel implements IDailyBudget {
	distribution?: Distribution;
	limit?: number;

	constructor(dailyBudget?: IDailyBudget) {
		this.distribution = dailyBudget?.distribution ? (capitalCase(dailyBudget?.distribution) as Distribution) : Distributions.Eager;
		this.limit = dailyBudget?.limit || 0;

		makeAutoObservable(this);
	}

	public getFormData(): IDailyBudget {
		return {
			distribution: this.distribution ? (this.distribution.toUpperCase() as Distribution) : undefined,
			limit: this.limit,
		};
	}

	public getDistribution(): Distribution | undefined {
		return this.distribution;
	}

	public setDistribution(distribution: Distribution): void {
		this.distribution = distribution;
	}

	public getLimit(): number | undefined {
		return this.limit;
	}

	public setLimit(limit: number): void {
		this.limit = limit;
	}
}
