import { observer } from 'mobx-react';
import { useAdvertiserGet } from '../../hooks/apis/use-advertiser-get';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel } from '../../models/advertiser.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import styles from './main-breadcrumbs.module.scss';
import { IAdminxStore } from '../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { SelectNew, SelectOptions } from '@monorepo/base/src/components/select-new/select-new';

interface IAdvertiserBreadcrumb {
	advertiser?: AdvertiserModel;
}

export const AdvertiserBreadcrumb = observer((props: IAdvertiserBreadcrumb) => {
	const { advertiser } = props;
	const { campaignStore } = useStores<IAdminxStore>();
	const advertisers = campaignStore.getInputOptions()?.getAdvertisers();
	const advertiserName = advertiser?.getName();
	const isLoadingInputOptions = campaignStore.getInputOptions().getIsLoading();

	const navigate = useNavigateParams();

	const onListItemAdvertiser = (advertisersId: (string | number)[]) => {
		const advertiserId = advertisersId[0];
		if (advertiserId === 'All') {
			navigate(`${routes.advertisers.index()}`);
			return;
		}

		if (!advertiserId) {
			return null;
		}
		navigate(routes.advertisers.campaignGroups(advertiserId));
	};

	const onBreadcrumbLink = () => {
		const advertiseId = advertiser?.getId();
		if (advertiseId) {
			navigate(`${routes.advertisers.campaignGroups(advertiseId)}`);
		}
	};

	return (
		<BreadcrumbMenu
			label={'Advertiser'}
			title={advertiserName || 'All'}
			isArrow={false}
			navigateToModel={onBreadcrumbLink}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser_breadcrumbs')] }}
			isLoading={isLoadingInputOptions}>
			<div className={styles.menuWrapper}>
				<SelectNew
					classNames={{ list: styles.list }}
					placeholder={'Search Advertiser'}
					options={[
						{ label: 'All', value: 'All' },
						...advertisers.map(advertiser => {
							return {
								label: advertiser.getName() as string,
								value: advertiser.getId() as string,
								to: routes.advertisers.campaignGroups(advertiser.getId() as string),
							};
						}),
					]}
					sortBy={(a: SelectOptions, b: SelectOptions) => (a.label > b.label ? 1 : -1)}
					required
					onSelect={onListItemAdvertiser}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser_breadcrumbs_select')] }}
					disabled={isLoadingInputOptions}
					defaultValues={[advertiser?.getId() || 'All']}
				/>
			</div>
		</BreadcrumbMenu>
	);
});

interface IAdvertiserPageBreadcrumbs {
	advertiserId: id;
}

export const AdvertiserPageBreadcrumbs = observer((props: IAdvertiserPageBreadcrumbs) => {
	const { advertiserId } = props;
	const { advertiserStore } = useStores<IAdminxStore>();
	const advertiser = advertiserStore.getCrud().getData();

	useAdvertiserGet({ id: advertiserId });

	return (
		<div className={styles.wrapper}>
			<AdvertiserBreadcrumb advertiser={advertiser} />
		</div>
	);
});
