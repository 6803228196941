import { observer } from 'mobx-react';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';
import styles from './replace-segmentation-cids.module.scss';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { CidModel } from '../../../modules/models/cid.model';
import { Fragment, useEffect, useState } from 'react';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';

export const ToolsReplaceSegmentationCids = observer(() => {
	const { cidsStore, modalsStore } = useStores<IAdminxStore>();
	const cidsCrudStore = cidsStore.getCrud();
	const cid = cidsCrudStore.getData();
	const formStore = cidsCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isSuccess = cidsCrudStore.getIsSuccess();
	const error = cidsCrudStore.getHttpError();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);

	const { dispatchLog } = useLogs();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			dispatchLog({
				msg: `CID was updated successfully`,
				type: DispatchLogsTypes.Success,
			});
			cidsCrudStore.setIsSuccess(false);
			cidsCrudStore.reset();
		}
	}, [isSuccess]);

	const onSubmitCids = () => {
		setFormClicked(true);
		if (formStore.getIsValid()) {
			modalsStore.addModal({
				children: (
					<ActionModal
						type={ModalTypes.Warning}
						primaryBtnTitle={'Yes, I am sure'}
						onAction={() => {
							cidsCrudStore.create();
						}}>
						<Fragment>Caution: You are about to update a CID which might be active, are you sure?</Fragment>
					</ActionModal>
				),
			});
		}
	};

	const removeTargetCid = (cid: CidModel, index: number) => {
		formStore.reset();
		cid.removeFromTargetCids(index);
	};

	const clearTargetCids = (cid: CidModel) => {
		formStore.reset();
		cid.clearTargetCids();
	};

	const targetCids = cid.getTargetCids();
	return (
		<Page>
			<div className={styles.formWrapper}>
				<div className={styles.pagebar}>
					<Pagebar>
						<PagebarTitle>Replace CID</PagebarTitle>
					</Pagebar>
				</div>

				<div className={styles.title}>
					<h2>Source CID</h2>
					<sub>Source CID will effect campaigns destination url</sub>
				</div>

				<Card className={styles.sourceCard}>
					<div className={styles.row}>
						<Input
							className={styles.input}
							onValue={value => {
								cid.setCurrentCid(value);
								formStore.clearError('current_cid');
							}}
							value={cid.getCurrentCid()}
							topLabel={'Current CID'}
							required
							requiredAsterisk={false}
							error={formStore.getErrors().get('current_cid')?.getMsg()}
							type={'number'}
							isId={true}
						/>
						<Input
							onValue={value => {
								cid.setNewCid(value);
								formStore.clearError('new_cid');
							}}
							value={cid.getNewCid()}
							requiredAsterisk={false}
							className={styles.input}
							topLabel={'New CID'}
							required
							error={formStore.getErrors().get('new_cid')?.getMsg()}
							type={'number'}
							isId={true}
						/>
					</div>
				</Card>

				{targetCids.length > 0 ? (
					<Fragment>
						<div className={styles.row}>
							<div className={styles.title}>
								<h2>Target CIDs</h2>
								<sub>Target CID will effect domains grouping table</sub>
							</div>
							<SecondaryButton
								className={styles.deleteAllTargetCidsBtn}
								onClick={() => clearTargetCids(cid)}
								isRotateIcon={false}
								icon={'trash-01'}
								iconColor={'rgb(99, 102, 241)'}>
								Remove All
							</SecondaryButton>
						</div>

						<Card className={styles.targetCard}>
							{targetCids.map((targetCid, j) => {
								return (
									<div className={`${styles.row}  ${styles.error}`} key={j}>
										<SecondaryButton
											className={styles.deleteTargetCidBtn}
											onClick={() => removeTargetCid(cid, j)}
											isRotateIcon={false}
											icon={'x-close'}
										/>
										<span className={styles.groupNumber}> Target CID group {j + 1} </span>
										<Input
											className={styles.input}
											onValue={value => {
												targetCid.setCurrentTargetCid(value);
												formStore.clearError(`targets[${j}].current_cid`);
											}}
											value={targetCid.getCurrentTargetCid()}
											topLabel={'Current CID'}
											required
											requiredAsterisk={false}
											error={formStore.getErrors().get(`targets[${j}].current_cid`)?.getMsg()}
											type={'number'}
											isId={true}
										/>
										<Input
											onValue={value => {
												targetCid.setNewTargetCid(value);
												formStore.clearError(`targets[${j}].new_cid`);
											}}
											value={targetCid.getNewTargetCid()}
											requiredAsterisk={false}
											className={styles.input}
											topLabel={'New CID'}
											required
											error={formStore.getErrors().get(`targets[${j}].new_cid`)?.getMsg()}
											type={'number'}
											isId={true}
										/>
									</div>
								);
							})}
						</Card>
					</Fragment>
				) : null}

				<SecondaryButton
					onClick={() => cid.addToTargets()}
					className={styles.addTargetCidsBtn}
					iconSize={'20px'}
					iconColor="rgb(99, 102, 241)"
					icon={'plus'}>
					Add target CIDs
				</SecondaryButton>
			</div>
			<BottomBar>
				{error ? <ErrorMsg error={error} /> : null}
				<BottomBarActions>
					<PrimaryButton onClick={() => onSubmitCids()}>Submit</PrimaryButton>
				</BottomBarActions>
			</BottomBar>
		</Page>
	);
});
