import { makeAutoObservable, observable } from 'mobx';
import { action } from 'mobx';
import { CreativesApi } from '../../apis/creatives.api';
import { PerformancesColumnsSnakeCase } from '../../hooks/apis/use-reports-list';
import { CreativeModel } from '../../models/creative.model';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { PerformanceStore } from '../performance.store';
import { CreativeCrudStore } from './creative-crud.store';
import { filterPrototypesByProp } from '../../components/filters-menu/filters/index';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { HttpError } from '../../models/http-error.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export enum CreativeFiltersStoreKeys {
	Creatives = 'creatives',
	Campaign = 'campaignscreatives',
	CampaignGroup = 'campaigngroupscreatives',
	Advertiser = 'advertiserscreatives',
	CreativeGroup = 'creativegroupscreatives',
}

export class CreativeStore {
	crud = new CreativeCrudStore();
	list = new BaseListStore<CreativeModel>({
		listFunc: CreativesApi.list,
		model: CreativeModel,
	});
	performance = new PerformanceStore<CreativeModel>({
		keys: new Set([PerformancesColumnsSnakeCase.CreativeId]),
	});
	settingsStore: SettingsStore;
	bulkCreateCSVStore = new HttpStore<FormData, null, HttpError>({
		httpFunc: CreativesApi.bulkCreateCSV,
		errorModel: HttpError,
	});

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this, {
			crud: observable,
			list: observable,
			resetCrud: action, // TODO - RONT no need more for resetCrud, see campaign crud store reset function
			performance: observable,
		});
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		Object.values(CreativeFiltersStoreKeys).map((key: CreativeFiltersStoreKeys) => {
			if (!this.settingsStore.getPages().get(key)) {
				this.settingsStore.getPages().set(key, { tableStore: new TableStore() });
			}
			const creativeFilterByKey = new FilterStore({
				key,
				currentFilters: undefined,
				filterPrototypesByProp,
			});
			this.settingsStore.getPages().get(key)?.tableStore.setFiltersStore(key, creativeFilterByKey);
		});
	}

	getCrud() {
		return this.crud;
	}

	resetCrud() {
		this.crud = new CreativeCrudStore();
	}

	getListStore() {
		return this.list;
	}

	getPerformance() {
		return this.performance;
	}

	getBulkCreateCSVStore() {
		return this.bulkCreateCSVStore;
	}

	async downloadCreativeHtmlFiles(creativeIds: id[]) {
		const data = this.getListStore()
			.getList()
			.filter(creative => creative.id && creativeIds.includes(creative.id))
			.map(creative => {
				return {
					id: creative.id,
					name: creative.brand,
					link: creative.resource_url,
				};
			});
		for (const rowData of data) {
			try {
				const response = await fetch(rowData.link as string);
				const htmlContent = await response.text();
				const fileName = `${rowData.name}(${rowData.id})`;
				const blob = new Blob([htmlContent], { type: 'text/html' });
				const url = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = `${fileName}.html`;
				link.click();
				URL.revokeObjectURL(url);
			} catch (error) {
				console.error('Error downloading HTML file:', error);
			}
		}
	}
}
