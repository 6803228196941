import { Icon } from '@monorepo/base/src/components/icon/icon';
import { LoginSVG } from '@monorepo/base/src/assets/login.icon';
import styles from './login.module.scss';
import { Logo } from '@monorepo/base/src/components/logo/logo';
import { AdminxLogo } from '../../modules/components/logo/logo';
import { ThirdPartyAuth } from '../../modules/components/third-party-auth/third-party-auth';

export const Login = () => {
	return (
		<div className={styles.pageWrapper}>
			<section className={styles.imgWrapper}>
				<Logo>
					<AdminxLogo />
				</Logo>
				<Icon className={styles.homeIcon}>
					<LoginSVG />
				</Icon>
			</section>
			<section className={styles.loginWrapper}>
				<div className={styles.loginContent}>
					<div className={styles.title}>Welcome</div>
					<div className={styles.subtitle}>Sorry, for now we have the option to sign in with google only</div>
					<ThirdPartyAuth />
				</div>
			</section>
		</div>
	);
};
