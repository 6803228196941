import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { DeviceType } from '@monorepo/tools/src/lib/enums/device-types';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Environment } from '../../../../enums/environment';
import styles from './targeting-list-option.module.scss';
import { DividerX } from '@monorepo/base/src/components/divider/divider';
type TargetOptionType = {
	name: string;
	description: string;
	id: id;
	environment?: Environment;
	device_types?: Set<DeviceType>;
	op_systems?: Set<string>;
	browsers?: Set<string>;
	geos?: string[];
};
interface ISelectionListOption {
	option: TargetOptionType;
}

type OptionsGenType = {
	devices: JSX.Element[];
	geos: JSX.Element[];
	env: JSX.Element;
	browsers: JSX.Element[];
	operatingSystems: JSX.Element[];
};

//TODO: ask gerby if we should create an ENUM for this
const browserMapping = new Map([
	['Active Chrome', 'chrome'],
	['Active Firefox', 'firefox'],
	['Active Edge', 'edge'],
	['Active Mobile Edge', 'edge'],
	['Active Samsung', 'samsung'],
	['Active Mobile Samsung', 'samsung'],
	['Active Samsung Browser', 'samsung'],
	['Active Safari', 'safari'],
	['Active Opera', 'opera'],
	['All', 'all'],
]);

const OperatingSystemMapping = new Map([
	['Chrome Os', 'chromeos'],
	['Windows', 'windows'],
	['Windows 10', 'windows'],
	['Mac Os X', 'macosx'],
	['Android', 'android'],
	['Ios', 'ios'],
	['Psp', 'psp'],
	['Linux', 'linux'],
]);

export function TargetingListOption(props: ISelectionListOption) {
	const { option } = props;
	const seperator = (
		<div className={styles.seperator}>
			<DividerX alignSelf="center" height="15px" />
		</div>
	);

	const { env, geos, devices, browsers, operatingSystems } = options(option, 2);
	return (
		<div className={styles.rowWrapper} key={option.id}>
			<span className={styles.idTitle}>ID: {option.id}</span>
			<div className={styles.targetLogoSummary}>
				<div className={styles.contentWrapper}>
					{geos} {seperator} {env} {seperator} {devices} {seperator} {browsers} {seperator} {operatingSystems}
				</div>
			</div>
		</div>
	);
}

const options = (option: TargetOptionType, cutoff: number): OptionsGenType => {
	const geoData = Array.from(option?.geos || []);
	const devicedata = Array.from(option?.device_types || []);
	const browserData = Array.from(option?.browsers || []);
	const opData = Array.from(option?.op_systems || []);
	const data: OptionsGenType = {
		devices: [],
		geos: [],
		env: <div className={styles.env}>{option?.environment}</div>,
		browsers: [],
		operatingSystems: [],
	};

	const deviceOpts: JSX.Element[] = devicedata.map(g => (
		<div className={styles.tooltipWrapper}>
			<Tooltip content={g.toLowerCase()}>
				<img className={styles?.deviceTypes} src={`/assets/device_types/${g}.svg`} />
			</Tooltip>
		</div>
	));

	const geoOpts = geoData.map(g => (
		<div className={styles.tooltipWrapper}>
			<Tooltip content={g}>
				<img className={styles.geos} src={`/assets/country_logos/${g === 'N/A' || g === '' ? 'unknown' : g}.svg`} />
			</Tooltip>
		</div>
	));

	const browserOpts = browserData.map(g => (
		<div className={styles.tooltipWrapper}>
			<Tooltip content={browserMapping.get(g) || g}>
				<img className={styles?.deviceTypes} src={`/assets/browsers_logos/${browserMapping.get(g) || g}.svg`} />
			</Tooltip>
		</div>
	));

	const opOpts = opData.map(g => (
		<div className={styles.tooltipWrapper}>
			<Tooltip content={g.charAt(0).toUpperCase() + g.slice(1).toLowerCase()}>
				<img className={styles?.deviceTypes} src={`/assets/operating_systems_logos/${OperatingSystemMapping.get(g)}.svg`} />
			</Tooltip>
		</div>
	));

	data.devices = deviceOpts.length > cutoff ? cutoffData(deviceOpts, devicedata, new Map(), cutoff) : deviceOpts;
	data.geos = deviceOpts.length > cutoff ? cutoffData(geoOpts, geoData, new Map(), cutoff) : geoOpts;
	data.browsers = browserOpts.length > cutoff ? cutoffData(browserOpts, browserData, browserMapping, cutoff) : browserOpts;
	data.operatingSystems = opOpts.length > cutoff ? cutoffData(opOpts, opData, OperatingSystemMapping, cutoff) : opOpts;

	return data;
};

const cutoffData = (opts: any[], originalData: string[], mapping: Map<string, string>, cutoff: number): JSX.Element[] => {
	opts = opts.splice(0, cutoff);
	originalData = originalData.splice(cutoff);
	originalData = [...new Set(originalData.map(d => mapping.get(d) || d))];
	opts.push(
		<div className={styles.tooltipWrapper}>
			<Tooltip className={styles.tooltip} content={originalData.join(', ')}>
				<div className={styles.extraData}>+{originalData.length}</div>
			</Tooltip>
		</div>
	);

	return opts;
};
