import { AdvertisersApi } from '../../apis/advertisers.api';
import { CampaignsApi } from '../../apis/campaigns.api';
import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter, IEntityFilter } from '../../components/filters-menu/filters';
import { performanceFilters } from '../../components/filters-menu/filters/perfomance.filter';

export const reportFilters = new Map<FilterTypeKey, FilterType>([
	[
		'entityFilters',
		{
			...performanceFilters.entityFilters,
			campaignEntity: {
				columnName: 'Campaign',
				cellKey: 'campaignnameid',
				autocompleteCallback: async () => {
					return await CampaignsApi.list().then(campaigns => {
						return campaigns.map(campaign => {
							return {
								label: `${campaign.name} (${campaign.id})`,
								value: campaign.id,
							};
						});
					});
				},
			},
			advertiserEntity: {
				columnName: 'Advertiser',
				cellKey: 'advertisernameid',
				autocompleteCallback: async () => {
					return await AdvertisersApi.list().then(advertisers => {
						return advertisers.map(advertiser => {
							return {
								label: `${advertiser.name} (${advertiser.id})`,
								value: advertiser.id,
							};
						});
					});
				},
			},
		} as IEntityFilter,
	],
	[
		'numberFilters',
		{
			...performanceFilters.numberFilters,
			avgBidFloor: { columnName: 'Avg. Bid Floor', cellKey: 'avgBidFloor' },
			avgBidToWin: { columnName: 'Avg. Bid To Win', cellKey: 'avgBidToWin' },
			minBidFloor: { columnName: 'Min Bid Floor', cellKey: 'minBidFloor' },
			maxBidFloor: { columnName: 'Max Bid Floor', cellKey: 'maxBidFloor' },
			maxBidToWin: { columnName: 'Max Bid To Win', cellKey: 'maxBidToWin' },
			optCost: { columnName: 'Optimal Cost', cellKey: 'optCost' },
			optGain: { columnName: 'Optimal Gain', cellKey: 'optGain' },
			gain: { columnName: 'Gain', cellKey: 'gain' },
			campaignId: { columnName: 'Campaign ID', cellKey: 'campaignId' },
			advertiserId: { columnName: 'Advertiser ID', cellKey: 'advertiserId' },
			strategyId: { columnName: 'Strategy ID', cellKey: 'strategyId' },
			campaignGroupId: { columnName: 'Campaign Group ID', cellKey: 'campaignGroupId' },
			creativeId: { columnName: 'Creative ID', cellKey: 'creativeId' },
			testId: { columnName: 'Test ID', cellKey: 'testId' },
			preferredDealId: { columnName: 'Preferred Deal ID', cellKey: 'preferredDealId' },
		} as INumberFilter,
	],
	[
		'stringFilters',
		{
			...performanceFilters.stringFilters,
			campaignName: { columnName: 'Campaign', cellKey: 'campaignName' },
			campaignGroupName: { columnName: 'Campaign Group Name', cellKey: 'campaignGroupName' },
			creativeBrandName: { columnName: 'Creative Brand Name', cellKey: 'creativeBrandName' },
			advertiserName: { columnName: 'Advertiser', cellKey: 'advertiserName' },
			creativeSize: { columnName: 'Creative Size', cellKey: 'creativeSize' },
			creativeGroupDesign: { columnName: 'Creative Group Design', cellKey: 'creativeGroupDesign' },
			page: { columnName: 'Page', cellKey: 'page' },
			domain: { columnName: 'Domain', cellKey: 'domain' },
			browserName: { columnName: 'Browser Name', cellKey: 'browserName' },
			osName: { columnName: 'OS Name', cellKey: 'osName' },
			testName: { columnName: 'Test Name', cellKey: 'testName' },
			state: { columnName: 'State', cellKey: 'state' },
			city: { columnName: 'City', cellKey: 'city' },
			preferredDealName: { columnName: 'Preferred Deal Name', cellKey: 'preferredDealName' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
		} as IEnumFilter,
	],
]);
