import {
	CampaignDailyBudgetColumnConfig,
	UseDomainBudgetLimitColumnConfig,
	DailyDomainMaxLossColumnConfig,
	CampaignNameColumnConfig,
	CampaignIdColumnConfig,
} from '../../../columns/adx-repr.columns';
import { generateAdxReprPerformanceColumns } from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DimensionsColumnConfig } from '../../../columns/columns';
import { TextFilterDefinition } from '../../../table-filters/filters.definitions';

// Notes:
// - This configuration is used to generate a table report for campaigns domains
// - if the filters object or sorting object contains a column that is not in the columns array, it will cause an error !!!!!
// -

export const domainCampaignsTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'campaign_name',
				sortOrder: SortOrderEnum.ASC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new DimensionsColumnConfig({
			name: 'domain',
			accessor: 'domain',
			header: 'Domain',
			visibility: ColumnVisibilityOptions.AlwaysHidden,
			availableFilters: new TextFilterDefinition(),
		}),
		new CampaignNameColumnConfig({ header: 'Name', visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new CampaignIdColumnConfig({ header: 'Id', visibility: ColumnVisibilityOptions.Visible }),
		new CampaignDailyBudgetColumnConfig({ name: 'daily_budget' }),
		new UseDomainBudgetLimitColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DailyDomainMaxLossColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		...generateAdxReprPerformanceColumns(),
	],
	includeChart: true,
	tableName: 'adxReprDomainCampaignsPerformance', // must be uniq for each table report, it is the key for the local storage and export file
};
