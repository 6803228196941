export interface IThirdPartyIcon {
	classes?: string;
}

export const GoogleIcon = (props: IThirdPartyIcon) => {
	const { classes = '' } = props;
	return (
		<svg className={classes} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_1619_41378)">
				<path
					d="M24.266 12.2763C24.266 11.4605 24.1999 10.6404 24.0588 9.83789H12.74V14.4589H19.2217C18.9528 15.9492 18.0885 17.2676 16.823 18.1054V21.1037H20.69C22.9608 19.0137 24.266 15.9272 24.266 12.2763Z"
					fill="#4285F4"
				/>
				<path
					d="M12.74 24.0013C15.9764 24.0013 18.7058 22.9387 20.6944 21.1044L16.8274 18.106C15.7516 18.838 14.3626 19.2525 12.7444 19.2525C9.61376 19.2525 6.95934 17.1404 6.00693 14.3008H2.01648V17.3917C4.05359 21.4439 8.20278 24.0013 12.74 24.0013Z"
					fill="#34A853"
				/>
				<path
					d="M6.00253 14.3007C5.49987 12.8103 5.49987 11.1965 6.00253 9.70618V6.61523H2.01649C0.31449 10.006 0.31449 14.0009 2.01649 17.3916L6.00253 14.3007Z"
					fill="#FBBC04"
				/>
				<path
					d="M12.74 4.74966C14.4508 4.7232 16.1043 5.36697 17.3433 6.54867L20.7694 3.12262C18.6 1.0855 15.7207 -0.034466 12.74 0.000808666C8.20277 0.000808666 4.05359 2.55822 2.01648 6.61481L6.00252 9.70575C6.95052 6.86173 9.60935 4.74966 12.74 4.74966Z"
					fill="#EA4335"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1619_41378">
					<rect width="24" height="24" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};
