import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export class BidderEndpoints {
	private rootApi: string;
	private baseUrl: string;
	private crudUrl: string;

	constructor() {
		this.rootApi = getConfig().env.rootApi || 'invalid root api';
		this.baseUrl = `${this.rootApi}/bidder`;
		this.crudUrl = `${getConfig().env.sheriffRoot}/adminx-bidder/admin`;
	}

	campaigns() {
		return `${this.baseUrl}/campaigns`;
	}

	getPublishers() {
		return `${this.crudUrl}/getPublishers`;
	}

	getCampaign(id: id) {
		return `${this.crudUrl}/getCampaignDetails?id=${id}`;
	}

	updateCampaign() {
		return `${this.crudUrl}/updateCampaign`;
	}

	enableCampaign(id: id) {
		return `${this.crudUrl}/activateCampaign?id=${id}`;
	}

	pauseCampaign(id: id) {
		return `${this.crudUrl}/pauseCampaign?id=${id}`;
	}
}
