import styles from './highlight.module.scss';

interface IHighlight {
	color: string;
	children: string | number;
}

export enum HighlightStatuses {
	Success = 'success',
	Error = 'error',
	New = 'new',
	Info = 'info',
	Pending = 'pending',
	Paused = 'paused',
}

export type HighlighStatus =
	| HighlightStatuses.Success
	| HighlightStatuses.Error
	| HighlightStatuses.New
	| HighlightStatuses.Info
	| HighlightStatuses.Pending
	| HighlightStatuses.Paused;

export const Highlight = (props: IHighlight) => {
	const { children, color } = props;
	return <span className={styles[color]}>{children}</span>;
};
