import { id } from '@monorepo/tools/src/lib/types/primitives';
import { makeAutoObservable } from 'mobx';

export interface IBidderCampaignDomainStatusLimits {
	id: id;
	domain_status: string;
	limit: number;
}

export interface IBidderCampaignPublisherDomainStatusLimits {
	pid: id;
	publisher_name: string;
	domain_status_limits: IBidderCampaignDomainStatusLimits[];
	isOpen?: boolean;
}

export class BidderCampaignDomainStatusLimitsModel implements IBidderCampaignDomainStatusLimits {
	_id: id;
	_domain_status: string;
	_limit: number;
	constructor(data: IBidderCampaignDomainStatusLimits) {
		const { id, domain_status, limit } = data;
		this._id = id;
		this._domain_status = domain_status;
		this._limit = limit;
		makeAutoObservable(this);
	}

	get id() {
		return this._id;
	}

	set id(value: id) {
		this._id = value;
	}

	get domain_status() {
		return this._domain_status;
	}

	set domain_status(value: string) {
		this._domain_status = value;
	}

	get limit() {
		return this._limit;
	}

	set limit(value: number) {
		this._limit = value;
	}

	toObject() {
		return {
			id: this.id,
			domain_status: this.domain_status,
			limit: this.limit,
		};
	}
}

export class BidderCampaignPublisherDomainStatusLimitsModel implements IBidderCampaignPublisherDomainStatusLimits {
	_pid: id;
	_publisher_name: string;
	_domain_status_limits: BidderCampaignDomainStatusLimitsModel[];
	_isOpen: boolean;
	constructor(data: IBidderCampaignPublisherDomainStatusLimits) {
		const { pid, publisher_name, domain_status_limits } = data;
		this._pid = pid;
		this._publisher_name = publisher_name;
		this._domain_status_limits = domain_status_limits.map(
			domainStatusLimit => new BidderCampaignDomainStatusLimitsModel(domainStatusLimit)
		);
		this._isOpen = false;
		makeAutoObservable(this);
	}

	get pid() {
		return this._pid;
	}

	set pid(value: id) {
		this._pid = value;
	}

	get publisher_name() {
		return this._publisher_name;
	}

	set publisher_name(value: string) {
		this._publisher_name = value;
	}

	get domain_status_limits() {
		return this._domain_status_limits;
	}

	set domain_status_limits(value: IBidderCampaignDomainStatusLimits[]) {
		this._domain_status_limits = value.map(domainStatusLimit => new BidderCampaignDomainStatusLimitsModel(domainStatusLimit));
	}

	get isOpen() {
		return this._isOpen;
	}

	set isOpen(value: boolean) {
		this._isOpen = value;
	}

	toObject() {
		return {
			pid: this.pid,
			publisher_name: this.publisher_name,
			domain_status_limits: this._domain_status_limits.map(domainStatusLimit => domainStatusLimit.toObject()),
			isOpen: this.isOpen,
		};
	}
}
