import styles from './form-error.module.scss';

interface IFormError {
	msg?: string;
	className?: string;
}

export const FormError = (props: IFormError) => {
	const { msg, className = '' } = props;

	return <div className={`${styles.error} ${msg ? styles.show : ''} ${className}`}>{msg}</div>;
};
