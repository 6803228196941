import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Nav, NavItem, NavPage } from '@monorepo/base/src/components/nav/nav';
import { Page } from '@monorepo/base/src/components/page/page';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import routes from '../../../modules/routes/index.routes';
import { route } from '@monorepo/tools/src/lib/types/url';
import styles from './creative-view.module.scss';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';

export const CreativeView = () => {
	const { creativeId } = useParams();
	const navigate = useNavigateParams();
	const { currentRoute } = useRoute();
	useNestedSidebar();

	if (!creativeId) {
		navigate(`${routes.creatives.index()}`, { replace: true });
		return null;
	}

	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);
	useEffect(() => {
		setActiveRoute(currentRoute);
	}, [currentRoute]);

	return (
		<NavPage>
			<Nav classes={{ nav: styles.nav }} onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				<NavItem to={creativeId ? routes.creatives.view(creativeId) : ''}>Overview</NavItem>
				<NavItem to={creativeId ? routes.creatives.campaigns(creativeId) : ''}>Campaigns</NavItem>
			</Nav>
			<Page>
				<Outlet />
			</Page>
		</NavPage>
	);
};
