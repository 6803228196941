import { Textarea } from '@monorepo/base/src/components/form/textarea/textarea';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import styles from './keys-management.module.scss';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { KeysManagementApi } from '../../../modules/apis/keys-management.api';
import { Checkbox } from '@monorepo/base/src/components/form/checkbox/checkbox';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import { AdminxStore } from '../../../modules/stores';
import { Disable } from '@monorepo/base/src/components/disable/disable';

const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'keys_management_pagebar')] };

export const KeysManagement: FC = observer(() => {
	const { toastsStore } = useStores<AdminxStore>();
	const [bidKeysData, setBidKeysData] = useState<{ name: string; status: string; isSelected: boolean }[]>([]);
	const [bidKeysForm, setBidKeysForm] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [isLoadingAnalyze, setIsLoadingAnalyze] = useState<boolean>(false);
	const [isLoadingReopen, setIsLoadingReopen] = useState<boolean>(false);
	const [selectAllCheckbox, setSelectAllCheckbox] = useState<boolean>(false);

	const analyzeBidKeys = () => {
		setIsLoadingAnalyze(true);
		const bidKeysArray = bidKeysForm
			.split('\n')
			.map(bidkey => bidkey.trim())
			.filter(bidkey => bidkey.length > 0);

		if (!bidKeysArray.length) {
			setIsLoadingAnalyze(false);
			setError('Please enter bid keys');
			return;
		}

		KeysManagementApi.listStatus(bidKeysArray)
			.then(res => {
				if (Object.keys(res).length === 0) {
					setError('Invalid Bid Keys or keys not found in trash / snapshot.');
				}
				const preparedData = Object.keys(res).map(bidKey => {
					return {
						name: bidKey,
						status: res[bidKey],
						isSelected: false,
					};
				});
				setBidKeysData(preparedData);
			})
			.catch(() => {
				setError('Failed to analyze Bidkeys');
				toastsStore.addToast({
					msg: 'Failed to analyze Bidkeys',
					type: DispatchLogsTypes.Error,
				});
			})
			.finally(() => {
				setSelectAllCheckbox(false);
				setIsLoadingAnalyze(false);
			});
	};

	const handleReopenBidkeys = () => {
		setIsLoadingReopen(true);
		const selectedBidKeys = bidKeysData.filter(bidKey => bidKey.isSelected).map(bidKey => bidKey.name);
		KeysManagementApi.reopenBidKeys(selectedBidKeys)
			.then(() => {
				toastsStore.addToast({
					msg: `${selectedBidKeys.length} Bidkeys reopened successfully.`,
					type: DispatchLogsTypes.Success,
				});
				setBidKeysData(prev => {
					return prev.map(item => {
						if (selectedBidKeys.includes(item.name)) {
							return { ...item, status: 'ACTIVE', isSelected: false };
						}
						return item;
					});
				});
				setSelectAllCheckbox(false);
			})
			.catch(() => {
				toastsStore.addToast({
					msg: 'Failed to reopen Bidkeys',
					type: DispatchLogsTypes.Error,
				});
			})
			.finally(() => {
				setIsLoadingReopen(false);
			});
	};

	const handleSelectAll = () => {
		setBidKeysData(prev => {
			return prev.map(item => ({
				...item,
				isSelected: item.status !== 'ACTIVE' ? !selectAllCheckbox : false,
			}));
		});
	};

	return (
		<Disable is={isLoadingReopen}>
			<Page>
				<Pagebar debugProps={pageBarDebugProps}>
					<PagebarTitle>Keys Management</PagebarTitle>
				</Pagebar>
				<div className={styles.wrapper}>
					<div className={styles.disclaimer}>
						Active Keys are keys that have no record in Keys_in_trash or in Closed_in_cache_snapshot. meaning if a traffic
						arrives for that key it will not filtered out for these 2 reasons.
					</div>
					<Textarea
						rows={10}
						inputWrapperClassName={styles.textareaWrapper}
						value={bidKeysForm}
						placeholder={'Bid Keys'}
						onValue={value => {
							if (error) {
								setError('');
							}
							setBidKeysForm(value);
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'bid_keys_text_area')] }}
					/>
					<PrimaryButton
						disabled={isLoadingAnalyze || !bidKeysForm}
						isRotateIcon={isLoadingAnalyze}
						onClick={analyzeBidKeys}
						className={styles.analayzeBtn}
						icon={'search-sm'}>
						Analayze
					</PrimaryButton>
					{error && <div className={styles.errorMsg}>{error}</div>}
					{Object.keys(bidKeysData).length > 0 && (
						<table className={styles.table}>
							<thead className={styles.headers}>
								<tr>
									<th>
										<Checkbox
											checked={selectAllCheckbox}
											onChange={() => {
												setSelectAllCheckbox(prev => !prev);
												handleSelectAll();
											}}
										/>
									</th>
									<th>Bidkey</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{bidKeysData.map(bidKey => {
									return (
										<tr key={bidKey.name}>
											<td className={styles.checkbox} width={'30px'}>
												<Checkbox
													disabled={bidKey.status === 'ACTIVE'}
													checked={bidKey.isSelected}
													onChange={() => {
														setBidKeysData(prev => {
															return prev.map(item => {
																if (item.name === bidKey.name) {
																	return { ...item, isSelected: !item.isSelected };
																}
																return item;
															});
														});
													}}
												/>
											</td>
											<td className={styles.bidkeyName}>{bidKey.name}</td>
											<td className={styles.status}>{bidKey.status}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					{!error && bidKeysData.length > 0 && (
						<div className={styles.selectedCounter}>
							Selected {bidKeysData.filter(bidkey => bidkey.isSelected).length} Bidkeys
						</div>
					)}
					<BottomBarActions>
						<PrimaryButton
							disabled={isLoadingAnalyze || bidKeysData.every(bidKey => !bidKey.isSelected || bidKey.status === 'ACTIVE')}
							onClick={() => handleReopenBidkeys()}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'save_campaign_button')] }}
							isRotateIcon={isLoadingAnalyze}
							icon={isLoadingAnalyze ? 'loading-01' : 'send-03'}>
							Reopen Bidkeys
						</PrimaryButton>
					</BottomBarActions>
				</BottomBar>
			</Page>
		</Disable>
	);
});
