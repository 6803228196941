import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ChangeEvent, LegacyRef } from 'react';
interface ICheckboxProps {
	onChange?: ((e: ChangeEvent) => void) | undefined;
	checked?: boolean | undefined;
	title?: string | undefined;
	indeterminate?: string | boolean | undefined;
	ref?: LegacyRef<HTMLInputElement> | undefined;
	className?: string;
	id?: string;
	style?: object;
	disabled?: boolean;
	debugProps?: IDebugProps;
}

export const Checkbox = (props: ICheckboxProps) => {
	const { debugProps, ...rest } = props;
	const { dataAttrs } = debugProps || {};
	return <input type={'checkbox'} {...rest} {...generateDataAttrs(dataAttrs)} />;
};
