import { NavigateOptions, useNavigate, To, useSearchParams, NavigateFunction } from 'react-router-dom';

let globalIncludeParams: string[] = [];
export const declareIncludeParams = (knownSearchParmas: string[]) => {
	globalIncludeParams = knownSearchParmas;
};

export const getParamsFromUrl = (params: URLSearchParams): string => {
	const searchParams: string[] = [];
	globalIncludeParams.forEach(key => {
		const value = params.get(key);
		if (value) {
			searchParams.push(`${key}=${encodeURIComponent(value)}`);
		}
	});
	return searchParams.length > 0 ? `?${searchParams.join('&')}` : '';
};

export const useNavigateParams = () => {
	const navigate = useNavigate();
	const [params] = useSearchParams();

	const navigateWithParams: NavigateFunction = (to: To | number, options?: NavigateOptions) => {
		if (typeof to === 'number') {
			navigate(to);
		} else {
			const searchParams = getParamsFromUrl(params);
			navigate(to + searchParams, options);
		}
	};
	return navigateWithParams;
};
