import { IAskError } from './ask';

export function isAbortError(error: IAskError) {
	return error?.data?.name === 'AbortError';
}

export function isUnauthorizedError<TError extends { httpStatus: number }>(error: TError): error is TError {
	return error && error.httpStatus === 401;
}

export function isForbiddenError<TError extends { httpStatus: number }>(error: TError): error is TError {
	return error && error.httpStatus === 403;
}

export function isBadRequest<TError extends { httpStatus: number }>(error: TError): error is TError {
	return error && error.httpStatus === 400;
}
