import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { IAdminxStore } from '../../stores';
import { PerformancesColumnsSnakeCase } from './use-reports-list';

export enum SideEffectKeys {
	DealId = 'deal_id',
	Id = 'id',
}

// TODO - remove after implementing the new reporting service
export type ForeignKeys =
	| PerformancesColumnsSnakeCase.CampaignId
	| PerformancesColumnsSnakeCase.CampaignGroupId
	| PerformancesColumnsSnakeCase.AdvertiserId
	| PerformancesColumnsSnakeCase.CreativeId
	| PerformancesColumnsSnakeCase.CreativeGroupId
	| PerformancesColumnsSnakeCase.TestId
	| PerformancesColumnsSnakeCase.PublisherId
	| PerformancesColumnsSnakeCase.PreferredDealId
	| SideEffectKeys.DealId
	| SideEffectKeys.Id;

interface IPerformanceOptionsPeriod {
	periodStart: string; // YYYY-MM-DD
	periodEnd: string; // YYYY-MM-DD
}

export enum IPerformanceOptionsFilterType {
	Include = 'INCLUDE',
}

interface IPerformanceOptionsFilter {
	column: string;
	filterType: string;
	value: string[];
	inverted?: boolean;
}

enum IPerformanceOptionsDateSegments {
	Daily = 'DAILY',
	None = 'NONE',
}

enum IPerformanceOptionsSortBy {
	Date = 'date',
	Wins = 'wins',
	Id = 'id',
}

enum IPerformanceOptionsSortOrder {
	Asc = 0,
	Desc = 1,
}

interface IPerformanceOptionsExternal extends IPerformanceOptions {
	type: string;
	submit: boolean;
}

interface IPerformanceOptions {
	start?: number;
	limit?: number;
	periodStart?: string; // YYYY-MM-DD
	periodEnd?: string; // YYYY-MM-DD
	multipleTimePeriods?: IPerformanceOptionsPeriod[];
	groupBys?: (PerformancesColumnsSnakeCase | null)[];
	filters?: IPerformanceOptionsFilter[];
	dateSegment?: IPerformanceOptionsDateSegments;
	sortBy?: string;
	sortOrder?: IPerformanceOptionsSortOrder;
	inResultsSort?: string | null;
	inResultsSortOrder?: IPerformanceOptionsSortOrder;
	inResultsFilters?: string[][];
	graphYaxis?: string[];
	graphLines?: string[]; // ?
	additionalColumns?: string[]; // ?
	reportName?: string;
	submit?: boolean;
	type?: string;
}

export const preparePerformanceRequest = (options: IPerformanceOptions): IPerformanceOptions => {
	const {
		start,
		limit,
		periodStart,
		periodEnd,
		multipleTimePeriods,
		groupBys,
		filters,
		dateSegment,
		sortBy,
		sortOrder,
		inResultsSort,
		inResultsSortOrder,
		inResultsFilters,
		graphYaxis,
		graphLines,
		additionalColumns,
		submit,
		reportName,
	} = options;

	const externalOptions: IPerformanceOptionsExternal & IPerformanceOptions = {
		type: 'STATISTICS',
		submit: submit || false,
		start: start || 0,
		limit: limit || 100,
		periodStart,
		periodEnd,
		multipleTimePeriods: multipleTimePeriods || [],
		groupBys: (() => {
			if (!groupBys) {
				return [];
			}
			// Default is by date, if we add date it ruins the results so remove it for now until fixed in reporting service (probably never blat)
			return groupBys
				?.filter(groupBy => groupBy !== PerformancesColumnsSnakeCase.Date)
				.filter((value, index, self) => self.indexOf(value) === index); // remove duplicates
		})(),
		filters: filters || [],
		dateSegment: (() => {
			if (dateSegment) {
				return dateSegment;
			}

			if (!groupBys?.includes(PerformancesColumnsSnakeCase.Date)) {
				return IPerformanceOptionsDateSegments.None;
			}
			return IPerformanceOptionsDateSegments.Daily;
		})(),
		sortBy: sortBy || IPerformanceOptionsSortBy.Date,
		inResultsSort: inResultsSort || null,
		inResultsSortOrder: inResultsSortOrder || IPerformanceOptionsSortOrder.Desc,
		sortOrder: sortOrder || IPerformanceOptionsSortOrder.Desc,
		inResultsFilters: inResultsFilters || [],
		graphYaxis: graphYaxis || [],
		graphLines: graphLines || [],
		additionalColumns: additionalColumns || [],
		reportName: reportName || 'adx',
	};

	return externalOptions;
};

export const usePerformance = (options: IPerformanceOptions, hookOptions: BetweenPagesStateProps = {}) => {
	const isBlockRequest = hookOptions.isBlockRequest || false;
	const { performanceStore } = useStores<IAdminxStore>();
	const location = useLocation();
	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const didMount = useDidMount(); // fetch data after did mount, solve problem of navigating between performance pages before fetch is resolved
	const [fetchParams, setFetchParams] = useState<IPerformanceOptions>({ ...options });

	useEffect(() => {
		const { periodStart, periodEnd } = options;
		if (isBlockRequest || !didMount) {
			return;
		}

		const params = preparePerformanceRequest({
			...options,
			periodStart: periodStart || primaryFromDate,
			periodEnd: periodEnd || primaryToDate,
		});

		performanceStore.reset();
		setFetchParams(params);
		performanceStore.fetch(params);

		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				performanceStore.abort();
			}
		};
	}, [location, primaryFromDate, primaryToDate, didMount]);

	return { error: performanceStore.getHttpError(), isLoading: performanceStore.getIsLoading(), fetchParams };
};
