import { advertiserNameAdxCell, campaignGroupNameAdxCell, campaignNameAdxCell, domainLinkCell } from '../cells/old-adx-report.cells';
import {
	AdvertiserEntityFilterDefinition,
	CampaignEntityFilterDefinition,
	NumbersFilterDefinition,
	TextFilterDefinition,
} from '../table-filters/filters.definitions';
import { DimensionsColumnConfig, ColumnConfig } from './columns';

// "campaign"
export class CampaignNameAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'campaign',
			accessor: 'campaign',
			header: 'Campaign Name',
			linkTo: ['campaign_id'],
			cell: campaignNameAdxCell,
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The name of the campaign in our system. Will only be populated if we decided to bid',
			...params,
		});
	}
}

// "campaign_id"
export class CampaignIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'campaign_id',
			accessor: 'campaignId',
			header: 'Campaign ID',
			linkTo: ['campaign'],
			availableFilters: new CampaignEntityFilterDefinition(),
			tooltip: 'The Campaign ID in our system. Will only be populated if we decided to bid',
			...params,
		});
	}
}

// "advertiser"
export class AdvertiserNameAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'advertiser',
			accessor: 'advertiser',
			linkTo: ['advertiser_id'],
			header: 'Advertiser Name',
			cell: advertiserNameAdxCell,
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The name of the advertiser in our system. Will only be populated if we decided to bid',
			...params,
		});
	}
}

// "advertiser_id"
export class AdvertiserIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'advertiser_id',
			accessor: 'advertiserId',
			linkTo: ['advertiser'],
			header: 'Advertiser ID',
			availableFilters: new AdvertiserEntityFilterDefinition(),
			...params,
		});
	}
}

// "campaign_group"
export class CampaignGroupNameAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'campaign_group',
			accessor: 'campaignGroup',
			linkTo: ['campaign_group_id'],
			header: 'Campaign Group',
			cell: campaignGroupNameAdxCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "campaign_group_id"
export class CampaignGroupIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'campaign_group_id',
			accessor: 'campaignGroupId',
			linkTo: ['campaign_group'],
			header: 'Campaign Group ID',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The Campaign Group ID in our system. Will only be populated if we decided to bid',
			...params,
		});
	}
}

// "creative_brand_name"
export class CreativeBrandAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'creative_brand_name',
			accessor: 'creativeBrandName',
			header: 'Creative Brand/Name',
			linkTo: ['creative_id'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "creative_id"
export class CreativeIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'creative_id',
			accessor: 'creativeId',
			header: 'Creative ID',
			linkTo: ['creative_brand_name'],
			availableFilters: new NumbersFilterDefinition(),
			tooltip: 'The ID of the creative that was chosen to serve for that auction. Will only be populated if we decided to bid',
			...params,
		});
	}
}

// "creative_group_design"
export class CreativeGroupDesignAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'creative_group_design',
			accessor: 'creativeGroupDesign',
			header: 'Creative Group Design',
			linkTo: ['creative_group_id'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "creative_group_id"
export class CreativeGroupIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'creative_group_id',
			accessor: 'creativeGroupId',
			header: 'Creative Group ID',
			linkTo: ['creative_group_design'],
			availableFilters: new NumbersFilterDefinition(),
			tooltip: 'The Creative Group ID in our system. Will only be populated if we decided to bid ',
			...params,
		});
	}
}

// "strategy_id"
export class StrategyIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'strategy_id',
			accessor: 'strategyId',
			header: 'Strategy ID',
			availableFilters: new NumbersFilterDefinition(),
			tooltip: 'The ID of the strategy attached to the campaign chosen to bid',
			...params,
		});
	}
}

// "creative_size"
export class CreativeSizeAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'creative_size',
			accessor: 'creativeSize',
			header: 'Creative Size',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "test_id"
export class TestIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'test_id',
			accessor: 'testId',
			header: 'Test ID',
			linkTo: ['test_name'],
			availableFilters: new NumbersFilterDefinition(),
			tooltip: 'If a request was part of a test, this will have the ID of that test in our system',
			...params,
		});
	}
}

// "test_name"
export class TestNameAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'test_name',
			accessor: 'testName',
			header: 'Test Name',
			linkTo: ['test_id'],
			availableFilters: new TextFilterDefinition(),
			tooltip: 'If a request was part of a test, this will have the name of that test in our system',
			...params,
		});
	}
}

// "test_group_name"
export class TestGroupNameAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'test_group_name',
			accessor: 'testGroupName',
			header: 'Test Group Name',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'If a request was part of a test, this will have the name  of the group that request was associated with in our test',
			...params,
		});
	}
}

// "page"
export class PageAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'page',
			accessor: 'page',
			header: 'Page',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The page of the banner placement as sent in the bid request. Does not include query params and URL path only',
			...params,
		});
	}
}

export class PreferredDealIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'preferred_deal_id',
			accessor: 'preferredDealId',
			header: 'Preferred Deal ID',
			linkTo: ['preferred_deal_name'],
			availableFilters: new NumbersFilterDefinition(),
			tooltip: 'The ID of the deal the request was associated with. Will onlybe populated if we chose to bid on that request',
			...params,
		});
	}
}

// "preferred_deal_name"
export class PreferredDealNameAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'preferred_deal_name',
			accessor: 'preferredDealName',
			header: 'Preferred Deal Name',
			linkTo: ['preferred_deal_id'],
			availableFilters: new TextFilterDefinition(),
			tooltip:
				'The name of the deal the request was associated with as in appears in AdX deals screen. Will only be populated if we chose to bid on that request',
			...params,
		});
	}
}

// "domain"
export class DomainAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'domain',
			accessor: 'domain',
			header: 'Domain',
			cell: domainLinkCell,
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The root domain (without any subdomains) the request arrived with',
			...params,
		});
	}
}

// "publisher_id"
export class PublisherIdAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'publisher_id',
			accessor: 'publisherId',
			header: 'Publisher ID',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The publisher ID the impression came from, a publisher can have multiple domains',
			...params,
		});
	}
}

// "browser_name"
export class BrowserNameAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'browser_name',
			accessor: 'browserName',
			header: 'Browser Name',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The browser of the user as sent in the bid request. ',
			...params,
		});
	}
}

// "os_name"
export class OsNameAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'os_name',
			accessor: 'osName',
			header: 'OS Name',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The OS of the user as sent in the bid request. Can be MAC_OS_X, CHROME_OS, WINDOWS, ANDROID, IOS, LINUX and others',
			...params,
		});
	}
}

// "geo"
export class GeoAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'geo',
			accessor: 'geo',
			header: 'Geo',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The country code of the user as sent in the bid request',
			...params,
		});
	}
}

// "state"
export class StateAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'state',
			accessor: 'state',
			header: 'State',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The state (US only) of the user as sent in the bid request',
			...params,
		});
	}
}

// "city"
export class CityAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'city',
			accessor: 'city',
			header: 'City',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The city of the user as sent in the bid request',
			...params,
		});
	}
}
// "auction_type"
export class AuctionTypeAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'auction_type',
			accessor: 'auctionType',
			header: 'Auction Type',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

// "bid_key"
export class BidKeyAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'bid_key',
			accessor: 'bidKey',
			header: 'Bid Key',
			availableFilters: new TextFilterDefinition(),
			tooltip:
				'The bid_key our system assigned to this request. In optimizer based campaigns, this is used to set a bid and otherwise used mainly as an aggregator of statistics. Please notice that bid_key is not the same as root_bid_key ans this is campaign specific',
			...params,
		});
	}
}

// "Date"
export class DateAdxColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'dt',
			accessor: 'date',
			header: 'Date',
			tooltip: 'The date selected in within your timerange',
			...params,
		});
	}
}

// Strategy Id
export class StrategyIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<ColumnConfig> = {}) {
		super({
			name: 'strategy_id',
			accessor: 'strategyId',
			header: 'Strategy ID',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The ID of the strategy attached to the campaign chosen to bid',
			...params,
		});
	}
}
