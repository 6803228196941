import { id } from '../types/primitives';
import { route } from '../types/url';

export interface IRouteOptions {
	placeholderId: string;
}

// TODO - split routes
// TODO - use generatePath
export class BaseRoutes {
	baseRoute: route;

	constructor(baseRoute: route) {
		this.baseRoute = baseRoute;
	}

	index() {
		return `/${this.baseRoute}`;
	}

	// CRUD Routes
	// TODO - need to remove, use CrudRoutes instead
	delete(id: id) {
		return `/${this.baseRoute}/${id}/delete`;
	}

	create() {
		return `/${this.baseRoute}/create`;
	}

	edit(id: id) {
		return `/${this.baseRoute}/${id}/edit`;
	}

	view(id: id) {
		return `/${this.baseRoute}/${id}`;
	}
	//

	duplicate(id: id) {
		return `/${this.baseRoute}/${id}/duplicate`;
	}

	debug(id: id) {
		return `/${this.baseRoute}/${id}/debug`;
	}

	// Nested lists Routes
	// TODO - need to remove, every model should be implement its nested lists
	advertisers(id: id) {
		return `/${this.baseRoute}/${id}/advertisers`;
	}

	campaignGroups(id: id) {
		return `/${this.baseRoute}/${id}/campaign-groups`;
	}

	creativeGroups(id: id) {
		return `/${this.baseRoute}/${id}/creative-groups`;
	}

	campaigns(id: id) {
		return `/${this.baseRoute}/${id}/campaigns`;
	}

	createCampaign(id: id) {
		return `/${this.baseRoute}/${id}/campaigns/create`;
	}

	createAdvertiser(id: id) {
		return `/${this.baseRoute}/${id}/advertisers/create`;
	}

	createCampaignGroup(id: id) {
		return `/${this.baseRoute}/${id}/campaign-groups/create`;
	}

	// TODO
	editCampaignGroup(advertiserId: id, campaignGroupId: id) {
		return `/${this.baseRoute}/${advertiserId}/campaign-groups/${campaignGroupId}/edit`;
	}

	createCreativeGroup(id: id) {
		return `/${this.baseRoute}/${id}/creative-groups/create`;
	}

	// TODO
	editCreativeGroup(advertiserId: id, creativeGroupId: id) {
		return `/${this.baseRoute}/${advertiserId}/creative-groups/${creativeGroupId}/edit`;
	}

	createCreative(id: id) {
		return `/${this.baseRoute}/${id}/creatives/create`;
	}

	creatives(id: id) {
		return `/${this.baseRoute}/${id}/creatives`;
	}

	deals(id: id) {
		return `/${this.baseRoute}/${id}/deals`;
	}

	dailyReport(id: id) {
		return `/${this.baseRoute}/${id}/daily-report`;
	}

	// Performance Routes
	domains(id: id) {
		return `/${this.baseRoute}/${id}/domains`;
	}

	bidKeys(id: id) {
		return `/${this.baseRoute}/${id}/bid-keys`;
	}

	trashedBidKeys(id: id) {
		return `/${this.baseRoute}/${id}/trashed-bid-keys`;
	}

	pages(id: id) {
		return `/${this.baseRoute}/${id}/pages`;
	}

	channels(id: id) {
		return `/${this.baseRoute}/${id}/channels`;
	}

	sources(id: id) {
		return `/${this.baseRoute}/${id}/sources`;
	}

	tool(toolName: string) {
		return `/${this.baseRoute}/${toolName}`;
	}
}

// TODO - use this!!
export class BaseAndCrudRoutes {
	baseRoute: route;

	constructor(baseRoute: route) {
		this.baseRoute = baseRoute;
	}

	index() {
		return `/${this.baseRoute}`;
	}

	delete(id: id) {
		return `/${this.baseRoute}/${id}/delete`;
	}

	create() {
		return `/${this.baseRoute}/create`;
	}

	edit(id: id) {
		return `/${this.baseRoute}/${id}/edit`;
	}

	view(id: id) {
		return `/${this.baseRoute}/${id}`;
	}
}
