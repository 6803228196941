import { IEntityFilters } from '.';

export const performanceFilters: IEntityFilters = {
	numberFilters: {
		auctions: { columnName: 'Auctions', cellKey: 'auctions' },
		wins: { columnName: 'Wins', cellKey: 'wins' },
		impressions: { columnName: 'Impressions', cellKey: 'impressions' },
		clicks: { columnName: 'Clicks', cellKey: 'clicks' },
		conversions: { columnName: 'Conversions', cellKey: 'conversions' },
		cost: { columnName: 'Cost', cellKey: 'cost' },
		conversionValue: { columnName: 'Revenue', cellKey: 'conversionValue' },
		winRate: { columnName: 'Win Rate', cellKey: 'winRate' },
		roas: { columnName: 'Roas', isPercentage: true, cellKey: 'roas' },
		ctr: { columnName: 'CTR', cellKey: 'ctr' },
		cvr: { columnName: 'CVR', cellKey: 'cvr' },
		gain: { columnName: 'Gain', cellKey: 'gain' },
		optGain: { columnName: 'Optimal Gain', cellKey: 'optGain' },
		optCost: { columnName: 'Optimal Cost', cellKey: 'optCost' },
	},

	stringFilters: {},

	enumFilters: {},

	entityFilters: {},
};
