import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CampaignModel } from './campaign.model';
import { Status } from '../enums/status';

/**
 * The props the deal crud page uses
 */
export interface IDeal {
	id?: id;
	deal_id?: id;
	deal_name?: string;
	formats?: string[];
	proposal_id?: id;
	proposal_name?: string;
	publisher_profile?: string;
	serving_status?: Status;
	campaigns?: CampaignModel[];
}

/**
 * The props the deal edit page uses
 */
export interface IDealEdit {
	id?: id;
	deal_id?: id;
	deal_name?: string;
	formats?: string[];
	proposal_id?: id;
	proposal_name?: string;
	publisher_profile?: string;
	serving_status?: Status;
	campaigns?: CampaignModel[];
}

/**
 * The props to send to server
 */
export interface IDealCreateForm {
	id?: id;
	deal_id?: id;
	deal_name?: string;
	formats?: string[];
	proposal_id?: id;
	proposal_name?: string;
	publisher_profile?: string;
	serving_status?: Status;
	campaigns?: CampaignModel[];
}

export interface IDealEditForm {
	id?: id;
	deal_id?: id;
	deal_name?: string;
	formats?: string[];
	proposal_id?: id;
	proposal_name?: string;
	publisher_profile?: string;
	serving_status?: Status;
	campaigns?: CampaignModel[];
}

export class DealModel implements IDeal {
	id?: id;
	deal_id?: id;
	deal_name?: string;
	formats?: string[];
	proposal_id?: id;
	proposal_name?: string;
	publisher_profile?: string;
	serving_status?: Status;
	campaigns?: CampaignModel[];

	constructor(props?: IDeal) {
		this.id = props?.id;
		this.deal_id = props?.deal_id;
		this.deal_name = props?.deal_name;
		this.formats = props?.formats;
		this.proposal_id = props?.proposal_id;
		this.proposal_name = props?.proposal_name;
		this.publisher_profile = props?.publisher_profile;
		this.serving_status = props?.serving_status;
		this.campaigns = props?.campaigns?.map(campaign => new CampaignModel(campaign));

		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getDealId(): id | undefined {
		return this.deal_id;
	}

	public setDealId(deal_id: id) {
		this.deal_id = deal_id;
	}

	public getDealName(): string | undefined {
		return this.deal_name;
	}

	public setDealName(deal_name: string) {
		this.deal_name = deal_name;
	}

	public getProposalId(): id | undefined {
		return this.proposal_id;
	}

	public setProposalId(proposal_id: id) {
		this.proposal_id = proposal_id;
	}

	public getProposalName(): string | undefined {
		return this.proposal_name;
	}

	public setProposalName(proposal_name: string) {
		this.proposal_name = proposal_name;
	}

	public getFormats(): string[] | undefined {
		return this.formats;
	}

	public setFormats(formats: string[]) {
		this.formats = formats;
	}

	public getPublisherProfile(): string | undefined {
		return this.publisher_profile;
	}

	public setPublisherProfile(publisher_profile: string) {
		this.publisher_profile = publisher_profile;
	}

	public getServingStatus(): Status | undefined {
		return this.serving_status;
	}

	public setServingStatus(serving_status: Status) {
		this.serving_status = serving_status;
	}

	public getCampaigns(): CampaignModel[] | undefined {
		return this.campaigns;
	}

	public setCampaigns(campaigns: CampaignModel[]) {
		this.campaigns = campaigns;
	}
}
