import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { Fragment } from 'react';

interface TableDefaultButtonProps {
	text?: string;
	to?: string;
	onClick?: () => void;
	icon?: string;
}

export const TableDefaultButton = ({ to, onClick, text = 'Create', icon = 'plus' }: TableDefaultButtonProps) => {
	return (
		<PrimaryLink onClick={onClick} icon={icon} to={to} width={'100px'}>
			{text}
		</PrimaryLink>
	);
};

interface TableDefaultButtonWrapperProps {
	children: React.ReactNode;
}

export const TableDefaultButtonWrapper = ({ children }: TableDefaultButtonWrapperProps) => {
	return <Fragment>{children}</Fragment>;
};
