import {
	addDays,
	endOfDay,
	startOfDay,
	startOfMonth,
	endOfMonth,
	addMonths,
	startOfWeek,
	endOfWeek,
	isSameDay,
	differenceInCalendarDays,
} from 'date-fns';

const defineds = {
	startOfWeek: startOfWeek(new Date()),
	endOfWeek: endOfWeek(new Date()),
	startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
	endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
	startOfToday: startOfDay(new Date()),
	endOfToday: endOfDay(new Date()),
	startOfYesterday: startOfDay(addDays(new Date(), -1)),
	startOfDaysAgo: daysAmount => startOfDay(addDays(new Date(), -daysAmount)),
	endOfYesterday: endOfDay(addDays(new Date(), -1)),
	startOfMonth: startOfMonth(new Date()),
	endOfMonth: endOfMonth(new Date()),
	startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
	endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
	range: {},
	isSelected(range) {
		const definedRange = this.range();
		return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
	},
};

export function createStaticRanges(ranges) {
	return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
	{
		label: 'Yesterday',
		range: () => ({
			startDate: defineds.startOfYesterday,
			endDate: defineds.endOfYesterday,
		}),
	},
	{
		label: 'Last 7 Days',
		range: () => ({
			startDate: defineds.startOfDaysAgo(7),
			endDate: defineds.endOfYesterday,
		}),
	},
	{
		label: 'Last Week',
		range: () => ({
			startDate: defineds.startOfLastWeek,
			endDate: defineds.endOfLastWeek,
		}),
	},
	{
		label: 'This Week',
		range: () => ({
			startDate: defineds.startOfWeek,
			endDate: defineds.endOfYesterday,
		}),
	},
	{
		label: 'Last Month',
		range: () => ({
			startDate: defineds.startOfLastMonth,
			endDate: defineds.endOfLastMonth,
		}),
	},
	{
		label: 'This Month',
		range: () => ({
			startDate: defineds.startOfMonth,
			endDate: defineds.endOfYesterday,
		}),
	},
]);

export const defaultInputRanges = [
	{
		label: 'Days up to yesterday',
		range(value) {
			return {
				startDate: addDays(defineds.startOfYesterday, (Math.max(Number(value), 1) - 1) * -1),
				endDate: defineds.endOfYesterday,
				value,
			};
		},
		getCurrentValue(range) {
			if (range?.value === 0 || !isSameDay(range.endDate, defineds.endOfYesterday)) {
				return '';
			}
			if (!range.startDate) {
				return '';
			}
			return differenceInCalendarDays(defineds.endOfYesterday, range.startDate) + 1;
		},
	},
];
