import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IPerformanceOptions } from '../hooks/apis/use-reports-list';
import endpoints from './endpoints/index.endpoints';
import { ReportResponse } from '../models/report.model';
import { ReportParams } from '../components/report-table/stores/data-stores/old-report.types';
import { AdminxReportResponse } from '../components/report-table/stores/data-stores/adx-repr.store';
import { IAdminxReporterParams } from '../components/report-table/stores/data-stores/adx-repr.types';

export const AdminxReporterApi = {
	report: async <T>(params: IPerformanceOptions): Promise<ReportResponse<T>> => {
		const res = await ask.post(endpoints.report.report(), params);
		const payload = new ReportResponse<T>(res.payload);
		return payload;
	},
	newReport: async <T>(params: ReportParams): Promise<ReportResponse<T>> => {
		const res = await ask.post(endpoints.report.report(), params);
		const payload = new ReportResponse<T>(res.payload);
		return payload;
	},

	newAdminxReport: async <T>(params: IAdminxReporterParams): Promise<AdminxReportResponse<T>> => {
		const res = await ask.post(endpoints.report.newReport(), params);
		const payload = new AdminxReportResponse<T>(res.payload);
		return payload;
	},

	newAdminxReportCsv: async (params?: IAdminxReporterParams): Promise<Blob | null> => {
		const res = await ask.downloadPost(`${endpoints.report.newReport()}`, { ...params, exportSchema: 'CSV' });
		return res;
	},

	downloadCsv: async (params?: ReportParams): Promise<Blob | null> => {
		const res = await ask.downloadPost(`${endpoints.report.report()}/download`, params);
		return res;
	},
};
