import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Card } from '@monorepo/base/src/components/card/card';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { CampaignTypes } from '../../../../enums/campaign-types';
import { CreativeSelectionModes } from '../../../../enums/creative-selection-modes';
import { AdvertiserModel } from '../../../../models/advertiser.model';
import { CampaignGroupModel } from '../../../../models/campaign-group.model';
import { CampaignModel, ICampaign } from '../../../../models/campaign.model';
import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const BasicInfo: FC = observer(() => {
	const { campaignStore, advertiserStore, campaignGroupsStore } = useStores<IAdminxStore>();

	const advertiserCrudStore = advertiserStore.getCrud();
	const campaginGroupCrudStore = campaignGroupsStore.getCrud();
	const advertiserView = advertiserCrudStore.getData();
	const campaignGroupView = campaginGroupCrudStore.getData();
	const advertiserViewName = advertiserView.getName();
	const campaignGroupViewName = campaignGroupView.getName();

	const advertisers = campaignStore.getInputOptions().getAdvertisers();
	const accounts = campaignStore.getInputOptions().getAccounts();

	const campaignCrudStore = campaignStore.getCrud();
	const formStore = campaignCrudStore.getFormStore();
	const campaign = campaignCrudStore.getData();
	const advertiser = campaign.getAdvertiser();
	const account = campaign.getAccount();
	const campaignGroup = campaign.getCampaignGroup();
	const campaignType = campaign.getCampaignType();
	const parentCampaign = campaign.getParentCampaign();
	const creativeSelectionMode = campaign.getCreativeSelectionMode();

	const advertisersNames = advertisers.map(_advertiser => _advertiser.getName() || '');
	const accountsNames = accounts.map(_account => _account.getName() || '');
	const campaignGroups = advertisers.find(_advertiser => _advertiser.getId() === advertiser?.getId())?.getCampaignGroups();
	const campaignGroupNames = campaignGroups?.map(_campaignGroup => _campaignGroup.getName() || '');
	const campaigns = campaignStore
		.getListStore()
		.getData()
		?.data.map(_campaign => new CampaignModel(_campaign as ICampaign));
	const parentCampaignsOptions = campaigns
		?.map(_campaign => ({ name: _campaign.getName() || '', id: _campaign.getId() }))
		.filter(parentCamp => parentCamp.id !== campaign.getId())
		.map(pc => pc.name); // TODO - remove campaigns from the same hierarchy

	useEffect(() => {
		// In case of coming from advertiser view then complete the advertiser dropdown
		if (advertiserViewName) {
			onAdvertiserSelect(advertiserViewName);
		}
	}, [advertiserViewName, advertisers]);

	useEffect(() => {
		// In case of coming from campaign group view then complete the advertiser dropdown
		if (campaignGroupViewName) {
			onCampaignGroupSelect(campaignGroupViewName);
		} // TODO - check
	}, [advertiser, advertiserViewName, campaignGroupViewName]);

	const onAdvertiserSelect = (advertiserName: option) => {
		const _advertiser = advertisers.find(_advertiser => _advertiser.getName() === advertiserName);
		if (_advertiser) {
			campaign.setAdvertiser(new AdvertiserModel(_advertiser));
			campaign.setCampaignGroup(undefined);
		}
	};

	const onAccountSelect = (accountName: option) => {
		const _account = accounts.find(_account => _account.getName() === accountName);
		if (_account) {
			campaign.setAccount(_account);
		}
	};

	const onCampaignGroupSelect = (campaignGroupName: option) => {
		if (!advertiser) {
			// TODO - log
			return null;
		}
		const campaignGroup = campaignGroups ? campaignGroups.find(campaignGroup => campaignGroup.name === campaignGroupName) : undefined;
		if (!campaignGroup) {
			// TODO - log
			return null;
		}
		campaign.setCampaignGroup(new CampaignGroupModel(campaignGroup));
	};

	const onParentCampaignSelect = (parent: option | undefined) => {
		if (!parent) {
			return campaign.setParentCampaign(undefined);
		}

		const _parent_campaign = campaigns?.find(campaign => campaign.getName() === parent);

		if (!_parent_campaign) {
			return null;
		}
		campaign.setParentCampaign(new CampaignModel({ id: _parent_campaign.id || 0, name: _parent_campaign.name || '' }));
	};

	return (
		<Card
			id={'basicInfo'}
			title={'Basic Info'}
			icon={'settings-01'}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'basic_info_section')] }}>
			<Input
				value={campaign.getName()}
				placeholder={'Campaign Name'}
				onValue={value => {
					campaign.setName(value.toLowerCase().replaceAll(' ', '_'));
					formStore.clearError('name');
				}}
				required={true}
				error={formStore.getErrors().get('account')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'name')] }}
			/>
			<Dropdown
				required={true}
				defaultOption={account?.getName()}
				label={'Select an Account'}
				options={accountsNames}
				onSelect={(accountsNames: Set<option> | undefined) => onAccountSelect(accountsNames?.values().next().value)}
				error={formStore.getErrors().get('account')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'account')] }}
			/>
			<Dropdown
				required={true}
				defaultOption={advertiser?.getName()}
				label={'Select an Advertiser'}
				disabled={Boolean(advertiserView?.getName())}
				options={advertisersNames}
				onSelect={(advertiserName: Set<option> | undefined) => onAdvertiserSelect(advertiserName?.values().next().value)}
				error={formStore.getErrors().get('advertiser')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'advertiser')] }}
			/>
			<Dropdown
				required={true}
				defaultOption={campaignGroup?.getName()}
				disabled={!advertiser || Boolean(campaignGroupView?.getName())}
				label={'Select Campaign Group'}
				options={campaignGroupNames || []}
				onSelect={(campaignGroupName: Set<option> | undefined) => onCampaignGroupSelect(campaignGroupName?.values().next().value)}
				error={formStore.getErrors().get('campaign_group')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_group')] }}
			/>
			<Dropdown
				defaultOption={parentCampaign?.getName()}
				label={'Parent Campaign'}
				options={parentCampaignsOptions || []}
				onSelect={(campaign: Set<option> | undefined) => onParentCampaignSelect(campaign?.values().next().value)}
				error={formStore.getErrors().get('parent_campaign')?.getMsg()}
				autocomplete={true}
				autocompletePlaceholder={'Search Campaign'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'parent_campaign')] }}
			/>
			<Dropdown
				label={'Select Cache & bid Key Generator'}
				required={true}
				defaultOption={campaign.getCacheKeyGenerator()}
				options={campaignStore.getInputOptions().getKeyGenerators()}
				onSelect={(cacheKeyGenerator: Set<option> | undefined) => {
					campaign.setCacheKeyGenerator(cacheKeyGenerator?.values().next().value);
					formStore.clearError('cache_key_generator');
				}}
				error={formStore.getErrors().get('cache_key_generator')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'cache_key_generator')] }}
			/>
			<Input
				type={'number'}
				value={campaign.getPriority()?.toString()}
				placeholder={'Priority'}
				onValue={value => campaign.setPriority(parseInt(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'priority')] }}
			/>
			<Input
				type={'number'}
				value={campaign.getSecondaryPriority()?.toString()}
				placeholder={'2nd Priority'}
				onValue={value => campaign.setSecondaryPriority(parseInt(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', '2nd_priority')] }}
			/>
			<Spacer />
			<ButtonGroup
				title={'Campaign Type'}
				required={true}
				defaultValue={campaignType}
				error={formStore.getErrors().get('campaign_type')?.getMsg()}
				onChange={setValues => {
					campaign.setCampaignType(setValues.values().next().value);
					formStore.clearError('campaign_type');
				}}>
				<GButton
					icon={'looks-one'}
					text={CampaignTypes.CPA}
					value={CampaignTypes.CPA}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_type_cpa')] }}
				/>
				<GButton
					icon={'1k'}
					text={CampaignTypes.CPM}
					value={CampaignTypes.CPM}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_type_cpm')] }}
				/>
			</ButtonGroup>
			<Spacer />
			<ButtonGroup
				title={'Creative Selection Modes'}
				defaultValues={creativeSelectionMode ? new Set([creativeSelectionMode]) : undefined}
				error={formStore.getErrors().get('creative_selection_mode')?.getMsg()}
				onChange={setValues => {
					campaign.setCreativeSelectionMode(setValues.values().next().value);
					formStore.clearError('creative_selection_mode');
				}}>
				<GButton
					icon={'slash-circle-01'}
					text={CreativeSelectionModes.Off}
					value={CreativeSelectionModes.Off}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_selection_off')] }}></GButton>
				<GButton
					icon={'edit-05'}
					text={CreativeSelectionModes.Format}
					value={CreativeSelectionModes.Format}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_selection_format')] }}></GButton>
				<GButton
					icon={'design-services'}
					text={CreativeSelectionModes.Design}
					value={CreativeSelectionModes.Design}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_selection_design')] }}></GButton>
				<GButton
					icon={'circle'}
					text={CreativeSelectionModes.Full}
					value={CreativeSelectionModes.Full}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_selection_full')] }}></GButton>
			</ButtonGroup>
		</Card>
	);
});
