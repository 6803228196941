import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useBidderCampaignTableOfContents = () => {
	const [isTableOfContents, setIsTableOfContents] = useState<boolean>(false);
	const location = useLocation();

	useEffect(() => {
		return () => setIsTableOfContents(false);
	}, [location]);

	return {
		isTableOfContents,
		tableOfContents: [
			{ id: 'basicInfo', element: 'Basic Info' },
			{ id: 'bidderTargetings', element: 'Targetings' },
			{ id: 'bidderDomainLimits', element: 'Bidder Domain Limits' },
		],
		setIsTableOfContents,
	};
};
