import { ReactNode } from 'react';
import styles from './quick-actions-menu.module.scss';

interface IQuickActionButton {
	children: ReactNode;
}
export const QuickActionButton = (props: IQuickActionButton) => {
	const { children } = props;
	return <div className={styles.quickActionButton}>{children}</div>;
};
