import { observer } from 'mobx-react';
import { FC } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Distributions } from '../../../../enums/distribution';
import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const Budget: FC = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const dailyBudget = campaign.getDailyBudget();
	const distribution = dailyBudget?.getDistribution();
	const limit = dailyBudget?.getLimit();

	return (
		<Card
			id={'dailyBudget'}
			title={'Daily Budget'}
			icon={'currency-dollar-circle'}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'daily_budget_section')] }}>
			<Spacer y={2} />
			<ButtonGroup
				defaultValue={distribution}
				title={'Distribution'}
				onChange={(setValues: Set<string>) => dailyBudget?.setDistribution(setValues.values().next().value)}>
				<GButton
					icon={'directions-run'}
					text={Distributions.Eager}
					value={Distributions.Eager}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'distribution_eager')] }}
				/>
				<GButton
					icon={'scales-02'}
					text={Distributions.Evenly}
					value={Distributions.Evenly}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'distribution_evenly')] }}
				/>
			</ButtonGroup>
			<Input
				type={'number'}
				icon={'currency-dollar'}
				value={limit?.toString()}
				placeholder={'Limit'}
				onValue={value => dailyBudget?.setLimit(parseInt(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'daily_budget')] }}
			/>
		</Card>
	);
});
