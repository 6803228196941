import { useEffect, useState } from 'react';
import { ask, IResponseInterceptor } from '@monorepo/tools/src/lib/tools/ask/ask';
import { isUnauthorizedError } from '@monorepo/tools/src/lib/tools/ask/guards';
import { HttpStatuses } from '@monorepo/tools/src/lib/enums/http-statuses';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { HttpError, IHttpError } from '../../models/http-error.model';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { HttpErrorModal } from '@monorepo/controlled/src/components/modal/modals/http-error-modal/http-error-modal';
import { IAdminxStore } from '../../stores';

// TODO - probably will need to change this functionality
const httpErrorModalStatuses = [
	HttpStatuses.NOT_ACCEPTABLE,
	HttpStatuses.METHOD_NOT_ALLOWED,
	HttpStatuses.NOT_IMPLEMENTED, // TODO- new modal for this?
	HttpStatuses.NOT_FOUND, // TODO- new modal for this?
];

const inlineErrorStatuses = [HttpStatuses.BAD_REQUEST];

export const ErrorsContainer = () => {
	const { modalsStore, authStore, userStore } = useStores<IAdminxStore>();
	const [isErrorModal, setErrorModal] = useState<boolean>(false);
	const [error, setError] = useState<HttpError | null>(null);

	const getErrorModal = () => {
		if (!isErrorModal) {
			return null;
		}

		const httpStatus = error?.httpStatus;
		if (httpStatus) {
			if (httpErrorModalStatuses.includes(httpStatus)) {
				return <HttpErrorModal message={error.getMessage()} />;
			}
			if (inlineErrorStatuses.includes(httpStatus)) {
				return null;
			}
		}
		return null;
	};

	useEffect(() => {
		if (isErrorModal) {
			const ErrorModal = getErrorModal();
			if (!ErrorModal) {
				return;
			}
			modalsStore.addModal({
				children: (
					<ActionModal type={ModalTypes.Error} primaryBtnTitle={'Ok'} onClose={() => setErrorModal(false)}>
						{ErrorModal}
					</ActionModal>
				),
			});
		}
	}, [isErrorModal]);
	const forbiddenInterceptor: IResponseInterceptor = {
		error: (response, data) => {
			const err = new HttpError({ httpStatus: response?.status, message: data?.message || '' } as IHttpError);
			// if (isAbortError(err)) {
			// 	return;
			// }

			if (isUnauthorizedError(err)) {
				authStore.removeToken(false, { error: err });
				userStore.removeUser();

				if (!isErrorModal) {
					setErrorModal(true);
					setError(error);
				}
			}
		},
	};
	ask.interceptors.responses.set('Forbidden', forbiddenInterceptor);
	useEffect(() => {
		return () => setErrorModal(false);
	}, []);

	return null;
};
