import { CrudEndpoint } from './crud.endpoint';

export class TestsEndpoint extends CrudEndpoint {
	constructor() {
		super('tests');
	}

	enableTests() {
		return `${this.baseUrl}/status/ACTIVE`;
	}
	pauseTests() {
		return `${this.baseUrl}/status/PAUSED`;
	}
}
