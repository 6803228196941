import { Directions } from '@monorepo/base/src/components/buttons/buttons';
import { action, makeAutoObservable, observable } from 'mobx';
import { ReactElement, RefObject } from 'react';
import { Alignments, TooltipThemes } from '../components/tooltip/tooltip/tooltip';

export type Tooltip = {
	hoverRef: RefObject<HTMLElement>;
	content: ReactElement | string;
	delay: number;
	theme: TooltipThemes;
	is: boolean;
	isPositionFixed: boolean;
	align?: Alignments;
	direction?: Directions;
	forceHover?: boolean;
	isHoveredOnTooltip?: boolean;
	maxHeight?: number;
};

interface ITooltips {
	tooltip: Tooltip[];
}

export class TooltipsStore implements ITooltips {
	tooltip: Tooltip[] = [];

	constructor() {
		makeAutoObservable(this, {
			tooltip: observable,
			addTooltip: action,
		});
	}

	getTooltips(): Tooltip[] {
		return this.tooltip;
	}

	addTooltip(tooltip: Tooltip) {
		this.tooltip?.push(tooltip);
	}

	removeTooltips() {
		this.tooltip = [];
	}
}
