import { id } from './../../../../tools/src/lib/types/primitives';
import { makeAutoObservable } from 'mobx';
import { randomNumber } from '@monorepo/tools/src/lib/utils/number';

export interface ISegmentation {
	id: id;
	auction_package_id?: id;
	auction_package_name?: string;
	source_cid?: id;
	target_cid?: id;
}

export interface ICampaignSegmentations {
	id?: id;
	campaignId?: id;
	segmentations?: ISegmentation[];
}

export type ICampaignSegmentationCreateForm = CampaignSegmentationsModel;
export type ICampaignSegmentationEditForm = CampaignSegmentationsModel;

export class SegmentationModel implements ISegmentation {
	id: id;
	auction_package_id?: id;
	auction_package_name?: string;
	source_cid?: id;
	target_cid?: id;

	constructor(props: ISegmentation) {
		this.id = props.id;
		this.auction_package_id = props.auction_package_id;
		this.auction_package_name = props.auction_package_name;
		this.source_cid = props.source_cid;
		this.target_cid = props.target_cid;
		makeAutoObservable(this);
	}

	public getId(): id {
		return this.id;
	}

	public getAuctionPackageId(): id | undefined {
		return this.auction_package_id;
	}

	public getAuctionPackageName(): string | undefined {
		return this.auction_package_name;
	}

	public getSourceCid(): id | undefined {
		return this.source_cid;
	}

	public getTargetCid(): id | undefined {
		return this.target_cid;
	}

	public setAuctionPackageId(auction_package_id: id) {
		this.auction_package_id = auction_package_id;
	}

	public setAuctionPackageName(auction_package_name: string) {
		this.auction_package_name = auction_package_name;
	}

	public setSourceCid(source_cid: id) {
		this.source_cid = source_cid;
	}

	public setTargetCid(target_cid: id) {
		this.target_cid = target_cid;
	}
}

export class CampaignSegmentationsModel implements ICampaignSegmentations {
	id?: id;
	campaignId?: id;
	segmentations?: SegmentationModel[];

	constructor(props?: ICampaignSegmentations) {
		this.id = props?.id;
		this.campaignId = props?.campaignId;
		this.segmentations =
			props?.segmentations?.map(
				segment => new SegmentationModel({ ...segment, id: segment?.id || parseInt(randomNumber(111, 444, 0)) })
			) || [];
		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public getCampaignId(): id | undefined {
		return this.campaignId;
	}

	public setCampaignId(campaignId: id) {
		this.campaignId = campaignId;
	}

	public getSegmentations(): SegmentationModel[] | undefined {
		return this.segmentations;
	}

	public setSegmentations(segmentations: SegmentationModel[]) {
		this.segmentations = segmentations;
	}

	public addSegmentation() {
		this.segmentations?.push(new SegmentationModel({ id: parseInt(randomNumber(111, 444, 0)) }));
	}

	public removeSegmentation(segmentId: id) {
		this.segmentations = this.segmentations?.filter(segment => segment.getId() !== segmentId);
	}
}
