import { makeAutoObservable } from 'mobx';
import { CidsCrudStore } from './cids-crud.store';

export class CidsStore {
	crud = new CidsCrudStore();

	constructor() {
		makeAutoObservable(this);
	}

	getCrud() {
		return this.crud;
	}
}
