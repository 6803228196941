import { makeAutoObservable } from 'mobx';

export interface IBanner {
	size?: string;
	fileExtension?: string;
	creative?: File;
	resource_url?: string;
}

export class BannerModel implements IBanner {
	size?: string;
	fileExtension?: string;
	creative?: File;
	resource_url?: string;

	constructor(banner?: IBanner) {
		this.size = banner?.size;
		this.fileExtension = banner?.fileExtension;
		this.creative = banner?.creative;
		this.resource_url = banner?.resource_url;

		makeAutoObservable(this);
	}

	getSize(): string | undefined {
		return this.size;
	}

	getFileExtension(): string | undefined {
		return this.fileExtension;
	}

	getResourceUrl(): string | undefined {
		return this.resource_url;
	}

	setResourceUrl(resource_url: string): void {
		this.resource_url = resource_url;
	}

	getCreative(): File | undefined {
		return this.creative;
	}
}
