/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FilterModel, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { observer } from 'mobx-react';
import { FilterEnumMenu } from '@monorepo/base/src/components/filters/filter-enum-menu/filter-enum-menu';
import { enumAction, enumFuncs, stringAction, stringFuncs } from '@monorepo/tools/src/lib/utils/string';
import { SelectOptions } from '@monorepo/base/src/components/select-new/select-new';

interface IEnumFilterProto {
	cellKey: string;
	columnName: string;
	enumOptions: SelectOptions[];
	deletable?: boolean;
	entity?: string;
	multi?: boolean;
}

interface IEnumFilterComponentProps extends IFilterComponentProps {
	cellKey: string;
	columnName: string;
	enumOptions: SelectOptions[];
	deletable?: boolean;
	entity?: string;
	multi?: boolean;
}

const EnumsFilter = (props: IEnumFilterComponentProps) => {
	const { filter, onCancel, onApply, addFilter, editFilter, columnName, enumOptions, cellKey, deletable = false, entity, multi } = props;
	const protoObj = EnumFilterPrototype({ columnName, enumOptions, deletable, cellKey, entity, multi });

	const onAddEnumFilter = (_action: enumAction, _value: (string | number)[]) => {
		const setValue = (value: (string | number)[]) => {
			return value.map(val => {
				val = val.toString().trim();
				return val;
			});
		};

		const enumFilter = new FilterModel({
			label: `${columnName} ${_action} to ${typeof _value === 'string' ? _value : _value.join(', ')}`,
			action: _action,
			value: setValue(_value),
			prototype: protoObj,
		});

		if (filter?.index && editFilter) {
			editFilter(filter.index, enumFilter);
		} else {
			addFilter(enumFilter);
		}

		onApply();
	};

	const getDefaultValue = () => {
		if (filter?.value) {
			if (typeof filter?.value === 'string') {
				return filter?.value.split(',');
			}

			if (Array.isArray(filter?.value)) {
				return filter?.value;
			}
		}

		return [];
	};

	return (
		<FilterEnumMenu
			title={columnName}
			defaultValues={getDefaultValue()}
			defaultAction={filter?.action}
			onApply={onAddEnumFilter}
			onCancel={onCancel}
			enumOptions={enumOptions}
			multi={multi}
			isSelectAll={false}
		/>
	);
};

export const EnumFilterPrototype = (props: IEnumFilterProto): IFilterProto => {
	const { columnName, enumOptions, deletable, cellKey, entity, multi } = props;

	return {
		MenuComponent: columnName,
		FilterComponent: observer((props: IFilterComponentProps) =>
			EnumsFilter({
				...props,
				columnName,
				enumOptions,
				cellKey,
				deletable,
				entity,
				multi,
			})
		),
		prop: `${entity}${cellKey}`,
		multi,
		deletable,
		filterFunc<T>(models: Array<T>, filter: FilterModel): Array<T> {
			const { value, action } = filter;
			return models.filter(model => {
				if (typeof value === 'number') {
					return;
				}
				const actionFunc = stringFuncs[action as stringAction];

				if (!actionFunc) {
					return true;
				}

				if (typeof value === 'string') {
					//@ts-ignore
					return model[cellKey]?.toString()?.toLowerCase() === value?.toLowerCase();
				}

				return value.some(val => {
					if (val.length === 0) {
						return false;
					}

					//@ts-ignore
					if (model[cellKey] !== null) {
						//@ts-ignore
						if (typeof model[cellKey] === 'string') {
							//@ts-ignore
							return actionFunc(model[cellKey], val);
							//@ts-ignore
						} else if (Array.isArray(model[cellKey])) {
							//@ts-ignore
							return enumFuncs[action as enumAction](model[cellKey], val);
						}
					}
				});
			});
		},
	};
};
