import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { JobsApi } from '../../apis/job.api';
import { HttpError } from '../../models/http-error.model';
import { JobModel, IJobCreateForm, IJobEditForm } from '../../models/job.model';

export class JobCrudStore extends BaseCrudStore<JobModel, IJobCreateForm, IJobEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: JobsApi,
			model: JobModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidJob before calling this function
	 * @returns
	 */
	public getCreateFormData(): IJobCreateForm {
		return {
			name: this.getData().getName(),
			task_classes: this.getData().getTaskClasses(),
			cron_expression: this.getData().getCronExpression(),
			is_active: this.getData().getIsActive(),
			run_on_init: this.getData().getRunOnInit(),
			max_retries: this.getData().getMaxRetries(),
			retry_timeout: this.getData().getRetryTimeout(),
			refresh_rate: this.getData().getRefreshRate(),
		};
	}

	/**
	 * Must call isValidJob before calling this function
	 * @returns
	 */
	public getEditFormData(): IJobEditForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			task_classes: this.getData().getTaskClasses(),
			cron_expression: this.getData().getCronExpression(),
			is_active: this.getData().getIsActive(),
			run_on_init: this.getData().getRunOnInit(),
			max_retries: this.getData().getMaxRetries(),
			retry_timeout: this.getData().getRetryTimeout(),
			refresh_rate: this.getData().getRefreshRate(),
		};
	}

	public isValid(): boolean {
		const name = this.getData().getName();
		if (!name) {
			this.formStore.addError(new FormError('name', 'Please provide a job name'));
		}

		const task_classes = this.getData().getTaskClasses();
		if (!task_classes) {
			this.formStore.addError(new FormError('task_classes', 'Please provide a task'));
		}

		const cron_expression = this.getData().getCronExpression();
		if (!cron_expression) {
			this.formStore.addError(new FormError('cron_expression', 'Please provide a cron_expression'));
		}

		const max_retries = this.getData().getMaxRetries();
		if (typeof max_retries !== 'number') {
			this.formStore.addError(new FormError('max_retries', 'Please provide a max_retries'));
		}

		const retry_timeout = this.getData().getRetryTimeout();
		if (typeof retry_timeout !== 'number') {
			this.formStore.addError(new FormError('retry_timeout', 'Please provide a retry_timeout'));
		}

		const refresh_rate = this.getData().getRefreshRate();
		if (typeof refresh_rate !== 'number') {
			this.formStore.addError(new FormError('refresh_rate', 'Please provide a refresh_rate'));
		}

		return this.formStore.getIsValid();
	}
}
