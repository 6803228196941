import { id } from '@monorepo/tools/src/lib/types/primitives';
import { randomString } from '@monorepo/tools/src/lib/utils/string';

export interface IDomainRoleForm {
	domain?: id;
	roles?: string[];
	errorMessages?: {
		entityErrorMessage?: string;
		rolesErrorMessage?: string;
	};
	key?: string;
}

export class DomainRoleForm implements IDomainRoleForm {
	domain?: id;
	roles?: string[];
	errorMessages?: {
		entityErrorMessage?: string;
		rolesErrorMessage?: string;
	};
	key?: string;

	constructor(domainRole: IDomainRoleForm) {
		this.domain = domainRole.domain;
		this.roles = domainRole.roles;
		this.errorMessages = domainRole?.errorMessages || {};
		this.key = randomString(5);
	}
}
