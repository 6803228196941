import ReportStore from '../data-stores/old-report.store';
import { ReportFilter, ReportParams, ReportSortOrder } from '../data-stores/old-report.types';
import { OnChangeFn, PaginationState, RowSelectionState, SortingState, VisibilityState } from '@tanstack/react-table';
import { ITableState } from '../../table-state.store';
import { TableStore } from './table.store';
import { IDataType } from '../data-stores/data-store.types';
import { ColumnConfig } from '../../columns/columns';

export interface IReportTableStoreParams {
	reportParams: Partial<ReportParams>;
	columns: ColumnConfig[];
	tableState?: Partial<ITableState>;
	isGraphAvailable: boolean;
	tableName: string;
}

export class ReportTableStore<T = IDataType> extends TableStore<T, ReportParams> {
	constructor({ reportParams, columns, isGraphAvailable, tableState = {}, tableName }: IReportTableStoreParams) {
		super({
			columns,
			tableName,
			tableState: {
				sorting: reportParams.sortBy ? [{ id: reportParams.sortBy, desc: reportParams.sortOrder === ReportSortOrder.Desc }] : [],
				filters: reportParams.filters ?? [],
				...tableState,
			},
			dataStore: new ReportStore<T>({
				reportParams: {
					...reportParams,
					groupBys: Array.from(
						new Set(
							columns
								.filter(
									col =>
										col.isAlwaysHidden() ||
										col.isAlwaysVisible() ||
										col.type === 'data' ||
										(tableState?.columnVisibility?.[col.accessor] && col.type === 'dimensions')
								)
								.reduce((acc, col) => {
									acc.push(col.name, ...(col.linkTo || []));
									return acc;
								}, [] as string[])
						)
					),
				},
				isGraphAvailable,
			}),
		});
	}

	getParamsFromState = (): Partial<ReportParams> => {
		const { pagination, sorting, columnVisibility, filters } = this._tableState;
		const groupBys = this.columns
			.filter(
				col =>
					col.isAlwaysHidden() ||
					col.isAlwaysVisible() ||
					col.type === 'data' ||
					(columnVisibility[col.accessor] && col.type !== 'metrics')
			)
			.reduce((acc, col) => {
				acc.push(col.name, ...(col.linkTo?.filter(lt => !!this.columns.find(({ name }) => name === lt)) || []));
				return acc;
			}, [] as string[]);
		return {
			start: pagination.pageIndex * pagination.pageSize,
			limit: Math.max(pagination.pageSize, this.dataStore.reportParams.limit),
			sortBy: this.columns.find(({ accessor }) => accessor === sorting[0]?.id)?.name ?? sorting[0]?.id,
			sortOrder: sorting[0]?.desc ? ReportSortOrder.Desc : ReportSortOrder.Asc,
			groupBys: Array.from(new Set(groupBys)),
			filters,
		};
	};

	fetchReport = async (reportParams: Partial<ReportParams>, appendData = false) => {
		const params = { ...this.getParamsFromState(), ...reportParams };
		await this.dataStore.fetchReport({ params, appendData });
	};

	onSortingChange: OnChangeFn<SortingState> = sorting => {
		const newSorting = typeof sorting === 'function' ? sorting(this.tableState.sorting) : sorting;
		if (this.dataStore.getTotalRows() <= (this.dataStore.getReportData?.() ?? []).length) {
			this.updateTableState({ sorting: newSorting });
			return;
		}
		if (newSorting.length === 0) {
			this.fetchReport({
				sortBy: this.dataStore.defaultReportParams.sortBy,
				sortOrder: this.dataStore.defaultReportParams.sortOrder,
			}).then(() => {
				const defaultSorting = [
					{
						id:
							this.columns.find(c => c.name === this.dataStore.defaultReportParams.sortBy)?.accessor ??
							this.dataStore.defaultReportParams.sortBy,
						desc: this.dataStore.defaultReportParams.sortOrder === ReportSortOrder.Desc,
					},
				];
				this.updateTableState({ sorting: defaultSorting });
			});
			return;
		}
		const column = this.columns.find(col => col.accessor === newSorting[0]?.id);
		if (column) {
			this.fetchReport({ sortBy: column.name, sortOrder: newSorting[0].desc ? ReportSortOrder.Desc : ReportSortOrder.Asc }).then(
				() => {
					this.updateTableState({ sorting: newSorting });
				}
			);
		}
	};

	onColumnVisibilityChange: OnChangeFn<VisibilityState> = columnVis => {
		const columnVisibility = typeof columnVis === 'function' ? columnVis(this.tableState.columnVisibility) : columnVis;
		const newGroupBys: string[] = [];
		this.columns.forEach(col => {
			const columnsLinkedTo = col.linkTo || [];
			if (
				col.isAlwaysHidden() ||
				col.isAlwaysVisible() ||
				col.type === 'data' ||
				(columnVisibility[col.accessor] && col.type !== 'metrics')
			) {
				newGroupBys.push(col.name);
				newGroupBys.push(...columnsLinkedTo);
			}
		});
		const groupBys = Array.from(new Set(newGroupBys));
		if (groupBys.length !== this.dataStore.reportParams.groupBys.length) {
			this.fetchReport({ groupBys });
			this.updateTableState({ rowSelection: {} });
		}

		this.updateTableState({ columnVisibility });
	};

	onPaginationChange: OnChangeFn<PaginationState> = pagination => {
		const newPagination = typeof pagination === 'function' ? pagination(this.tableState.pagination) : pagination;
		this.updateTableState({ pagination: newPagination });
		const newStart = newPagination.pageIndex * newPagination.pageSize;
		const newLimit = newPagination.pageSize;
		const oldLimit = this.dataStore.reportParams.start + this.dataStore.reportParams.limit;
		if (newStart >= oldLimit || newLimit > oldLimit) {
			this.fetchReport(
				{
					start: newStart,
					limit: Math.max(newPagination.pageSize, this.dataStore.reportParams.limit),
				},
				true
			);
		}
	};

	onRowSelectionChange: OnChangeFn<RowSelectionState> = rowSelection => {
		const newRowSelection = typeof rowSelection === 'function' ? rowSelection(this.tableState.rowSelection) : rowSelection;
		this.updateTableState({ rowSelection: newRowSelection });
	};

	getSelectedRows = () => {
		const rowSelection = this.tableState.rowSelection;
		const selectedRowsTableId = Object.keys(rowSelection).filter(rowId => rowSelection[rowId]);
		return this.dataStore.getReportData().filter(row => selectedRowsTableId.includes(this.getRowId(row)));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getRowId = (row: any) => {
		let rowId = '';
		this.dataStore.reportParams.groupBys.forEach(groupBy => {
			const accessor = this.columns.find(col => col.name === groupBy)?.accessor;
			if (accessor && row[accessor]) {
				rowId += `${row[accessor]}_`;
			}
		});
		return rowId;
	};

	onFilterChange = (filters: ReportFilter[]) => {
		this.updateTableState({ filters });
		this.fetchReport({ filters });
	};

	get dataStore(): ReportStore<T> {
		return this._dataStore as ReportStore<T>;
	}

	set dataStore(value: ReportStore<T>) {
		this._dataStore = value;
	}

	setHiddenFilters = (filters: ReportFilter[]) => {
		this.dataStore.setHiddenFilters(filters);
	};

	downloadCsv = () => {
		this.dataStore.downloadReportFileCsv();
	};
}
