import { RefObject, useState } from 'react';

// See: https://usehooks-ts.com/react-hook/use-event-listener
import useEventListener from './use-event-listener';

export function useHover<T extends HTMLElement = HTMLElement>(elementRef: RefObject<T>): boolean {
	const [value, setValue] = useState<boolean>(false);

	const handleMouseEnter = () => setValue(true);
	const handleMouseLeave = () => setValue(false);

	useEventListener('mouseenter', handleMouseEnter, elementRef);
	useEventListener('mouseleave', handleMouseLeave, elementRef);

	return value;
}
