import { Card } from '@monorepo/base/src/components/card/card';
import { observer } from 'mobx-react';
import styles from './bidder-targeting.module.scss';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Hint } from '@monorepo/base/src/components/form/hint/hint';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../stores';
import { FormError } from '@monorepo/base/src/components/form/form-error/form-error';
import { ButtonGroup, GButton } from '@monorepo/base/src/components/buttons/group/button-group';
import { bidderCampaignTypeToTitle, CampaignTargetingLinkChoicePolicy } from '../../../../models/bidder-campaign/bidder-campaign.types';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Statuses } from '../../../../enums/status';
import { sentenceCase } from 'change-case';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { BidderCampaignTargetingLinkModel } from '../../../../models/bidder-campaign/bidder-campaign-targeting-link.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Fragment } from 'react';
import { TargetingLink } from './bidder-targeting-links';
import { Icon } from '@monorepo/base/src/components/icon/icon';

export const BidderTargeting = observer(() => {
	const { bidderStore } = useStores<IAdminxStore>();
	const campaignCrudStore = bidderStore.getCrud();
	const formStore = campaignCrudStore.getFormStore();
	const campaign = campaignCrudStore.getData().campaign;
	const targetings = campaign.campaign_targeting || [];
	const targetingPolicy = campaign.campaign_targeting_link_choice_policy || CampaignTargetingLinkChoicePolicy.PerPublisher;
	const isPolicyPerPublisher = targetingPolicy === CampaignTargetingLinkChoicePolicy.PerPublisher;

	const publisherNamesMapById = bidderStore.publishersList.getData()?.publishers.reduce((acc, publisher) => {
		acc[publisher.id] = publisher.name;
		return acc;
	}, {} as Record<id, string>);

	return (
		<div className={styles.targetings}>
			<Card
				className={styles.targetingList}
				title={`Targetings`}
				id={'bidderTargetings'}
				icon={'target-05'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'bidder_targeting_section')] }}>
				<Spacer />
				<ButtonGroup
					className={styles.performancePixelTypeWrapper}
					title={'Targeting link policy'}
					required
					defaultValue={targetingPolicy}
					error={formStore.getErrors().get('campaign_targeting_link_choice_policy')?.getMsg()}
					onChange={setValues => {
						campaign.campaign_targeting_link_choice_policy = setValues.values().next().value;
						formStore.clearError('campaign_targeting_link_choice_policy');
					}}>
					<GButton
						text={bidderCampaignTypeToTitle.get(CampaignTargetingLinkChoicePolicy.PerPublisher)}
						value={CampaignTargetingLinkChoicePolicy.PerPublisher}
					/>
					<GButton
						text={bidderCampaignTypeToTitle.get(CampaignTargetingLinkChoicePolicy.ByWeight)}
						value={CampaignTargetingLinkChoicePolicy.ByWeight}
					/>
					<GButton
						text={bidderCampaignTypeToTitle.get(CampaignTargetingLinkChoicePolicy.WithConversionLimit)}
						value={CampaignTargetingLinkChoicePolicy.WithConversionLimit}
					/>
				</ButtonGroup>
				<Spacer />
				{targetings.map(targeting => {
					const existsTargetingLinks = targeting.campaign_targeting_links.filter(({ id }) => id !== 0);
					const newTargetingLinks = targeting.campaign_targeting_links.filter(({ id }) => id === 0);
					const targetingLinkWithPublisherId = existsTargetingLinks.filter(link => link.pid !== '');
					const targetingLinkWithoutPublisherId = existsTargetingLinks.filter(link => link.pid === '');
					const targetingLinkGrouped = targetingLinkWithPublisherId.reduce((acc, link) => {
						const indexKey = isPolicyPerPublisher ? link.pid : link.id;
						if (indexKey === undefined) {
							return acc;
						}
						if (!acc[indexKey]) {
							acc[indexKey] = [];
						}
						acc[indexKey].push(link);
						return acc;
					}, {} as Record<string, BidderCampaignTargetingLinkModel[]>);
					return (
						<Card className={styles.targetingItem} title={`Targeting - ${targeting.id}`} key={targeting?.id}>
							<Toggle
								label={targeting.status === Statuses.Active ? sentenceCase(Statuses.Active) : sentenceCase(Statuses.Paused)}
								onChange={status => {
									targeting.status = status ? Statuses.Active : Statuses.Paused;
								}}
								defaultValue={targeting.status === Statuses.Active}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_status')] }}
							/>
							<div className={styles.inputWrapper}>
								<Input
									className={`${styles.inputWithHint}`}
									value={targeting.black_hour}
									placeholder={'Black Hour'}
									onValue={value => {
										targeting.black_hour = value;
										formStore.clearError('blackHour');
									}}
									debugProps={{ dataAttrs: [new DataAttribute('id', 'black_hour')] }}
								/>
								<Hint>Each hour should be separated by a comma (“,”)</Hint>
								<FormError className={styles.fromError} msg={formStore.getErrors().get('black_hour')?.getMsg()} />
							</div>
							<div className={styles.inputsWrapper}>
								<div className={styles.inputWrapper}>
									<Input
										type={'number'}
										className={`${styles.inputWithHint}`}
										value={targeting.estimated_rate ? targeting.estimated_rate?.toString() : '0'}
										placeholder={'Estimated Rate'}
										onValue={value => {
											targeting.estimated_rate = parseFloat(value);
											formStore.clearError('estimated_rate');
										}}
										debugProps={{ dataAttrs: [new DataAttribute('id', 'estimated_rate')] }}
									/>
									<FormError className={styles.fromError} msg={formStore.getErrors().get('estimatedRate')?.getMsg()} />
								</div>
								<div className={styles.inputWrapper}>
									<Input
										type={'number'}
										className={`${styles.inputWithHint}`}
										value={targeting.q_estimated_rate ? targeting.q_estimated_rate?.toString() : '0'}
										placeholder={'Q Estimated Rate'}
										onValue={value => {
											targeting.q_estimated_rate = parseFloat(value);
											formStore.clearError('q_estimated_rate');
										}}
										debugProps={{ dataAttrs: [new DataAttribute('id', 'q_estimated_rate')] }}
									/>
									<FormError className={styles.fromError} msg={formStore.getErrors().get('qEstimatedRate')?.getMsg()} />
								</div>
							</div>
							{isPolicyPerPublisher ? (
								<Fragment>
									{Object.keys(targetingLinkGrouped).map(key => {
										const targetingLinks = targetingLinkGrouped[key];
										const publisherName = publisherNamesMapById && publisherNamesMapById[key];
										return (
											<Card
												className={styles.targetingLinkWrapper}
												collapsible
												title={`Publisher - ${publisherName} (${key})`}
												key={key}>
												<Spacer />
												{targetingLinks.map((link, index) => {
													return (
														<Fragment key={link.id}>
															<TargetingLink policy={targetingPolicy} targetingLink={link} />
															{targetingLinks.length - 1 > index && <Spacer />}
														</Fragment>
													);
												})}
											</Card>
										);
									})}
									{targetingLinkWithoutPublisherId.map(link => {
										return <TargetingLink key={link.id} policy={targetingPolicy} targetingLink={link} />;
									})}
								</Fragment>
							) : (
								existsTargetingLinks.map(link => {
									return <TargetingLink key={link.id} policy={targetingPolicy} targetingLink={link} />;
								})
							)}
							{newTargetingLinks.map(link => {
								return <TargetingLink key={link.id} policy={targetingPolicy} targetingLink={link} />;
							})}
							<div className={styles.createButtonWrapper}>
								<Icon size={'1.5rem'} className={styles.addIconButton} isMFP onClick={() => targeting.addNewLink()}>
									plus
								</Icon>
							</div>
						</Card>
					);
				})}
				<Spacer />
			</Card>
		</div>
	);
});
