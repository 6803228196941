import {
	ICampaignSegmentationCreateForm,
	ICampaignSegmentationEditForm,
	CampaignSegmentationsModel,
	SegmentationModel,
} from './../../../models/campaign-segmentation.model';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { CampaignSegmentationsApi } from '../../../apis/campaign-segmentations.api';
import { HttpError } from '../../../models/http-error.model';
import { runInAction } from 'mobx';
import { IAskError } from '@monorepo/tools/src/lib/tools/ask/ask';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export class CampaignSegmentationCrudStore extends BaseCrudStore<
	CampaignSegmentationsModel,
	ICampaignSegmentationCreateForm,
	ICampaignSegmentationEditForm,
	HttpError
> {
	constructor() {
		super({
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			apiLayer: CampaignSegmentationsApi,
			model: CampaignSegmentationsModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidCampaignSegmentation before calling this function
	 * @returns
	 */

	public getCreateFormData(): ICampaignSegmentationCreateForm {
		return this.getData() || [];
	}

	public getEditFormData(): ICampaignSegmentationEditForm {
		return this.getData() || [];
	}

	public isValid(): boolean {
		this.formStore.reset();
		return this.formStore.getIsValid();
	}

	public clearData(): void {
		this.data = new CampaignSegmentationsModel();
	}

	public createSegmentation(): Promise<CampaignSegmentationsModel | { id: id } | void> {
		const isValid = this.isValid();
		this.setHttpError(null);
		if (!isValid) {
			return new Promise(res => res());
		}
		this.setIsLoading(true);
		this.setIsSuccess(false);

		return CampaignSegmentationsApi.create(this.getCreateFormData())
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch((error: IAskError) => {
				if (this.onError) {
					this.onError(error.data);
				}

				runInAction(() => {
					this.setHttpError(
						this.errorModel
							? new this.errorModel({
									...error.data,
									message: error.data.message.message,
									httpStatus: error.response?.status,
							  })
							: (error as HttpError)
					);
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
			});
	}

	public getById(id: id): Promise<CampaignSegmentationsModel | SegmentationModel[] | void> {
		const isValid = this.isValid();
		this.setHttpError(null);
		if (!isValid) {
			return new Promise(res => res());
		}
		this.setIsLoading(true);

		return CampaignSegmentationsApi.get(id)
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setData(
						new CampaignSegmentationsModel({
							campaignId: id,
							segmentations: res.cid_segments,
						})
					);
				});
				return res.cid_segments;
			})
			.catch((error: IAskError) => {
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					this.setHttpError(
						this.errorModel ? new this.errorModel({ ...error.data, httpStatus: error.response?.status }) : (error as HttpError)
					);
					this.setIsLoading(false);
				});
			});
	}
}
