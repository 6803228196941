import { Fragment } from 'react';
import { id } from '@monorepo/tools/src/lib/types/primitives';

type IDetachCampaignCreative = {
	creativeIds: id[];
};
export const DetachCampaignCreativeModal = (props: IDetachCampaignCreative) => {
	const { creativeIds } = props;
	return (
		<Fragment>
			<div>Deatch the following creatives:</div>
			<span style={{ color: '#4f46e5' }}>
				{creativeIds.length < 6
					? creativeIds.map((creativeId, index) => `${index !== creativeIds.length - 1 ? creativeId + ', ' : creativeId + '.'}`)
					: `${creativeIds.length} creatives`}
			</span>
		</Fragment>
	);
};
