import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import styles from './job-form.module.scss';
import { Decisions } from '../../../enums/decisions';
import { IAdminxStore } from '../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const JobForm = observer(() => {
	const { jobStore } = useStores<IAdminxStore>();
	const jobCrudStore = jobStore.getCrud();
	const formStore = jobCrudStore.getFormStore();
	const job = jobCrudStore.getData();

	return (
		<Card debugProps={{ dataAttrs: [new DataAttribute('id', 'job_form')] }}>
			<div className={styles.JobFormWrapper}>
				<h2 className={styles.title}>
					<Icon isMFP={true} className={styles.titleIcon}>
						settings-01
					</Icon>
					{'Job Information'}
				</h2>
				<Fragment>
					<Input
						value={job.getName()}
						placeholder={'Job Name'}
						onValue={value => {
							job.setName(value);
							formStore.clearError('name');
						}}
						required={true}
						error={formStore.getErrors().get('name')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'job_name')] }}
					/>
					<Input
						value={job.getTaskClasses()}
						placeholder={'Task Classes'}
						onValue={value => {
							job.setTaskClasses(value);
							formStore.clearError('task_classes');
						}}
						required={true}
						error={formStore.getErrors().get('task_classes')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'job_task_classes')] }}
					/>
					<Input
						type={'number'}
						value={job.getMaxRetries()?.toString() || '0'}
						placeholder={'Max Retries'}
						onValue={value => {
							job.setMaxRetries(parseInt(value));
							formStore.clearError('max_retries');
						}}
						required={true}
						error={formStore.getErrors().get('max_retries')?.getMsg()}
						min={0}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'job_max_retries')] }}
					/>
					<Input
						type={'number'}
						value={job.getRetryTimeout()?.toString() || '0'}
						placeholder={'Retry Timeout'}
						onValue={value => {
							job.setRetryTimeout(parseInt(value));
							formStore.clearError('retry_timeout');
						}}
						required={true}
						error={formStore.getErrors().get('retry_timeout')?.getMsg()}
						min={0}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'job_retry_timeout')] }}
					/>
					<Input
						type={'number'}
						value={job.getRefreshRate()?.toString() || '0'}
						placeholder={'Refresh Rate'}
						onValue={value => {
							job.setRefreshRate(parseInt(value));
							formStore.clearError('refresh_rate');
						}}
						required={true}
						error={formStore.getErrors().get('refresh_rate')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'job_refresh_rate')] }}
					/>
					<Input
						type={'text'}
						value={job.getCronExpression()}
						onValue={value => {
							job.setCronExpression(value);
							formStore.clearError('cron_expression');
						}}
						placeholder={'Cron Expression'}
						required={true}
						error={formStore.getErrors().get('cron_expression')?.getMsg()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'job_cron_expression')] }}
					/>
					<SpacerY />
					<Toggle
						title={'Run On Init?'}
						label={job?.getRunOnInit() ? Decisions.Yes : Decisions.No}
						onChange={status => job?.setRunOnInit(status)}
						defaultValue={job?.getRunOnInit()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'job_run_on_init')] }}
					/>
				</Fragment>
			</div>
		</Card>
	);
});
