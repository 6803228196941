import { generateDataAttrs, DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import styles from './bar-loader.module.scss';

interface IBarLoader {
	is: boolean;
}

export const BarLoader = (props: IBarLoader) => {
	const { is } = props;
	if (!is) {
		return null;
	}

	return (
		<div className={styles.loadingLine}>
			<div className={`${styles.loadingLineInner} ${styles.loadingLineInnerTransparent}`}></div>
			<div
				className={`${styles.loadingLineInner} ${styles.loadingLineInnerColor}`}
				{...generateDataAttrs([new DataAttribute('id', 'bar_loader_attached')])}></div>
		</div>
	);
};
