import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useCampaignInputOptions } from '../../../modules/hooks/apis/use-campaign-input-options';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Fragment, useEffect, useState } from 'react';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { CampaignForm } from '../../../modules/components/forms/campaign/campaign.form';
import { observer } from 'mobx-react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import styles from './create-campaign.module.scss';
import { useList } from '../../../modules/hooks/tools/use-list';
import { CreativeModel } from '../../../modules/models/creative.model';
import { sentenceCase } from 'change-case';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { Nav, NavPage } from '@monorepo/base/src/components/nav/nav';
import { TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useCampaignTableOfContents } from '../../../modules/hooks/toc/campaign.toc';
import { IAdminxStore } from '../../../modules/stores';
import { Statuses } from '../../../modules/enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { IPerformanceOptionsSortBy, PerformancesColumnsSnakeCase, useReportsList } from '../../../modules/hooks/apis/use-reports-list';

interface ICreateCampaign {
	parentRoute?: route;
}

export const CreateCampaign = observer((props: ICreateCampaign) => {
	const { parentRoute } = props;
	const { campaignStore, creativeStore, modalsStore } = useStores<IAdminxStore>();
	const { dispatchLog } = useLogs();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const { setIsTableOfContents } = useOutletContext<{ setIsTableOfContents: (isSetTableOfContents: boolean) => void }>();
	const { tableOfContents } = useCampaignTableOfContents();
	const reportList = campaignStore.getListStore();

	const navigate = useNavigateParams();
	const location = useLocation();

	useEffect(() => {
		return () => campaignCrudStore.reset();
	}, [location]);

	useCampaignInputOptions();
	useReportsList(reportList, {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxCampaigns',
		sortBy: IPerformanceOptionsSortBy.Id,
	});
	useList<CreativeModel>(creativeStore.getListStore());

	const name = campaignCrudStore.getData().getName();
	const formStore = campaignCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = campaignCrudStore.getIsLoading();
	const isSuccess = campaignCrudStore.getIsSuccess();
	const error = campaignCrudStore.getHttpError();
	const targeting = campaign.getTargeting();

	useEffect(() => {
		if (parentRoute) {
			setIsTableOfContents(true);
		}
	}, []);

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(-1);
			dispatchLog({
				msg: `Campaign ${name} was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			campaignCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onCreateCampaign = () => {
		setFormClicked(true);
		if (targeting?.getIsSharedTargetingModified()) {
			modalsStore.addModal({
				children: (
					<ActionModal
						type={ModalTypes.Warning}
						primaryBtnTitle={'Yes, I am sure'}
						onAction={() => {
							campaignStore.getCrud().create();
						}}>
						<Fragment>Caution: Altering shared targeting impacts all associated campaigns, are you sure?</Fragment>
					</ActionModal>
				),
			});
		} else {
			campaignStore.getCrud().create();
		}
	};

	const renderPage = () => (
		<Disable is={isLoading}>
			<Page>
				<Pagebar>
					<PagebarTitle unstyled={true}>
						<div className={styles.title}>Create a campaign</div>
						<SpacerX />
						<Toggle
							label={campaign.getStatus() === Statuses.Active ? sentenceCase(Statuses.Active) : sentenceCase(Statuses.Paused)}
							onChange={status => campaign.setStatus(status ? Statuses.Active : Statuses.Paused)}
							defaultValue={campaign.getStatus() === Statuses.Active}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_status')] }}
						/>
					</PagebarTitle>
				</Pagebar>
				<CampaignForm />
			</Page>
			<BottomBar>
				{error ? <ErrorMsg error={error} /> : null}
				<BottomBarActions>
					<PrimaryButton
						onClick={() => onCreateCampaign()}
						isRotateIcon={isLoading}
						icon={isLoading ? 'loading-01' : 'send-03'}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_campaign_button')] }}>
						Create
					</PrimaryButton>
				</BottomBarActions>
			</BottomBar>
		</Disable>
	);

	if (!parentRoute) {
		return (
			<NavPage>
				<Nav>
					<TableOfContents title={'Campaign'} heads={tableOfContents} />
				</Nav>
				{renderPage()}
			</NavPage>
		);
	}

	return renderPage();
});
