/* eslint-disable @typescript-eslint/ban-ts-comment */
import { camelCase } from 'change-case';
import { PerformanceModel } from '../../models/performance.model';
import { PerformanceStore } from '../../stores/performance.store';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { ForeignKeys, SideEffectKeys } from '../apis/use-performance';
import { PerformancesColumnsSnakeCase } from '../apis/use-reports-list';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { IAdminxStore } from '../../stores';
import { Status } from '../../enums/status';

export type ModelWithPerformance<TModel> = PerformanceModel | TModel;

// TODO - wtf
export interface IMendatoryModelParams {
	id?: id;
	name?: JSX.Element | id;
	status?: Status;
	preferred_deal_id?: id;
	deal_id?: id;
	campaign_id?: id;
	campaign_group_id?: id;
	advertiser_id?: id;
	creative_id?: id;
	creative_group_id?: id;
	test_id?: id;
	publisher_id?: id;
}

interface IUseWithPerformance<TModel> {
	data: TModel[];
	key: PerformancesColumnsSnakeCase;
	foreignKey?: ForeignKeys;
	attachExtraParams?: (row: TModel) => { [key: string]: string | number | boolean | undefined | object };
	performanceStore?: PerformanceStore<TModel>;
}

interface IAggergatedData<TModel extends IMendatoryModelParams> {
	data: TModel[];
	key: PerformancesColumnsSnakeCase;
	foreignKey: ForeignKeys;
	performanceItems: PerformanceModel[];
	segment?: ISegment<TModel, PerformanceModel, PerformancesColumnsSnakeCase>;
	attachExtraParams?: (row: TModel) => { [key: string]: string | number | boolean | undefined | object };
}

interface IAggergatedDataWithSegment<TModel extends IMendatoryModelParams> extends IAggergatedData<TModel> {
	segment: ISegment<TModel, PerformanceModel, PerformancesColumnsSnakeCase>;
}

function aggergateData<TModel extends IMendatoryModelParams>(props: IAggergatedData<TModel>) {
	const { data, key, attachExtraParams = () => ({}), performanceItems, foreignKey = SideEffectKeys.Id } = props;

	return (
		data.map(model => {
			const performance = performanceItems.find(performanceItem => {
				return performanceItem[camelCase(key) as keyof PerformanceModel]?.toString() === model[foreignKey]?.toString(); // foreignKey need to be snake case, key need to be camelCase
			});

			return {
				id: model.id,
				name: model.name,
				status: model.status,
				...attachExtraParams(model),
				...performance,
			};
		}) || []
	);
}

function aggergateDataBySegment<TModel extends IMendatoryModelParams>(props: IAggergatedDataWithSegment<TModel>) {
	const { data, key, attachExtraParams = () => ({}), performanceItems, segment, foreignKey = SideEffectKeys.Id } = props;
	return (
		data.map(model => {
			const performances = performanceItems.filter(
				performanceItem => performanceItem[camelCase(key) as keyof PerformanceModel]?.toString() === model[foreignKey]?.toString() // foreignKey need to be snake case, key need to be camelCase
			);

			if (performances.length === 1) {
				return {
					id: model.id,
					name: model.name,
					status: model.status,
					...attachExtraParams(model),
					...performances[0],
				};
			}

			let totalPerformance = {};
			const subRows: ModelWithPerformance<TModel>[] = [];
			totalPerformance = performances.reduce(
				(performanceAcc, currentPerformance) => {
					// TODO - think of a way to generalize this
					// TODO - win rate
					if (performanceAcc.auctions !== undefined) {
						performanceAcc.auctions += currentPerformance.auctions ?? 0;
					}
					if (performanceAcc.clicks !== undefined) {
						performanceAcc.clicks += currentPerformance.clicks ?? 0;
					}
					if (performanceAcc.conversionValue !== undefined) {
						performanceAcc.conversionValue += currentPerformance.conversionValue ?? 0;
					}
					if (performanceAcc.conversions !== undefined) {
						performanceAcc.conversions += currentPerformance.conversions ?? 0;
					}
					if (performanceAcc.cost !== undefined) {
						performanceAcc.cost += currentPerformance.cost ?? 0;
					}
					if (performanceAcc.impressions !== undefined) {
						performanceAcc.impressions += currentPerformance.impressions ?? 0;
					}
					if (performanceAcc.wins !== undefined) {
						performanceAcc.wins += currentPerformance.wins ?? 0;
					}
					if (performanceAcc.gain !== undefined) {
						performanceAcc.gain += currentPerformance.gain ?? 0;
					}
					if (performanceAcc.optGain !== undefined) {
						performanceAcc.optGain += currentPerformance.optGain ?? 0;
					}
					if (performanceAcc.optCost !== undefined) {
						performanceAcc.optCost += currentPerformance.optCost ?? 0;
					}

					const subRow = {
						...Object.keys(attachExtraParams(model)).reduce((acc, curr) => ({ ...acc, [curr]: null }), {}), // for subrows no need extra params values, just the keys, must be on top, if we want to override it
						name: (() => {
							if (segment.renderCell) {
								return segment.renderCell({ model, performanceItem: currentPerformance });
							}
							return currentPerformance[camelCase(segment?.performanceGroupBy || '') as keyof PerformanceModel]; // performanceGroupBy need to be camelCase
						})(),
						...currentPerformance,
						segment: segment.performanceGroupBy,
					};

					//@
					//@ts-ignore
					subRows?.push(subRow);

					return performanceAcc;
				},
				{
					auctions: 0,
					clicks: 0,
					conversionValue: 0,
					conversions: 0,
					cost: 0,
					impressions: 0,
					wins: 0,
					gain: 0,
					optGain: 0,
					optCost: 0,
				}
			);

			const sortBy = segment?.sortFn;
			if (sortBy) {
				subRows.sort((a, b) => sortBy(a as PerformanceModel, b as PerformanceModel));
			}

			return {
				id: model.id,
				name: model.name,
				status: model.status,
				...attachExtraParams(model),
				...totalPerformance,
				subRows,
			};
		}) || []
	);
}

// TODO - Rethink, maybe we can merge between aggergateDataBySegment and aggergateData
export const useMergeWithPerformance = <TModel extends IMendatoryModelParams>(props: IUseWithPerformance<TModel>) => {
	const { data, attachExtraParams = () => ({}), key, performanceStore, foreignKey = SideEffectKeys.Id } = props;
	const { performanceStore: deprecatedPerformanceStore } = useStores<IAdminxStore>();
	const _performanceStore = performanceStore || deprecatedPerformanceStore;
	const segment = performanceStore?.getSegments()?.values()?.next()?.value; // Currently only one segment valid
	const performanceItems = _performanceStore.getData()?.getData() || [];
	const performanceSummary = _performanceStore.getData()?.getFooter();

	const _props = {
		data,
		performanceItems,
		attachExtraParams,
		key,
		segment,
		foreignKey,
	};
	if (segment) {
		return { data: aggergateDataBySegment<TModel>(_props), summary: performanceSummary };
	}
	return { data: aggergateData<TModel>(_props), summary: performanceSummary };
};
