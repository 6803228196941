import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CrudEndpoint } from './crud.endpoint';

const DEAL_ID = ':dealId';

// TODO - RONT
export class DealsEndpoint extends CrudEndpoint {
	private attachCampaignsUrl: string;

	constructor() {
		super('deals');
		this.attachCampaignsUrl = `${this.rootApi}/deals/${DEAL_ID}/set-campaigns-related-to-deal`;
	}

	attachCampaigns(id: id) {
		return this.attachCampaignsUrl.replace(`${DEAL_ID}`, id.toString());
	}
}
