import { makeAutoObservable } from 'mobx';
import { CreativeSelectionModes } from '../enums/creative-selection-modes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { StrategyModel } from './strategy.model';
import { url } from '@monorepo/tools/src/lib/types/url';
import { Status, Statuses } from '../enums/status';

/**
 * The props the test crud page uses
 */
export interface ITestGroup {
	id?: id;
	name?: string;
	weight?: number;
	creative_selection_mode?: CreativeSelectionModes;
	cache_key_generator?: string;
	dest_url?: url;
	status?: Status;
	strategy?: StrategyModel;
	creative_group?: string;
	second_bid_factor?: number;
}
export class TestGroupModel implements ITestGroup {
	id?: id;
	name?: string;
	weight?: number;
	creative_selection_mode?: CreativeSelectionModes;
	cache_key_generator?: string;
	dest_url?: url;
	status?: Status = Statuses.Active;
	strategy?: StrategyModel;
	creative_group?: string;
	second_bid_factor?: number;

	constructor(props?: ITestGroup) {
		this.id = props?.id;
		this.name = props?.name;
		this.weight = props?.weight;
		this.creative_selection_mode = props?.creative_selection_mode;
		this.cache_key_generator = props?.cache_key_generator;
		this.dest_url = props?.dest_url;
		this.status = props?.status || Statuses.Active;
		this.strategy = props?.strategy;
		this.creative_group = props?.creative_group;
		this.second_bid_factor = props?.second_bid_factor;

		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getName() {
		return this.name;
	}

	public setName(name: string) {
		this.name = name;
	}

	public getCreativeGroup(): string | undefined {
		return this.creative_group;
	}

	public setCreativeGroup(creative_group: string) {
		this.creative_group = creative_group;
	}

	public getWeight() {
		return this.weight;
	}

	public setWeight(weight: number) {
		this.weight = weight;
	}

	public getCreativeSelectionMode() {
		return this.creative_selection_mode;
	}

	public setCreativeSelectionMode(creative_selection_mode: CreativeSelectionModes) {
		this.creative_selection_mode = creative_selection_mode;
	}

	public getCacheKeyGenerator(): string | undefined {
		return this.cache_key_generator;
	}

	public setCacheKeyGenerator(cache_key_generator: string): void {
		this.cache_key_generator = cache_key_generator;
	}

	public getDestinationUrl(): url | undefined {
		return this.dest_url;
	}

	public setDestinationUrl(dest_url: url): void {
		this.dest_url = dest_url;
	}

	public getStatus(): Status | undefined {
		return this.status;
	}

	public setStatus(status: Status): void {
		this.status = status;
	}

	public getStrategy(): StrategyModel | undefined {
		return this.strategy;
	}

	public setStrategy(strategy: StrategyModel): void {
		this.strategy = strategy;
	}

	public getSecondBidFactor(): number | undefined {
		return this.second_bid_factor;
	}

	public setSecondBidFactor(second_bid_factor: number): void {
		this.second_bid_factor = second_bid_factor;
	}
}
