import { AdvertisersApi } from '../../apis/advertisers.api';
import { CreativeGroupsApi } from '../../apis/creative-groups.api';
import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter, IEntityFilter } from '../../components/filters-menu/filters';
import { performanceFilters } from '../../components/filters-menu/filters/perfomance.filter';

export const creativeGroupFilters = new Map<FilterTypeKey, FilterType>([
	[
		'entityFilters',
		{
			...performanceFilters.entityFilters,
			campaignEntity: {
				columnName: 'Creative Group',
				cellKey: 'nameid',
				autocompleteCallback: async () => {
					return await CreativeGroupsApi.list().then(creativeGroups => {
						return creativeGroups.map(creativeGroup => {
							return {
								label: `${creativeGroup.design} (${creativeGroup.id})`,
								value: creativeGroup.id,
							};
						});
					});
				},
			},
			advertiserEntity: {
				columnName: 'Advertiser',
				cellKey: 'advertisernameid',
				autocompleteCallback: async () => {
					return await AdvertisersApi.list().then(advertisers => {
						return advertisers.map(advertiser => {
							return {
								label: `${advertiser.name} (${advertiser.id})`,
								value: advertiser.id,
							};
						});
					});
				},
			},
		} as IEntityFilter,
	],
	['numberFilters', { ...performanceFilters.numberFilters } as INumberFilter],
	[
		'stringFilters',
		{
			...performanceFilters.stringFilters,
			name: { columnName: 'Design', cellKey: 'name' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
		} as IEnumFilter,
	],
]);
