import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { NavPage, Nav, NavItem } from '@monorepo/base/src/components/nav/nav';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import routes from '../../../modules/routes/index.routes';
import { Page } from '@monorepo/base/src/components/page/page';
import { Outlet } from 'react-router-dom';

export const Tools: FC = observer(() => {
	const { currentRoute } = useRoute();
	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);

	useEffect(() => {
		setActiveRoute(currentRoute);
	}, [currentRoute]);

	return (
		<NavPage>
			<Nav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				<NavItem to={`${routes.tools.tool('create-campaign-segmentation')}`}>Create Campaign Segmentation</NavItem>
				<NavItem to={`${routes.tools.tool('view-campaign-segmentation')}`}>View Campaign Segmentation</NavItem>
				<NavItem to={`${routes.tools.tool('replace-cids')}`}>Replace Segmentation CID</NavItem>
			</Nav>
			<Page>
				<Outlet />
			</Page>
		</NavPage>
	);
});
