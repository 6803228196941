import NomadTooltip from '@monorepo/controlled/src/components/tooltip/nomad-tooltip/nomad-tooltip';
import styles from './overflow-column.module.scss';

interface IOverflowColumn {
	data: string;
	maxWidth?: string;
}

export const OverflowColumn = (props: IOverflowColumn) => {
	const { data, maxWidth } = props;
	return (
		<NomadTooltip text={data}>
			<div className={styles.overflowColumn} style={{ maxWidth }}>
				{data}
			</div>
		</NomadTooltip>
	);
};
