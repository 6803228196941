import { ReactNode, useState } from 'react';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './accordion.module.scss';

export interface IAccordion {
	title: ReactNode | string;
	children: ReactNode;
	defaultOpen?: boolean;
	icon?: string;
	titleClassName?: string;
	classes?: IAccordionClasses;
	togglerIcon?: string;
}

export interface IAccordionClasses {
	wrapper?: string;
	toggler?: string;
	title?: string;
	titleIcon?: string;
	togglerIcon?: string;
	content?: string;
}

export const Accordion = (props: IAccordion) => {
	const { children, title, defaultOpen = false, icon, classes, togglerIcon } = props;
	const [isOpen, toggleAccordion] = useState(defaultOpen);

	return (
		<div className={`${styles.wrapper} ${classes?.wrapper}`}>
			<div onClick={() => toggleAccordion(!isOpen)} className={`${styles.toggler} ${classes?.toggler}`}>
				<h2 className={`${styles.title} ${classes?.title}`}>
					{icon ? (
						<Icon isMFP={true} className={`${styles.titleIcon} ${classes?.titleIcon}`}>
							{icon}
						</Icon>
					) : null}
					{title}
				</h2>
				<Icon isMFP={true} className={`${styles.icon} ${isOpen ? styles.open : ''} ${classes?.togglerIcon}`}>
					{togglerIcon || 'chevron-up'}
				</Icon>
			</div>
			<div className={`${styles.content} ${isOpen ? styles.open : styles.close} ${classes?.content}`}>{children}</div>
		</div>
	);
};
