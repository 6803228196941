import { useEffect, useState } from 'react';
import { StringActions, enumAction } from '@monorepo/tools/src/lib/utils/string';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './filter-enum-menu.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { FilterOptions } from '../filter-options/filter-options';
import { SelectOptions } from '../../select-new/select-new';

interface IFilterEnumMenu {
	title: string;
	onApply: (action: enumAction, value: (string | number)[]) => void;
	onCancel: () => void;
	defaultAction?: string;
	defaultValues?: (string | number)[];
	debugProps?: IDebugProps;
	enumOptions: SelectOptions[];
	multi?: boolean;
	isSelectAll?: boolean;
}

export const FilterEnumMenu = (props: IFilterEnumMenu) => {
	const {
		title,
		onApply,
		onCancel,
		defaultAction = StringActions.Equals,
		debugProps,
		defaultValues = [],
		enumOptions,
		multi,
		isSelectAll,
	} = props;

	const [action, setAction] = useState<option>(defaultAction);
	const [filterValue, setFilterValue] = useState<(string | number)[]>(defaultValues);
	const [actionError, setActionError] = useState<string | undefined>(undefined);
	const [, setInputError] = useState<string | undefined>(undefined);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setAction(defaultAction);
	}, [defaultAction]);

	return (
		<div className={styles.wrapper} {...generateDataAttrs(dataAttrs)}>
			<div className={styles.title}>
				{title}
				<Icon isMFP={true} className={styles.closeButton} onClick={onCancel}>
					x-close
				</Icon>
			</div>
			<div className={styles.actionsDiv}>Actions</div>
			<div className={styles.content}>
				<Dropdown
					className={styles.dropdown}
					required={true}
					defaultOption={action}
					options={[StringActions.Equals, StringActions.DoesNotEqual]}
					onSelect={(_action: Set<option> | undefined) => {
						setAction(_action?.values().next().value);
						setActionError(undefined);
					}}
					error={actionError}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_action')] }}
				/>
				<div className={styles.subTitle}>{title}</div>
				<FilterOptions
					placeholder={`Search ${title}`}
					value={filterValue}
					onEnumFilterOption={setFilterValue}
					onCancel={onCancel}
					options={enumOptions}
					isSelectAll={isSelectAll}
					isMultiple={multi}
				/>
			</div>
			<div className={styles.actions}>
				<div className={styles.action}>
					<SecondaryButton
						className={`${styles.actionButton} ${styles.cancelButton}`}
						onClick={onCancel}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_menu_cancel_button')] }}>
						Cancel
					</SecondaryButton>
				</div>
				<div className={styles.action}>
					<PrimaryButton
						className={styles.actionButton}
						disabled={filterValue.length === 0}
						onClick={() => {
							if (!filterValue) {
								setInputError('Required');
								return;
							}
							if (!action) {
								setActionError('Required');
								return;
							}
							if (filterValue && action) {
								onApply(action as enumAction, filterValue);
							}
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_menu_apply_button')] }}>
						Apply
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};
