import { observer } from 'mobx-react';
import { Outlet, useParams } from 'react-router-dom';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../../../modules/routes/index.routes';
import { useCampaignGroupActions } from '../../../../modules/hooks/actions/campaign-group.actions';
import { IPerformanceOptionsFilterType } from '../../../../modules/hooks/apis/use-performance';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { Chart } from '@monorepo/base/src/components/chart/chart';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { useCampaignGroupHeaders } from '../../../../modules/hooks/theaders/campaign-groups.headers';
import { IAdminxStore } from '../../../../modules/stores';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Card } from '@monorepo/base/src/components/card/card';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import {
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsSortBy,
	IPerformanceOptionsSortOrder,
	PerformancesColumnsSnakeCase,
	groupByMapper,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

export const AdvertiserCampaignGroups = observer(() => {
	const { campaignGroupsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { advertiserId } = useParams();

	const metricsStore = campaignGroupsStore.getMetrics();
	const reportList = campaignGroupsStore.getListStore();
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();
	const defaultSortBy = usePageSettings().tableStore?.getSortingBy();
	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val) as PerformancesColumnsSnakeCase[];

	const filters = advertiserId
		? [
				{
					column: PerformancesColumnsSnakeCase.AdvertiserId,
					filterType: IPerformanceOptionsFilterType.Include,
					value: [advertiserId],
				},
		  ]
		: [];

	const { fetchReports } = useReportsList(reportList, {
		groupBys: [...groupByColumns],
		reportName: 'adxCampaignGroup',
		sortBy: defaultSortBy?.[0]?.id || IPerformanceOptionsSortBy.Wins,
		sortOrder: defaultSortBy?.[0]?.desc ? IPerformanceOptionsSortOrder.Desc : IPerformanceOptionsSortOrder.Asc,
		dateSegment: groupByColumns.includes(PerformancesColumnsSnakeCase.Dt)
			? IPerformanceOptionsDateSegments.Daily
			: IPerformanceOptionsDateSegments.None,
		submit: true,
		filters,
	});

	useReportsList(metricsStore, {
		groupBys: [PerformancesColumnsSnakeCase.Dt],
		reportName: 'adxCampaignGroup',
		dateSegment: IPerformanceOptionsDateSegments.Daily,
		filters,
	});

	const onEdit = (campaignGroupId: id) => {
		if (!advertiserId) {
			return routes.campaignGroups.edit(campaignGroupId);
		}
		return routes.advertisers.editCampaignGroup(advertiserId, campaignGroupId);
	};

	const { SelectedActions, EntityActions, IndexActions } = useCampaignGroupActions({
		onEdit,
		createRoute: advertiserId ? routes.advertisers.createCampaignGroup(advertiserId) : undefined,
	});
	const { columns } = useCampaignGroupHeaders({
		includeColumns: { advertiser: false },
		EntityActions,
		onEdit,
		summary: reportList.getData()?.footer,
	});
	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Campaign Groups</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>

			<Card full>
				<Chart
					isLoading={metricsStore.getIsLoading()}
					metrics={metrics}
					labels={xLabels}
					legendOptions={Object.keys(availablePerformanceToMetrics)}
				/>
			</Card>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_groups_table')] }}
				columns={columns}
				data={reportList.getData()?.data || []}
				isLoading={campaignGroupsStore.getListStore().getIsLoading()}
				fetchReports={fetchReports}
				isGlobalFilter={false}
				isSummary={true}>
				{() => {
					return {
						IndexActions,
						SelectedActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
