import { createColumnHelper } from '@tanstack/react-table';
import { HighlighStatus, HighlightStatuses } from '@monorepo/base/src/components/highlight/highlight';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { DealModel } from '../../models/deal.model';
import { PerformanceModel } from '../../models/performance.model';
import { useDealActions } from '../actions/deals.actions';
import { IMendatoryModelParams } from '../tools/use-merge-with-performance';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../stores';
import { useEffect } from 'react';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';

const columnHelper = createColumnHelper<DealModel & PerformanceModel & IMendatoryModelParams>();

export const useDealHeaders = (props: IEntityHeaders): IMergedHeadersReturn<DealModel> => {
	const { summary } = props;
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;
	const { settingsStore } = useStores<IAdminxStore>();
	const { EntityActions } = useDealActions();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				id: true,
				preferred_deal_id: true,
				name: true,
				serving_status: true,
				formats: true,
				proposal_name: true,
				impressions: false,
				proposal_id: false,
				publisher_profile: false,
				auctions: true,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: true,
				roas: true,
				lpCvr: true,
				optCost: false,
				optGain: false,
				gain: false,
				ctr: false,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	const _mapToTagStatus = (status?: string): HighlighStatus => {
		if (status === 'active') {
			return HighlightStatuses.Success;
		}
		if (status === 'ended') {
			return HighlightStatuses.Error;
		}
		return HighlightStatuses.Info;
	};

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const deal = new DealModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<SpacerX x={0.7} />
							<QuickActionsMenu>
								<EntityActions entity={deal} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('preferred_deal_id', {
				header: 'Deal Id',
			}),
			columnHelper.accessor('name', {
				header: 'Deal Name',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow<DealModel>({ row });
					}
					return <span>{row.getValue('name')}</span>;
				},
			}),
			columnHelper.accessor('serving_status', {
				header: 'Serving Status',
				cell: info => {
					const { row } = info;
					const servingStatus = row.getValue('serving_status') as string;
					return <Tag type={_mapToTagStatus(servingStatus?.toLowerCase())}>{servingStatus}</Tag>;
				},
			}),
			columnHelper.accessor('formats', {
				header: 'Formats',
				cell: info => {
					const { row } = info;
					const formats = row.getValue('formats') as string[];
					return <OverflowColumn data={formats?.join(' ')} />;
				},
			}),
			columnHelper.accessor('proposal_name', {
				header: 'Proposal Name',
			}),
			columnHelper.accessor('proposal_id', {
				header: 'Proposal Id',
			}),
			columnHelper.accessor('publisher_profile', {
				header: 'Publisher Profile',
			}),
			...mergedPerforamnceHeaders,
		],
	};
};
