import { AdvertisersApi } from '../../apis/advertisers.api';
import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter, IEntityFilter } from '../../components/filters-menu/filters';
import { performanceFilters } from '../../components/filters-menu/filters/perfomance.filter';
import { Statuses } from '../../enums/status';

export const campaignGroupFilters = new Map<FilterTypeKey, FilterType>([
	[
		'entityFilters',
		{
			...performanceFilters.entityFilters,
			advertiserEntity: {
				columnName: 'Advertiser',
				cellKey: 'advertisernameid',
				autocompleteCallback: async () => {
					return await AdvertisersApi.list().then(advertisers => {
						return advertisers.map(advertiser => {
							return {
								label: `${advertiser.name} (${advertiser.id})`,
								value: advertiser.id,
							};
						});
					});
				},
			},
		} as IEntityFilter,
	],
	['numberFilters', { ...performanceFilters.numberFilters } as INumberFilter],
	[
		'stringFilters',
		{
			...performanceFilters.stringFilters,
			name: { columnName: 'Name', cellKey: 'name' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
			status: {
				columnName: 'Status',
				cellKey: 'status',
				enumOptions: [
					{ value: Statuses.Active, label: 'Active' },
					{ value: Statuses.Paused, label: 'Paused' },
					{ value: Statuses.NoBalance, label: 'No Balance' },
					{ value: Statuses.New, label: 'New' },
					{ value: Statuses.Ended, label: 'Ended' },
				],
				deletable: false,
			},
		} as IEnumFilter,
	],
]);
