import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel } from '../../models/advertiser.model';
import { CreativeGroupModel } from '../../models/creative-group.model';
import { useCreativeGroupGet } from '../../hooks/apis/use-creative-group-get';
import routes from '../../routes/index.routes';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import styles from './main-breadcrumbs.module.scss';
import { AdvertiserBreadcrumb } from './advertiser-page.breadcrumbs';
import { SkeletonBreadCrumb } from './main-breadcrumbs';
import { IAdminxStore } from '../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { SelectNew, SelectOptions } from '@monorepo/base/src/components/select-new/select-new';
import { IPerformanceOptionsFilterType, PerformancesColumnsSnakeCase, useReportsList } from '../../hooks/apis/use-reports-list';

interface ICreativeGroupBreadcrumb {
	creativeGroup: CreativeGroupModel;
	advertiser?: AdvertiserModel;
}

export const CreativeGroupBreadcrumb = observer((props: ICreativeGroupBreadcrumb) => {
	const { creativeGroup, advertiser } = props;
	const { creativeGroupsStore } = useStores<IAdminxStore>();
	const navigate = useNavigateParams();

	const advertiserId = advertiser?.getId();

	const advertiserFilter = advertiserId
		? [
				{
					column: PerformancesColumnsSnakeCase.AdvertiserId,
					filterType: IPerformanceOptionsFilterType.Include,
					value: [advertiserId as string],
				},
		  ]
		: [];

	useReportsList(creativeGroupsStore.getListStore(), {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Design, PerformancesColumnsSnakeCase.AdvertiserId],
		reportName: 'adxCreativeGroups',
		filters: advertiserFilter,
	});

	const creativeGroupName = creativeGroup?.getDesign();

	const onListItemCreativeGroup = (creativeGroupsId: (string | number)[]) => {
		const creativeGroupId = creativeGroupsId[0];
		if (!creativeGroupId) {
			return null;
		}
		navigate(routes.creativeGroups.creatives(creativeGroupId));
	};

	const creativeGroups = creativeGroupsStore.getListStore().getData()?.getData();

	if (!creativeGroupName) {
		return null;
	}

	const onBreadcrumbLink = () => {
		const creativeGroupId = creativeGroup.getId();
		if (creativeGroupId) {
			navigate(`${routes.creativeGroups.creatives(creativeGroupId)}`);
		}
	};

	return (
		<BreadcrumbMenu
			label={'Creative Group'}
			title={creativeGroupName}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_group_breadcrumbs')] }}
			navigateToModel={onBreadcrumbLink}>
			<div className={styles.menuWrapper}>
				{creativeGroups ? (
					<SelectNew
						classNames={{ list: styles.list }}
						placeholder={'Search Creative Group'}
						options={creativeGroups.map(_creativeGroup => {
							return {
								label: _creativeGroup.design as string,
								value: _creativeGroup.id as string,
								to: routes.creativeGroups.creatives(_creativeGroup.id as string),
							};
						})}
						sortBy={(a: SelectOptions, b: SelectOptions) => (a.label > b.label ? 1 : -1)}
						required
						debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_group_breadcrumbs_select')] }}
						onSelect={onListItemCreativeGroup}
						defaultValues={(creativeGroup.getId() ? [creativeGroup.getId()] : []) as string[]}
					/>
				) : null}
			</div>
		</BreadcrumbMenu>
	);
});

interface ICreativeGroupPageBreadcrumbs {
	creativeGroupId: string | number;
}

export const CreativeGroupPageBreadcrumbs = observer((props: ICreativeGroupPageBreadcrumbs) => {
	const { creativeGroupId } = props;
	const { creativeGroupsStore } = useStores<IAdminxStore>();

	useCreativeGroupGet({ id: creativeGroupId });

	const creativeGroup = creativeGroupsStore.getCrud().getData();
	const advertiser = creativeGroup?.getAdvertiser();

	return (
		<Fragment>
			<div className={styles.wrapper}>
				{advertiser ? <AdvertiserBreadcrumb advertiser={advertiser} /> : <SkeletonBreadCrumb isNext={true} />}
				{creativeGroup.getDesign() ? (
					<CreativeGroupBreadcrumb creativeGroup={creativeGroup} advertiser={advertiser} />
				) : (
					<SkeletonBreadCrumb />
				)}
			</div>
		</Fragment>
	);
});
