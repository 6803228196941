import { Dispatch, Fragment, SetStateAction } from 'react';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { SelectOptions, SelectNew } from '../../select-new/select-new';

interface IFilterOptions {
	options: SelectOptions[];
	value?: (string | number)[];
	onEnumFilterOption: Dispatch<SetStateAction<(string | number)[]>>;
	isLoading?: boolean;
	placeholder?: string;
	onCancel?: () => void;
	isSelectAll?: boolean;
	isMultiple?: boolean;
	isUppercasedEnum?: boolean;
}

export const FilterOptions = (props: IFilterOptions) => {
	const { value, options, isLoading = false, onEnumFilterOption, placeholder, isSelectAll = false, isMultiple = true } = props;

	return (
		<Fragment>
			<BarLoader is={isLoading} />
			<SelectNew
				placeholder={placeholder}
				options={options}
				onSelect={_value => {
					onEnumFilterOption(_value);
				}}
				disabled={isLoading}
				defaultValues={value}
				multiple={isMultiple}
				isSelectAll={isSelectAll}
				isLoading={isLoading}
			/>
		</Fragment>
	);
};
