// import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './avatar.module.scss';
import { url } from '@monorepo/tools/src/lib/types/url';
// import { getTheme } from '@monorepo/tools/src/lib/get-config';

// const theme = getTheme();

interface IAvatar {
	url?: url;
	alt?: string;
	firstName?: string;
	lastName?: string;
}

const letterToHexColor = (letter?: string): string => {
	if (!letter) {
		return '#000000';
	}

	letter = letter.toLowerCase(); // Convert letter to lowercase
	const colorMap: Record<string, string> = {
		abc: '#ec407a',
		def: '#512da8',
		ghi: '#004d40',
		jkl: '#01579b',
		mno: '#33691e',
		pqr: '#0098a7',
		stu: '#77919d',
		vwx: '#66a139',
		yz: '#ec407a',
	};

	for (const group in colorMap) {
		if (group.includes(letter)) {
			return colorMap[group];
		}
	}

	return '#000000';
};

export const Avatar = (props: IAvatar) => {
	const { url, alt, firstName, lastName } = props;
	if (!url) {
		const label = `${firstName?.charAt(0)}${lastName?.charAt(0) || ''}`;
		return (
			// <Icon isMFP={true} color={theme.subtitleColor} size={'28px'}>
			// 	user-circle
			// </Icon>
			<div
				className={styles.noPictureAvatar}
				style={{ backgroundColor: letterToHexColor(firstName?.charAt(0)), fontSize: label.length > 1 ? 14 : undefined }}>
				{label}
			</div>
		);
	}
	return <img alt={alt} className={styles.avatar} src={url} />;
};
