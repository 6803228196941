import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CampaignModel, ICampaign } from '../../../models/campaign.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { SelectionList } from '@monorepo/base/src/components/selection-list/selection-list';
import styles from './attach-deal-campaigns.module.scss';
import { IAdminxStore } from '../../../stores';
import { Status, Statuses } from '../../../enums/status';
import { SelectionListOption } from '../../selection-list-option/selection-list-option';
import { IPerformanceOptionsSortBy, PerformancesColumnsSnakeCase, useReportsList } from '../../../hooks/apis/use-reports-list';

interface IAttachDealCampaigns {
	dealId: id;
}

interface IAttachDealCampaignOption {
	name: string;
	description: string;
	status: Status;
}

export const AttachDealCampaigns = observer((props: IAttachDealCampaigns) => {
	const { dealId } = props;

	const { dealStore, campaignStore } = useStores<IAdminxStore>();
	const dealCrudStore = dealStore.getCrud();
	const deal = dealCrudStore.getData();
	const reportList = campaignStore.getListStore();

	useEffect(() => {
		dealStore.getCrud().get(dealId);
	}, []);

	useReportsList(reportList, {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxCampaigns',
		sortBy: IPerformanceOptionsSortBy.Id,
	});

	const campaigns =
		campaignStore
			.getListStore()
			.getData()
			?.data.map(campaign => new CampaignModel(campaign as ICampaign)) || [];

	//without useMemo you won't be able to filter and click without rerender also there is no point calling this again
	const campaignOptions = useMemo(
		() =>
			campaigns.map(campaign => {
				return {
					name: campaign.getName() || '',
					description: `${campaign.getAdvertiser()?.getName()} - ${campaign.getCampaignGroup()?.getName()}`,
					status: campaign.getStatus() || Statuses.Paused,
				};
			}),
		[campaigns]
	);

	const selectedCampaigns = deal.getCampaigns()?.map(campaign => {
		return {
			name: campaign.getName() || '',
			description: `${campaign.getAdvertiser()?.getName()} - ${campaign.getCampaignGroup()?.getName()}`,
			status: campaign.getStatus() || Statuses.Paused,
		};
	});

	const onCampaignSelect = (options: IAttachDealCampaignOption[]) => {
		const selectedCampaigns = options.map(option =>
			campaigns.find(campaign => {
				return option.name === campaign.getName();
			})
		);
		const campaignsArr: CampaignModel[] = [];
		selectedCampaigns.forEach(_campaign => {
			if (_campaign) {
				campaignsArr.push(new CampaignModel(_campaign));
			}
		});
		deal.setCampaigns(campaignsArr);
	};

	return (
		<div className={styles.wrapper}>
			<SelectionList
				label={'Campaigns'}
				options={campaignOptions}
				defaultOptions={selectedCampaigns}
				onSelect={(campaign: IAttachDealCampaignOption[]) => onCampaignSelect(campaign)}
				isLoadingOptions={campaignStore.getListStore().getIsLoading()}
				renderOption={option => <SelectionListOption option={option} />}
			/>
		</div>
	);
});
