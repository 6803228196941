import { useEffect, useRef, useState } from 'react';

interface IUseHeadsObserver {
	heads: { index: number; id: string; scroll?: boolean }[]; // if scroll is false, the instersection observer will ignore this element
	contentClicked: string;
	options?: { threshold?: number; rootMargin?: string; intersectionRatio?: number };
}

export function useHeadsObserver(props: IUseHeadsObserver) {
	const { heads, contentClicked = '', options = {} } = props;
	const { threshold = 1, rootMargin = '0px', intersectionRatio = 1 } = options;

	const observer = useRef<IntersectionObserver>();
	const [activeId, setActiveId] = useState('');

	useEffect(() => {
		const handleObsever = (entries: IntersectionObserverEntry[]) => {
			entries.forEach(entry => {
				if (entry?.isIntersecting && entry.intersectionRatio >= intersectionRatio) {
					setActiveId(entry.target.id);
				}
			});
		};

		observer.current = new IntersectionObserver(handleObsever, {
			threshold,
			rootMargin,
		});

		let elements: (HTMLElement | null)[] = [];

		if (contentClicked === '') {
			elements = heads.filter(head => head.scroll !== false).map(head => document.getElementById(head.id));
			elements.forEach(element => {
				if (element) {
					// element.style.border = '1px solid red';
				}
			});
		} else {
			setActiveId(contentClicked);
		}

		elements.forEach(elem => {
			if (elem !== null) {
				observer.current?.observe(elem);
			}
		});

		return () => observer.current?.disconnect();
	}, [contentClicked]);

	return { activeId, setActiveId };
}
