import md5Hex from 'md5-hex';
import { customAlphabet } from 'nanoid';

export const hash = {
	encrypt: (text: string) => md5Hex(text),
	makeId: (length: number, characters = 'abcdefghijklmnopqrstuvwxyz0123456789_'): string => {
		const nanoid = customAlphabet(characters);
		return nanoid(length);
	},
	uuid: (): string => {
		return hash.makeId(36, 'abcdefghijklmnopqrstuvwxyz0123456789_');
	},
};
