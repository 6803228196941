import { observer } from 'mobx-react';
import { FC } from 'react';
import { Soon } from '@monorepo/base/src/components/soon/soon';
import styles from './home.module.scss';

export const Home: FC = observer(() => {
	return (
		<article className={styles.wrapper}>
			<Soon />
		</article>
	);
});
