import { AdvertisersApi } from '../../apis/advertisers.api';
import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter, IEntityFilter } from '../../components/filters-menu/filters';
import { performanceFilters } from '../../components/filters-menu/filters/perfomance.filter';
import { Statuses } from '../../enums/status';

export const advertiserFilters = new Map<FilterTypeKey, FilterType>([
	[
		'entityFilters',
		{
			...performanceFilters.entityFilters,
			advertiserEntity: {
				columnName: 'Advertiser',
				cellKey: 'nameid',
				autocompleteCallback: async () => {
					return await AdvertisersApi.list().then(advertisers => {
						return advertisers.map(advertiser => {
							return {
								label: `${advertiser.name} (${advertiser.id})`,
								value: advertiser.id,
							};
						});
					});
				},
			},
		} as IEntityFilter,
	],
	[
		'numberFilters',
		{
			...performanceFilters.numberFilters,
			totalBudgetLimit: { columnName: 'Total Spent', cellKey: 'stats.total_spent' }, //['stats', 'total_spent']
			statsTotalEarn: { columnName: 'Total Money Earn', cellKey: 'stats.total_earn' }, //['stats', 'total_earn']
			statsDailySpent: { columnName: 'Total Daily Spent', cellKey: 'stats.daily_spent' }, //['stats', 'daily_spent']
			statsDailyEarn: { columnName: 'Total Daily Earn', cellKey: 'stats.daily_earn' }, //['stats', 'daily_earn']
		} as INumberFilter,
	],
	['stringFilters', { ...performanceFilters.stringFilters, name: { columnName: 'Name', cellKey: 'name' } } as IStringFilter],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
			user_cap_enabled: {
				columnName: 'User Cap Enabled',
				cellKey: 'user_cap_enabled',
				enumOptions: [
					{ value: 'Enabled', label: 'Enabled' },
					{ value: 'Disabled', label: 'Disabled' },
				],
				deletable: true,
			},
			status: {
				columnName: 'Status',
				cellKey: 'status',
				enumOptions: [
					{ value: Statuses.Active, label: 'Active' },
					{ value: Statuses.Paused, label: 'Paused' },
					{ value: Statuses.NoBalance, label: 'No Balance' },
					{ value: Statuses.New, label: 'New' },
					{ value: Statuses.Ended, label: 'Ended' },
				],
				// deletable: false,
			},
		} as IEnumFilter,
	],
]);
