import { Fragment } from 'react';
import { SecondaryText } from '@monorepo/base/src/components//buttons/buttons';
import { ColumnsPicker } from '../columns-picker/columns-picker';
import { SearchFilter } from '../search-filter/search-filter';
import { IndexLinerButton, SelectedLinerButton } from './liner-buttons';
import styles from './action-liner.module.scss';
import { usePrevious } from '@monorepo/tools/src/lib/hooks/utils/use-previous';
import { DividerX } from '@monorepo/base/src/components/divider/divider';
import { Row, Table } from '@tanstack/react-table';
import { ITableLiners } from '../table';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IconFonts } from '../../icon/icon';
import { BarLoader } from '../../bar-loader/bar-loader';

interface IActionLiner<T> {
	tableProps: Table<T>;
	tableActions: ITableLiners;
	onSetGlobalFilter: (arg0: string) => void;
	onExportToCsv?: () => void;
	isLoadingCsv?: boolean;
	isGlobalFilter?: boolean;
	debugProps?: IDebugProps;
	disabledExport?: boolean;
}

const theme = getTheme();

export function ActionLiner<T>(props: IActionLiner<T>) {
	const {
		tableProps,
		tableActions,
		onSetGlobalFilter,
		onExportToCsv,
		debugProps,
		isGlobalFilter = true,
		isLoadingCsv,
		disabledExport,
	} = props;
	const { getAllColumns, toggleAllRowsSelected, getSelectedRowModel, getState } = tableProps;
	const { flatRows: selectedRows } = getSelectedRowModel();
	const { SelectedActions, IndexActions, FiltersActions, SegmentActions } = tableActions;
	const { globalFilter } = getState();

	const prevSelectedRows = usePrevious<Row<T>[]>(selectedRows);
	const isTransitionFromSelectedToIndex = prevSelectedRows && prevSelectedRows?.length > 0 && selectedRows.length === 0;
	const isTransitionFromIndexToSelected = selectedRows && selectedRows?.length > 0 && prevSelectedRows?.length === 0;

	const returnToIndexLiner = () => {
		toggleAllRowsSelected(false);
	};

	const SelectedLiner = () => {
		if (!SelectedActions) {
			return null;
		}

		return (
			<div
				className={`${styles.selectedLiner} ${isTransitionFromIndexToSelected ? styles.animation : ''}`}
				{...generateDataAttrs([new DataAttribute('id', 'table_action_liner')])}>
				<div className={styles.selectedActions}>
					<div className={styles.amountTitle}>{selectedRows.length} selected</div>
					<DividerX height={'20px'} />
					<SelectedActions rows={selectedRows} />
				</div>
				<SelectedLinerButton>
					<SecondaryText
						onClick={returnToIndexLiner}
						icon={'close'}
						iconColor={theme.actionLinerIconColor}
						textColor={theme.actionLinerIconTextColor}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'close_filters_button')] }}>
						Close
					</SecondaryText>
				</SelectedLinerButton>
			</div>
		);
	};

	const renderIndexLinerLeftSide = () => {
		if (!IndexActions) {
			return null;
		}
		return <IndexActions />;
	};

	const renderIndexLinerRightSide = () => {
		return (
			<Fragment>
				{SegmentActions ? <SegmentActions debugProps={debugProps} /> : null}
				<ColumnsPicker<T> allColumns={getAllColumns()} debugProps={debugProps} />
				{onExportToCsv && (
					<IndexLinerButton>
						<SecondaryText
							icon={'file-download-02'}
							font={IconFonts.Outlined}
							iconColor={theme.actionLinerActionIconsColor}
							iconSize={'18px'}
							disabled={disabledExport}
							onClick={onExportToCsv}>
							<span>Export</span>
						</SecondaryText>
						<BarLoader is={!!isLoadingCsv} />
					</IndexLinerButton>
				)}
				{isGlobalFilter ? (
					<SearchFilter globalFilter={globalFilter} onSetGlobalFilter={onSetGlobalFilter} debugProps={debugProps} />
				) : null}
			</Fragment>
		);
	};

	const IndexLiner = () => {
		return (
			<div className={`${styles.indexLiner} ${isTransitionFromSelectedToIndex ? styles.animation : ''}`}>
				<div className={styles.dynamicIndexActions}>{renderIndexLinerLeftSide()}</div>
				<div className={styles.defaultIndexActions}>{renderIndexLinerRightSide()}</div>
			</div>
		);
	};

	// Shit ass MF code have to be done
	// !TODO Think again MF
	const IndexLinerWithFilters = () => {
		if (!FiltersActions) {
			return null;
		}

		return (
			<div
				className={`${styles.indexLiner} ${isTransitionFromSelectedToIndex ? styles.animation : ''}`}
				{...generateDataAttrs([new DataAttribute('id', 'table_action_liner')])}>
				<FiltersActions
					IndexLinerLeftSide={renderIndexLinerLeftSide()}
					IndexLinerRightSide={renderIndexLinerRightSide()}
					debugProps={debugProps}
				/>
			</div>
		);
	};

	const renderIndexLiner = () => {
		if (FiltersActions) {
			return IndexLinerWithFilters();
		}
		return IndexLiner();
	};

	return (
		<div className={styles.actionLiner}>
			{selectedRows.length > 0 && SelectedActions ? <SelectedLiner /> : null}
			{selectedRows.length === 0 ? renderIndexLiner() : null}
		</div>
	);
}

// export const ActionLiner = memo(_ActionLiner) as typeof _ActionLiner;
