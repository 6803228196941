import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './selection-list-option.module.scss';
import { Statuses } from '../../enums/status';
import { generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';

interface ISelectionListOption {
	option: {
		status?: Statuses;
		name: string;
		description: string;
		disabled?: boolean;
	};
	debugProps?: IDebugProps;
}

export function SelectionListOption(props: ISelectionListOption) {
	const { option, debugProps } = props;
	const { dataAttrs } = debugProps || {};
	return (
		<div className={styles.singleOptionWrapper}>
			{option.status ? (
				<div className={styles.left}>
					<Icon size={'14px'} isMFP={true} color={option.status === Statuses.Active ? '#0b8043' : undefined}>
						{option.status === Statuses.Active ? 'circle' : 'pause-circle'}
					</Icon>
				</div>
			) : null}
			<div className={styles.right} {...generateDataAttrs(suffixToDataAttr(`_option_${option.name}`, dataAttrs))}>
				<div className={styles.optionName}>{option.name}</div>
				{option.description ? <div className={styles.optionDescription}>{option.description}</div> : null}
			</div>
		</div>
	);
}
