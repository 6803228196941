import { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './filter-number-menu.module.scss';
import { numberAction, NumberActions, RangeActions } from '@monorepo/tools/src/lib/utils/number';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
// import { DropdownNew } from '../../dropdown-new/dropdown-new';

// enum CommissionOptions {
// 	All = 'All',
// 	Dollar = 'Dollar',
// 	Percentage = 'Percentage',
// }

// const commissionOptions = [
// 	{
// 		label: CommissionOptions.All,
// 		value: CommissionOptions.All,
// 	},
// 	{
// 		label: CommissionOptions.Percentage,
// 		value: CommissionOptions.Percentage,
// 	},
// 	{
// 		label: CommissionOptions.Dollar,
// 		value: CommissionOptions.Dollar,
// 	},
// ];

interface IFilterNumberMenu {
	title: string;
	onApply: (action: numberAction, value: number) => void;
	onCancel: () => void;
	defaultAction?: string;
	defaultValue?: number;
	inputClassNames?: string;
	debugProps?: IDebugProps;
	isRange?: boolean;
	isInventoryCommission?: boolean;
}

export const FilterNumberMenu = (props: IFilterNumberMenu) => {
	const { title, onApply, onCancel, defaultValue, inputClassNames, debugProps, isRange, isInventoryCommission } = props;
	let { defaultAction } = props;
	const [action, setAction] = useState<option | undefined>(defaultAction);
	const [filterValue, setFilterValue] = useState<number | string | undefined>(defaultValue);
	const [actionError, setActionError] = useState<string | undefined>(undefined);
	const [inputError, setInputError] = useState<string | undefined>(undefined);
	// const [commission, setCommission] = useState<CommissionOptions>(CommissionOptions.All);
	const { dataAttrs } = debugProps || {};

	// useEffect(() => {
	// 	textInput.current?.focus();
	// }, []);

	useEffect(() => {
		if (!defaultAction) {
			defaultAction = isRange ? RangeActions.GreaterThanRange : NumberActions.Equals;
		}
		setAction(defaultAction);
	}, [defaultAction]);

	return (
		<div className={`${styles.wrapper} ${isInventoryCommission ? styles.extraHeight : ''}`} {...generateDataAttrs(dataAttrs)}>
			<div className={styles.title}>
				{title}
				<Icon isMFP={true} className={styles.closeButton} onClick={onCancel}>
					x-close
				</Icon>
			</div>
			<div className={styles.actionsDiv}>Actions</div>
			<div className={styles.content}>
				<Dropdown
					className={styles.dropdown}
					label={'Actions'}
					required={true}
					defaultOption={action}
					options={isRange ? Object.values(RangeActions) : Object.values(NumberActions)}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_action')] }}
					onSelect={(_action: Set<option> | undefined) => {
						setAction(_action?.values().next().value);
						setActionError(undefined);
					}}
					error={actionError}
				/>
				{/* {isInventoryCommission && (
					<DropdownNew
						onSelect={_commission => {
							setCommission(_commission[0] as CommissionOptions);
						}}
						options={commissionOptions}
						defaultValues={[commission]}
						required={true}
					/>
				)} */}
				<Input
					className={inputClassNames}
					type={'number'}
					value={filterValue?.toString()}
					onValue={value => {
						setFilterValue(value[value.length - 1] == '0' ? value : parseFloat(value));
						// setFilterValue(isRange ? parseFloat(value) : parseInt(value));
						setInputError(undefined);
					}}
					isAutoFocus={true}
					error={inputError}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'action_filter_value')] }}
				/>
			</div>
			<div className={`${styles.actions} ${isInventoryCommission ? styles.extraMargin : ''}`}>
				<div className={styles.action}>
					<SecondaryButton
						className={`${styles.actionButton} ${styles.cancelButton}`}
						onClick={onCancel}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_menu_cancel_button')] }}>
						Cancel
					</SecondaryButton>
				</div>
				<div className={styles.action}>
					<PrimaryButton
						className={styles.actionButton}
						onClick={() => {
							if (!filterValue && filterValue !== 0) {
								setInputError('Required');
							}
							if (!action) {
								setActionError('Required');
							}
							if ((filterValue || filterValue === 0) && action) {
								onApply(action as numberAction, filterValue as number);
							}
						}}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'filter_menu_apply_button')] }}>
						Apply
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};
