import { URLSearchParams } from 'url';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { ICampaignInputOptions } from '../models/campaign-input-options.model';
import endpoints from './endpoints/index.endpoints';

const CampaignsInputOptionsApi = {
	get: (options?: URLSearchParams): Promise<ICampaignInputOptions> => {
		return ask.get(endpoints.campaigns.inputOptions(), options);
	},
};

export { CampaignsInputOptionsApi };
