import { ReactElement, useEffect } from 'react';
import styles from './table-elements.module.scss';
import { PrimaryButton, PrimaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { useTableContext, useTableStoreContext } from '../table-component';
import { DividerX } from '@monorepo/base/src/components/divider/divider';
import { IndexLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { TableStore } from '../stores/table-stores/table.store';

type TableRawActionChildrens = ReactElement<typeof TableRawActionButton>;
const theme = getTheme();

interface TableRawActionButtonProps {
	text?: string;
	to?: string;
	onClick?: () => void;
	icon?: string;
	disabled?: boolean;
	isLoading?: boolean;
}

export const TableRawActionButton = ({
	to,
	onClick,
	text = 'Create',
	icon = 'plus',
	disabled = false,
	isLoading = false,
}: TableRawActionButtonProps) => {
	if (to) {
		return (
			<PrimaryLink disabled={disabled || isLoading} onClick={isLoading ? undefined : onClick} icon={icon} to={to} width={'100px'}>
				{text}
			</PrimaryLink>
		);
	}
	return (
		<PrimaryButton
			isRotateIcon={isLoading}
			disabled={disabled || isLoading}
			onClick={isLoading ? undefined : onClick}
			icon={icon}
			width={'100px'}>
			{text}
		</PrimaryButton>
	);
};

interface TableActionBarProps {
	children?: TableRawActionChildrens;
}

export const TableActionsBar = observer(({ children }: TableActionBarProps) => {
	const { tableStore } = useTableContext();
	const selectedRows = Object.values(tableStore.tableState.rowSelection).filter(Boolean).length;
	return (
		<div className={`${styles.rawActionBar} ${selectedRows === 0 ? styles.nonSelected : ''}`}>
			<div className={styles.rawActionBarButtons}>
				<div className={styles.rawActionBarNumberOfSelected}>{selectedRows} selected</div>
				<DividerX height={'20px'} />
				{children}
			</div>
			<div className={styles.rawActionBarButtons}>
				<TableRawActionButton text={'Close'} onClick={() => tableStore.onRowSelectionChange({})} icon={'x'} />
			</div>
		</div>
	);
});

export const ExportToCSV = observer(() => {
	const { tableStore } = useTableStoreContext();
	useEffect(() => tableStore.dataStore.downloadCsv.setIsLoading(false), []);
	const isDownloadCsv = tableStore.dataStore.downloadCsv.getIsLoading();
	const isTableLoading = tableStore.dataStore.getIsLoading();
	return (
		<IndexLinerButton>
			<SecondaryText
				icon={'file-download-02'}
				font={IconFonts.Outlined}
				iconColor={theme.actionLinerActionIconsColor}
				iconSize={'18px'}
				disabled={isTableLoading}
				onClick={() => tableStore.dataStore.downloadReportFileCsv(tableStore.tableState.tableName)}>
				<span>Export</span>
			</SecondaryText>
			<BarLoader is={isDownloadCsv} />
		</IndexLinerButton>
	);
});

export const ToggeleSpeedyMode = observer(({ tableStore }: { tableStore: TableStore }) => {
	const isSpeedyModeOn = tableStore.tableState.isSpeedyModeOn;
	const onToggle = (value: boolean) => {
		tableStore.toggleSpeedyModeColumnVisibility(value);
		tableStore.tableState.updateTableState({ isSpeedyModeOn: value });
	};

	return (
		<Toggle
			icon={'rocket-02'}
			iconSize={'1.5rem'}
			iconColor={isSpeedyModeOn ? theme.actionLinerActionIconsColor : '#D0D5DD'}
			label=""
			onChange={onToggle}
			defaultValue={isSpeedyModeOn}
		/>
	);
});
