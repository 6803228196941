import { createRef, memo, ReactNode, useCallback, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import styles from './nomad-tooltip.module.scss';

interface TooltipSingletonProps {
	text: string;
	position: { top: number; left: number };
	visible: boolean;
	direction: string;
	bubbleRef: React.RefObject<HTMLDivElement>;
}

const TooltipSingleton = memo(({ text, position, visible, direction, bubbleRef }: TooltipSingletonProps) => {
	return (
		<div
			className={`${styles['tooltip-container']}  ${visible ? ` ${styles.visible}` : ''}`}
			style={{ display: visible ? 'block' : 'none', top: position.top, left: position.left }}
			ref={bubbleRef}>
			<div className={`${styles[`tooltip-arrow-${direction}`]}`}></div>

			<div className={`${styles['tooltip-text-wrapper']}`}>
				<span className={`${styles['tooltip-text']}`}>{text}</span>
			</div>
		</div>
	);
});

class TooltipManager {
	tooltipElement: HTMLDivElement;
	root: any;
	text: string;
	position: { top: number; left: number };
	visible: boolean;
	direction: string;
	bubbleRef: React.RefObject<HTMLDivElement>;

	constructor() {
		this.tooltipElement = document.createElement('div');
		document.body.appendChild(this.tooltipElement);
		this.root = createRoot(this.tooltipElement);
		this.text = '';
		this.position = { top: 0, left: 0 };
		this.visible = false;
		this.direction = 'top';
		this.bubbleRef = createRef();
		this.renderTooltip();
	}

	renderTooltip() {
		const tooltipInstance = (
			<TooltipSingleton
				text={this.text}
				position={this.position}
				visible={this.visible}
				direction={this.direction}
				bubbleRef={this.bubbleRef}
			/>
		);
		this.root.render(tooltipInstance);
	}

	show(text: string, position: { top: number; left: number }, direction: string) {
		this.text = text;
		this.position = position;
		this.visible = true;
		this.direction = direction;
		this.renderTooltip();
	}

	hide() {
		this.visible = false;
		this.renderTooltip();
	}
}

const tooltipManager = new TooltipManager();

const useTooltip = () => {
	const showTooltip = useCallback((text: string, position: { top: number; left: number }, direction: string) => {
		tooltipManager.show(text, position, direction);
	}, []);
	const hideTooltip = useCallback(() => {
		tooltipManager.hide();
	}, []);

	const getRef = useCallback(() => {
		return tooltipManager.bubbleRef;
	}, []);
	return { showTooltip, hideTooltip, getRef };
};

interface NomadTooltipProps {
	children: ReactNode;
	text: string;
}

const NomadTooltip = ({ children, text }: NomadTooltipProps) => {
	const { showTooltip, hideTooltip, getRef } = useTooltip();
	const tooltipRef = useRef<HTMLDivElement>(null);
	const hideTimeoutRef = useRef<number | null>(null);

	const handleMouseEnter = useCallback(
		(event: MouseEvent) => {
			if (tooltipRef.current) {
				const rect = tooltipRef.current.getBoundingClientRect();

				const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
				const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

				const elementLeft = rect.x;
				const elementTop = rect.y;
				const elementWidth = rect.width;
				const elementHeight = rect.height;

				const renderOnTop = text.length <= 40;

				const position = renderOnTop
					? {
							top: scrollTop + elementTop - 50,
							left: scrollLeft + elementLeft + elementWidth / 2 - 50,
					  }
					: {
							top: scrollTop + elementTop + elementHeight,
							left: scrollLeft + elementLeft + elementWidth / 2 - 50,
					  };

				showTooltip(text, position, renderOnTop ? 'top' : 'bottom');
			}
		},
		[showTooltip, text]
	);

	const handleMouseLeave = useCallback(
		(event: React.MouseEvent) => {
			if (hideTimeoutRef.current) {
				clearTimeout(hideTimeoutRef.current);
				hideTimeoutRef.current = null;
			}

			hideTimeoutRef.current = window.setTimeout(() => {
				const relatedTarget = event.relatedTarget as HTMLElement;
				if (relatedTarget && (tooltipRef.current?.contains(relatedTarget) || getRef().current?.contains(relatedTarget))) {
					return;
				}
				hideTooltip();
			}, 1000);
		},
		[hideTooltip, getRef]
	);

	useEffect(() => {
		return () => {
			if (hideTimeoutRef.current) {
				clearTimeout(hideTimeoutRef.current);
			}
		};
	}, []);

	return (
		<div ref={tooltipRef} onMouseEnter={e => handleMouseEnter(e as any)} onMouseLeave={e => handleMouseLeave(e as any)}>
			{children}
		</div>
	);
};

export default NomadTooltip;
