import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles';
import { defaultInputRanges, defaultStaticRanges } from '../../defaultRanges';
import { rangeShape } from '../DayCell';
import InputRangeField from '../InputRangeField';
import cx from 'classnames';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

const theme = getTheme();

class DefinedRange extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rangeOffset: 0,
			focusedInput: -1,
		};
	}

	handleRangeChange = (staticRange, value) => {
		const { onDefinedRangeChange, ranges, focusedRange } = this.props;
		const range = staticRange.range(value);
		if (range.startDate < this.props.minDate) {
			range.startDate = this.props.minDate;
		}
		if (range.endDate > this.props.maxDate) {
			range.endDate = this.props.maxDate;
		}
		const selectedRange = ranges[focusedRange[0]];
		if (!onDefinedRangeChange || !selectedRange) {
			return;
		}
		onDefinedRangeChange(
			{
				[selectedRange.key || `range${focusedRange[0] + 1}`]: {
					...selectedRange,
					...range,
				},
			},
			staticRange.label
		);
	};

	getRangeOptionValue(option) {
		const { ranges = [], focusedRange = [] } = this.props;

		if (typeof option.getCurrentValue !== 'function') {
			return '';
		}

		const selectedRange = ranges[focusedRange[0]] || {};
		return option.getCurrentValue(selectedRange) || '';
	}

	getSelectedRange(ranges, staticRange) {
		const focusedRangeIndex = ranges.findIndex(range => {
			if (!range.startDate || !range.endDate || range.disabled) {
				return false;
			}
			return staticRange.isSelected(range) && range.label === staticRange.label;
		});
		const selectedRange = ranges[focusedRangeIndex];
		return { selectedRange, focusedRangeIndex };
	}

	render() {
		const {
			headerContent,
			footerContent,
			onPreviewChange,
			inputRanges,
			staticRanges,
			ranges,
			renderStaticRangeLabel,
			rangeColors,
			className,
		} = this.props;

		return (
			<div className={cx(styles.definedRangesWrapper, className)}>
				{headerContent}
				<div className={styles.staticRanges}>
					{staticRanges.map((staticRange, i) => {
						const { selectedRange, focusedRangeIndex } = this.getSelectedRange(ranges, staticRange);
						let labelContent;

						if (staticRange.hasCustomRendering) {
							labelContent = renderStaticRangeLabel(staticRange);
						} else {
							labelContent = staticRange.label;
						}

						return (
							<button
								type="button"
								className={cx(styles.staticRange, {
									[styles.staticRangeSelected]: Boolean(selectedRange),
								})}
								style={{
									color: selectedRange ? selectedRange.color || rangeColors[focusedRangeIndex] : null,
								}}
								key={i}
								onClick={() => this.handleRangeChange(staticRange)}
								onFocus={() => onPreviewChange && onPreviewChange(staticRange.range(this.props))}
								onMouseOver={() => onPreviewChange && onPreviewChange(staticRange.range(this.props))}
								onMouseLeave={() => onPreviewChange && onPreviewChange()}
								{...generateDataAttrs([new DataAttribute('id', `date-picker-${labelContent.replaceAll(' ', '-')}`)])}>
								<span tabIndex={-1} className={styles.staticRangeLabel}>
									{labelContent}
								</span>
							</button>
						);
					})}
				</div>
				<div className={styles.inputRanges}>
					{inputRanges.map((rangeOption, i) => (
						<InputRangeField
							key={i}
							styles={styles}
							label={rangeOption.label}
							onFocus={() => this.setState({ focusedInput: i, rangeOffset: 0 })}
							onBlur={() => this.setState({ rangeOffset: 0 })}
							onChange={value => this.handleRangeChange(rangeOption, value)}
							value={this.getRangeOptionValue(rangeOption)}
							debugProps={{ dataAttrs: [new DataAttribute('id', `date-picker-days-amount`)] }}
						/>
					))}
				</div>
				{footerContent}
			</div>
		);
	}
}

DefinedRange.propTypes = {
	inputRanges: PropTypes.array,
	staticRanges: PropTypes.array,
	ranges: PropTypes.arrayOf(rangeShape),
	focusedRange: PropTypes.arrayOf(PropTypes.number),
	onPreviewChange: PropTypes.func,
	onChange: PropTypes.func,
	footerContent: PropTypes.any,
	headerContent: PropTypes.any,
	rangeColors: PropTypes.arrayOf(PropTypes.string),
	className: PropTypes.string,
	renderStaticRangeLabel: PropTypes.func,
	onDefinedRangeChange: PropTypes.func,
};

DefinedRange.defaultProps = {
	inputRanges: defaultInputRanges,
	staticRanges: defaultStaticRanges,
	ranges: [],
	rangeColors: [theme.primaryColor, '#3ecf8e', '#fed14c'],
	focusedRange: [0, 0],
};

export default DefinedRange;
