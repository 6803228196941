import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Checkbox } from '../../form/checkbox/checkbox';
import { isSubRow } from '../table';
import { HeaderContext, CellContext } from '@tanstack/react-table';
import styles from './table-selection-cell.module.scss';

interface ISelectionCell<T> {
	cellProps: CellContext<T, unknown>;
}

export const TableSelectionCell = <T,>({ cellProps }: ISelectionCell<T>) => {
	const { row } = cellProps;
	const checkboxProps = {
		checked: row.getIsSelected(),
		// indeterminate: row.getIsSomeSelected(),
		onChange: row.getToggleSelectedHandler(),
	};

	// delete checkboxProps.indeterminate; // TODO
	if (isSubRow(row)) {
		return <span></span>;
	}
	return <Checkbox className={styles.checkbox} {...checkboxProps} debugProps={{ dataAttrs: [new DataAttribute('id', 'checkbox')] }} />;
};

interface ISelectionHeader<T> {
	headerProps: HeaderContext<T, unknown>;
}

export const TableSelectionHeader = <T,>({ headerProps }: ISelectionHeader<T>) => {
	const { table } = headerProps;
	const checkboxProps = {
		checked: table.getIsAllRowsSelected(),
		// indeterminate: table.getIsSomeRowsSelected(),
		onChange: table.getToggleAllRowsSelectedHandler(),
	};
	// delete checkboxProps.indeterminate; // TODO
	return <Checkbox {...checkboxProps} className={styles.checkbox} debugProps={{ dataAttrs: [new DataAttribute('id', 'checkbox')] }} />;
};
