import routes from '../routes/index.routes';
import { Tools } from '../../pages/tools/index/tools.page';
import { ToolsReplaceSegmentationCids } from '../../pages/tools/replace-segmentation-cids/replace-segmentation-cids.page';
import { ToolsAddSegmentationsToCampaign } from '../../pages/tools/add/add-segmentations-to-campaign.page';
import { ToolsViewCampaignSegmentations } from '../../pages/tools/view/view-campaign-segmentations.page';

export const ToolsRouter = [
	{
		path: routes.tools.index(),
		element: <Tools />,
		children: [
			{
				path: routes.tools.tool('replace-cids'),
				element: <ToolsReplaceSegmentationCids />,
			},
			{
				path: routes.tools.tool('create-campaign-segmentation'),
				element: <ToolsAddSegmentationsToCampaign />,
			},
			{
				path: routes.tools.tool('view-campaign-segmentation'),
				element: <ToolsViewCampaignSegmentations />,
			},
		],
	},
];
