import { CreateCreative } from '../../pages/creatives/create/create-creative.page';
import { DebugCreative } from '../../pages/creatives/debug/debug-creative.page';
import { EditCreative } from '../../pages/creatives/edit/edit-creative.page';
import { Creatives } from '../../pages/creatives/index/creatives.page';
import { CreativeCampaigns } from '../../pages/creatives/view/camapigns/creatives.campaigns';
import { CreativeView } from '../../pages/creatives/view/creative-view.page';
import routes from '../routes/index.routes';
import { Soon } from '@monorepo/base/src/components/soon/soon';

export const CreativesRouter = [
	{
		path: routes.creatives.index(),
		element: <Creatives />,
	},
	{
		path: routes.creatives.create(),
		element: <CreateCreative />,
	},
	{
		path: routes.creatives.edit(':creativeId'),
		element: <EditCreative />,
	},
	{
		path: routes.creatives.debug(':creativeId'),
		element: <DebugCreative />,
	},
	{
		element: <CreativeView />,
		children: [
			{
				path: routes.creatives.view(':creativeId'),
				element: <Soon />,
			},
			{
				path: routes.creatives.campaigns(':creativeId'),
				element: <CreativeCampaigns />,
			},
		],
	},
];
