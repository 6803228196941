import { DataColumnConfig } from '../../../columns/columns';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { CreatedAtColumnConfig, CreatedByColumnConfig } from '../../../columns/data.columns';
import { ReportFilterType, ReportSortOrder } from '../../../stores/data-stores/old-report.types';
import { TextFilterDefinition } from '../../../table-filters/filters.definitions';
import { FilterType } from '../../../table-filters/filters.types';

export const domainHistoryTableConfig = {
	reportParams: {
		reportName: 'adxDomainHistory',
		sortBy: 'created_at',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new DataColumnConfig({
			name: 'id',
			accessor: 'id',
			header: 'ID',
			visibility: ColumnVisibilityOptions.AlwaysVisible,
		}),
		new DataColumnConfig({
			name: 'domain_status',
			accessor: 'domainStatus',
			header: 'Status',
			visibility: ColumnVisibilityOptions.Visible,
			availableFilters: {
				type: FilterType.Select,
				actions: [
					{
						label: 'Equals',
						value: ReportFilterType.EQUALS,
					},
				],
				options: [
					{
						label: 'BLOCKED',
						value: 'BLOCKED',
					},
					{
						label: 'NEW',
						value: 'NEW',
					},
				],
			},
		}),
		new DataColumnConfig({
			name: 'description',
			accessor: 'description',
			header: 'Description',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new DataColumnConfig({
			name: 'billing_name',
			accessor: 'billingName',
			header: 'Billing Name',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new DataColumnConfig({
			name: 'billing_id',
			accessor: 'billingId',
			header: 'Billing ID',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new CreatedByColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CreatedAtColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
	],
	tableState: { pagination: { pageIndex: 0, pageSize: 5 } },
	isGraphAvailable: false,
	tableName: 'domainsHistoryTable',
};
