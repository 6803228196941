import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter } from '../../components/filters-menu/filters';
import { Decisions } from '../../enums/decisions';
import { Statuses } from '../../enums/status';

export const jobFilters = new Map<FilterTypeKey, FilterType>([
	[
		'numberFilters',
		{
			max_retries: { columnName: 'Max Retries', cellKey: 'max_retries' },
			retry_timeout: { columnName: 'Retry Timeout', cellKey: 'retry_timeout' },
			refresh_rate: { columnName: 'Refresh Rate', cellKey: 'refresh_rate' },
		} as INumberFilter,
	],
	[
		'stringFilters',
		{
			name: { columnName: 'Name', cellKey: 'name' },
			task_classes: { columnName: 'Task', cellKey: 'task_classes' },
			comment: { columnName: 'Comment', cellKey: 'comment' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			is_active: {
				columnName: 'Active',
				cellKey: 'is_active',
				enumOptions: [
					{ value: Statuses.Active, label: 'Active' },
					{ value: Statuses.Paused, label: 'Paused' },
				],
				deletable: true,
			},
			run_on_init: {
				columnName: 'Run On Init',
				cellKey: 'run_on_init',
				enumOptions: [
					{ value: Decisions.Yes, label: Decisions.Yes },
					{ value: Decisions.No, label: Decisions.No },
				],
				deletable: true,
			},
		} as IEnumFilter,
	],
]);
