import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useCampaignTableOfContents } from '../../../modules/hooks/toc/campaign.toc';
import { Nav, NavGroup, NavItem, NavPage } from '@monorepo/base/src/components/nav/nav';
import { Page } from '@monorepo/base/src/components/page/page';
import { TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import routes from '../../../modules/routes/index.routes';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';
import { observer } from 'mobx-react';

export const AdvertiserView = observer(() => {
	const { advertiserId } = useParams();
	const navigate = useNavigateParams();
	const { currentRoute } = useRoute();
	const { isTableOfContents, tableOfContents, setIsTableOfContents } = useCampaignTableOfContents();
	useNestedSidebar();

	if (!advertiserId) {
		navigate(`${routes.advertisers.index()}`, { replace: true });
		return null;
	}

	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);
	useEffect(() => {
		setActiveRoute(currentRoute);
	}, [currentRoute]);

	const renderLastSection = () => {
		if (isTableOfContents) {
			return <TableOfContents title={'Campaign'} divider={true} heads={tableOfContents} />;
		}
		return (
			<NavGroup title={'Actions'}>
				<NavItem to={advertiserId ? routes.advertisers.edit(advertiserId) : ''}>Edit Advertiser</NavItem>
			</NavGroup>
		);
	};

	return (
		<NavPage>
			<Nav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				<NavItem to={advertiserId ? `${routes.advertisers.view(advertiserId)}` : ''}>Overview</NavItem>
				<NavItem to={advertiserId ? `${routes.advertisers.campaignGroups(advertiserId)}` : ''}>Campaign Groups</NavItem>
				<NavItem to={advertiserId ? `${routes.advertisers.campaigns(advertiserId)}` : ''}>Campaigns</NavItem>
				<NavItem to={advertiserId ? `${routes.advertisers.creativeGroups(advertiserId)}` : ''}>Creative Groups</NavItem>
				<NavItem to={advertiserId ? `${routes.advertisers.creatives(advertiserId)}` : ''}>Creatives</NavItem>
				<NavGroup title={'Placements'}>
					<NavItem to={advertiserId ? `${routes.advertisers.domains(advertiserId)}` : ''}>Domains</NavItem>
					<NavItem to={advertiserId ? `${routes.advertisers.pages(advertiserId)}` : ''}>Pages</NavItem>
					<NavItem to={advertiserId ? `${routes.advertisers.bidKeys(advertiserId)}` : ''}>BidKeys</NavItem>
				</NavGroup>
				{renderLastSection()}
			</Nav>
			<Page unstyled={true}>
				<Outlet context={{ setIsTableOfContents }} />
			</Page>
		</NavPage>
	);
});
