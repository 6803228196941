import { IUserCreateForm, IUserEditForm, UserModel } from '../../models/user.model';
import { UsersApi } from '../../apis/users.api';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { runInAction } from 'mobx';
import { ErrorPayloadType, HttpError, IHttpError } from '../../models/http-error.model';
import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';

export interface IUserRes {
	payload: { user: UserModel };
}

export class UserCrudStore extends BaseCrudStore<UserModel, IUserCreateForm, IUserEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: UsersApi,
			model: UserModel,
			errorModel: HttpError,
		});
	}

	public verify2faValidation(): boolean {
		this.formStore.reset();
		this.clearHttpError();
		const code = this.getData().getCode();
		if (!code) {
			this.formStore.addError(new FormError('code', 'Please enter a valid code'));
		}

		return this.formStore.getIsValid();
	}

	public validate2fa({ userId, token }: { userId: string; token: string }): Promise<FullResponse<IUserRes> | void> {
		const isValid = this.verify2faValidation();
		if (!isValid) {
			this.setHttpError(new HttpError({ message: 'Please fix the issues above' } as IHttpError));
			return Promise.resolve();
		}

		this.setIsLoading(true);
		this.setIsSuccess(false);

		return UsersApi.validate2fa({ userId, token })
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(error => {
				this.onError(error.data);
				runInAction(() => {
					this.setHttpError(new HttpError(error.data));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	public profile(): Promise<UserModel> {
		this.setIsLoading(true);
		this.setIsSuccess(false);
		this.setHttpError(null);

		return UsersApi.profile()
			.then(res => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
				return res;
			})
			.catch(error => {
				this.onError(error.data);
				runInAction(() => {
					this.setHttpError(new HttpError(error.data));
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}

	public onError(error: IHttpError) {
		if (error?.errorPayload?.type === ErrorPayloadType.Properties) {
			Object.keys(error.errorPayload?.data).forEach(key => {
				this.formStore.addError(new FormError(key, error.errorPayload?.data[key] as string));
			});
		}
	}

	getCreateFormData(): IUserCreateForm {
		throw new Error('Method not implemented.');
	}
	getEditFormData(): IUserEditForm {
		throw new Error('Method not implemented.');
	}
	isValid(): boolean {
		throw new Error('Method not implemented.');
	}
}
