import styles from './data-comparison.module.scss';

interface IDataComparisonModal {
	changes: { key: string; old: any; new: any }[];
	ignoreKeys?: string[];
}

export const DataComparisonModal = (props: IDataComparisonModal) => {
	const { changes, ignoreKeys = [] } = props;

	return (
		<div className={styles.wrapper}>
			<div>Be aware of recent changes. See the comparison below.</div>
			{ignoreKeys.length > 0 && (
				<div>
					The comparison <b>does not</b> include the following keys: <b>{ignoreKeys.join(', ')}</b>.{' '}
				</div>
			)}
			{changes.map(change => {
				if (ignoreKeys.includes(change.key)) {
					return null;
				}

				if (typeof change.old === 'object' || typeof change.new === 'object') {
					change.old =
						Array.isArray(change.old) && change.old.length > 4
							? `${change.key} size is ${change.old.length}`
							: JSON.stringify(change.old);
					change.new =
						Array.isArray(change.new) && change.new.length > 4
							? `${change.key} size is ${change.new.length}`
							: JSON.stringify(change.new);
				}

				return (
					<div className={styles.changeWrapper}>
						<div>{`Property: ${change.key}`}</div>
						<div className={styles.old}>{`Old value: ${change.old}`}</div>
						<div className={styles.new}>{`New value: ${change.new}`}</div>
					</div>
				);
			})}
		</div>
	);
};
