import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useCampaignGet } from '../../../../modules/hooks/apis/use-campaign-get';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { useMergeWithPerformance } from '../../../../modules/hooks/tools/use-merge-with-performance';
import { IPerformanceOptionsFilterType, SideEffectKeys } from '../../../../modules/hooks/apis/use-performance';
import { DealModel } from '../../../../modules/models/deal.model';
import { usePerformanceEntity } from '../../../../modules/hooks/apis/use-performance-entity';
import { useDealHeaders } from '../../../../modules/hooks/theaders/deals.headers';
import { IAdminxStore } from '../../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useDealActions } from '../../../../modules/hooks/actions/deals.actions';
import { PerformancesColumnsSnakeCase } from '../../../../modules/hooks/apis/use-reports-list';

export const CampaignDeals = observer(() => {
	const { campaignStore, dealStore } = useStores<IAdminxStore>();
	const performanceStore = dealStore.getPerformance();
	const { SelectedActions, SegmentActions, FiltersActions } = useDealActions();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const { campaignId } = useParams();
	if (campaignId) {
		useCampaignGet({ id: campaignId });
		const campaignFilter = {
			column: PerformancesColumnsSnakeCase.CampaignId,
			filterType: IPerformanceOptionsFilterType.Include,
			value: [campaignId],
		};
		usePerformanceEntity(performanceStore, {
			filters: [campaignFilter],
		});
	}

	const campaignStoreCrud = campaignStore.getCrud();
	const campaignDeals = campaignStoreCrud
		.getData()
		.getPreferredDeals()
		?.map(campaignDeal => campaignDeal);

	const { data, summary } = useMergeWithPerformance<DealModel>({
		data: campaignDeals || [],
		key: PerformancesColumnsSnakeCase.PreferredDealId,
		foreignKey: SideEffectKeys.DealId,
		attachExtraParams(row) {
			return {
				preferred_deal_id: row.deal_id,
				deal_name: row.deal_name,
				name: row.deal_name,
				serving_status: row.serving_status,
				formats: row.formats,
				proposal_id: row.proposal_id,
				proposal_name: row.proposal_name,
				publisher_profile: row.publisher_profile,
			};
		},
	});

	const { columns } = useDealHeaders({ summary });
	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Deals</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				columns={columns}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'deals_campaign_table')] }}
				data={data}
				isLoading={campaignStore.getCrud().getIsLoading()}
				isLoadingPerformance={performanceStore.getIsLoading()}
				isSummary={true}>
				{() => {
					return {
						SelectedActions,
						SegmentActions,
						FiltersActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
	return null;
});
