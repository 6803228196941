import { CrudEndpoint } from './crud.endpoint';

export class PreferencesEndpoint extends CrudEndpoint {
	constructor() {
		super('preferences');
	}

	bulkDelete() {
		return `${this.baseUrl}`;
	}
}
