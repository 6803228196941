import { observer } from 'mobx-react';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useTestActions } from '../../../modules/hooks/actions/test.actions';
import { PerformancesColumnsSnakeCase } from '../../../modules/hooks/apis/use-reports-list';
import { useList } from '../../../modules/hooks/tools/use-list';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useMergeWithPerformance } from '../../../modules/hooks/tools/use-merge-with-performance';
import { TestModel } from '../../../modules/models/test.model';
import routes from '../../../modules/routes/index.routes';
import { usePerformanceEntity } from '../../../modules/hooks/apis/use-performance-entity';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useTestHeaders } from '../../../modules/hooks/theaders/tests.headers';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '../../../modules/hooks/tools/use-route';

const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'tests_pagebar')] };
const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'tests_table')] };
const actionsDebugProps = { dataAttrs: [new DataAttribute('id', 'tests_actions')] };

export const Tests: FC = observer(() => {
	const { testStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const performanceStore = testStore.getPerformance();

	useList<TestModel>(testStore.getListStore());
	usePerformanceEntity(performanceStore);

	const { data, summary } = useMergeWithPerformance<TestModel>({
		performanceStore,
		data: testStore.getListStore().getList(),
		key: PerformancesColumnsSnakeCase.TestId,
	});

	const { SelectedActions, SegmentActions, FiltersActions } = useTestActions({ debugProps: actionsDebugProps });
	const { columns } = useTestHeaders({ summary });

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Tests</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				createRoute={routes.tests.create()}
				columns={columns}
				data={data}
				isLoading={testStore.getListStore().getIsLoading()}
				debugProps={tableDebugProps}
				isLoadingPerformance={performanceStore.getIsLoading()}
				isSummary={true}
				defaultSortBy={[{ id: 'name', desc: false }]}>
				{() => {
					return {
						SelectedActions,
						SegmentActions,
						FiltersActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
