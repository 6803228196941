import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './create-preference.module.scss';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { PreferenceForm } from '../../../modules/components/forms/preference/preference.form';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import routes from '../../../modules/routes/index.routes';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

export const CreatePreference = observer(() => {
	const { preferenceStore, toastsStore } = useStores<IAdminxStore>();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const preferenceCrudStore = preferenceStore.getCrud();

	const { dispatchLog } = useLogs();
	const navigate = useNavigateParams();
	const location = useLocation();

	useEffect(() => {
		preferenceCrudStore.reset();
	}, [location]);

	const name = preferenceCrudStore.getData().getName();
	const formStore = preferenceCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = preferenceCrudStore.getIsLoading();
	const isSuccess = preferenceCrudStore.getIsSuccess();
	const error = preferenceCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(`${routes.preferences.index()}`, {
				state: { isBlockRequset: false } as BetweenPagesStateProps,
			});
			toastsStore.addToastOnNav({ msg: `Preference ${name} was successfully added`, type: DispatchLogsTypes.Success });
			preferenceCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onCreatePreference = () => {
		setFormClicked(true);
		preferenceStore.getCrud().create();
	};

	return (
		<SiderSlider
			onClose={() => navigate(routes.preferences.index(), { state: { isBlockRequest: true } as BetweenPagesStateProps })}
			isOpen={true}>
			<Page className={isLoading ? styles.loadingPage : ''}>
				<Pagebar>
					<PagebarTitle>
						<div className={styles.title}>Create preference</div>
					</PagebarTitle>
				</Pagebar>
				<PreferenceForm />
			</Page>
			<BottomBar>
				{error ? <ErrorMsg error={error} /> : null}
				<BottomBarActions>
					<PrimaryButton
						className={styles.createBtn}
						onClick={() => onCreatePreference()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'create_preference_button')] }}
						isRotateIcon={isLoading}
						icon={isLoading ? 'loading-01' : 'send-03'}>
						Create
					</PrimaryButton>{' '}
				</BottomBarActions>
			</BottomBar>
		</SiderSlider>
	);
});
