import {
	AdvertiserIdAdxColumnConfig,
	AdvertiserNameAdxColumnConfig,
	AuctionTypeAdxColumnConfig,
	BidKeyAdxColumnConfig,
	BrowserNameAdxColumnConfig,
	CampaignGroupIdAdxColumnConfig,
	CampaignGroupNameAdxColumnConfig,
	CampaignIdAdxColumnConfig,
	CampaignNameAdxColumnConfig,
	CityAdxColumnConfig,
	CreativeBrandAdxColumnConfig,
	CreativeGroupDesignAdxColumnConfig,
	CreativeGroupIdAdxColumnConfig,
	CreativeIdAdxColumnConfig,
	CreativeSizeAdxColumnConfig,
	DateAdxColumnConfig,
	DomainAdxColumnConfig,
	GeoAdxColumnConfig,
	OsNameAdxColumnConfig,
	PageAdxColumnConfig,
	PreferredDealIdAdxColumnConfig,
	PreferredDealNameAdxColumnConfig,
	PublisherIdAdxColumnConfig,
	StateAdxColumnConfig,
	StrategyIdAdxColumnConfig,
	TestGroupNameAdxColumnConfig,
	TestIdAdxColumnConfig,
	TestNameAdxColumnConfig,
} from '../../../columns/old-adx-report.columns';
import { generatePerformanceColumns } from '../../../columns/metrics.columns';
import { IReportTableStoreParams } from '../../../stores/table-stores/old-report-table.store';
import { ReportSortOrder } from '../../../stores/data-stores/old-report.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export interface ReportMetrics {
	auctions: number;
	avgBidFloor: number;
	avgBidToWin: number;
	clicks: number;
	conversions: number;
	cost: number;
	cpc: number;
	cpm: number;
	ctr: number;
	impressions: number;
	maxBidFloor: number;
	maxBidToWin: number;
	minBidFloor: number;
	minBidToWin: number;
	optCost: number;
	winRate: number;
	wins: number;
	totalBudgetLimit: number;
	conversionValue: number;
	roas: number;
	lpCvr: number;
	optGain: number;
	gain: number;
	cpa: number;
	bid: string;
}

export interface DynamicReport extends ReportMetrics {
	date: string;
	campaignId: number;
	campaign: string;
	campaignGroupId: number;
	campaignGroup: string;
	advertiserId: number;
	advertiser: string;
	creativeId: number;
	testId: number;
	page: string;
	publisherId: string;
	browserName: string;
	osName: string;
	geo: string;
	state: string;
	city: string;
	auctionType: string;
	creativeBrandName: string;
	creativeSize: string;
	creativeGroupDesign: string;
	preferredDealId: string;
	testName: string;
	preferredDealName: string;
	testGroupName: string;
	domain: string;
	creativeGroupId: number;
	bidKey: string;
	bidIsEditable: boolean;
	bidKeyId: number;
	creativeImageUrl: string;
}

export const dynamicReportTableConfig: IReportTableStoreParams = {
	reportParams: {
		reportName: 'adx',
		sortBy: 'wins',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new DateAdxColumnConfig({}),
		new CampaignNameAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CampaignIdAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AdvertiserNameAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AdvertiserIdAdxColumnConfig({}),
		new CampaignGroupNameAdxColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new CampaignGroupIdAdxColumnConfig({}),
		new CreativeGroupDesignAdxColumnConfig({}),
		new CreativeGroupIdAdxColumnConfig({}),
		new CreativeBrandAdxColumnConfig({}),
		new CreativeIdAdxColumnConfig({}),
		new StrategyIdAdxColumnConfig({}),
		new CreativeSizeAdxColumnConfig({}),
		new TestIdAdxColumnConfig({}),
		new TestNameAdxColumnConfig({}),
		new TestGroupNameAdxColumnConfig({}),
		new DomainAdxColumnConfig({}),
		new PreferredDealIdAdxColumnConfig({}),
		new PreferredDealNameAdxColumnConfig({}),
		new PublisherIdAdxColumnConfig({}),
		new BrowserNameAdxColumnConfig({}),
		new OsNameAdxColumnConfig({}),
		new GeoAdxColumnConfig({}),
		new StateAdxColumnConfig({}),
		new CityAdxColumnConfig({}),
		new AuctionTypeAdxColumnConfig({}),
		new BidKeyAdxColumnConfig({}),
		new PageAdxColumnConfig({}),

		...generatePerformanceColumns(),
	],
	isGraphAvailable: true,
	tableName: 'dynamic-report',
};
