import { AdvertisersApi } from '../../apis/advertisers.api';
import { CreativeGroupsApi } from '../../apis/creative-groups.api';
import { CreativesApi } from '../../apis/creatives.api';
import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter, IEntityFilter } from '../../components/filters-menu/filters';
import { performanceFilters } from '../../components/filters-menu/filters/perfomance.filter';
import { ResourceTypes } from '../../enums/creative-resource-types';

const parentEntitiesMapper = new Map<string, string>([['creative-groups', 'creative_group']]);

export const creativeFilters = new Map<FilterTypeKey, FilterType>([
	[
		'entityFilters',
		{
			...performanceFilters.entityFilters,
			campaignEntity: {
				columnName: 'Creative',
				cellKey: 'id',
				isClientFilter: true,
				autocompleteCallback: async () => {
					const locationPathname = window.location.pathname;
					const parentEntityName = locationPathname.split('/')[1];
					const parentEntityId = locationPathname.split('/')[2]?.trim();
					return await CreativesApi.list().then(creatives => {
						return creatives
							.map(creative => {
								if (!parentEntityId || !parentEntityName) {
									return {
										label: `${creative.brand} (${creative.id})`,
										value: creative.id,
									};
								}

								if ((creative as any)[parentEntitiesMapper.get(parentEntityName) as string]?.id == parentEntityId) {
									return {
										label: `${creative.brand} (${creative.id})`,
										value: creative.id,
									};
								}
							})
							.filter(creative => creative);
					});
				},
			},
			creativeGroupEntity: {
				columnName: 'Creative Group',
				cellKey: 'creative_group.id',
				isClientFilter: true,
				autocompleteCallback: async () => {
					return await CreativeGroupsApi.list().then(creativeGroups => {
						return creativeGroups.map(creativeGroup => {
							return {
								label: `${creativeGroup.design} (${creativeGroup.id})`,
								value: creativeGroup.id,
							};
						});
					});
				},
			},
			advertiserEntity: {
				columnName: 'Advertiser',
				cellKey: 'creative_group.advertiser.id',
				isClientFilter: true,
				autocompleteCallback: async () => {
					return await AdvertisersApi.list().then(advertisers => {
						return advertisers.map(advertiser => {
							return {
								label: `${advertiser.name} (${advertiser.id})`,
								value: advertiser.id,
							};
						});
					});
				},
			},
		} as IEntityFilter,
	],
	['numberFilters', { ...performanceFilters.numberFilters } as INumberFilter],
	[
		'stringFilters',
		{
			...performanceFilters.stringFilters,
			name: { columnName: 'Brand', cellKey: 'name' },
			resource_url: { columnName: 'Resource URL', cellKey: 'resource_url' },
			size: { columnName: 'Size', cellKey: 'size' },
			default_dest_url: { columnName: 'Destination URL', cellKey: 'default_dest_url' },
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
			resource_type: {
				columnName: 'Resource Type',
				cellKey: 'resource_type',
				enumOptions: [
					{ value: ResourceTypes.HTML, label: ResourceTypes.HTML },
					{ value: ResourceTypes.IMAGE, label: ResourceTypes.IMAGE },
					{ value: ResourceTypes.NATIVE, label: ResourceTypes.NATIVE },
				],
				deletable: true,
			},
			adx_deals_status: {
				columnName: 'Deal Status',
				cellKey: 'adx_deals_status',
				enumOptions: [
					{ value: 'approved', label: 'Approved' },
					{ value: 'pending', label: 'Pending' },
					{ value: 'disapproved', label: 'Disapproved' },
				],
				deletable: true,
			},
			adx_network_status: {
				columnName: 'Network Status',
				cellKey: 'adx_network_status',
				enumOptions: [
					{ value: 'active', label: 'Active' },
					{ value: 'pending', label: 'Pending' },
					{ value: 'disapproved', label: 'Disapproved' },
				],
				deletable: true,
			},
		} as IEnumFilter,
	],
]);
