import { Fragment } from 'react';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { campaignFilterPrototypes } from '../../components/filters-menu/filters';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, useDefaultActions } from './default.actions';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import { CampaignModel, ICampaign } from '../../models/campaign.model';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { EnablePauseCampaignsModal } from '../../components/modals/enable-pause-campaigns';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IAdminxStore } from '../../stores';
import { Statuses } from '../../enums/status';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';

const theme = getTheme();

interface ICampaignActions {
	debugProps?: IDebugProps;
}

export function useCampaignActions(props: ICampaignActions = {}) {
	const { debugProps } = props;
	const { campaignStore, modalsStore, toastsStore, settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const campaignsCrudStore = campaignStore.getCrud();
	const navigate = useNavigateParams();
	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars);
	const currentFilters = filterStore?.getCurrentFilters();
	const { dataAttrs } = debugProps || {};

	const DefaultAvailableFilters = [...campaignFilterPrototypes];

	const onEditRoute = (id: id) => routes.campaigns.edit(id);
	const onDelete = (ids: id[]) => campaignStore.getCrud().delete(ids[0]);

	const funcActions = {
		onEditRoute,
		onDelete,
	};

	const duplicateCampaign = (campaignId: id) => {
		const campaigns = campaignStore.getListStore().getData()?.data || [];
		const selectedCampaign = campaigns.find(campaign => campaignId === campaign.id);
		if (selectedCampaign) {
			campaignStore.getCrud().setData(new CampaignModel(selectedCampaign as ICampaign));
			navigate(`${routes.campaigns.duplicate(campaignId)}`);
		}
	};

	const enableCampaigns = (campaignIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Enable'}
					onAction={() => {
						campaignsCrudStore.enableCampaigns(campaignIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Campaigns ${campaignIds} Enabled`, type: DispatchLogsTypes.Success });
							navigate(0);
						});
					}}>
					<EnablePauseCampaignsModal action={'Enable'} campaignIds={campaignIds} />
				</ActionModal>
			),
		});
	};

	const pauseCampaigns = (campaignIds: id[]) => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Paused'}
					onAction={() => {
						campaignsCrudStore.pauseCampaigns(campaignIds).then(() => {
							toastsStore.addToastOnNav({ msg: `Campaigns ${campaignIds} Paused`, type: DispatchLogsTypes.Success });
							navigate(0);
						});
					}}>
					<EnablePauseCampaignsModal action={'Paused'} campaignIds={campaignIds} />
				</ActionModal>
			),
		});
	};

	// TODO - row should be converted to CampaignModel
	const SelectedActions = (props: ITableActions<CampaignModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new CampaignModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities, debugProps, ...funcActions });
		const entitiesIds: id[] = [];
		entities.forEach(e => {
			const id = e.getId();
			if (id) {
				entitiesIds.push(id);
			}
		});

		return (
			<Fragment>
				<SelectedLinerButton>
					<SecondaryText
						icon={'play'}
						onClick={() => enableCampaigns(entitiesIds)}
						textColor={'white'}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_enable', dataAttrs) }}>
						Enable
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						icon={'pause-square'}
						onClick={() => pauseCampaigns(entitiesIds)}
						textColor={'white'}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_pause', dataAttrs) }}>
						Pause
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<EditAction debugProps={{ dataAttrs: suffixToDataAttr('_selected_edit', dataAttrs) }} />
				</SelectedLinerButton>
				<SelectedLinerButton>
					<SecondaryText
						textColor={'white'}
						icon={'file-plus-01'}
						disabled={entitiesIds.length !== 1}
						onClick={() => duplicateCampaign(entitiesIds[0])}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_duplicate', dataAttrs) }}>
						Duplicate
					</SecondaryText>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<DeleteAction
						disabled={entitiesIds.length !== 1}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_delete', dataAttrs) }}
					/>
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<CampaignModel>) => {
		const { entity } = props;
		// const campaign = new CampaignModel(row.original);
		const { EditAction, DeleteAction } = useDefaultActions({ entities: [entity], ...funcActions });
		const entityId = entity.getId();
		if (!entityId) {
			// log error
			return null;
		}

		return (
			<Fragment>
				{entity.getStatus() === Statuses.Paused ? (
					<QuickActionButton>
						<SecondaryText
							icon={'circle'}
							iconSize={'14px'}
							iconColor={'#0b8043'}
							textColor={theme.titleColor}
							onClick={() => enableCampaigns([entityId])}>
							Enable
						</SecondaryText>
					</QuickActionButton>
				) : null}
				{entity.getStatus() === Statuses.Active ? (
					<QuickActionButton>
						<SecondaryText
							icon={'pause-circle'}
							iconSize={'14px'}
							iconColor={theme.titleColor}
							textColor={theme.titleColor}
							onClick={() => pauseCampaigns([entityId])}>
							Pause
						</SecondaryText>
					</QuickActionButton>
				) : null}
				<QuickActionButton>
					<EditAction iconColor={theme.titleColor} textColor={theme.titleColor} />
				</QuickActionButton>
				<QuickActionButton>
					<SecondaryText
						icon={'file-plus-01'}
						iconSize={'14px'}
						iconColor={theme.titleColor}
						textColor={theme.titleColor}
						onClick={() => duplicateCampaign(entityId)}>
						Duplicate
					</SecondaryText>
				</QuickActionButton>
				<QuickActionButton>
					<DeleteAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} />
				</QuickActionButton>
			</Fragment>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	// const SegmentActions = (props: ISegmentActions) => {
	// 	const { segments, onSegment } = useSegments<CampaignModel>({ performanceStore });

	// 	return (
	// 		<Segments<CampaignModel, PerformanceModel, PerformancesColumnsSnakeCase>
	// 			currentSegments={performanceStore.getSegments()}
	// 			availableSegments={segments}
	// 			onClick={segment => onSegment(segment)}
	// 			{...props}
	// 		/>
	// 	);
	// };

	return {
		SelectedActions,
		EntityActions,
		FiltersActions,
		// SegmentActions,
	};
}
