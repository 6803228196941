import { CampaignSegmentationsModel, ICampaignSegmentationCreateForm, SegmentationModel } from '../models/campaign-segmentation.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export const CampaignSegmentationsApi = {
	create: async (
		campaignSegmentation: ICampaignSegmentationCreateForm,
		options?: IRequestOptions
	): Promise<CampaignSegmentationsModel> => {
		if (!campaignSegmentation?.campaignId) {
			return new Promise(res => res(new CampaignSegmentationsModel()));
		}

		return ask.post(
			endpoints.campaignSegmentations.createWith(campaignSegmentation?.campaignId),
			{
				cid_segments: campaignSegmentation?.getSegmentations()?.map(seg => ({
					auction_package_id: seg.auction_package_id,
					auction_package_name: seg.auction_package_name,
					source_cid: seg.source_cid,
					target_cid: seg.target_cid,
				})),
			},
			options
		);
	},

	edit: (): Promise<CampaignSegmentationsModel> => {
		throw new Error('Not implemented');
	},

	list: async (): Promise<CampaignSegmentationsModel[]> => {
		throw new Error('Not implemented');
	},

	get: async (id: id): Promise<{ cid_segments: SegmentationModel[] }> => {
		return ask.get(endpoints.campaignSegmentations.get(id));
	},

	delete: async () => {
		throw new Error('Not implemented');
	},
};
