import { Fragment } from 'react';
import { useTableContext } from '../table-component';
import { TableRawActionButton } from '../table-elements/table-actions';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../stores';
import routes from '../../../routes/index.routes';
import { DeleteModal } from '../../modals/delete-modal';
import { observer } from 'mobx-react';

export const CampaignGroupRowActions = observer(() => {
	const { tableStore } = useTableContext();
	const { campaignGroupsStore, modalsStore } = useStores<IAdminxStore>();
	const campaignGroupCrudStore = campaignGroupsStore.getCrud();
	const { dispatchLog } = useLogs();

	const selectedIds = tableStore.getSelectedRows().map(r => r['campaign_group_id']) as string[];
	const campaignGroupSingleAction = tableStore.getSelectedRows()[0];

	const deleteAdvertiser = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Delete'}
					onAction={() => {
						const deleteId = selectedIds[0];
						campaignGroupCrudStore
							.delete(deleteId)
							.then(() => {
								dispatchLog({
									msg: `Campaign Group ${campaignGroupSingleAction['campaign_group_name']}(${deleteId}) ${'Deleted'}`,
									type: DispatchLogsTypes.Success,
								});
								tableStore.fetchReport({}, false);
							})
							.catch(() => {
								dispatchLog({
									msg: `Delete ${campaignGroupSingleAction['campaign_group_name']}(${deleteId}) Failed`,
									type: DispatchLogsTypes.Error,
								});
							});
					}}>
					<DeleteModal
						label={`Are you sure you want to ${campaignGroupSingleAction['campaign_group_name']}(${selectedIds[0]}) these rows?`}
					/>
				</ActionModal>
			),
		});
	};

	return (
		<Fragment>
			<TableRawActionButton
				text={'Edit'}
				to={routes.campaignGroups.edit(selectedIds[0])}
				icon={'edit-02'}
				disabled={selectedIds.length !== 1}
			/>
			<TableRawActionButton text={'Delete'} onClick={deleteAdvertiser} icon={'trash-02'} disabled={selectedIds.length !== 1} />
		</Fragment>
	);
});
