import { OAuthProviderEnum } from '../enums/oauth-providers';

export class AuthRoutes {
	login() {
		return 'login';
	}
	redirect(provider: OAuthProviderEnum) {
		return `redirect/${provider}`;
	}
	twoFactor() {
		return `/2fa`;
	}
}
