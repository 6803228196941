import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useCampaignTableOfContents } from '../../../modules/hooks/toc/campaign.toc';
import { Nav, NavGroup, NavItem, NavPage } from '@monorepo/base/src/components/nav/nav';
import { Page } from '@monorepo/base/src/components/page/page';
import { TableOfContents } from '@monorepo/base/src/components/table-of-contents/table-of-contents';
import { useRoute } from '../../../modules/hooks/tools/use-route';
import routes from '../../../modules/routes/index.routes';
import { route } from '@monorepo/tools/src/lib/types/url';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';
import { observer } from 'mobx-react';

export const CampaignView = observer(() => {
	const { campaignId } = useParams();
	const navigate = useNavigateParams();
	const { currentRoute } = useRoute();
	const { isTableOfContents, tableOfContents, setIsTableOfContents } = useCampaignTableOfContents();

	useNestedSidebar();

	if (!campaignId) {
		navigate(`${routes.campaigns.index()}`, { replace: true });
		return null;
	}

	const [activeRoute, setActiveRoute] = useState<route>(currentRoute);
	useEffect(() => {
		setActiveRoute(currentRoute);
	}, [currentRoute]);

	const renderLastSection = () => {
		if (isTableOfContents) {
			return <TableOfContents title={'Campaign'} divider={true} heads={tableOfContents} />;
		}
		return (
			<NavGroup title={'Actions'}>
				<NavItem to={campaignId ? `${routes.campaigns.edit(campaignId)}` : ''}>Edit Campaign</NavItem>
				<NavItem to={campaignId ? `${routes.campaigns.duplicate(campaignId)}` : ''}>Duplicate Campaign</NavItem>
			</NavGroup>
		);
	};

	return (
		<NavPage>
			<Nav onNavClick={({ to }) => to && setActiveRoute(to)} activeRoute={activeRoute}>
				<NavItem to={campaignId ? `${routes.campaigns.view(campaignId)}` : ''}>Overview</NavItem>
				<NavItem to={campaignId ? `${routes.campaigns.creatives(campaignId)}` : ''}>Creatives</NavItem>
				<NavItem to={campaignId ? `${routes.campaigns.deals(campaignId)}` : ''}>Deals</NavItem>
				<NavItem to={campaignId ? `${routes.campaigns.dailyReport(campaignId)}` : ''}>Daily Report</NavItem>
				<NavGroup title={'Placements'}>
					<NavItem to={campaignId ? `${routes.campaigns.domains(campaignId)}` : ''}>Domains</NavItem>
					<NavItem to={campaignId ? `${routes.campaigns.pages(campaignId)}` : ''}>Pages</NavItem>
					<NavItem to={campaignId ? `${routes.campaigns.bidKeys(campaignId)}` : ''}>BidKeys</NavItem>
					<NavItem to={campaignId ? `${routes.campaigns.trashedBidKeys(campaignId)}` : ''}>Trashed BidKeys</NavItem>
				</NavGroup>
				{renderLastSection()}
			</Nav>
			<Page unstyled={true}>
				<Outlet context={{ setIsTableOfContents }} />
			</Page>
		</NavPage>
	);
});
