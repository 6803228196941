import { CreativesApi } from '../../apis/creatives.api';
import { CreativeModel, ICreativeCreateForm, ICreativeEditForm } from '../../models/creative.model';
import { runInAction } from 'mobx';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { ResourceTypes } from '../../enums/creative-resource-types';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { HttpError } from '../../models/http-error.model';
import { toLowerCaseAndTrim, randomString } from '@monorepo/tools/src/lib/utils/string';

// export const HTML_SIZES = [
// 	'468x60',
// 	'728x90',
// 	'970x90',
// 	'970x250',
// 	'120x600',
// 	'160x600',
// 	'300x600',
// 	'320x50',
// 	'300x250',
// 	'336x280',
// 	'200x200',
// ];

export class CreativeCrudStore extends BaseCrudStore<CreativeModel, ICreativeCreateForm, ICreativeEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: CreativesApi,
			model: CreativeModel,
			errorModel: HttpError,
		});
	}

	async create() {
		this.setBannerData();
		this.setHttpError(null);
		const isValid = this.isValid();
		if (!isValid) {
			return;
		}

		this.setIsLoading(true);
		this.setIsSuccess(false);
		try {
			const bannersFormData = this.getData().getBannersFormData();
			if (bannersFormData) {
				await CreativesApi.postBanner(bannersFormData);
			}

			const createCreativeData = this.getMultiCreateFormData();
			const createCreativeRes = await CreativesApi.multiCreate(createCreativeData);
			if (createCreativeRes !== null && createCreativeRes.length > 0) {
				createCreativeRes.forEach(async creativeRes => {
					const campaignsIds = this.getData()
						.getCampaigns()
						?.map(campaign => campaign.getId()?.toString());
					if (campaignsIds && campaignsIds?.length > 0) {
						await CreativesApi.attachCampaigns(creativeRes.id, campaignsIds);
					}
				});
			}
			runInAction(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			});
		} catch (error) {
			if (error instanceof HttpError) {
				this.setHttpError(new HttpError(error));
			}
			this.setIsLoading(false);
			this.setIsSuccess(false);
		}
	}

	async edit() {
		this.setBannerData();
		const isValid = this.isValid(true);
		this.setHttpError(null);
		if (!isValid) {
			return;
		}
		this.setIsLoading(true);
		this.setIsSuccess(false);
		try {
			const bannersFormData = this.getData().getBannersFormData();
			if (bannersFormData instanceof FormData) {
				await CreativesApi.postBanner(bannersFormData);
			}
			const creativeId = this.getData().getId() || 0;
			const editCreativeData = this.getEditFormData();
			if (creativeId) {
				const campaignsIds = this.getData()
					.getCampaigns()
					?.map(campaign => campaign.getId()?.toString());

				await this.apiLayer.edit(creativeId.toString(), editCreativeData);
				if (campaignsIds) {
					await CreativesApi.attachCampaigns(creativeId, campaignsIds);
				}
			}

			runInAction(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			});
		} catch (error) {
			if (error instanceof HttpError) {
				this.setHttpError(new HttpError(error));
			}
			this.setIsLoading(false);
			this.setIsSuccess(false);
		}
	}

	public setBannerData() {
		const banners = this.getData().getBanners();
		const creativeGroup = toLowerCaseAndTrim(this.getData().creative_group?.design);
		const brand = toLowerCaseAndTrim(this.getData().getBrand());
		const folder = `${brand}-${creativeGroup}`;
		const formData = new FormData();

		banners?.forEach(banner => {
			const fileExtension = banner.getFileExtension();
			const size = banner.getSize();
			const randString = randomString(5);
			if (banner) {
				const creative = banner.getCreative();
				formData.append('folder', `${folder}`);
				formData.append('names[]', `${size}-${randString}.${fileExtension}` || '');
				if (creative) {
					formData.append('creatives[]', creative);
				}
				banner.setResourceUrl(`https://cdn.8proof.com/ads/${folder}/${size}-${randString}.${fileExtension}`);
				this.getData().setBannersFormData(formData);
			}
		});
	}

	/**
	 * Must call isValid before calling this function
	 * @returns
	 */
	public getCreateFormData(): ICreativeCreateForm {
		const creativeGroup = this.getData().getCreativeGroup();
		return {
			brand: this.getData().getBrand(),
			description: this.getData().getDescription(),
			size: this.getData().getSize(),
			banner_sizes: this.getData().getBannerSizes(),
			resource_url: this.getData().getResourceUrl(),
			default_dest_url: this.getData().getDefaultDestUrl(),
			creative_group: creativeGroup ? { id: this.getData().getCreativeGroup()?.id || 0 } : null,
		};
	}

	public getMultiCreateFormData(): ICreativeCreateForm[] {
		switch (this.getData().getResourceType()) {
			case ResourceTypes.IMAGE:
				return this.getImageResourceData();
			case ResourceTypes.HTML:
				return this.getHtmlResourceData();
			case ResourceTypes.NATIVE:
				return this.getNativeResourceData();
		}
		return [];
	}

	public getMultiEditFormData(): ICreativeCreateForm[] {
		const creativeGroup = this.getData().getCreativeGroup();
		const banners = this.getData().getBanners();
		const bannersDataArr: ICreativeCreateForm[] = [];

		banners?.forEach(banner => {
			bannersDataArr.push({
				brand: this.getData().getBrand(),
				description: this.getData().getDescription(),
				size: banner.getSize(),
				banner_sizes: this.getData().getBannerSizes(),
				resource_url: banner.getResourceUrl(),
				resource_type: this.getData().getResourceType(),
				creative_group: creativeGroup ? { id: this.getData().getCreativeGroup()?.id || 0 } : null,
				default_dest_url: this.getData().getDefaultDestUrl(),
			});
		});

		return bannersDataArr;
	}

	/**
	 * Must call isValid before calling this function
	 * @returns
	 */
	public getEditFormData(): ICreativeEditForm {
		const creativeGroup = this.getData().getCreativeGroup();
		const banners = this.getData().getBanners();

		return {
			id: this.getData().getId(),
			brand: this.getData().getBrand(),
			description: this.getData().getDescription(),
			size: banners ? banners[0].getSize() : '',
			resource_url: banners ? banners[0].getResourceUrl() : this.getData().getResourceUrl(),
			creative_group: creativeGroup ? { id: this.getData().getCreativeGroup()?.id || 0 } : null,
			default_dest_url: this.getData().getDefaultDestUrl(),
			banner_sizes: this.getData().getBannerSizes(),
		};
	}

	private getImageResourceData() {
		const creativeGroup = this.getData().getCreativeGroup();
		const banners = this.getData().getBanners();
		const bannersDataArr: ICreativeCreateForm[] = [];

		banners?.forEach(banner => {
			bannersDataArr.push({
				brand: this.getData().getBrand(),
				description: this.getData().getDescription(),
				size: banner.getSize(),
				resource_url: banner.getResourceUrl(),
				resource_type: this.getData().getResourceType(),
				creative_group: creativeGroup ? { id: this.getData().getCreativeGroup()?.id || 0 } : null,
				default_dest_url: this.getData().getDefaultDestUrl(),
			});
		});

		return bannersDataArr;
	}

	private getHtmlResourceData() {
		const creativeGroup = this.getData().getCreativeGroup();
		const banners = this.getData().getBanners();
		const bannersDataArr: ICreativeCreateForm[] = [];

		banners?.forEach(banner => {
			bannersDataArr.push({
				brand: this.getData().getBrand(),
				description: this.getData().getDescription(),
				banner_sizes: this.getData().getBannerSizes(),
				resource_url: banner.getResourceUrl(),
				resource_type: this.getData().getResourceType(),
				creative_group: creativeGroup ? { id: this.getData().getCreativeGroup()?.id || 0 } : null,
				default_dest_url: this.getData().getDefaultDestUrl(),
			});
		});

		return bannersDataArr;
	}

	private getNativeResourceData() {
		const creativeGroup = this.getData().getCreativeGroup();
		const banners = this.getData().getBanners();
		const bannersDataArr: ICreativeCreateForm[] = [];

		banners?.forEach(banner => {
			bannersDataArr.push({
				brand: this.getData().getBrand(),
				description: this.getData().getDescription(),
				size: '0x0',
				resource_url: banner.getResourceUrl(),
				resource_type: this.getData().getResourceType(),
				creative_group: creativeGroup ? { id: this.getData().getCreativeGroup()?.id || 0 } : null,
				default_dest_url: this.getData().getDefaultDestUrl(),
			});
		});

		return bannersDataArr;
	}

	isValid(isEdit = false): boolean {
		this.formStore.reset();
		const creativeBrand = this.getData().getBrand();
		const resourceType = this.getData().getResourceType();
		const banner_sizes = this.getData().getBannerSizes()?.length || 0;

		if (!creativeBrand) {
			this.formStore.addError(new FormError('brand', 'Please provide a brand'));
		}
		const banner = this.getData().getBanners();

		if ((!banner || banner.length === 0) && !isEdit) {
			this.formStore.addError(new FormError('banner', 'Please upload a file'));
		}

		if (banner && banner.length > 20) {
			this.formStore.addError(new FormError('banner', 'Limited to 20 files'));
		}

		const creativeGroup = this.getData().getCreativeGroup();
		if (!creativeGroup) {
			this.formStore.addError(new FormError('creative_group', 'Please select a creative group'));
		}

		if (resourceType === ResourceTypes.HTML && !isEdit && banner_sizes < 1) {
			this.formStore.addError(new FormError('banner_sizes', 'Please select at least one size'));
		}

		return this.formStore.getIsValid();
	}
}
