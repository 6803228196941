import { makeAutoObservable } from 'mobx';

interface IReportResponse<T> {
	success: boolean;
	errorMessage: string | null;
	data: T[];
	total: number;
	limited: boolean;
	footer: T;
	columns: string[][];
	graphData: object;
}

export class ReportResponse<T> {
	public success: boolean;
	public errorMessage: string | null;
	public data: T[];
	public total: number;
	public limited: boolean;
	public footer: T;
	public columns: string[][];
	public graphData: object;

	constructor(performanceResponse: IReportResponse<T>) {
		this.success = performanceResponse.success;
		this.errorMessage = performanceResponse.errorMessage;
		this.data = performanceResponse.data;
		this.total = performanceResponse.total;
		this.limited = performanceResponse.limited;
		this.footer = performanceResponse.footer;
		this.columns = performanceResponse.columns;
		this.graphData = performanceResponse.graphData;
		makeAutoObservable(this);
	}

	public getFooter(): T {
		return this.footer;
	}

	public getData(): T[] {
		return this.data;
	}
}
