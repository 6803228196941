import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { ICrudGetProps } from '@monorepo/tools/src/lib/interfaces/crud';
import { useLocation } from 'react-router-dom';
import { IAdminxStore } from '../../stores';

export function useBidderCampaignGet(props: ICrudGetProps) {
	const { id } = props;
	const { bidderStore } = useStores<IAdminxStore>();
	const location = useLocation();

	useEffect(() => {
		if (id) {
			bidderStore.getCrud().getBidderCampaign(id);
		}
		// TODO - think maybe when unmount reset to crud model
	}, [location]);

	if (!id) {
		return null;
	}

	return { error: bidderStore.getCrud().getHttpError(), isLoading: bidderStore.getCrud().getIsLoading() };
}
