import { DataColumnConfig } from './columns';
import { IColumnConfig } from './columns.types';

export class CreatedByColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'created_by',
			accessor: 'createdBy',
			header: 'Created By',
			...params,
		});
	}
}

export class CreatedAtColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'created_at',
			accessor: 'createdAt',
			header: 'Created At',
			...params,
		});
	}
}

export class UpdatedAtColumnConfig extends DataColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'updated_at',
			accessor: 'updatedAt',
			header: 'Updated At',
			...params,
		});
	}
}
