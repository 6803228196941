import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { PreferencesApi } from '../../apis/preferences.api';
import { HttpError } from '../../models/http-error.model';
import { PreferenceModel, IPreferenceCreateForm, IPreferenceEditForm } from '../../models/preference.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { runInAction } from 'mobx';

export class PreferenceCrudStore extends BaseCrudStore<PreferenceModel, IPreferenceCreateForm, IPreferenceEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: PreferencesApi,
			model: PreferenceModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidPreference before calling this function
	 * @returns
	 */
	public getCreateFormData(): IPreferenceCreateForm {
		return {
			name: this.getData().getName(),
			value: this.getData().getValue(),
			comment: this.getData().getComment(),
		};
	}

	/**
	 * Must call isValidPreference before calling this function
	 * @returns
	 */
	public getEditFormData(): IPreferenceEditForm {
		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			value: this.getData().getValue(),
			comment: this.getData().getComment(),
		};
	}

	public isValid(): boolean {
		const preferenceName = this.getData().getName();
		if (!preferenceName) {
			this.formStore.addError(new FormError('name', 'Please provide a preference name'));
		}

		const value = this.getData().getValue();
		if (!value) {
			this.formStore.addError(new FormError('value', 'Please provide a value'));
		}

		return this.formStore.getIsValid();
	}

	public bulkDelete(ids: id[]): Promise<void | HttpError> {
		this.setIsLoading(true);
		this.setIsSuccess(false);
		return PreferencesApi.bulkDelete(ids)
			.then(() => {
				runInAction(() => {
					this.setIsLoading(false);
					this.setIsSuccess(true);
				});
			})
			.catch(error => {
				// TODO - log
				if (this.onError) {
					this.onError(error.data);
				}
				runInAction(() => {
					if (this.errorModel) {
						this.setHttpError(new this.errorModel({ ...error.data, httpStatus: error.response.status }));
					}
					this.setIsLoading(false);
					this.setIsSuccess(false);
				});
				return error;
			});
	}
}
