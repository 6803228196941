import { Fragment, ReactNode, useRef, useState } from 'react';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { IndexLinerButton } from '../action-liner/liner-buttons';
import { FilterComponent, FilterModel, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import styles from './table-filters.module.scss';
import { Nav, NavItem } from '@monorepo/base/src/components/nav/nav';
import { DividerX } from '@monorepo/base/src/components/divider/divider';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { randomString } from '@monorepo/tools/src/lib/utils/string';

const theme = getTheme();

export interface ITableFiltersActions {
	currentFilters: Map<number, FilterModel>;
	clearAll: () => void;
	clearFilter: (index: number) => void;
	availableFilters: IFilterProto[];
	addFilter: (arg0: FilterModel) => void;
	editFilter: (arg0: number, arg1: FilterModel) => void;

	IndexLinerLeftSide: ReactNode;
	IndexLinerRightSide: ReactNode;
	debugProps?: IDebugProps;
}

interface ITableFilter {
	filter: FilterModel;
	clearFunc: (label: number) => void;
	onClickFilter?: (filter: FilterModel) => void;
	addFilter: (arg0: FilterModel) => void;
	editFilter: (arg0: number, arg1: FilterModel) => void;
	isLabelCircle?: boolean;
}

export const TableFilter = (props: ITableFilter) => {
	const { filter, clearFunc, onClickFilter, addFilter, editFilter, isLabelCircle } = props;
	const { index, label, value, prototype } = filter;
	const { FilterComponent, prop, deletable } = prototype;
	const currentFilterRef = useRef<HTMLDivElement>(null);
	const [isCurrentFilterOpen, setIsCurrentFilterOpen] = useState<boolean>(false);

	const onToggleFilter = () => {
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.INDEX_PAGE.FILTERS.EDIT_FILTER,
			value: label,
		});

		setIsCurrentFilterOpen(!isCurrentFilterOpen);
		if (onClickFilter) {
			onClickFilter(filter);
		}
	};

	const onCloseFilterComponent = () => {
		setIsCurrentFilterOpen(false);
	};

	if (!FilterComponent) {
		return null;
	}

	return (
		<div
			className={styles.filterWrapper}
			key={`${prop}${label}${value}`}
			ref={currentFilterRef}
			{...generateDataAttrs([new DataAttribute('id', `${prop}_filter_button`)])}>
			<Menu
				debugProps={{ dataAttrs: [new DataAttribute('id', 'status_filter_menu')] }}
				unstyled={true}
				isOpen={isCurrentFilterOpen}
				alignToLeft={true}
				className={styles.filterComponentMenu}
				onClose={onCloseFilterComponent}>
				<FilterComponent
					filter={filter}
					onCancel={onCloseFilterComponent}
					onApply={onCloseFilterComponent}
					addFilter={addFilter}
					editFilter={editFilter}
				/>
			</Menu>
			<div className={styles.filter} onClick={onToggleFilter}>
				{isLabelCircle ? <div className={styles.labelCircle}>{label?.[0]?.toUpperCase()}</div> : null}
				<div className={styles.filterContent}>
					<span>{label}</span>
				</div>
				{deletable ? (
					<Icon
						isMFP={true}
						onClick={() => {
							sendGtagEvent({
								action: TrackingActions.Click,
								category: EVENTS.CLICK.INDEX_PAGE.FILTERS.REMOVE_FILTER,
								value: label,
							});
							return index ? clearFunc(index) : null;
						}}
						size={'16px'}
						className={styles.clearIcon}>
						x-close
					</Icon>
				) : null}
			</div>
		</div>
	);
};

export const TableFilters = (props: ITableFiltersActions) => {
	const {
		addFilter,
		editFilter,
		clearAll,
		clearFilter,
		currentFilters,
		availableFilters,

		IndexLinerLeftSide,
		IndexLinerRightSide,
		debugProps,
	} = props;
	const { dataAttrs } = debugProps || {};
	const [isOpenAllFilters, setIsOpenAllFilters] = useState<boolean>(false);
	const [isFilterLiner, setIsFilterLiner] = useState<boolean>(false);
	const [CurrentFilterMenu, setCurrentFilterMenu] = useState<FilterComponent | null>(null);
	const filterButtonRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(filterButtonRef, () => {
		setIsFilterLiner(false);
	});

	const onAddFilter = () => {
		sendGtagEvent({
			action: TrackingActions.Click,
			category: EVENTS.CLICK.INDEX_PAGE.FILTERS.OPEN,
		});
		setIsOpenAllFilters(!isOpenAllFilters);
		if (!isFilterLiner) {
			setIsFilterLiner(true);
		}
	};

	const onClickFilter = () => {
		if (!isFilterLiner) {
			setIsFilterLiner(true);
		}
	};

	const resetFilters = () => {
		clearAll();
	};

	// This reduce will diff between currentFilters and availableFilters,
	// some currentFilters cannot be set twice so we remove it from the available filter (like StatusFilter)
	const usedFiltersTypes = Array.from(currentFilters.values()).map(x => x.prototype.MenuComponent);
	const _availableFilters = availableFilters.reduce((acc, availableFilter) => {
		if (!availableFilter) {
			return acc;
		}
		if (availableFilter.multi || !usedFiltersTypes.includes(availableFilter.MenuComponent)) {
			acc.push(availableFilter);
		}
		return acc;
	}, [] as IFilterProto[]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.filtersWrapper}>
				{isFilterLiner ? null : (
					<Fragment>
						{IndexLinerLeftSide}
						{currentFilters.size > 0 && IndexLinerLeftSide ? (
							<DividerX marginRight={'15px'} marginLeft={'15px'} height={'35px'} />
						) : null}
					</Fragment>
				)}
				<div className={styles.filters} ref={filterButtonRef}>
					{/* Current Filters */}
					{Array.from(currentFilters).map(([index, filter]) => (
						<TableFilter
							filter={filter}
							key={`${index}${filter.prototype.prop}${filter.value}`}
							clearFunc={clearFilter}
							onClickFilter={onClickFilter}
							addFilter={addFilter}
							editFilter={editFilter}
						/>
					))}

					<div className={styles.menus}>
						{/* Available currentFilters menu  */}
						<Menu
							isOpen={isOpenAllFilters}
							alignToLeft={true}
							onClose={() => setIsOpenAllFilters(false)}
							className={`${styles.menu}`}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'add_filter_main_menu')] }}>
							<Nav classes={{ nav: styles.nav }} list>
								{_availableFilters.map(availableFilter => {
									return (
										<NavItem
											key={`${availableFilter.prop}_${randomString(4)}`}
											debugProps={{
												dataAttrs: [
													new DataAttribute(
														'id',
														`add_filter_${availableFilter.MenuComponent.toLowerCase().replaceAll(
															' ',
															'_'
														)}_button`
													),
												],
											}}
											onNavItem={() => {
												setIsOpenAllFilters(false);
												setCurrentFilterMenu(availableFilter.FilterComponent);
											}}>
											<div className={styles.menuItem}>
												<div>{availableFilter.MenuComponent}</div>
												<Icon isMFP={true} size={'20px'}>
													chevron-right
												</Icon>
											</div>
										</NavItem>
									);
								})}
							</Nav>
						</Menu>

						{/* Add Filter  */}
						<Menu isOpen={Boolean(CurrentFilterMenu)} onClose={() => setCurrentFilterMenu(null)} className={styles.menu}>
							{CurrentFilterMenu ? (
								<CurrentFilterMenu
									onCancel={() => setCurrentFilterMenu(null)}
									onApply={() => setCurrentFilterMenu(null)}
									addFilter={addFilter}
									editFilter={editFilter}
								/>
							) : null}
						</Menu>

						{_availableFilters.length > 0 ? (
							<IndexLinerButton>
								<SecondaryText
									icon={'plus'}
									iconColor={theme.actionLinerActionIconsColor}
									iconSize={'20px'}
									debugProps={{ dataAttrs: suffixToDataAttr('_add_filter', dataAttrs) }}
									onClick={onAddFilter}>
									Add Filter
								</SecondaryText>
							</IndexLinerButton>
						) : null}
					</div>
				</div>
			</div>
			<div className={styles.actions}>
				{/* {isFilterLiner ? (
					<Fragment>
						<IndexLinerButton>
							<SecondaryText
								textColor={theme.titleColor}
								iconColor={theme.actionLinerActionIconsColor}
								icon={'delete'}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'clear_filters_button')] }}
								font={IconFonts.Outlined}
								onClick={resetFilters}>
								Clear
							</SecondaryText>
						</IndexLinerButton>
						<IndexLinerButton>
							<SecondaryText
								onClick={() => setIsFilterLiner(false)}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'close_filters_button')] }}
								icon={'x-close'}
								iconColor={theme.actionLinerActionIconsColor}
								textColor={theme.titleColor}>
								Close
							</SecondaryText>
						</IndexLinerButton>
					</Fragment>
				) : ( */}
				<div className={styles.contentActions}>{IndexLinerRightSide}</div>
				{/* )} */}
			</div>
		</div>
	);
};
