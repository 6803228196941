import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { observer } from 'mobx-react';
import { generatePath, useLocation, useParams } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import styles from './create-campaign-group.module.scss';
import { CampaignGroupForm } from '../../../modules/components/forms/campaign-group/campaign-group.form';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import routes from '../../../modules/routes/index.routes';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { route } from '@monorepo/tools/src/lib/types/url';
import { IAdminxStore } from '../../../modules/stores';
import { Statuses } from '../../../modules/enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

interface ICreateCampaignGroups {
	parentRoute?: route;
}

export const CreateCampaignGroups = observer((props: ICreateCampaignGroups) => {
	const { parentRoute } = props;
	const { campaignGroupsStore } = useStores<IAdminxStore>();
	const { dispatchLog } = useLogs();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const { advertiserId } = useParams();

	const campaignGroupCrudStore = campaignGroupsStore.getCrud();
	const campaignGroup = campaignGroupCrudStore.getData();
	const name = campaignGroupCrudStore.getData().getName();

	const navigate = useNavigateParams();
	const location = useLocation();

	useEffect(() => {
		campaignGroupCrudStore.reset();
	}, [location]);

	const formStore = campaignGroupCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = campaignGroupCrudStore.getIsLoading();
	const isSuccess = campaignGroupCrudStore.getIsSuccess();
	const error = campaignGroupCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(generatePath(parentRoute || routes.campaignGroups.index(), { advertiserId }), {
				state: { isBlockRequset: false } as BetweenPagesStateProps,
			});
			dispatchLog({
				msg: `Campaign Group ${name} was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			campaignGroupCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onCreateCampaignGroup = () => {
		setFormClicked(true);
		campaignGroupsStore.getCrud().create();
	};

	const onCloseSiderSlider = () => {
		navigate(generatePath(parentRoute || routes.campaignGroups.index(), { advertiserId }), {
			state: { isBlockRequest: true } as BetweenPagesStateProps,
		});
	};

	return (
		<SiderSlider onClose={() => onCloseSiderSlider()} isOpen={true}>
			<Disable is={isLoading}>
				<Page>
					<Pagebar>
						<PagebarTitle unstyled={true}>
							<div className={styles.title}>Create Campaign Group</div>
							<SpacerX />
							<Toggle
								label={campaignGroup.getStatus() === Statuses.Active ? Statuses.Active : Statuses.Paused}
								onChange={status => campaignGroup.setStatus(status ? Statuses.Active : Statuses.Paused)}
								defaultValue={campaignGroup.getStatus() === Statuses.Active}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_group_status')] }}
							/>
						</PagebarTitle>
					</Pagebar>
					<CampaignGroupForm />
				</Page>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					<BottomBarActions>
						<PrimaryButton
							onClick={() => onCreateCampaignGroup()}
							isRotateIcon={isLoading}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'create_campaign_group_button')] }}
							icon={isLoading ? 'loading-01' : 'send-03'}>
							Create
						</PrimaryButton>
					</BottomBarActions>
				</BottomBar>
			</Disable>
		</SiderSlider>
	);
});
