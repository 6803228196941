import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../stores';
import { DailyMaxLossDisplayInAdvertisers } from '../../components/forms/campaign/max-loss/max-loss';

export const useCampaignTableOfContents = () => {
	const [isTableOfContents, setIsTableOfContents] = useState<boolean>(false);
	const location = useLocation();
	const { campaignStore } = useStores<IAdminxStore>();
	const advertiserId = campaignStore.getCrud().getData().getAdvertiser()?.getId();

	const [tableOfContents, setTableOfContents] = useState([
		{ id: 'basicInfo', element: 'Basic Info', display: true },
		{ id: 'pretargeting', element: 'Pretargeting', display: true },
		{ id: 'dailyBudget', element: 'Daily Budget', display: true },
		{ id: 'defaultPayout', element: 'Default Payout', display: true },
		{ id: 'targeting', element: 'Targeting', display: true },
		{ id: 'maxLossPerDay', element: 'Maximum Loss', display: false },
		{ id: 'campaignCreatives', element: 'Creatives', display: true },
		{ id: 'rules', element: 'Rules', display: true },
		{ id: 'sitesInTargetInfo', element: 'Sites In Target', display: true },
		{ id: 'bidKeysInfo', element: 'Bidkeys Info', display: true },
		{ id: 'sitesInTarget', element: 'Keys In Target', display: true },
	]);

	useEffect(() => {
		return () => setIsTableOfContents(false);
	}, [location]);

	useEffect(() => {
		setTableOfContents(prev => {
			if (DailyMaxLossDisplayInAdvertisers.includes(Number(advertiserId))) {
				prev[5].display = true;
			} else {
				prev[5].display = false;
			}
			return [...prev];
		});
	}, [advertiserId]);

	return {
		isTableOfContents,
		tableOfContents: tableOfContents.filter(({ display }) => display).map(({ id, element }) => ({ id, element })),
		setIsTableOfContents,
	};
};
