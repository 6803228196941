import { Soon } from '@monorepo/base/src/components/soon/soon';
import { Domains } from '../../pages/domains/index/domains.page';
// import { DomainsBidKeysPage } from '../../pages/domains/view/bidkeys/domains-bidkeys.page';
// import { DomainsCampaignsPage } from '../../pages/domains/view/campaigns/domains-campaigns.page';
import { DomainsView } from '../../pages/domains/view/domains-view.page';
// import { DomainsPagesPage } from '../../pages/domains/view/pages/domains-pages.page';
import routes from '../routes/index.routes';
import { DomainsOverviewPage } from '../../pages/domains/view/overview/domains-overview.page';
import { DomainsCampaignsPage } from '../../pages/domains/view/campaigns/domains-campaigns.page';

export const DomainsRouter = [
	{
		path: routes.domains.index(),
		element: <Domains />,
	},
	{
		element: <DomainsView />,
		children: [
			{
				path: routes.domains.view(':domainName'),
				element: <DomainsOverviewPage />,
			},
			{
				path: routes.domains.campaigns(':domainName'),
				element: <DomainsCampaignsPage />,
			},
			{
				path: routes.domains.pages(':domainName'),
				element: <Soon />,
			},
			{
				path: routes.domains.bidKeys(':domainName'),
				element: <Soon />,
			},
		],
	},
];
