import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';

export function useDates() {
	const { dateStore } = useStores<IControlledStore>();
	const dateSelection = dateStore.getPrimarySelection();

	return {
		startDate: dateSelection.getStartDate(),
		endDate: dateSelection.getEndDate(),
	};
}
