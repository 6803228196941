import {
	CampaignIdColumnConfig,
	CampaignNameColumnConfig,
	CampaignStatusColumnConfig,
	CampaignGroupIdColumnConfig,
	CampaignGroupNameColumnConfig,
	CampaignDailyBudgetColumnConfig,
	AdvertiserNameColumnConfig,
	AdvertiserIdColumnConfig,
	StrategyIdColumnConfig,
	ParentIdColumnConfig,
	CampaignTypeColumnConfig,
	PriorityColumnConfig,
	SecondaryPriorityColumnConfig,
	CacheKeyGeneratorColumnConfig,
	BidKeyGeneratorColumnConfig,
	DestUrlColumnConfig,
	CreatedAtColumnConfig,
	UpdatedAtColumnConfig,
	SecondBidFactorColumnConfig,
	UseOptimalGainForMaxLossCalculationColumnConfig,
	CreativeSelectionModeColumnConfig,
	MaxCpmColumnConfig,
	MaxLossPerKeyColumnConfig,
	MaxWinsPerConversionColumnConfig,
	MaxWinsPerClickColumnConfig,
	MaxClicksPerConversionColumnConfig,
	DailySalesColumnConfig,
	UseDomainBudgetLimitColumnConfig,
	AllowMultipleSalesColumnConfig,
	DefaultPayoutColumnConfig,
	DailyDomainMaxLossColumnConfig,
	DailyMaxSalesColumnConfig,
} from '../../../columns/adx-repr.columns';
import { generateAdxReprPerformanceColumns } from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { FilterTypeEnum, SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const campaignsTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'campaign_name',
				sortOrder: SortOrderEnum.ASC,
			},
		],
		bypassCache: true,
		filters: [
			{
				column: 'campaign_status',
				filterType: FilterTypeEnum.EQUALS,
				value: ['ACTIVE'],
				inverted: false,
			},
		],
	},
	columns: [
		new CampaignNameColumnConfig({ header: 'Name', visibility: ColumnVisibilityOptions.AlwaysVisible, inSpeedyMode: true }),
		new CampaignIdColumnConfig({ header: 'ID', visibility: ColumnVisibilityOptions.Visible, inSpeedyMode: true }),
		new CampaignStatusColumnConfig({ visibility: ColumnVisibilityOptions.Visible, inSpeedyMode: true }),
		new AdvertiserNameColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AdvertiserIdColumnConfig(),
		new CampaignGroupNameColumnConfig({ visibility: ColumnVisibilityOptions.Visible, inSpeedyMode: true }),
		new CampaignGroupIdColumnConfig(),
		new CampaignDailyBudgetColumnConfig({ visibility: ColumnVisibilityOptions.Visible, inSpeedyMode: true }),
		new DailyMaxSalesColumnConfig({ inSpeedyMode: true }),
		new StrategyIdColumnConfig(),
		new ParentIdColumnConfig(),
		new CampaignTypeColumnConfig(),
		new PriorityColumnConfig({ inSpeedyMode: true }),
		new SecondaryPriorityColumnConfig({ inSpeedyMode: true }),
		new CacheKeyGeneratorColumnConfig(),
		new BidKeyGeneratorColumnConfig(),
		new DestUrlColumnConfig({ inSpeedyMode: true }),
		new CreatedAtColumnConfig(),
		new UpdatedAtColumnConfig(),
		new SecondBidFactorColumnConfig(),
		new UseOptimalGainForMaxLossCalculationColumnConfig(),
		new CreativeSelectionModeColumnConfig(),
		new MaxCpmColumnConfig(),
		new MaxLossPerKeyColumnConfig(),
		new MaxWinsPerConversionColumnConfig(),
		new MaxWinsPerClickColumnConfig(),
		new MaxClicksPerConversionColumnConfig(),
		new DailySalesColumnConfig(),
		new UseDomainBudgetLimitColumnConfig(),
		new AllowMultipleSalesColumnConfig(),
		new DefaultPayoutColumnConfig(),
		new DailyDomainMaxLossColumnConfig(),
		...generateAdxReprPerformanceColumns(),
	],
	tableState: { isSpeedyModeOn: true },
	includeChart: true,
	tableName: 'adxReprCampaignsPerformance',
};
