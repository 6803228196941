import { observer } from 'mobx-react';
import { Fragment } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './preference-form.module.scss';
import { IAdminxStore } from '../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

export const PreferenceForm = observer(() => {
	const { preferenceStore } = useStores<IAdminxStore>();
	const preferenceCrudStore = preferenceStore.getCrud();
	const formStore = preferenceCrudStore.getFormStore();
	const preference = preferenceCrudStore.getData();

	return (
		<div className={styles.preferenceFlowWrapper}>
			<h2 className={styles.title}>
				<Icon isMFP={true} className={styles.titleIcon}>
					settings-01
				</Icon>
				{'Preference Information'}
			</h2>
			<Fragment>
				<Input
					value={preference.getName()}
					placeholder={'Preference Name'}
					onValue={value => {
						preference.setName(value);
						formStore.clearError('name');
					}}
					required={true}
					error={formStore.getErrors().get('name')?.getMsg()}
					className={styles.internalInputs}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'preference_name')] }}
				/>
				<Input
					value={preference.getValue()}
					placeholder={'Value'}
					onValue={value => {
						preference.setValue(value);
						formStore.clearError('value');
					}}
					required={true}
					error={formStore.getErrors().get('value')?.getMsg()}
					className={styles.internalInputs}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'preference_value')] }}
				/>
				<Input
					value={preference.getComment()}
					placeholder={'Comment'}
					onValue={comment => {
						preference.setComment(comment);
						formStore.clearError('comment');
					}}
					required={false}
					error={formStore.getErrors().get('comment')?.getMsg()}
					className={styles.internalInputs}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'preference_comment')] }}
				/>
			</Fragment>
		</div>
	);
});
