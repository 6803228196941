import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { TestsApi } from '../../apis/tests-api';
import { TestModel, ITestCreateForm, ITestEditForm } from '../../models/test.model';
import { constantCase } from 'change-case';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Status } from '../../enums/status';
import { HttpError } from '../../models/http-error.model';

export class TestCrudStore extends BaseCrudStore<TestModel, ITestCreateForm, ITestEditForm, HttpError> {
	constructor() {
		super({
			apiLayer: TestsApi,
			model: TestModel,
			errorModel: HttpError,
		});
	}

	enableTests(testsIds: id[]): Promise<void> {
		return TestsApi.enableTests(testsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	pauseTests(testsIds: id[]): Promise<void> {
		return TestsApi.pauseTests(testsIds)
			.then(() => {
				this.setIsLoading(false);
				this.setIsSuccess(true);
			})
			.catch(() => {
				this.setIsLoading(false);
				this.setIsSuccess(false);
			});
	}

	/**
	 * Must call isValidTest before calling this function
	 * @returns
	 */
	public getCreateFormData(): ITestCreateForm {
		const campaign = this.getData().getCampaign();
		const status = this.getData()?.getStatus();

		return {
			name: this.getData().getName(),
			description: this.getData().getDescription(),
			status: status ? (constantCase(status) as Status) : undefined,
			campaign: {
				id: campaign?.id,
			},
			test_groups: this.getData()
				.getTestGroups()
				?.map(testGroup => {
					if (testGroup.status) {
						testGroup.status = constantCase(testGroup.status) as Status;
					}
					return testGroup;
				}),
			percentage: this.getData().getPercentage(),
		};
	}

	/**
	 * Must call isValidTest before calling this function
	 * @returns
	 */
	public getEditFormData(): ITestEditForm {
		const campaign = this.getData().getCampaign();
		const status = this.getData()?.getStatus();

		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			description: this.getData().getDescription(),
			status: status ? (constantCase(status) as Status) : undefined,
			campaign: {
				id: campaign?.id,
			},
			test_groups: this.getData()
				.getTestGroups()
				?.map(testGroup => {
					if (testGroup.status) {
						testGroup.status = constantCase(testGroup.status) as Status;
					}
					return testGroup;
				}),
			percentage: this.getData().getPercentage(),
		};
	}

	public isValid(): boolean {
		const testName = this.getData().getName();
		const campaign = this.getData().getCampaign();
		const percentage = this.getData().getPercentage();

		if (!testName) {
			this.formStore.addError(new FormError('name', 'Please provide a test name'));
		}

		if (!campaign) {
			this.formStore.addError(new FormError('campaign', 'Please select a campaign'));
		}

		if (!percentage) {
			this.formStore.addError(new FormError('percentage', 'Please provide a percentage'));
		}

		if (percentage) {
			if (percentage < 1 || percentage > 100) {
				this.formStore.addError(new FormError('percentage', 'Please provide a between 1 and 100'));
			}
		}

		return this.formStore.getIsValid();
	}
}
