import { IQueryParams } from '../interfaces/url';

export const isValidUrl = (url: string) => {
	try {
		// if (!url.includes('http')) {
		// 	url = 'https://' + url;
		// }
		new URL(url);
	} catch (e) {
		return false;
	}
	return true;
};

// IIFE to make regexChecks only available to the function without creating them on every call
export const whatIsWrongWithMyUrl = (url: string, params?: { allowHttp: boolean }) => {
	const allowedProtocols = new Set(['https:']);
	if (params?.allowHttp) {
		allowedProtocols.add('http:');
	}
	const protocolsMessage = Array.from(allowedProtocols)
		.map(p => p.replace(':', ''))
		.join(' or ');

	try {
		const urlObject = new URL(url);
		if (!allowedProtocols.has(urlObject.protocol?.toLowerCase?.() || '')) {
			return {
				error: `URLs support only ${protocolsMessage}`,
			};
		}

		return {
			validatedUrl: url,
		};
	} catch (e) {
		return {
			error: 'Please provide a valid url',
		};
	}
};

export const queryParamsToSearchParams = (queryParams?: IQueryParams): URLSearchParams => {
	const params: URLSearchParams = new URLSearchParams();
	if (queryParams) {
		Object.entries(queryParams).forEach(keyValue => {
			const [key, value] = keyValue;
			if (value) {
				params.append(key, value.toString());
			}
		});
	}

	return params;
};

export function getUrlLevelUp({ pathname, levelsUp = 1 }: { pathname: string; levelsUp?: number }): string {
	const parts = pathname.split('/');
	let levelUp = parts.splice(0, parts.length - (levelsUp < 1 ? 1 : levelsUp)).join('/');

	if (!levelUp.length) {
		levelUp = '/';
	}
	return levelUp;
}
