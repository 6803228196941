import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { Statuses } from '../../enums/status';

interface ITableStatusCell {
	status: Statuses;
}

export const TableStatusCell = (props: ITableStatusCell) => {
	const { status } = props;
	return (
		<Icon size={'14px'} isMFP={true} color={status === Statuses.Active ? '#0b8043' : undefined} font={IconFonts.Filled} key={'3'}>
			{status === Statuses.Active ? 'circle' : 'pause-circle'}
		</Icon>
	);
};
