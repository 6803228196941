import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { IPerformanceOptionsFilterType, usePerformance } from '../../../../modules/hooks/apis/use-performance';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Table } from '@monorepo/base/src/components/table/table';
import { IAdminxStore } from '../../../../modules/stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Upload } from '@monorepo/base/src/components/form/upload/upload';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import styles from './bid-keys.module.scss';
import { IndexLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { useDownload } from '@monorepo/tools/src/lib/hooks/tools/use-download';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { useBidKeysHeaders } from '../../../../modules/hooks/theaders/bid-keys.headers';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { useState } from 'react';
import { DispatchLogsTypes } from '@monorepo/controlled/src/hooks/use-logs';
import dayjs from 'dayjs';
import { PerformanceApi } from '../../../../modules/apis/performance.api';
import {
	IPerformanceOptionsSortBy,
	IPerformanceOptionsSortOrder,
	PerformancesColumnsSnakeCase,
} from '../../../../modules/hooks/apis/use-reports-list';
import { CampaignsApi } from '../../../../modules/apis/campaigns.api';

const theme = getTheme();

export const CampaignBidKeys = observer(() => {
	const { performanceStore, settingsStore, toastsStore } = useStores<IAdminxStore>();
	const { campaignId } = useParams();
	const { columns } = useBidKeysHeaders();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;
	const [isDownloading, setIsDownloading] = useState<boolean>(false);
	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isLoadingCsv, setIsLoadingCsv] = useState<boolean>(false);

	if (!campaignId) {
		return null;
	}

	const { fetchParams } = usePerformance({
		groupBys: [PerformancesColumnsSnakeCase.BidKeys],
		filters: [
			{ column: PerformancesColumnsSnakeCase.CampaignId, filterType: IPerformanceOptionsFilterType.Include, value: [campaignId] },
		],
		limit: 5000,
		sortBy: IPerformanceOptionsSortBy.Wins,
		sortOrder: IPerformanceOptionsSortOrder.Desc,
	});

	const downloadBidKeys = async () => {
		setIsDownloading(true);
		useDownload({
			httpStore: new HttpStore<undefined, File | null>({
				httpFunc: () => CampaignsApi.downloadBidKeys(campaignId as any),
			}),
			fileName: `${campaignId}_bidkeys_${new Date().toISOString().substring(0, 19).replace('T', ' ').replace(/:/g, '-')}`,
			extension: 'csv',
		})
			.catch(err => {
				toastsStore.addToast({ msg: 'Download Failed, try again later.', type: DispatchLogsTypes.Error });
				console.log(err);
			})
			.finally(() => {
				setIsDownloading(false);
			});
	};

	const onUpload = (file: File | FileList) => {
		setIsUploading(true);
		CampaignsApi.uploadBidKeys(campaignId as any, file)
			.then(() => {
				toastsStore.addToast({ msg: 'Upload Success', type: DispatchLogsTypes.Success });
			})
			.catch(() => {
				toastsStore.addToast({ msg: 'Upload Failed', type: DispatchLogsTypes.Error });
			})
			.finally(() => {
				setIsUploading(false);
			});
	};

	const onDownloadReportFile = () => {
		const fileName = `Bidkeys report ${dayjs(fetchParams.periodStart).format('YYYY/MM/DD')} - ${dayjs(fetchParams.periodEnd).format(
			'YYYY/MM/DD'
		)}`;
		let totalRows = performanceStore?.getData()?.total || 0;

		if (typeof totalRows === 'string') {
			totalRows = parseInt(totalRows);
		}

		if (totalRows <= 0) {
			toastsStore.addToast({
				msg: 'Cannot export empty file.',
				type: DispatchLogsTypes.Info,
			});
		} else if (totalRows >= 100000) {
			setIsLoadingCsv(true);
			PerformanceApi.generateCsv({ ...fetchParams })
				.then(() => {
					toastsStore.addToast({
						msg: 'Exporting to CSV, an email will be sent to you when the file is ready.',
						type: DispatchLogsTypes.Success,
					});
				})
				.catch(() => {
					toastsStore.addToast({
						msg: 'Could not export file, try again later.',
						type: DispatchLogsTypes.Error,
					});
				})
				.finally(() => setIsLoadingCsv(false));
		} else {
			setIsLoadingCsv(true);
			useDownload({
				httpStore: new HttpStore<undefined, File | null>({
					httpFunc: () => PerformanceApi.downloadCsv({ ...fetchParams }).finally(() => setIsLoadingCsv(false)),
				}),
				fileName,
				extension: 'csv',
			});
		}
	};

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Bid Keys</PagebarTitle>
				<PagebarActions>
					<div className={styles.wrapper}>
						<IndexLinerButton>
							<div>
								<SecondaryText
									className={styles.actions}
									icon={'download-04'}
									debugProps={{ dataAttrs: [new DataAttribute('id', 'bid_keys_actions_download')] }}
									onClick={downloadBidKeys}
									iconColor={theme.actionLinerActionIconsColor}
									textColor={theme.gray500}
									disabled={isDownloading}
									iconSize={'18px'}>
									Download campaign bid keys
								</SecondaryText>
								<BarLoader is={isDownloading} />
							</div>
						</IndexLinerButton>
						<IndexLinerButton>
							<div>
								<SecondaryText
									className={styles.actions}
									icon={'upload-04'}
									debugProps={{ dataAttrs: [new DataAttribute('id', 'bid_keys_actions_upload')] }}
									iconColor={theme.actionLinerActionIconsColor}
									textColor={theme.gray500}
									disabled={isUploading}
									iconSize={'18px'}>
									<Upload accept={'.csv'} onUpload={onUpload}>
										Upload campaign bid keys
									</Upload>
								</SecondaryText>
								<BarLoader is={isUploading} />
							</div>
						</IndexLinerButton>
					</div>

					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			{tableStore && (
				<Table
					onExportToCsv={onDownloadReportFile}
					isLoadingCsv={isLoadingCsv}
					isLoading={performanceStore.getIsLoading()}
					isBarLoader={performanceStore.getIsLoading()}
					columns={columns}
					data={performanceStore?.getData()?.getData() || []}
					defaultSortBy={[{ id: 'wins', desc: true }]}
					columnVisibility={tableStore?.getColumnVisibility()}
				/>
			)}
		</Page>
	);
});
