import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { jwt } from '@monorepo/tools/src/lib/types/jwt';
import { generateStorageKey } from './helper';
import * as Sentry from '@sentry/react';
import { getConfig } from '@monorepo/tools/src/lib/get-config';

class UserThrowedOutException extends Error {
	constructor(message: string) {
		super(message);
		this.name = 'UserThrowedOutException';
	}
}

export class AuthStore {
	token?: jwt;
	// for debugging user throw out
	lastUpdatedToken?: number;

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: generateStorageKey('ilbbaicnl'),
			properties: ['token', 'lastUpdatedToken'],
			// storage: {
			// 	setItem: (key, data) => cookies.setCookie(key, data),
			// 	removeItem: key => cookies.deleteCookie(key),
			// 	getItem: key => {
			// 		const data = JSON.parse(cookies.getCookie(key) || '{}');

			// 		return {
			// 			...data,
			// 		};
			// 	},
			// },
			// TODO: Cookies currently does not save properly, we dont need cookie because the token is encrypted

			storage: {
				setItem: (key, data) => {
					window.localStorage.setItem(key, data);
				},
				removeItem: key => window.localStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');

					return {
						...data,
					};
				},
			},
		});
	}

	setToken(token?: jwt) {
		this.lastUpdatedToken = new Date().getTime();
		this.token = token;
	}

	getToken(): jwt | undefined {
		return this.token;
	}

	removeToken(signedOut: boolean, params?: unknown) {
		if (!this.token) {
			return;
		}
		// check if an hour are passed since last update
		const now = new Date();
		const sessionId = sessionStorage.getItem(generateStorageKey('sessionId')) || '';
		if (!signedOut) {
			console.log({
				now,
				lastUpdatedToken: this.lastUpdatedToken,
				env: getConfig().env.projectEnv,
				sessionId,
				isError: !signedOut,
				params,
			});
			Sentry.captureException(new UserThrowedOutException('UserThrowedOutException'), {
				extra: {
					params,
					sessionId,
				},
				tags: {
					feature: 'user-throwed',
				},
				level: 'error',
			});
		}

		this.token = undefined;
	}
}
