import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';

/**
 * The props the test crud page uses
 */
export interface IJob {
	id?: id;
	name?: string;
	task_classes?: string;
	cron_expression?: string;
	is_active?: boolean;
	run_on_init?: boolean;
	max_retries?: number;
	retry_timeout?: number;
	refresh_rate?: number;
}

/**
 * The props to send to server
 */
export interface IJobCreateForm {
	id?: id;
	name?: string;
	task_classes?: string;
	cron_expression?: string;
	is_active?: boolean;
	run_on_init?: boolean;
	max_retries?: number;
	retry_timeout?: number;
	refresh_rate?: number;
}

export interface IJobEditForm {
	id?: id;
	name?: string;
	task_classes?: string;
	cron_expression?: string;
	is_active?: boolean;
	run_on_init?: boolean;
	max_retries?: number;
	retry_timeout?: number;
	refresh_rate?: number;
}

export class JobModel implements IJob {
	id?: id;
	name?: string;
	task_classes?: string;
	cron_expression?: string;
	is_active?: boolean;
	run_on_init?: boolean;
	max_retries?: number;
	retry_timeout?: number;
	refresh_rate?: number;

	constructor(props?: IJob) {
		this.id = props?.id;
		this.name = props?.name;
		this.task_classes = props?.task_classes;
		this.cron_expression = props?.cron_expression;
		this.is_active = props?.is_active;
		this.run_on_init = props?.run_on_init;
		this.max_retries = props?.max_retries || 0;
		this.retry_timeout = props?.retry_timeout || 0;
		this.refresh_rate = props?.refresh_rate || 0;

		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getName() {
		return this.name;
	}

	public setName(name: string) {
		this.name = name;
	}

	public getTaskClasses() {
		return this.task_classes;
	}

	public setTaskClasses(task_classes: string) {
		this.task_classes = task_classes;
	}

	public getCronExpression(): string | undefined {
		return this.cron_expression;
	}

	public setCronExpression(cron_expression: string): void {
		this.cron_expression = cron_expression;
	}

	public getIsActive(): boolean | undefined {
		return this.is_active;
	}

	public setIsActive(is_active: boolean): void {
		this.is_active = is_active;
	}

	public getRunOnInit(): boolean | undefined {
		return this.run_on_init;
	}

	public setRunOnInit(run_on_init: boolean): void {
		this.run_on_init = run_on_init;
	}

	public getMaxRetries(): number | undefined {
		return this.max_retries;
	}

	public setMaxRetries(max_retries: number): void {
		this.max_retries = max_retries;
	}

	public getRetryTimeout(): number | undefined {
		return this.retry_timeout;
	}

	public setRetryTimeout(retry_timeout: number): void {
		this.retry_timeout = retry_timeout;
	}

	public getRefreshRate(): number | undefined {
		return this.refresh_rate;
	}

	public setRefreshRate(refresh_rate: number): void {
		this.refresh_rate = refresh_rate;
	}
}
