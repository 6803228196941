import { CrudEndpoint } from './crud.endpoint';

export class BidKeysManagementEndpoint extends CrudEndpoint {
	constructor() {
		super('keystats');
	}

	listStatus() {
		return `${this.rootApi}/keystats/listStatus`;
	}

	reopenBidKey() {
		return `${this.rootApi}/keystats/delete`;
	}
}
