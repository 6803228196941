import styles from './logo.module.scss';
import { Icon } from '@monorepo/base/src/components/icon/icon';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';
import { url } from '@monorepo/tools/src/lib/types/url';

interface logoProps {
	text?: string;
	to?: url;
	children: JSX.Element;
	classes?: {
		wrapper: string;
	};
}

export function Logo(props: logoProps) {
	const { text, to, children, classes } = props;

	return (
		<LinkWithParams to={to || ''}>
			<div className={`${styles.logoWrapper} ${classes?.wrapper || ''}`}>
				<Icon>{children}</Icon>
				<span>{text}</span>
			</div>
		</LinkWithParams>
	);
}
