import { CampaignGroupModel, ICampaignGroupCreateForm, ICampaignGroupEditForm } from '../models/campaign-group.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';

export const CampaignGroupsApi = {
	list: (params?: URLSearchParams): Promise<CampaignGroupModel[]> => {
		return ask.get(endpoints.campaignGroups.list(), params);
	},
	create: (campaignGroup: ICampaignGroupCreateForm, options?: IRequestOptions): Promise<{ id: id }> => {
		return ask.post<ICampaignGroupCreateForm>(endpoints.campaignGroups.create(), campaignGroup, options);
	},
	get: (id: id, options?: URLSearchParams): Promise<CampaignGroupModel> => {
		return ask.get(endpoints.campaignGroups.get(id), options);
	},
	edit: (id: id, campaignGroup: ICampaignGroupEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<ICampaignGroupEditForm>(endpoints.campaignGroups.edit(id), campaignGroup, options);
	},
	delete: (id: id) => {
		return ask.delete(endpoints.campaignGroups.delete(id));
	},
};
