import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './create-creative.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { CreativeForm } from '../../../modules/components/forms/creative/creative.form';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import {
	IPerformanceOptionsDateSegments,
	IPerformanceOptionsSortBy,
	IPerformanceOptionsSortOrder,
	PerformancesColumnsSnakeCase,
	groupByMapper,
	useReportsList,
} from '../../../modules/hooks/apis/use-reports-list';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

export const CreateCreative = observer(() => {
	const { creativeStore, creativeGroupsStore } = useStores<IAdminxStore>();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const columnVisibility = usePageSettings().tableStore?.getColumnVisibility();
	const defaultSortBy = usePageSettings().tableStore?.getSortingBy();

	const { dispatchLog } = useLogs();
	const navigate = useNavigateParams();
	const location = useLocation();

	useEffect(() => {
		creativeStore.resetCrud();
	}, [location]);

	const groupByColumns = Object.keys(columnVisibility || {})
		.map((col: string) => {
			if (typeof col === 'string' && columnVisibility && columnVisibility[col]) {
				return groupByMapper[col];
			}
		})
		.filter(val => !!val) as PerformancesColumnsSnakeCase[];

	useReportsList(creativeGroupsStore.getListStore(), {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxCreativeGroups',
		sortBy: defaultSortBy?.[0]?.id || IPerformanceOptionsSortBy.Wins,
		sortOrder: defaultSortBy?.[0]?.desc ? IPerformanceOptionsSortOrder.Desc : IPerformanceOptionsSortOrder.Asc,
		submit: true,
		dateSegment: groupByColumns.includes(PerformancesColumnsSnakeCase.Dt)
			? IPerformanceOptionsDateSegments.Daily
			: IPerformanceOptionsDateSegments.None,
	});

	const creativeCrudStore = creativeStore.getCrud();
	const brand = creativeCrudStore.getData().getBrand();
	const formStore = creativeCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = creativeCrudStore.getIsLoading();
	const isSuccess = creativeCrudStore.getIsSuccess();
	const error = creativeCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(-1);
			dispatchLog({
				msg: `Creative ${brand} was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			creativeCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onCreateCreative = () => {
		setFormClicked(true);
		creativeStore.getCrud().create();
	};

	return (
		<Fragment>
			<Disable is={isLoading}>
				<Page className={isLoading ? styles.loadingPage : ''}>
					<Pagebar>
						<PagebarTitle>
							<div className={styles.title}>Create a creative</div>
						</PagebarTitle>
					</Pagebar>
					<CreativeForm />
				</Page>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					<BottomBarActions>
						<PrimaryButton
							onClick={onCreateCreative}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'create_creative_button')] }}
							isRotateIcon={isLoading}
							icon={isLoading ? 'loading-01' : 'send-03'}>
							Create
						</PrimaryButton>
					</BottomBarActions>
				</BottomBar>
			</Disable>
		</Fragment>
	);
});
