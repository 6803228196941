import { observer } from 'mobx-react';
import { StringActions, enumFuncs, stringAction } from '@monorepo/tools/src/lib/utils/string';
import { FilterModel, IFilterComponentProps, IFilterProto } from '@monorepo/controlled/src/models/filter.model';
import { snakeCase } from 'change-case';
import { FilterEntityMenu } from '@monorepo/base/src/components/filters/filter-entity-menu/filter-entity-menu';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { SelectOptions } from '@monorepo/base/src/components/select-new/select-new';
import { useEffect, useState } from 'react';
import { toJS } from 'mobx';

interface IEntityFilterProto {
	cellKey: string;
	columnName: string;
	autocompleteCallback?: (...params: any) => Promise<SelectOptions[]>;
	entity?: string;
	isClientFilter?: boolean;
}

interface IEntityFilterProps extends IFilterComponentProps {
	cellKey: string;
	columnName: string;
	entity?: string;
	autocompleteCallback?: (...params: any) => Promise<SelectOptions[]>;
	isClientFilter?: boolean;
}

const EntityFilter = (props: IEntityFilterProps) => {
	const { filter, onCancel, onApply, addFilter, editFilter, columnName, cellKey, entity, autocompleteCallback, isClientFilter } = props;
	const protoObj = EntityFilterPrototype({ columnName, cellKey, entity, autocompleteCallback, isClientFilter });
	const [entities, setEntities] = useState<SelectOptions[]>([]);
	const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(false);

	const onAddStringFilter = (stringFilterProps: { _value: SelectOptions[]; _action?: stringAction }) => {
		const { _value, _action } = stringFilterProps;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		const firstSelectedEntity = entities.find(e => _value[0] == e.value);
		const filterLabel =
			_value.length > 1
				? `${columnName}s includes ${firstSelectedEntity?.label} and ${_value.length - 1} more`
				: `${columnName} is ${firstSelectedEntity?.label}`;

		const stringsFilter = new FilterModel({
			label: filterLabel,
			action: _action,
			value: _value as any,
			prototype: protoObj,
		});

		if (filter?.index && editFilter) {
			editFilter(filter.index, stringsFilter);
		} else {
			if (protoObj?.multi && addFilter) {
				addFilter(stringsFilter);
			}
		}
		onApply();
	};

	const fetchEntities = async () => {
		setIsLoadingOptions(true);
		return (
			autocompleteCallback &&
			(await autocompleteCallback()
				.then(r => {
					setEntities(r);
				})
				.finally(() => setIsLoadingOptions(false)))
		);
	};
	useEffect(() => {
		fetchEntities();
	}, []);

	return (
		<FilterEntityMenu
			title={columnName}
			defaultValues={filter?.value as any[]}
			defaultAction={filter?.action}
			onApply={(_value: SelectOptions[]) => onAddStringFilter({ _value, _action: StringActions.Include })}
			onCancel={onCancel}
			debugProps={{ dataAttrs: [new DataAttribute('id', `${snakeCase(columnName)}_menu`)] }}
			entityOptions={entities}
			isLoadingOptions={isLoadingOptions}
		/>
	);
};

export const EntityFilterPrototype = (props: IEntityFilterProto): IFilterProto => {
	const { columnName, cellKey, entity, autocompleteCallback, isClientFilter } = props;
	return {
		MenuComponent: columnName,
		FilterComponent: observer((props: IFilterComponentProps) =>
			EntityFilter({ ...props, columnName, cellKey, entity, autocompleteCallback, isClientFilter })
		),
		prop: `${entity}${cellKey}`,
		deletable: true,
		multi: true,
		filterFunc<T>(models: Array<any>, filter: FilterModel): Array<T> {
			const { value } = filter;
			if (!isClientFilter) {
				return models;
			}
			return models.filter(model => {
				const getLabel = (val: any) => {
					return val.label || val;
				};
				const actualValues = Array.isArray(value) ? value.map(getLabel) : getLabel(value);
				const actionFunc = enumFuncs[StringActions.Equals];
				const key = cellKey;

				if (Array.isArray(actualValues)) {
					return actualValues.some((val: string) => {
						// incase of nested key
						if (key.includes('.')) {
							const keys = key.split('.');
							let value = toJS(model);
							for (const k of keys) {
								if (!value) {
									return false;
								}
								value = value[k];
							}
							return actionFunc([value] || [], val);
						}
						return actionFunc([model[key]] || [], val);
					});
				}
			});
		},
	};
};
