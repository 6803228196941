import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './pagination.module.scss';
import { generateDataAttrs, DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';

interface IPagination {
	// previousPage: () => void;
	// nextPage: () => void;
	gotoPage: (_pageIndex: number, isScrollToTableWrapper?: boolean) => void;
	canPreviousPage: boolean;
	canNextPage: boolean;
	pageOptions: number[];
	pageIndex: number;
	pageSize: number;
	total: string | number;
}

export const Pagination = (props: IPagination) => {
	const { canPreviousPage, canNextPage, gotoPage, pageIndex = 0, total, pageSize } = props;

	const min = pageIndex === 0 ? 1 : pageSize * pageIndex + 1;

	const totalInt = parseInt(`${total}`.replace(/\,/g, ''));
	const max = totalInt < pageSize * (pageIndex + 1) ? total : pageSize * (pageIndex + 1);
	// {min} - {max} of {total}

	return (
		<div className={styles.pagination}>
			{/* <Icon
				isMFP={true}
				className={`${styles.start} ${pageIndex === 0 ? styles.disable : ''}`}
				onClick={() => {
					tableStore.setPageIndex(0);
					gotoPage(0);
				}}>
				keyboard_double_arrow_left
			</Icon> */}
			<Icon
				isMFP={true}
				className={`${styles.prev} ${!canPreviousPage ? styles.disable : ''}`}
				onClick={() => {
					sendGtagEvent({
						action: TrackingActions.Click,
						category: EVENTS.CLICK.INDEX_PAGE.TABLE_SETTINGS.PAGINATION.PREVIOUS,
						label: window.location.href,
						value: pageIndex - 1,
					});
					gotoPage(pageIndex - 1, true);
					// previousPage();
				}}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'pagination_left')] }}>
				chevron-left
			</Icon>
			<div className={styles.info} {...generateDataAttrs([new DataAttribute('id', `page_${pageIndex + 1}`)])}>
				{min.toLocaleString()} - {max.toLocaleString()} of {total.toLocaleString()}
			</div>
			<Icon
				isMFP={true}
				className={`${styles.next} ${!canNextPage ? styles.disable : ''}`}
				onClick={() => {
					sendGtagEvent({
						action: TrackingActions.Click,
						category: EVENTS.CLICK.INDEX_PAGE.TABLE_SETTINGS.PAGINATION.NEXT,
						label: window.location.href,
						value: pageIndex + 1,
					});
					gotoPage(pageIndex + 1, true);

					// nextPage();
				}}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'pagination_right')] }}>
				chevron-right
			</Icon>
			{/* <Icon
				isMFP={true}
				className={`${styles.end} ${pageOptions.length - 1 === pageIndex ? styles.disable : ''}`}
				onClick={() => {
					tableStore.setPageIndex(pageOptions.length - 1);
					gotoPage(pageOptions.length - 1);
				}}>
				keyboard_double_arrow_right
			</Icon> */}
		</div>
	);
};
