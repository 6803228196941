import { useLocation, useParams } from 'react-router-dom';
import { useCampaignInputOptions } from '../../hooks/apis/use-campaign-input-options';
import { CampaignPageBreadcrumbs } from './campaign-page.breadcrumbs';
import { CampaignGroupPageBreadcrumbs } from './campaign-group-page.breadcrumbs';
import { AdvertiserBreadcrumb, AdvertiserPageBreadcrumbs } from './advertiser-page.breadcrumbs';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CreativeGroupPageBreadcrumbs } from './creative-group-page.breadcrumbs';
import { TestPageBreadcrumbs } from './test-page.breadcrumbs';
import { Skeleton } from '@monorepo/base/src/components/skeleton/skeleton';
import styles from './main-breadcrumbs.module.scss';
import { CreativePageBreadcrumbs } from './creative-page.breadcrumbs';
import { IAdminxStore } from '../../stores';

interface ISkeletonBreadCrumb {
	isNext?: boolean;
}

// TODO - @MFP remove from controlled
export const SkeletonBreadCrumb = (props: ISkeletonBreadCrumb) => {
	const { isNext } = props;

	return (
		<div className={styles.breadcrumbWrapper}>
			<div className={styles.skeletons}>
				<div className={styles.topSkeleton}>
					<Skeleton is={true} rectanglesAmount={1} />
				</div>
				<div className={styles.bottomSkeleton}>
					<Skeleton is={true} rectanglesAmount={1} />
				</div>
			</div>
			{isNext && <div className={styles.next}>|</div>}
		</div>
	);
};

export const MainBreadcrumbs = observer(() => {
	const { campaignId, campaignGroupId, advertiserId, creativeGroupId, creativeId, testId } = useParams();
	const { advertiserStore, campaignGroupsStore, campaignStore, creativeStore, creativeGroupsStore } = useStores<IAdminxStore>();

	useCampaignInputOptions();
	const location = useLocation();

	useEffect(() => {
		// In manager view reset all crud stores
		// if (!campaignId && !campaignGroupId && !advertiserId && !creativeId && !creativeGroupId) {
		advertiserStore.getCrud().reset();
		campaignGroupsStore.getCrud().reset();
		campaignStore.getCrud().reset();
		creativeStore.getCrud().reset();
		creativeGroupsStore.getCrud().reset();
		// }
	}, [location]);

	if (campaignId) {
		return <CampaignPageBreadcrumbs campaignId={campaignId} />;
	}

	if (campaignGroupId) {
		return <CampaignGroupPageBreadcrumbs campaignGroupId={campaignGroupId} />;
	}

	if (advertiserId) {
		return <AdvertiserPageBreadcrumbs advertiserId={advertiserId} />;
	}

	if (creativeGroupId) {
		return <CreativeGroupPageBreadcrumbs creativeGroupId={creativeGroupId} />;
	}

	if (testId) {
		return <TestPageBreadcrumbs />;
	}

	if (creativeId) {
		return <CreativePageBreadcrumbs creativeId={creativeId} />;
	}

	return <AdvertiserBreadcrumb />;
});
