import { observer } from 'mobx-react';
import { useState } from 'react';
import { useCampaignGet } from '../../hooks/apis/use-campaign-get';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel } from '../../models/advertiser.model';
import { CampaignGroupModel } from '../../models/campaign-group.model';
import { CampaignModel } from '../../models/campaign.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { BreadcrumbMenu } from '@monorepo/base/src/components/breadcrumb-menu/breadcrumb-menu';
import { SkeletonBreadCrumb } from './main-breadcrumbs';
import styles from './main-breadcrumbs.module.scss';
import { AdvertiserBreadcrumb } from './advertiser-page.breadcrumbs';
import { CampaignGroupBreadcrumb } from './campaign-group-page.breadcrumbs';
import { IAdminxStore } from '../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { SelectNew, SelectOptions } from '@monorepo/base/src/components/select-new/select-new';
import { IPerformanceOptionsDateSegments, PerformancesColumnsSnakeCase } from '../../hooks/apis/use-reports-list';
import { PerformanceModel } from '../../models/performance.model';

interface ICampaignBreadcrumb {
	campaign: CampaignModel;
	campaignGroup?: CampaignGroupModel;
	advertiser?: AdvertiserModel;
}

export const CampaignBreadcrumb = observer((props: ICampaignBreadcrumb) => {
	const { campaignStore } = useStores<IAdminxStore>();
	const { campaign, campaignGroup, advertiser } = props;
	const isLoadingCampaigns = campaignStore.getListStore().getIsLoading();
	const [advertiserCampaigns, setAdvertiserCampaigns] = useState<PerformanceModel[]>([]);
	const campaignName = campaign.getName() || '';
	const navigate = useNavigateParams();

	const onListItemCampaign = (campaignsId: (string | number)[]) => {
		const campaignId = campaignsId[0];
		if (!campaignId) {
			// TODO - log
			return null;
		}
		navigate(routes.campaigns.view(campaignId));
	};

	const onBreadcrumbCampaign = () => {
		campaignStore
			.getListStore()
			.fetch({
				type: 'STATISTICS',
				submit: true,
				start: 0,
				limit: 100,
				periodStart: new Date().toLocaleDateString('en-CA'),
				periodEnd: new Date().toLocaleDateString('en-CA'),
				multipleTimePeriods: [],
				groupBys: [
					PerformancesColumnsSnakeCase.Id,
					PerformancesColumnsSnakeCase.Name,
					PerformancesColumnsSnakeCase.AdvertiserId,
					PerformancesColumnsSnakeCase.CampaignGroupId,
				],
				filters: [{ column: 'status', filterType: 'INCLUDE', value: ['ACTIVE'], inverted: false }],
				dateSegment: IPerformanceOptionsDateSegments.None,
				sortBy: 'wins',
				inResultsSort: null,
				inResultsSortOrder: 1,
				sortOrder: 1,
				inResultsFilters: [],
				graphYaxis: [],
				graphLines: [],
				additionalColumns: [],
				reportName: 'adxCampaigns',
			})
			.then(res => {
				const _campaigns = res?.getData();
				if (!Array.isArray(_campaigns)) {
					return null;
				}
				setAdvertiserCampaigns(
					_campaigns.filter(
						campaign => campaign.advertiserId === advertiser?.getId() && campaign.campaignGroupId === campaignGroup?.getId()
					)
				);
			});
	};

	const onBreadcrumbLink = () => {
		const campaignId = campaign.getId();
		if (campaignId) {
			navigate(`${routes.campaigns.view(campaignId)}`);
		}
	};

	return (
		<BreadcrumbMenu
			label={'Campaign'}
			title={campaignName}
			onArrowClick={onBreadcrumbCampaign}
			navigateToModel={onBreadcrumbLink}
			isBarLoader={isLoadingCampaigns}
			isLoading={isLoadingCampaigns && advertiserCampaigns.length === 0}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_breadcrumbs')] }}
			skeletonRectanglesAmount={12}>
			<div className={styles.menuWrapper}>
				{advertiserCampaigns ? (
					<SelectNew
						classNames={{ list: styles.list }}
						placeholder={'Search Campaign'}
						options={advertiserCampaigns.map(_advertiserCampaign => {
							return {
								label: _advertiserCampaign.name as string,
								value: _advertiserCampaign.id as string,
								to: routes.campaigns.view(_advertiserCampaign.id as string),
							};
						})}
						required
						debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_breadcrumbs_select')] }}
						onSelect={onListItemCampaign}
						disabled={isLoadingCampaigns}
						defaultValues={(campaign.getId() ? [campaign.getId()] : []) as string[]}
						sortBy={(a: SelectOptions, b: SelectOptions) => (a.label > b.label ? 1 : -1)}
					/>
				) : null}
			</div>
		</BreadcrumbMenu>
	);
});
interface ICampaignBreadcrumbs {
	campaignId: id;
}

export const CampaignPageBreadcrumbs = observer((props: ICampaignBreadcrumbs) => {
	const { campaignId } = props;
	const { campaignStore } = useStores<IAdminxStore>();
	useCampaignGet({ id: campaignId });

	const campaign = campaignStore.getCrud().getData();
	const advertiser = campaign.getAdvertiser();
	const campaignGroup = campaign.getCampaignGroup();

	return (
		<div className={styles.wrapper}>
			{advertiser ? <AdvertiserBreadcrumb advertiser={advertiser} /> : <SkeletonBreadCrumb isNext={true} />}
			{campaignGroup ? (
				<CampaignGroupBreadcrumb campaignGroup={campaignGroup} advertiser={advertiser} />
			) : (
				<SkeletonBreadCrumb isNext={true} />
			)}
			{campaign.getName() ? (
				<CampaignBreadcrumb campaign={campaign} campaignGroup={campaignGroup} advertiser={advertiser} />
			) : (
				<SkeletonBreadCrumb />
			)}
		</div>
	);
});
