import { id } from './../../../../tools/src/lib/types/primitives';
import { makeAutoObservable } from 'mobx';

export interface ITargetCid {
	current_cid?: string;
	new_cid?: string;
}

export interface ICid {
	source: {
		current_cid: string;
		new_cid: string;
	};
	targets?: TargetCidModel[];
}

export interface ITargetCidCreateForm {
	current_cid?: string;
	new_cid?: string;
}

export interface ITargetCidEditForm {
	current_cid?: string;
	new_cid?: string;
}

export interface ICidCreateForm {
	source: {
		current_cid: string;
		new_cid: string;
	};
	targets?: TargetCidModel[];
}

export interface ICidEditForm {
	source: {
		current_cid: string;
		new_cid: string;
	};
	targets?: TargetCidModel[];
}

export class TargetCidModel implements ITargetCid {
	current_cid?: string;
	new_cid?: string;

	constructor(props?: ITargetCid) {
		this.current_cid = props?.current_cid || '';
		this.new_cid = props?.new_cid || '';

		makeAutoObservable(this);
	}

	public getCurrentTargetCid(): string | undefined {
		return this.current_cid;
	}

	public setCurrentTargetCid(current_cid: string) {
		this.current_cid = current_cid;
	}

	public getNewTargetCid(): string | undefined {
		return this.new_cid;
	}

	public setNewTargetCid(new_cid: string) {
		this.new_cid = new_cid;
	}
}

export class CidModel implements ICid {
	source: {
		current_cid: string;
		new_cid: string;
	} = { current_cid: '', new_cid: '' };
	targets: TargetCidModel[];

	constructor(props?: ICid) {
		this.source.current_cid = props?.source.current_cid || '';
		this.source.new_cid = props?.source.new_cid || '';
		this.targets = props?.targets || [];

		makeAutoObservable(this);
	}

	getId(): id | undefined {
		throw new Error('Method not implemented.');
	}

	public getCurrentCid(): string {
		return this.source.current_cid;
	}

	public setCurrentCid(current_cid: string) {
		this.source.current_cid = current_cid;
	}

	public getNewCid(): string {
		return this.source.new_cid;
	}

	public setNewCid(new_cid: string) {
		this.source.new_cid = new_cid;
	}

	public addToTargets() {
		this.targets.push(new TargetCidModel());
	}

	public removeFromTargetCids(index: number) {
		this.targets.splice(index, 1);
	}

	public getTargetCids(): TargetCidModel[] {
		return this.targets;
	}

	public clearTargetCids() {
		this.targets = new Array<TargetCidModel>();
	}
}
