export const EVENTS = {
	CLICK: {
		DEAD: 'Dead Click',
		RAGE: 'Rage Click',
		NAVBAR: {
			BREADCRUMB: {
				ARROW: 'Breadcrumb arrow click',
				BREADCRUMB: 'Breadcrumb content click',
				SELECT: 'Select option from breadcrumb',
			},
			DOCS: {
				OPEN: 'Open docs',
				SELECT_OPTION: 'Select docs option',
			},
			USER: {
				OPEN: 'Open User Menu',
				MENU: {
					CHANGE_PASSWORD: 'Change Password',
					TERMS_AND_CONDITIONS: 'Click on Terms And Condition via User menu',
					PRIVACY_POLICY: 'Click on Privacy Policy via User Menu',
					LOGOUT: 'Click on logout via User Menu',
				},
			},
			LOGO: 'Click on logo',
		},
		SIDEBAR: {
			ITEM: 'Click on sidebar item',
			TOGGLE: 'Minimize or Maximize sidebar',
		},
		DATER: {
			EXPEND_BUTTON: 'Dater expend button',
			CANCEL: 'Dater menu cancel',
			APPLY: 'Dater menu apply',
			SELECT_DATE: 'Select date from dater',
		},
		INDEX_PAGE: {
			CHART: {
				LEGEND: {
					TOGGLE: 'Click on toggle legend',
				},
			},
			ACTION_LINER: {
				EXPORT: 'Export to csv',
				COLUMN_PICKER: {
					EXPEND: 'Column picker expend',
					COLLAPSE: 'Column picker collapse',
					SELECT_ALL: 'Select all columns',
					RESET: 'Reset columns',
					APPLY: 'Apply columns',
				},
				SEARCH: { TOGGLE: 'Click on Search', CLOSE: 'Close Search' },
				EDIT_ENTITY: 'Click on edit via action liner',
				DELETE_ENTITY: 'Click on delete via action liner',
				ACT_AS: 'Click on act as via action liner',
			},
			FILTERS: {
				OPEN: 'Click on Add Filter button',
				APPLY_FILTER: 'Click on apply filter',
				CANCEL_FILTER: 'Click on cancel from filter menu',
				REMOVE_FILTER: 'Click on remove filter',
				EDIT_FILTER: 'Click on added filter to edit',
			},
			TABLE_SETTINGS: {
				SHOW_AMOUNT: {
					OPEN: 'Open show amount selection',
					CHOOSE: 'Click on amount of rows',
				},
				PAGINATION: {
					NEXT: 'Click or next page',
					PREVIOUS: 'Click or previous page',
				},
				SORT_BY: 'Click on sort by',
			},
			USERS_PAGE: {
				NEW_USER: 'Click on new User',
				EDIT_USER: 'Click on edit user through cell action',
				ACT_AS: 'Click on act as through cell action',
				FORM: {
					EDIT: 'Click on save edited user button',
					CREATE: 'Click on create user button',
					CANCEL: 'Click on cancel button',
					CLOSE_X: 'Click on X button',
					SUBMIT_CHANGE_PASSWORD: 'Click on change password button',
				},
			},
		},
		ENTITY: {
			SAVE_BUTTON: 'Save button',
		},
		TOAST: {
			CLOSE: 'Click on close toast',
		},
		LOGIN: {
			LOGIN: 'Click on login via email and password',
			TERMS_AND_CONDITIONS: 'Click on Terms And Condition through login page',
			PRIVACY_POLICY: 'Click on Privacy Policy through login page',
			FOROGOT_PASSOWRD: {
				CLICK: 'Click on forogt password',
				SUBMIT: 'Submit form of forgot password',
			},
		},
	},
	GENERAL: {
		PAGE_LOAD_SPEED: 'Page load speed',
		CONSOLE_ERROR: 'Console error',
	},
	NETWORK: {
		API_ERROR: 'Request failed',
	},
};
