import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { FilterTypeEnum, SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { bidderCampaignAdxCell } from '../../../cells/old-adx-report.cells';
import { IdColumnConfig, NameColumnConfig, StatusColumnConfig } from '../../../columns/adminx.columns';
import {
	DimensionsColumnConfig,
	MetricsColumnConfig,
	MetricsCurrencyColumnConfig,
	MetricsPercentageColumnConfig,
} from '../../../columns/columns';
import { AdvertiserNameColumnConfig } from '../../../columns/adx-repr.columns';
import { AdvertiserEntityBidderFilterDefinition } from '../../../table-filters/filters.definitions';

export const bidderCampaignsPerformanceTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'DomainBidderCampaignsReport',
		sort: [
			{
				column: 'id',
				sortOrder: SortOrderEnum.ASC,
			},
		],
		bypassCache: true,
		filters: [
			{
				column: 'status',
				filterType: FilterTypeEnum.EQUALS,
				value: ['ACTIVE'],
				inverted: false,
			},
		],
	},

	columns: [
		new IdColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new NameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible, cell: bidderCampaignAdxCell }),
		new StatusColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new AdvertiserNameColumnConfig({
			visibility: ColumnVisibilityOptions.Visible,
			linkTo: undefined,
			cell: undefined,
			availableFilters: new AdvertiserEntityBidderFilterDefinition(),
		}),
		new DimensionsColumnConfig({
			name: 'real_advertiser_id',
			accessor: 'real_advertiser_id',
			header: 'Real Advertiser Id',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsCurrencyColumnConfig({
			type: 'dimensions',
			name: 'daily_budget',
			accessor: 'daily_budget',
			header: 'Daily Budget',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsCurrencyColumnConfig({
			type: 'dimensions',
			name: 'daily_money_spent',
			accessor: 'daily_money_spent',
			header: 'Daily Money Spent',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsCurrencyColumnConfig({
			type: 'dimensions',
			name: 'daily_money_earn',
			accessor: 'daily_money_earn',
			header: 'Daily Money Earn',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'max_loss_per_domain',
			accessor: 'max_loss_per_domain',
			header: 'Max Loss Per Domain',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'max_clicks_to_first_conv',
			accessor: 'max_clicks_to_first_conv',
			header: 'Max Clicks To First Conv',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'q_max_loss_per_domain',
			accessor: 'q_max_loss_per_domain',
			header: 'Q Max Loss Per Domain',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'q_max_clicks_to_first_conv',
			accessor: 'q_max_clicks_to_first_conv',
			header: 'Q Max Clicks To First Conv',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'daily_sales',
			accessor: 'daily_sales',
			header: 'Daily Sales',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new DimensionsColumnConfig({
			name: 'state_machine_id',
			accessor: 'state_machine_id',
			header: 'State Machine Id',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'max_wins_to_first_click',
			accessor: 'max_wins_to_first_click',
			header: 'Max Wins To First Click',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'pageloads_clicks_ratio',
			accessor: 'pageloads_clicks_ratio',
			header: 'Pageloads Clicks Ratio',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'max_clicks_to_first_pageload',
			accessor: 'max_clicks_to_first_pageload',
			header: 'Max Clicks To First Pageload',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsColumnConfig({
			type: 'dimensions',
			name: 'max_pageloads_to_first_sale',
			accessor: 'max_pageloads_to_first_sale',
			header: 'Max Pageloads To First Sale',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsPercentageColumnConfig({
			type: 'dimensions',
			name: 'conv_pageloads_ratio',
			accessor: 'conv_pageloads_ratio',
			header: 'Conv Pageloads Ratio',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new MetricsPercentageColumnConfig({
			type: 'dimensions',
			name: 'conv_clicks_ratio',
			accessor: 'conv_clicks_ratio',
			header: 'Conv Clicks Ratio',
			visibility: ColumnVisibilityOptions.Visible,
		}),
		new DimensionsColumnConfig({
			type: 'dimensions',
			name: 'targeting_link_choice_policy',
			accessor: 'targeting_link_choice_policy',
			header: 'Targeting Link Choice Policy',
			visibility: ColumnVisibilityOptions.Visible,
		}),
	],
	includeChart: true,
	tableName: 'adxReprBidderCampaignsPerformance',
};
