import { observer } from 'mobx-react';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IToastItem } from '../../../stores/toasts.store';
import { Toast } from '../toast/toast';
import styles from './toasts-container.module.scss';

export const ToastsContainer = observer(() => {
	const { toastsStore } = useStores<IControlledStore>();
	const toasts = toastsStore.getToasts();

	return (
		<div className={styles.wrapper}>
			{toasts.map((toast: IToastItem) => {
				const { children, id } = toast;
				return (
					<Toast key={id} {...toast}>
						{children}
					</Toast>
				);
			})}
		</div>
	);
});
