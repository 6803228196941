import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../../modules/stores';
import { SitesInTargetFiles } from '../../../../modules/components/sites-in-target-files/sites-in-target-files';
import { ReportFilterType } from '../../../../modules/components/report-table/stores/data-stores/old-report.types';
import { useTableStore } from '../../../../modules/components/report-table/stores/use-table-store.hook';
import { useTableStoreMetrics } from '../../../../modules/hooks/tools/use-repr-metrics';
import { Card } from '@monorepo/base/src/components/card/card';
import ReportTable from '../../../../modules/components/report-table/table-component';
import { Chart } from '@monorepo/base/src/components/chart/chart';

export const CampaignDomains = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();
	const { campaignDomainsPerformance } = campaignStore;
	const { campaignId } = useParams();
	const hiddenFilters = campaignId
		? [{ column: 'campaign_id', filterType: ReportFilterType.EQUALS, value: [campaignId], inverted: false }]
		: undefined;

	useTableStore({ reportTableStore: campaignDomainsPerformance, hiddenFilters });
	const chartProps = useTableStoreMetrics(campaignDomainsPerformance);

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Domains</PagebarTitle>
				<PagebarActions>
					<SitesInTargetFiles />
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<Card full>
				<Chart isLoading={campaignDomainsPerformance.dataStore.getIsLoading()} {...chartProps} />
			</Card>
			<ReportTable tableStore={campaignDomainsPerformance} />
		</Page>
	);
});
