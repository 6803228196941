import { DataColumnConfig } from '../../../columns/columns';
import { CampaignIdColumnConfig, CampaignNameColumnConfig } from '../../../columns/adminx.columns';
import { ReportFilterType, ReportSortOrder } from '../../../stores/data-stores/old-report.types';
import { TextFilterDefinition } from '../../../table-filters/filters.definitions';
import { FilterType } from '../../../table-filters/filters.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';

export const domainsCampaignsStatusTableConfig = {
	reportParams: {
		reportName: 'adxDomainCampaignsStatus',
		sortBy: 'campaign_name',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new CampaignNameColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new CampaignIdColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DataColumnConfig({
			name: 'campaign_status',
			accessor: 'campaignStatus',
			header: 'Status',
			visibility: ColumnVisibilityOptions.Visible,
			availableFilters: {
				type: FilterType.Select,
				actions: [
					{
						label: 'Equals',
						value: ReportFilterType.EQUALS,
					},
				],
				options: [
					{
						label: 'PAUSED',
						value: 'PAUSED',
					},
					{
						label: 'ACTIVE',
						value: 'ACTIVE',
					},
				],
			},
		}),
		new DataColumnConfig({
			name: 'list_type',
			accessor: 'listType',
			header: 'List Type',
			availableFilters: new TextFilterDefinition(),
			visibility: ColumnVisibilityOptions.Visible,
		}),
	],
	tableState: { pagination: { pageIndex: 0, pageSize: 5 } },
	isGraphAvailable: false,
	tableName: 'domainsCampaignsStatusTable',
};
