import { makeAutoObservable } from 'mobx';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';
import { AdminxReporterApi } from '../../../../apis/adminx-reporter.api';
import { ReportFilter, ReportFilterType } from './old-report.types';
import {
	FilterTypeEnum,
	FilterTypeNew2OldMap,
	FilterTypeOld2NewMap,
	generateReportParams,
	IAdminxReporterParams,
	IAdminxReporterResponse,
	ReportInputFilterItemInterface,
} from './adx-repr.types';
import { IDataStore, IDataType } from './data-store.types';

export class AdminxReportResponse<T> implements IAdminxReporterResponse<T> {
	reportName: string;
	total: T;
	graphData: T[];
	rows: T[];
	segments: T[];
	isCached: boolean;
	missingRows: boolean;

	constructor(reportResponse: IAdminxReporterResponse<T>) {
		this.reportName = reportResponse.reportName;
		this.total = reportResponse.total;
		this.graphData = reportResponse.graphData;
		this.rows = reportResponse.rows;
		this.segments = reportResponse.segments;
		this.isCached = reportResponse.isCached;
		this.missingRows = reportResponse.missingRows;
		makeAutoObservable(this);
	}
}

interface IAdminxReportStoreParams {
	reportParams: Partial<IAdminxReporterParams>;
	includeChart: boolean;
}

export class AdminxReportStore<T = IDataType> implements IDataStore<T, IAdminxReporterParams> {
	reportParams: IAdminxReporterParams = generateReportParams();
	defaultReportParams: IAdminxReporterParams;
	hiddenFilters: ReportFilter[] = [];
	report = new HttpStore<IAdminxReporterParams, AdminxReportResponse<T>>({
		httpFunc: AdminxReporterApi.newAdminxReport,
		model: AdminxReportResponse<T>,
	});
	downloadCsv = new HttpStore<IAdminxReporterParams, Blob | null>({
		httpFunc: AdminxReporterApi.newAdminxReportCsv,
	});

	constructor({ reportParams, includeChart }: IAdminxReportStoreParams) {
		this.reportParams = { ...this.reportParams, ...{ ...reportParams, includeDateGraph: includeChart } };
		this.defaultReportParams = JSON.parse(JSON.stringify(this.reportParams));
		makeAutoObservable(this);
	}

	async fetchReport({ params, appendData = false }: { params: Partial<IAdminxReporterParams>; appendData?: boolean }) {
		this.report.abort();
		const reportFilters = this.hiddenFilters.map(AdminxReportStore.convertStateFilterToFilter).concat(params.filters || []);
		this.reportParams = { ...this.reportParams, ...params, filters: reportFilters };
		if (this.reportParams.sort.length === 0) {
			this.reportParams.sort = [...this.defaultReportParams.sort];
		}
		await this.report.fetch(this.reportParams, undefined, { appendData });
	}

	setHiddenFilters(filters: ReportFilter[]) {
		// reset report store if hidden filters are changed
		if (
			filters.length !== this.hiddenFilters.length ||
			!filters.every((val, index) => JSON.stringify(val) === JSON.stringify(this.hiddenFilters[index]))
		) {
			this.report.reset();
		}
		this.hiddenFilters = filters;
	}

	getReportData() {
		return this.report.getData()?.rows || [];
	}

	getReportFooter() {
		return this.report.getData()?.total || ({} as T);
	}

	getReportGraphData() {
		return this.report.getData()?.graphData || [];
	}

	getTotalRows() {
		return this.report.getData()?.rows.length || 0;
	}

	getIsLoading() {
		return this.report.getIsLoading();
	}

	getError() {
		return this.report.getHttpError();
	}

	static convertFilterToStateFilter(filter: ReportInputFilterItemInterface): ReportFilter {
		return {
			column: filter.column,
			filterType: FilterTypeNew2OldMap.get(filter.filterType) as ReportFilterType,
			value: filter.value,
			inverted: filter.inverted,
		};
	}

	static convertStateFilterToFilter(filter: ReportFilter): ReportInputFilterItemInterface {
		return {
			column: filter.column,
			filterType: FilterTypeOld2NewMap.get(filter.filterType) as FilterTypeEnum,
			value: filter.value.map(i => {
				if (i.toString().endsWith('%')) {
					return (parseInt(i.toString().slice(0, -1)) / 100).toString();
				}

				return i.toString();
			}),
			inverted: filter.inverted || false,
		};
	}

	async downloadReportFileCsv(reportFileName = 'report') {
		const fileName = `adminx ${reportFileName} ${this.reportParams.periodStart} - ${this.reportParams.periodEnd}`;
		const totalRows = this.getTotalRows();
		if (totalRows <= 0) {
			return;
		}
		this.downloadCsv.fetch(this.reportParams).then((blob: Blob | null) => {
			if (blob !== null) {
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${fileName}.csv`);
				link.style.display = 'none';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		});
	}

	isParamsAreDifferent(partialParams: Partial<IAdminxReporterParams>, params: IAdminxReporterParams = this.reportParams): boolean {
		// Check each property explicitly if it exists in partialParams
		if (partialParams.periodStart !== undefined && partialParams.periodStart !== params.periodStart) {
			return true;
		}
		if (partialParams.periodEnd !== undefined && partialParams.periodEnd !== params.periodEnd) {
			return true;
		}
		if (partialParams.groupBys !== undefined) {
			if (
				partialParams.groupBys.length !== params.groupBys.length ||
				!partialParams.groupBys.every((val, index) => val === params.groupBys[index])
			) {
				return true;
			}
		}
		if (partialParams.metrics !== undefined) {
			if (
				partialParams.metrics.length !== params.metrics.length ||
				!partialParams.metrics.every((val, index) => val === params.metrics[index])
			) {
				return true;
			}
		}
		if (partialParams.filters !== undefined) {
			if (
				partialParams.filters.length !== params.filters.length ||
				!partialParams.filters.every((val, index) => JSON.stringify(val) === JSON.stringify(params.filters[index]))
			) {
				return true;
			}
		}
		if (partialParams.start !== undefined && partialParams.start !== params.start) {
			return true;
		}
		if (partialParams.limit !== undefined && partialParams.limit !== params.limit) {
			return true;
		}
		if (partialParams.bypassCache !== undefined && partialParams.bypassCache !== params.bypassCache) {
			return true;
		}
		if (partialParams.sort !== undefined) {
			if (
				partialParams.sort.length !== params.sort.length ||
				!partialParams.sort.every((val, index) => JSON.stringify(val) === JSON.stringify(params.sort[index]))
			) {
				return true;
			}
		}

		return false;
	}

	isParamsAreDifferentWithDebug(
		partialParams: Partial<IAdminxReporterParams>,
		params: IAdminxReporterParams = this.reportParams
	): boolean {
		// Check each property explicitly if it exists in partialParams
		if (partialParams.periodStart !== undefined && partialParams.periodStart !== params.periodStart) {
			console.log('periodStart', partialParams.periodStart, params.periodStart);
			return true;
		}
		if (partialParams.periodEnd !== undefined && partialParams.periodEnd !== params.periodEnd) {
			console.log('periodEnd', partialParams.periodEnd, params.periodEnd);
			return true;
		}
		if (partialParams.groupBys !== undefined) {
			if (
				partialParams.groupBys.length !== params.groupBys.length ||
				!partialParams.groupBys.every((val, index) => val === params.groupBys[index])
			) {
				console.log('groupBys', partialParams.groupBys, params.groupBys);
				return true;
			}
		}
		if (partialParams.metrics !== undefined) {
			if (
				partialParams.metrics.length !== params.metrics.length ||
				!partialParams.metrics.every((val, index) => val === params.metrics[index])
			) {
				console.log('metrics', partialParams.metrics, params.metrics);
				return true;
			}
		}
		if (partialParams.filters !== undefined) {
			if (
				partialParams.filters.length !== params.filters.length ||
				!partialParams.filters.every((val, index) => JSON.stringify(val) === JSON.stringify(params.filters[index]))
			) {
				console.log('filters', partialParams.filters, params.filters);
				return true;
			}
		}
		if (partialParams.start !== undefined && partialParams.start !== params.start) {
			console.log('start', partialParams.start, params.start);
			return true;
		}
		if (partialParams.limit !== undefined && partialParams.limit !== params.limit) {
			console.log('limit', partialParams.limit, params.limit);
			return true;
		}
		if (partialParams.bypassCache !== undefined && partialParams.bypassCache !== params.bypassCache) {
			console.log('bypassCache', partialParams.bypassCache, params.bypassCache);
			return true;
		}
		if (partialParams.sort !== undefined) {
			if (
				partialParams.sort.length !== params.sort.length ||
				!partialParams.sort.every((val, index) => JSON.stringify(val) === JSON.stringify(params.sort[index]))
			) {
				console.log('sort', partialParams.sort, params.sort);
				return true;
			}
		}

		return false;
	}
}
