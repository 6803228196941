import { makeAutoObservable } from 'mobx';
import { CampaignSegmentationCrudStore } from './campaign-segmentation-crud.store';

export class CampaignSegmentationStore {
	crud = new CampaignSegmentationCrudStore();

	constructor() {
		makeAutoObservable(this);
	}

	getCrud() {
		return this.crud;
	}
}
