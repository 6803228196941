import { IAdvertiserCreateForm, IAdvertiserEditForm } from '../models/advertiser.model';
import { AdvertiserModel } from '../models/advertiser.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export const AdvertisersApi = {
	list: (params?: URLSearchParams): Promise<AdvertiserModel[]> => {
		return ask.get(endpoints.advertisers.list(), params);
	},
	create: (advertiser: IAdvertiserCreateForm, options?: IRequestOptions): Promise<{ id: id }> => {
		return ask.post<IAdvertiserCreateForm>(endpoints.advertisers.create(), advertiser, options);
	},
	edit: (advertiserId: id, advertiser: IAdvertiserEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<IAdvertiserEditForm>(endpoints.advertisers.edit(advertiserId), advertiser, options);
	},
	get: (advertiserId: id, options?: URLSearchParams): Promise<AdvertiserModel> => {
		return ask.get(endpoints.advertisers.get(advertiserId), options);
	},
	delete: (advertiserId: id) => {
		return ask.delete(endpoints.advertisers.delete(advertiserId));
	},
};
