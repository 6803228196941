import { action, makeObservable, observable } from 'mobx';
import { PerformanceApi } from '../apis/performance.api';
import { ISegment } from '@monorepo/base/src/components/segments/segments';
import { PerformanceModel, PerformanceResponse } from '../models/performance.model';
import { IPerformanceOptions, PerformancesColumnsSnakeCase } from '../hooks/apis/use-reports-list';
import { HttpStore } from '@monorepo/controlled/src/stores/http.store';

interface IPerformanceStoreOptions<TModel> {
	keys?: Set<PerformancesColumnsSnakeCase>;
	segments?: Set<ISegment<TModel, PerformanceModel, PerformancesColumnsSnakeCase>>;
}

export class PerformanceStore<TModel>
	extends HttpStore<IPerformanceOptions, PerformanceResponse>
	implements IPerformanceStoreOptions<TModel>
{
	keys: Set<PerformancesColumnsSnakeCase>;
	segments: Set<ISegment<TModel, PerformanceModel, PerformancesColumnsSnakeCase>>;

	constructor(props: IPerformanceStoreOptions<TModel>) {
		super({
			httpFunc: PerformanceApi.performance,
			model: PerformanceResponse,
		});

		this.segments = props.segments || new Set();
		this.keys = props.keys || new Set();

		makeObservable(this, {
			segments: observable,
			setSegments: action,
		});
	}

	setSegments(segments: Set<ISegment<TModel, PerformanceModel, PerformancesColumnsSnakeCase>>) {
		this.segments = segments;
	}

	getSegments(): Set<ISegment<TModel, PerformanceModel, PerformancesColumnsSnakeCase>> | undefined {
		return this.segments;
	}

	setKeys(keys: Set<PerformancesColumnsSnakeCase>) {
		this.keys = keys;
	}

	getGroupBys(): Set<PerformancesColumnsSnakeCase> {
		return this.keys;
	}
}
