import { AdvertiserModel } from './advertiser.model';
import { action, makeObservable, observable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Status, Statuses } from '../enums/status';

export interface ICampaignGroupCreateForm {
	id?: id;
	name?: string;
	status?: Status;
	advertiser?: { id?: id }; // Advertiser id
}

export interface ICampaignGroupEditForm {
	id?: id;
	name?: string;
	status?: Status;
	advertiser?: { id?: id }; // Advertiser id
}

export interface ICampaignGroup {
	id?: id;
	name?: string;
	status?: Status;
	advertiser?: AdvertiserModel;
}

export class CampaignGroupModel implements ICampaignGroup {
	id?: id;
	name?: string;
	status?: Status;
	advertiser?: AdvertiserModel;

	constructor(campaignGroup?: ICampaignGroup) {
		this.id = campaignGroup?.id;
		this.name = campaignGroup?.name;
		this.status = campaignGroup?.status || Statuses.Paused;
		this.advertiser = campaignGroup?.advertiser ? new AdvertiserModel(campaignGroup.advertiser) : undefined;

		makeObservable(this, {
			id: observable,
			name: observable,
			status: observable,
			advertiser: observable,
			setName: action,
			setAdvertiser: action,
			setStatus: action,
		});
	}

	getId(): id | undefined {
		return this.id;
	}

	getName(): string | undefined {
		return this.name;
	}

	setName(name: string): void {
		this.name = name;
	}

	getStatus(): Status | undefined {
		return this.status;
	}

	setStatus(status: Status): void {
		this.status = status;
	}

	getAdvertiser(): AdvertiserModel | undefined {
		return this.advertiser;
	}

	setAdvertiser(advertiser: AdvertiserModel): void {
		this.advertiser = advertiser;
	}
}
