import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { DeviceType, DeviceTypes } from '@monorepo/tools/src/lib/enums/device-types';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Dropdown, option } from '@monorepo/base/src/components/dropdown/dropdown';
import { DropdownNew } from '@monorepo/base/src/components/dropdown-new/dropdown-new';
import countriesJson from '@monorepo/tools/src/lib/consts/geos.json';
import { Environments } from '../../../../enums/environment';
import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import styles from './create-targeting.module.scss';
import { objectToSelectOptions } from '@monorepo/tools/src/lib/utils/object';
import { InterstitialsTypesEnum, SellerTypesEnum } from '../../../../models/targeting.model';
import { capitalCase } from 'change-case';
import { DealTypes } from '../../../../enums/deal-types';

export const CreateTargeting: FC = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();

	const inputOptions = campaignStore.getInputOptions();
	const campaignCrudStore = campaignStore.getCrud();
	const formStore = campaignCrudStore.getFormStore();
	const campaign = campaignCrudStore.getData();
	const targeting = campaign.getTargeting();
	const environment = targeting?.getEnvironment();
	const deviceTypes = targeting?.getDeviceTypes();
	const operatingSystems = targeting?.getOperatingSystems();
	const browsers = targeting?.getBrowsers();
	const geos = targeting?.getGeos();
	const sellerType = targeting?.getSellerType();
	const interstitial = targeting?.getInterstitial();

	const pretargetingDealType = campaign.getDealType();

	useEffect(() => {
		formStore.clearError('targeting_id');
	}, []);

	return (
		<div className={styles.contentWrapper}>
			<ButtonGroup
				defaultValue={environment}
				title={'Environment'}
				onChange={(setValues: Set<string>) => targeting?.setEnvironment(setValues.values().next().value)}>
				<GButton
					icon={'devices'}
					text={Environments.All}
					value={Environments.All}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'environment_all')] }}
				/>
				<GButton
					icon={'globe-03'}
					text={Environments.Web}
					value={Environments.Web}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'environment_web')] }}
				/>
				<GButton
					icon={'tablet-01'}
					text={Environments.App}
					value={Environments.App}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'environment_app')] }}
				/>
			</ButtonGroup>
			{/* <Spacer y={1.5} /> */}
			<ButtonGroup
				defaultValues={deviceTypes}
				multi={true}
				required={true}
				title={'Device Types'}
				onChange={(value: Set<string>) => {
					targeting?.setDeviceTypes(value as Set<DeviceType>);
					formStore.clearError('device_types');
				}}
				error={formStore.getErrors().get('device_types')?.getMsg()}>
				<GButton
					icon={'phone-android'}
					text={DeviceTypes.Mobile}
					value={DeviceTypes.Mobile}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'device_types_mobile')] }}
				/>
				<GButton
					icon={'tablet-02'}
					text={DeviceTypes.Tablet}
					value={DeviceTypes.Tablet}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'device_types_tablet')] }}
				/>
				<GButton
					icon={'monitor-01'}
					text={DeviceTypes.Computer}
					value={DeviceTypes.Computer}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'device_types_computer')] }}
				/>
			</ButtonGroup>
			<ButtonGroup
				defaultValue={sellerType}
				title={'Seller Type'}
				onChange={(setValues: Set<string>) => {
					targeting?.setSellerType(setValues.values().next().value);
					formStore.clearError('seller_type');
				}}
				error={formStore.getErrors().get('seller_type')?.getMsg()}>
				<GButton
					icon={'currency-dollar-circle'}
					text={capitalCase(SellerTypesEnum.All)}
					value={SellerTypesEnum.All}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'seller_type_all')] }}
				/>
				<GButton
					icon={'credit-card-up'}
					text={capitalCase(SellerTypesEnum.Direct)}
					value={SellerTypesEnum.Direct}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'seller_type_direct')] }}
				/>
				<GButton
					icon={'coins-hand'}
					text={capitalCase(SellerTypesEnum.Reseller)}
					value={SellerTypesEnum.Reseller}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'seller_type_reseller')] }}
				/>
			</ButtonGroup>

			<ButtonGroup
				defaultValue={interstitial}
				title={'Interstitial'}
				onChange={(setValues: Set<string>) => {
					targeting?.setInterstitial(setValues.values().next().value);
					formStore.clearError('interstitial');
				}}
				error={formStore.getErrors().get('interstitial')?.getMsg()}>
				<GButton
					icon={'shuffle-01'}
					text={capitalCase(InterstitialsTypesEnum.All)}
					value={InterstitialsTypesEnum.All}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'interstitial_all')] }}
				/>
				<GButton
					icon={'expand-05'}
					text={capitalCase(InterstitialsTypesEnum.Interstitials)}
					value={InterstitialsTypesEnum.Interstitials}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'interstitial_interstital')] }}
				/>
				<GButton
					icon={'image-indent-left'}
					text={capitalCase(InterstitialsTypesEnum.Non_Interstitials)}
					value={InterstitialsTypesEnum.Non_Interstitials}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'interstitial_non_interstital')] }}
				/>
			</ButtonGroup>
			<div className={styles.operations}>
				<Dropdown
					defaultOptions={operatingSystems}
					label={'Select Operating Systems'}
					required={true}
					multi={true}
					options={inputOptions.getOperatingSystems()}
					onSelect={(operatingSystems: Set<option> | undefined) => {
						targeting?.setOperatingSystems(operatingSystems);
						formStore.clearError('operating_systems');
					}}
					error={formStore.getErrors().get('operating_systems')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'operating_systems')] }}
				/>
				<Dropdown
					defaultOptions={browsers}
					label={'Select Browsers'}
					required={true}
					multi={true}
					options={inputOptions.getBrowsers()}
					onSelect={(browsers: Set<option> | undefined) => {
						formStore.clearError('browsers');
						targeting?.setBrowsers(browsers);
					}}
					error={formStore.getErrors().get('browsers')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'browsers')] }}
				/>

				<DropdownNew
					classNames={{ dropdownWrapper: styles.dropdown, listItem: styles.listItem, dropdownInput: styles.dropdownInput }}
					required={true}
					defaultValues={geos ? Array.from(geos) : []}
					label={'Select Countries'}
					multi={pretargetingDealType === DealTypes.PreferredDeal}
					autocomplete={true}
					autocompletePlaceholder={'Search Countries'}
					options={objectToSelectOptions(countriesJson)}
					onSelect={geos => {
						targeting?.setGeos(geos.map(geo => geo.toString()));
						formStore.clearError('geos');
					}}
					error={formStore.getErrors().get('geos')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'geos')] }}
					isLabelOnBorder={true}
				/>
			</div>
		</div>
	);
});
