export enum ReportFilterType {
	CONTAINS = 'CONTAINS',
	EQUALS = 'EQUALS',
	IS_NULL = 'IS_NULL',
	IN = 'IN',
	GREATER = 'GREATER',
	LESS = 'LESSER',
	INCLUDE = 'INCLUDE',

	NOT_CONTAINS = 'NOT_CONTAINS',
	IS_NOT_NULL = 'IS_NOT_NULL',
	NOT_EQUALS = 'NOT_EQUALS',
	NOT_IN = 'NOT_IN',
	GREATER_AND_EQUALS = 'GREATER_EQUALS',
	LESS_AND_EQUALS = 'LESSER_EQUALS',
}

export const InvertedReportFilterTypes = [ReportFilterType.NOT_CONTAINS, ReportFilterType.NOT_EQUALS, ReportFilterType.IS_NOT_NULL];
export const InvertedReportFilterTypesMap = new Map<ReportFilterType, ReportFilterType>([
	[ReportFilterType.NOT_CONTAINS, ReportFilterType.CONTAINS],
	[ReportFilterType.NOT_EQUALS, ReportFilterType.EQUALS],
	[ReportFilterType.IS_NOT_NULL, ReportFilterType.IS_NULL],
]);

export const InvertedReportFilterTypesBackMap = new Map<ReportFilterType, ReportFilterType>([
	[ReportFilterType.CONTAINS, ReportFilterType.NOT_CONTAINS],
	[ReportFilterType.EQUALS, ReportFilterType.NOT_EQUALS],
	[ReportFilterType.IS_NULL, ReportFilterType.IS_NOT_NULL],
]);

export interface ReportFilter {
	column: string;
	filterType: ReportFilterType;
	value: (string | number)[];
	inverted?: boolean;
}
export interface ReportTimePeriod {
	periodStart: string; // 'YYYY-MM-DD'
	periodEnd: string; // 'YYYY-MM-DD'
}

export enum ReportDateSegments {
	Daily = 'DAILY',
	None = 'NONE',
}
export enum ReportSortOrder {
	Asc = 0,
	Desc = 1,
}

export enum ReportType {
	Statistics = 'STATISTICS',
}

export interface ReportParams {
	start: number;
	limit: number;
	periodStart: string; // 'YYYY-MM-DD'
	periodEnd: string; // 'YYYY-MM-DD'
	multipleTimePeriods: ReportTimePeriod[];
	filters: ReportFilter[];
	groupBys: string[];
	dateSegment?: ReportDateSegments;
	sortBy: string;
	sortOrder: ReportSortOrder;
	inResultsSort: string | null;
	inResultsSortOrder: ReportSortOrder | null;
	inResultsFilters: string[][];
	graphYaxis: string[]; // deprecated - always be an empty array
	graphLines: string[]; // deprecated - always be an empty array
	additionalColumns: string[]; // deprecated - always be an empty array
	reportName: string;
	submit: boolean;
	type: ReportType;
}

export const generateReportParams = (): ReportParams => {
	return {
		start: 0,
		limit: 1000,
		periodStart: '',
		periodEnd: '',
		multipleTimePeriods: [],
		filters: [],
		groupBys: [],
		sortBy: '',
		sortOrder: ReportSortOrder.Desc,
		dateSegment: ReportDateSegments.None,
		inResultsSort: null,
		inResultsSortOrder: 1,
		inResultsFilters: [],
		graphYaxis: [],
		graphLines: [],
		additionalColumns: [],
		reportName: '',
		submit: true,
		type: ReportType.Statistics,
	};
};

export interface ReportResponse<T> {
	success: boolean;
	errorMessage: string | null;
	data: T[];
	total: number;
	limited: boolean;
	footer: T;
	columns: string[][];
	graphData: object;
}
