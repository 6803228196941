import { useState } from 'react';
import useEventListener from '../utils/use-event-listener';

interface IDevices {
	minimum: boolean;
	smallMobile: boolean;
	mobile: boolean;
	tablet: boolean;
	largeTablet: boolean;
	laptop: boolean;
	desktop: boolean;
	largeDesktop: boolean;
	veryLargeDesktop: boolean;
	isMobileDevice: boolean;
	isTabletDevice: boolean;
	isBigRes: boolean;
	isComputerDevice: boolean;
	current: string;
	currentWidth: number;
}

const LIMITS = {
	minimum: 345,
	smallMobile: 374,
	mobile: 544,
	tablet: 768,
	largeTablet: 1024,
	laptop: 1140,
	desktop: 1240,
	largeDesktop: 1500,
	veryLargeDesktop: 1501,
};

function resolveDevices() {
	const w = window.innerWidth;
	const minimum = w <= LIMITS.minimum; // 0 - 345
	const smallMobile = w > LIMITS.minimum && w <= LIMITS.smallMobile; // 346 - 374
	const mobile = w > LIMITS.smallMobile && w <= LIMITS.mobile; // 375 - 544
	const tablet = w > LIMITS.mobile && w <= LIMITS.tablet; // 545 - 768
	const largeTablet = w > LIMITS.tablet && w <= LIMITS.largeTablet; // 769 - 1024
	const laptop = w > LIMITS.largeTablet && w <= LIMITS.laptop; // 1025 - 1140
	const desktop = w > LIMITS.laptop && w <= LIMITS.desktop; // 1141 - 1240
	const largeDesktop = w > LIMITS.desktop && w <= LIMITS.largeDesktop; // 1241 - 1500
	const veryLargeDesktop = w >= LIMITS.largeDesktop; // 1501 - Infinity

	const screens = {
		minimum,
		smallMobile,
		mobile,
		tablet,
		largeTablet,
		laptop,
		desktop,
		largeDesktop,
		veryLargeDesktop,
	};

	return {
		...screens,
		isMobileDevice: screens.minimum || screens.smallMobile || screens.mobile, // 0 - 544
		isTabletDevice: screens.tablet || screens.largeTablet, // 545 - 1024
		isBigRes: screens.largeTablet || screens.laptop || screens.desktop || screens.largeDesktop || screens.veryLargeDesktop, // 769 - Infinity
		isComputerDevice: screens.laptop || screens.desktop || screens.largeDesktop || screens.veryLargeDesktop, // 1025 - Infinity
		current: (() => {
			for (const [key, value] of Object.entries(screens)) {
				if (value) {
					return key;
				}
			}
			return 'unknown';
		})(),
		currentWidth: w,
	};
}

export function useDevices(): IDevices {
	const [devices, setDevices] = useState<IDevices>(resolveDevices());

	const handleResize = () => {
		const newDevices = resolveDevices();
		if (newDevices.current !== devices.current) {
			// Render only if devices were changed
			setDevices(newDevices);
		}
	};

	useEventListener('resize', handleResize);

	return devices;
}
