import { ReactNode } from 'react';
import styles from './bottom-bar.module.scss';
import { generateDataAttrs, DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';

interface IBottomBar {
	children: ReactNode;
	isBorderTopOnly?: boolean;
	isUnsticky?: boolean;
	classes?: IBottomBarClasses;
}

interface IBottomBarActions {
	children: ReactNode;
	classes?: IActionsClasses;
}

interface IBottomBarClasses {
	wrapperClassses?: string;
	barClassses?: string;
}
interface IActionsClasses {
	actions?: string;
}

export const BottomBar = (props: IBottomBar) => {
	const { children, isBorderTopOnly, isUnsticky, classes = {} } = props;

	return (
		<div
			className={`${styles.wrapper} ${isUnsticky ? styles.unsticky : ''} ${classes.wrapperClassses}`}
			{...generateDataAttrs([new DataAttribute('id', 'bottom_bar')])}>
			<div className={`${styles.bar} ${isBorderTopOnly ? styles.borderTopOnly : ''} ${classes.barClassses}`}>{children}</div>
		</div>
	);
};

export const BottomBarActions = (props: IBottomBarActions) => {
	const { children, classes = {} } = props;

	return <div className={`${styles.actions} ${classes.actions}`}>{children}</div>;
};
