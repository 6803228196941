import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter } from '../../components/filters-menu/filters';

export const preferenceFilters = new Map<FilterTypeKey, FilterType>([
	['numberFilters', {} as INumberFilter],
	[
		'stringFilters',
		{
			name: { columnName: 'Name', cellKey: 'name' },
			value: { columnName: 'Value', cellKey: 'value' },
			comment: { columnName: 'Comment', cellKey: 'comment' },
		} as IStringFilter,
	],
	['enumFilters', {} as IEnumFilter],
]);
