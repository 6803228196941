import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from './use-route';

export const usePageSettings = (currentRoute?: string) => {
	const { settingsStore } = useStores<IControlledStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const pageSettings = settingsStore.getPages().get(currentRoute || currentRouteWithoutSpecialChars);
	const tableStore = pageSettings?.tableStore;

	return { tableStore };
};
