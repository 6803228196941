import { observer } from 'mobx-react';
import { Fragment, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../routes/index.routes';
import { IAdminxStore } from '../../stores';

interface IRedirectAuth {
	children: ReactNode;
}

/**
 * Wrap the children element in this component if you want
 * to redirect authenticated users from the children component to homepage
 */
export const RedirectAuth = observer((props: IRedirectAuth) => {
	const { children } = props;
	const { authStore } = useStores<IAdminxStore>();

	if (!authStore.getToken()) {
		return <Fragment>{children}</Fragment>;
	}
	return <Navigate to={routes.general.home()} replace />;
});
