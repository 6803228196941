import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { IEntityHeaders, IMergedHeadersReturn } from './index.headers';
import { IPerformanceItem } from '../../models/performance.model';
import { IAdminxStore } from '../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';

const columnHelper = createColumnHelper<IPerformanceItem>();

export const usePagesHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<{ domain: string }> => {
	const { summary } = props;
	const { settingsStore } = useStores<IAdminxStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore?.setDefaultColumnVisibility({
				pages: true,
				user_cap_enabled: true,
				totalBudgetLimit: true,
				auctions: true,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: true,
				roas: true,
				ctr: true,
				lpCvr: true,
				impressions: true,
				optCost: true,
				optGain: true,
				gain: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('page', {
				header: 'Pages',
				cell: ({ row }) => <OverflowColumn data={row.getValue('page')} />,
			}),
			...mergedPerforamnceHeaders,
		],
	};
};
