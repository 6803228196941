import { observer } from 'mobx-react';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Page, Pagebar, PagebarTitle, PagebarActions } from '@monorepo/base/src/components/page/page';
import { useDealActions } from '../../../modules/hooks/actions/deals.actions';
import { SideEffectKeys } from '../../../modules/hooks/apis/use-performance';
import { PerformancesColumnsSnakeCase } from '../../../modules/hooks/apis/use-reports-list';
import { useList } from '../../../modules/hooks/tools/use-list';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useMergeWithPerformance } from '../../../modules/hooks/tools/use-merge-with-performance';
import { DealModel } from '../../../modules/models/deal.model';
import { usePerformanceEntity } from '../../../modules/hooks/apis/use-performance-entity';
import { useDealHeaders } from '../../../modules/hooks/theaders/deals.headers';
import { PerformanceModel } from '../../../modules/models/performance.model';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '../../../modules/hooks/tools/use-route';

export const Deals: FC = observer(() => {
	const { dealStore } = useStores<IAdminxStore>();
	const performanceStore = dealStore.getPerformance();
	const { SelectedActions, SegmentActions, FiltersActions } = useDealActions();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const dealListStore = dealStore.getListStore();

	useList<DealModel>(dealListStore);
	usePerformanceEntity(performanceStore);

	const { data, summary } = useMergeWithPerformance<DealModel & PerformanceModel>({
		performanceStore,
		data: dealListStore.getList(),
		key: PerformancesColumnsSnakeCase.PreferredDealId,
		foreignKey: SideEffectKeys.DealId,
		attachExtraParams(row) {
			return {
				preferred_deal_id: row.deal_id,
				deal_name: row.deal_name,
				name: row.deal_name,
				serving_status: row.serving_status,
				formats: row.formats,
				proposal_id: row.proposal_id,
				proposal_name: row.proposal_name,
				publisher_profile: row.publisher_profile,
			};
		},
	});

	const { columns } = useDealHeaders({ summary });

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Deals</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				columns={columns}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'deals_table')] }}
				data={data}
				isLoading={dealStore.getListStore().getIsLoading()}
				isLoadingPerformance={performanceStore.getIsLoading()}
				isSummary={true}>
				{() => {
					return {
						SelectedActions,
						SegmentActions,
						FiltersActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
