import { PreferenceModel } from '../../models/preference.model';
import { PreferencesApi } from '../../apis/preferences.api';
import { makeAutoObservable } from 'mobx';
import { BaseListStore } from '@monorepo/controlled/src/stores/base-list.store';
import { PreferenceCrudStore } from './preference-crud.store';
import { SettingsStore } from '@monorepo/controlled/src/stores/settings.store';
import { TableStore } from '@monorepo/controlled/src/stores/table.store';
import { FilterStore } from '@monorepo/controlled/src/stores/filter.store';
import { filterPrototypesByProp } from '../../components/filters-menu/filters';

export class PreferenceStore {
	private crud = new PreferenceCrudStore();
	private list = new BaseListStore<PreferenceModel>({
		listFunc: PreferencesApi.list,
		model: PreferenceModel,
	});
	settingsStore: SettingsStore;

	constructor(settingsStore: SettingsStore) {
		makeAutoObservable(this);
		this.settingsStore = settingsStore;
		this.createDefaultFilters();
	}

	createDefaultFilters() {
		if (!this.settingsStore.getPages().get('preferences')) {
			this.settingsStore.getPages().set('preferences', { tableStore: new TableStore() });
		}
		const preferenceFilterByKey = new FilterStore({
			key: 'preferences',
			currentFilters: undefined,
			filterPrototypesByProp,
		});

		this.settingsStore.getPages().get('preferences')?.tableStore.setFiltersStore('preferences', preferenceFilterByKey);
	}

	getCrud() {
		return this.crud;
	}

	getListStore() {
		return this.list;
	}
}
