import { AdminxReportStore } from '../stores/data-stores/adx-repr.store';
import { SortOrderEnum } from '../stores/data-stores/adx-repr.types';
import {
	campaignNameCell,
	campaignGroupNameCell,
	advertiserNameCell,
	campaignStatusCell,
	advertiserStatusCell,
	campaignGroupStatusCell,
} from '../cells/adx-repr.cells';
import { ReportFilterType } from '../stores/data-stores/old-report.types';
import { NumbersFilterDefinition, StatusFilterDefinition, TextFilterDefinition } from '../table-filters/filters.definitions';
import { DimensionsColumnConfig, MetricsCurrencyColumnConfig } from './columns';
import { FilterType } from '../table-filters/filters.types';
import { IColumnConfig } from './columns.types';

interface ICampaignEntityIdFilterData {
	campaign_id: number;
	campaign_name: string;
}

export class CampaignNameColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_name',
			accessor: 'campaign_name',
			header: 'Campaign Name',
			tooltip: 'The name of the campaign as defined in campaign creation / edit screen',
			linkTo: ['campaign_id'],
			availableFilters: {
				type: FilterType.SelectFromDataSource,
				actions: [{ label: 'Include', value: ReportFilterType.INCLUDE }],
				selectFromDataSource: {
					store: new AdminxReportStore<ICampaignEntityIdFilterData>({
						reportParams: {
							reportName: 'AdxCampaignsReport',
							groupBys: ['campaign_name', 'campaign_id'],
							sort: [{ column: 'campaign_name', sortOrder: SortOrderEnum.ASC }],
						},
						includeChart: false,
					}),
					cb: row => {
						const rowAs = row as ICampaignEntityIdFilterData;
						return {
							value: `${rowAs.campaign_id}`,
							label: `${rowAs.campaign_name} (${rowAs.campaign_id})`,
						};
					},
				},
			},
			cell: campaignNameCell,
			...params,
		});
	}
}

export class CampaignIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_id',
			accessor: 'campaign_id',
			header: 'Campaign ID',
			linkTo: ['campaign_name'],
			tooltip: 'The Campaign ID in our system. Will only be populated if we decided to bid',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

interface ICampaignGroupEntityIdFilterData {
	campaign_group_id: number;
	campaign_group_name: string;
}

export class CampaignGroupNameColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_group_name',
			accessor: 'campaign_group_name',
			header: 'Campaign Group',
			linkTo: ['campaign_group_id'],
			cell: campaignGroupNameCell,
			tooltip: 'The name of the campaign group in our system. Will only be populated if we decided to bid',
			availableFilters: {
				type: FilterType.SelectFromDataSource,
				actions: [{ label: 'Include', value: ReportFilterType.INCLUDE }],
				selectFromDataSource: {
					store: new AdminxReportStore<ICampaignGroupEntityIdFilterData>({
						reportParams: {
							reportName: 'AdxCampaignsReport',
							groupBys: ['campaign_group_name', 'campaign_group_id'],
							sort: [{ column: 'campaign_group_name', sortOrder: SortOrderEnum.ASC }],
						},
						includeChart: false,
					}),
					cb: row => {
						const rowAs = row as ICampaignGroupEntityIdFilterData;
						return {
							value: `${rowAs.campaign_group_id}`,
							label: `${rowAs.campaign_group_name} (${rowAs.campaign_group_id})`,
						};
					},
				},
			},
			...params,
		});
	}
}
export class CampaignGroupIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_group_id',
			accessor: 'campaign_group_id',
			header: 'Campaign Group ID',
			linkTo: ['campaign_group_name'],
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The Campaign Group ID in our system. Will only be populated if we decided to bid',
			...params,
		});
	}
}

interface IAdvertiserEntityIdFilterData {
	advertiser_id: number;
	advertiser_name: string;
}

export class AdvertiserNameColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'advertiser_name',
			accessor: 'advertiser_name',
			header: 'Advertiser',
			cell: advertiserNameCell,
			linkTo: ['advertiser_id'],
			tooltip: 'The name of the advertiser as defined in advertiser creation / edit screen',
			availableFilters: {
				type: FilterType.SelectFromDataSource,
				actions: [{ label: 'Include', value: ReportFilterType.INCLUDE }],
				selectFromDataSource: {
					store: new AdminxReportStore<IAdvertiserEntityIdFilterData>({
						reportParams: {
							reportName: 'AdxCampaignsReport',
							groupBys: ['advertiser_name', 'advertiser_id'],
							sort: [{ column: 'advertiser_name', sortOrder: SortOrderEnum.ASC }],
						},
						includeChart: false,
					}),
					cb: row => {
						const rowAs = row as IAdvertiserEntityIdFilterData;
						return {
							value: `${rowAs.advertiser_name}`,
							label: `${rowAs.advertiser_name} (${rowAs.advertiser_id})`,
						};
					},
				},
			},
			...params,
		});
	}
}
export class AdvertiserIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'advertiser_id',
			accessor: 'advertiser_id',
			header: 'Advertiser ID',
			linkTo: ['advertiser_name'],
			tooltip: 'The Advertiser ID in our system. Will only be populated if we decided to bid',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class AdvertiserStatusColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'advertiser_status',
			accessor: 'advertiser_status',
			header: 'Status',
			cell: advertiserStatusCell,
			availableFilters: new StatusFilterDefinition(),
			tooltip: 'The status (active / paused) of the advertiser as defined in advertiser creation / edit screen',
			...params,
		});
	}
}

export class CampaignGroupStatusColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_group_status',
			accessor: 'campaign_group_status',
			header: 'Status',
			cell: campaignGroupStatusCell,
			availableFilters: new StatusFilterDefinition(),
			tooltip: 'The status (active / paused) of the campaign group as defined in campaign group creation / edit screen',
			...params,
		});
	}
}

export class CampaignStatusColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_status',
			accessor: 'campaign_status',
			header: 'Status',
			cell: campaignStatusCell,
			tooltip: 'The status (active / paused) of the campaign as defined in campaign creation / edit screen',
			availableFilters: new StatusFilterDefinition(),
			...params,
		});
	}
}

export class CampaignDailyBudgetColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			type: 'dimensions',
			name: 'daily_budget',
			accessor: 'daily_budget',
			header: 'Daily Budget',
			tooltip: 'The daily budget of a campaign',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class DateColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'dt',
			accessor: 'dt',
			header: 'Date',
			tooltip: 'The date selected in within your timerange',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class StrategyIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'strategy_id',
			accessor: 'strategy_id',
			header: 'Strategy ID',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The ID of the strategy attached to the campaign chosen to bid',
			...params,
		});
	}
}

export class ParentIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'parent_id',
			accessor: 'parent_id',
			header: 'Parent ID',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class CampaignTypeColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_type',
			accessor: 'campaign_type',
			header: 'Campaign Type',
			tooltip: 'The type (CPA / CPM) of the campaign as defined in campaign creation / edit screen',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class PriorityColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'priority',
			accessor: 'priority',
			header: 'Priority',
			tooltip: 'The priority of the campaign as defined in campaign creation / edit screen. 1 is highest',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class SecondaryPriorityColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'secondary_priority',
			accessor: 'secondary_priority',
			header: 'Secondary Priority',
			tooltip:
				'The secondary priority of the campaign as defined in campaign creation / edit screen. 1 is highest. Used to set priority between campaigns that share the same priority',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class CacheKeyGeneratorColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'cache_key_generator',
			accessor: 'cache_key_generator',
			header: 'Cache Key Generator',
			tooltip:
				'The cache key type of the campaign as defined in campaign creation / edit screen. Meaning how do you count your losses before you close it (work by tag id, site key, etc)',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class BidKeyGeneratorColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'bid_key_generator',
			accessor: 'bid_key_generator',
			header: 'Bid Key Generator',
			tooltip:
				'The bid key type of the campaign as defined in campaign creation / edit screen. Meaning how do you count your performance before you set your bid by, also used by several explorers to move a bidkey between campaign groups',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class DestUrlColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'dest_url',
			accessor: 'dest_url',
			header: 'Destination URL',
			tooltip: 'The destination URL of the campaign, after a user clicks, where will he go to',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class CreatedAtColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'created_at',
			accessor: 'created_at',
			header: 'Created At',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class UpdatedAtColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'updated_at',
			accessor: 'updated_at',
			header: 'Updated At',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class SecondBidFactorColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'second_bid_factor',
			accessor: 'second_bid_factor',
			header: 'Second Bid Factor',
			tooltip: '',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class UseOptimalGainForMaxLossCalculationColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'use_optimal_gain_for_max_loss_calculation',
			accessor: 'use_optimal_gain_for_max_loss_calculation',
			header: 'Use Optimal Gain For Max Loss Calculation',
			availableFilters: new TextFilterDefinition(),
			tooltip:
				'Whether you want to calculate your max loss in cache key based on actual gain or optimal gain, using optimal gain will the campaign a LOT of grace',
			...params,
		});
	}
}

export class CreativeSelectionModeColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'creative_selection_mode',
			accessor: 'creative_selection_mode',
			header: 'Creative Selection Mode',
			tooltip:
				'How does the creative selection work, is it random distribution, use the multi arm bendit (beta distribution) or regular scorer',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class MaxCpmColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'max_cpm',
			accessor: 'max_cpm',
			header: 'Max CPM',
			tooltip: 'What is the maximum bid the campaign can give',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class MaxLossPerKeyColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'max_loss_per_key',
			accessor: 'max_loss_per_key',
			header: 'Max Loss Per Key',
			tooltip: 'How much are you willing to lose per cache key',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class MaxWinsPerConversionColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'max_wins_per_conversion',
			accessor: 'max_wins_per_conversion',
			header: 'Max Wins Per Conversion',
			tooltip: 'How many conversion you expect to see per win otherwise close the cache key',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class MaxWinsPerClickColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'max_wins_per_click',
			accessor: 'max_wins_per_click',
			header: 'Max Wins Per Click',
			tooltip: 'Minimum CTR a cache_key should have before you close it',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class MaxClicksPerConversionColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'max_clicks_per_conversion',
			accessor: 'max_clicks_per_conversion',
			header: 'Max Clicks Per Conversion',
			tooltip: 'Minimum CVR a cache_key should have before you close it',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class DailySalesColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'daily_sales',
			accessor: 'daily_sales',
			header: 'Daily Sales',
			tooltip: 'The total sales the campaign did today',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}

export class UseDomainBudgetLimitColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'use_domain_budget_limit',
			accessor: 'use_domain_budget_limit',
			header: 'Use Domain Budget Limit',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'Should there be a maximum loss per domain per day',
			...params,
		});
	}
}

export class AllowMultipleSalesColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'allow_multiple_sales',
			accessor: 'allow_multiple_sales',
			header: 'Allow Multiple Sales',
			tooltip: 'Is this campaign can receive multiple conversion pixels',
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class DefaultPayoutColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'default_payout',
			accessor: 'default_payout',
			header: 'Default Payout',
			availableFilters: new NumbersFilterDefinition(),
			tooltip: 'What is the default pixel value in this campaign',
			...params,
		});
	}
}

export class DailyDomainMaxLossColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'daily_domain_max_loss',
			accessor: 'daily_domain_max_loss',
			header: 'Daily Domain Max Loss',
			availableFilters: new NumbersFilterDefinition(),
			tooltip: 'How much can a domain lose per day',
			...params,
		});
	}
}
export class DailyMaxSalesColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'daily_max_sales',
			accessor: 'daily_max_sales',
			header: 'Daily Max Sales',
			availableFilters: new NumbersFilterDefinition(),
			tooltip:
				'The maximum daily conversions a campaign should receive, upon reaching this the campaign will not buy further traffic',
			...params,
		});
	}
}
