import { IPreferenceEditForm } from '../models/preference.model';
import { IPreferenceCreateForm, PreferenceModel } from '../models/preference.model';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { URLSearchParams } from 'url';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';

export const PreferencesApi = {
	list: (params?: URLSearchParams): Promise<PreferenceModel[]> => {
		return ask.get(endpoints.preferences.list(), params);
	},
	create: (preference: IPreferenceCreateForm, options?: IRequestOptions): Promise<{ id: id }> => {
		return ask.post<IPreferenceCreateForm>(endpoints.preferences.create(), preference, options);
	},
	get: (id: id, options?: URLSearchParams): Promise<PreferenceModel> => {
		return ask.get(endpoints.preferences.get(id), options);
	},
	edit: (id: id, preference: IPreferenceEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<IPreferenceEditForm>(endpoints.preferences.edit(id), preference, options);
	},
	delete: (id: id) => {
		return ask.delete(endpoints.preferences.delete(id));
	},
	bulkDelete: (ids: id[]) => {
		return ask.delete(endpoints.preferences.bulkDelete(), { queryParams: { ids } });
	},
};
