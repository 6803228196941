import { CampaignGroupsApi } from '../../apis/campaign-groups.api';
import { Status } from '../../enums/status';
import { CampaignGroupModel, ICampaignGroupCreateForm, ICampaignGroupEditForm } from '../../models/campaign-group.model';
import { BaseCrudStore } from '@monorepo/controlled/src/stores/base-crud.store';
import { constantCase } from 'change-case';
import { HttpError } from '../../models/http-error.model';
import { FormError } from '@monorepo/tools/src/lib/models/form-error.model';

export class CampaignGroupsCrudStore extends BaseCrudStore<
	CampaignGroupModel,
	ICampaignGroupCreateForm,
	ICampaignGroupEditForm,
	HttpError
> {
	constructor() {
		super({
			apiLayer: CampaignGroupsApi,
			model: CampaignGroupModel,
			errorModel: HttpError,
		});
	}

	/**
	 * Must call isValidCampaignGroup before calling this function
	 * @returns
	 */
	public getCreateFormData(): ICampaignGroupCreateForm {
		const advertiser = this.getData()?.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const status = this.getData().getStatus();

		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			status: status ? (constantCase(status) as Status) : undefined,
			advertiser: advertiserId ? { id: advertiserId } : undefined,
		};
	}

	/**
	 * Must call isValidCampaignGroup before calling this function
	 * @returns
	 */
	public getEditFormData(): ICampaignGroupEditForm {
		const advertiser = this.getData()?.getAdvertiser();
		const advertiserId = advertiser?.getId();
		const status = this.getData().getStatus();

		return {
			id: this.getData().getId(),
			name: this.getData().getName(),
			status: status ? (constantCase(status) as Status) : undefined,
			advertiser: advertiserId ? { id: advertiserId } : undefined,
		};
	}

	public isValid(): boolean {
		this.formStore.reset();

		const campaignName = this.getData().getName();
		if (!campaignName) {
			this.formStore.addError(new FormError('campaign_group_name', 'Please provide a campaign group name'));
		}

		const advertiser = this.getData().getAdvertiser();
		if (!advertiser) {
			this.formStore.addError(new FormError('advertiser', 'Please provide an advertiser'));
		}

		return this.formStore.getIsValid();
	}
}
