import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useEffect } from 'react';
import { ICrudGetProps } from '@monorepo/tools/src/lib/interfaces/crud';
import { useLocation } from 'react-router-dom';
import { IAdminxStore } from '../../stores';

export function useAdvertiserGet(props: ICrudGetProps) {
	const { id, options } = props;
	const { advertiserStore } = useStores<IAdminxStore>();
	const location = useLocation();

	useEffect(() => {
		if (id) {
			advertiserStore.getCrud().get(id, options);
		}
	}, [location]);

	if (!id) {
		return null;
	}

	return { error: advertiserStore.getCrud().getHttpError(), isLoading: advertiserStore.getCrud().getIsLoading() };
}
