import { runInAction, observable, action, makeObservable } from 'mobx';
import { URLSearchParams } from 'url';
import { IAskError, ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { Constructable } from '@monorepo/tools/src/lib/interfaces/class';
import { isAbortError } from '@monorepo/tools/src/lib/tools/ask/guards';

interface IBaseListStore<T> {
	listFunc: (options?: URLSearchParams) => Promise<T[]>;
	model: Constructable<T>;
}

// TODO - extends http.store
export class BaseListStore<T, TPerformance = unknown> {
	list: T[] = [];
	summary?: TPerformance;
	totalRows?: string | number;
	isLoading = false;
	error: IAskError | null = null;
	isLocalCache = false;
	listFunc: (
		options?: URLSearchParams
	) => Promise<T[] | { payload: { summary: TPerformance; data: T[]; totalRows: string | number }; message: string }>;
	model: Constructable<T>;
	abortController = new AbortController();

	constructor(options: IBaseListStore<T>) {
		const { listFunc, model } = options;
		this.listFunc = listFunc;
		this.model = model;

		makeObservable(this, {
			list: observable,
			isLoading: observable,
			error: observable,
			summary: observable,
			totalRows: observable,
			setList: action,
			setSummary: action,
			setTotalRows: action,
			setIsLoading: action,
			setError: action,
		});
	}

	fetch(
		options?: URLSearchParams
	): Promise<void | T[] | { content: { summary: TPerformance; data: T[]; totalRows: string | number }; message: string }> {
		if (this.getIsLocalCache()) {
			return Promise.resolve(this.getList());
		}

		this.setIsLocalCache(true);
		this.setIsLoading(true);

		this.abortController = new AbortController();

		ask.addSignal(this.abortController.signal);

		return this.listFunc(options)
			.then(res => {
				this.setError(null);
				let data: T[] = [];
				if (!Array.isArray(res)) {
					if (typeof res === 'object' && res !== null) {
						data = res.payload.data.map((item: T) => {
							return new this.model(item);
						});

						runInAction(() => {
							// TODO - @RONT should not be here
							this.setSummary(res.payload.summary);
							this.setTotalRows(res.payload.totalRows);
							this.setIsLocalCache(false);
							this.setList(data);
							this.setIsLoading(false);
						});

						return data;
					}

					return [];
				}
				data = res.map(item => new this.model(item));
				runInAction(() => {
					this.setIsLocalCache(false);
					this.setList(data);
					this.setIsLoading(false);
				});
				return data;
			})
			.catch((error: IAskError) => {
				// TODO - log
				runInAction(() => {
					this.setIsLocalCache(false);
					if (!isAbortError(error)) {
						// in case of abort error the user changed page, so the loading of the next page should be true
						this.setIsLoading(false);
						this.setError(error);
					}
				});
			});
	}

	public abort(reason?: string) {
		this.abortController.abort(reason || 'external abort');
		this.setIsLocalCache(false);
	}

	reset() {
		this.setList([]);
	}

	async setList(list: T[]): Promise<void> {
		this.list = list;
	}

	getList(): T[] {
		return this.list;
	}

	setIsLoading(isLoading: boolean) {
		this.isLoading = isLoading;
	}

	getIsLoading(): boolean {
		return this.isLoading;
	}

	getError(): IAskError | null {
		return this.error;
	}

	setError(error: IAskError | null) {
		this.error = error;
	}

	setIsLocalCache(isLocalCache: boolean) {
		this.isLocalCache = isLocalCache;
	}

	getIsLocalCache(): boolean {
		return this.isLocalCache;
	}

	getSummary(): TPerformance | undefined {
		return this.summary;
	}
	setSummary(summary: TPerformance): void {
		this.summary = summary;
	}
	getTotalRows(): number | string | undefined {
		return this.totalRows;
	}
	setTotalRows(totalRows: number | string | undefined): void {
		this.totalRows = totalRows;
	}
}
