import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { FormError } from '../form/form-error/form-error';
import styles from './file-selector.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Icon } from '../icon/icon';

interface IFileSelector {
	onFileSelect: (file: FormData, e: ChangeEvent<HTMLInputElement>) => void;
	multiple?: boolean;
	accept?: string;
	file?: File;
	fileVariableName: string;
	error?: string;
	icon?: string;
	required?: boolean;
	debugProps?: IDebugProps;
	children: ReactNode;
	rotateIcon?: boolean;
}

// TODO - RONT change input to Input component
export const FileSelector = (props: IFileSelector) => {
	const {
		children,
		multiple = false,
		accept = '*',
		fileVariableName,
		onFileSelect,
		error,
		rotateIcon = false,
		required,
		debugProps,
		icon = '',
	} = props;
	const [errorMsg, setErrorMsg] = useState<string | undefined>(error);
	const { dataAttrs } = debugProps || {};

	useEffect(() => {
		setErrorMsg(error);
	}, [error]);

	const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
		const fileList = e.target.files;

		if (required && fileList && fileList[0]) {
			setErrorMsg(undefined);
		}
		if (!fileList) {
			return;
		}

		const formData = new FormData();
		Array.from(fileList).forEach(file => {
			formData.append(fileVariableName, file, file.name);
		});

		onFileSelect(formData, e);
	};

	return (
		<div className={styles.wrapper}>
			<label className={styles.label} htmlFor={'file'}>
				{icon ? (
					<Icon className={`${styles.icon} ${rotateIcon ? styles.rotateIcon : ''}`} isMFP={true}>
						{icon}
					</Icon>
				) : null}
				{children}
				{required ? '*' : ''}
				<input
					className={styles.input}
					accept={accept}
					id="file"
					name="file"
					type="file"
					multiple={multiple}
					onChange={handleImageChange}
					{...generateDataAttrs(dataAttrs)}
				/>
			</label>
			<FormError msg={errorMsg} />
		</div>
	);
};
