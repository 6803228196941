interface ISpacer {
	y?: number;
	x?: number;
}

export const Spacer = (props: ISpacer) => {
	const { y = 1, x = 0 } = props;
	const height = `${16 * y}px`;
	const width = `${16 * x}px`;
	return <div style={{ height, width }}></div>;
};

export const SpacerY = (props: ISpacer) => {
	const { y = 1 } = props;
	const height = `${16 * y}px`;
	return <div style={{ height }}></div>;
};

export const SpacerX = (props: ISpacer) => {
	const { x = 1 } = props;
	const width = `${16 * x}px`;
	return <div style={{ width }}></div>;
};
