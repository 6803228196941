import { Fragment } from 'react';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { SelectedLinerButton } from '@monorepo/base/src/components/table/action-liner/liner-buttons';
import { QuickActionButton } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { CreativeGroupModel } from '../../models/creative-group.model';
import routes from '../../routes/index.routes';
import { id } from '@monorepo/tools/src/lib/types/primitives';
// import { PerformancesColumnsSnakeCase } from '../apis/use-reports-list';
// import { useSegments } from '../tools/use-segments';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IEntityActions, ITableActions, IUseEntityActions, useDefaultActions } from './default.actions';
// import { Segments } from '@monorepo/base/src/components/segments/segments';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IAdminxStore } from '../../stores';
// import { PerformanceModel } from '../../models/performance.model';
import { DataAttribute, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
// import { ISegmentActions } from '@monorepo/base/src/components/table/table';
import { ITableFiltersActions, TableFilters } from '@monorepo/base/src/components/table/table-filters/table-filters';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { creativeGroupsFilterPrototypes } from '../../components/filters-menu/filters';

const theme = getTheme();

export function useCreativeGroupActions(props: IUseEntityActions = {}) {
	const { onEdit, createRoute, debugProps } = props;
	const { creativeGroupsStore, settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { dataAttrs } = debugProps || {};

	// const performanceStore = creativeGroupsStore.getPerformance();

	const filterStore = settingsStore
		.getPages()
		.get(currentRouteWithoutSpecialChars)
		?.tableStore.getFiltersStore(currentRouteWithoutSpecialChars);
	const currentFilters = filterStore?.getCurrentFilters();

	const DefaultAvailableFilters = [...creativeGroupsFilterPrototypes];

	const onEditRoute = (creativeGroupId: id) => (onEdit ? onEdit(creativeGroupId) : routes.creativeGroups.edit(creativeGroupId));
	const onDelete = (ids: id[]) => creativeGroupsStore.getCrud().delete(ids[0]);

	const funcActions = {
		onEditRoute,
		onDelete,
	};

	const SelectedActions = (props: ITableActions<CreativeGroupModel>) => {
		const { rows } = props;
		const entities = rows.map(row => new CreativeGroupModel(row.original));
		const { EditAction, DeleteAction } = useDefaultActions({ entities, debugProps, ...funcActions });

		return (
			<Fragment>
				<SelectedLinerButton>
					<EditAction
						state={{ isBlockRequest: true } as BetweenPagesStateProps}
						debugProps={{ dataAttrs: suffixToDataAttr('_selected_edit', dataAttrs) }}
					/>
				</SelectedLinerButton>
				<SelectedLinerButton>
					<Tooltip content={`Delete has been disabled`}>
						<DeleteAction disabled={true} />
					</Tooltip>
				</SelectedLinerButton>
			</Fragment>
		);
	};

	const EntityActions = (props: IEntityActions<CreativeGroupModel>) => {
		const { entity } = props;
		const { EditAction, DeleteAction } = useDefaultActions({ entities: [entity], ...funcActions });

		return (
			<Fragment>
				<QuickActionButton>
					<EditAction
						state={{ isBlockRequest: true } as BetweenPagesStateProps}
						iconColor={theme.titleColor}
						textColor={theme.titleColor}
					/>
				</QuickActionButton>
				<QuickActionButton>
					<Tooltip content={`Delete has been disabled`}>
						<DeleteAction iconColor={theme.titleColor} textColor={theme.titleColor} iconSize={'14px'} disabled={true} />
					</Tooltip>
				</QuickActionButton>
			</Fragment>
		);
	};

	const IndexActions = () => {
		return (
			<PrimaryLink
				debugProps={{ dataAttrs: [new DataAttribute('id', 'create_creative_groups')] }}
				icon={'plus'}
				to={`${createRoute || routes.creativeGroups.create()}`}
				width={'100px'}
				state={{ isBlockRequest: true }}>
				Create
			</PrimaryLink>
		);
	};

	const FiltersActions = (props: ITableFiltersActions) => {
		if (!currentFilters) {
			return null;
		}

		return (
			<TableFilters
				{...props}
				availableFilters={DefaultAvailableFilters}
				currentFilters={currentFilters}
				clearAll={() => filterStore?.clearAll()}
				clearFilter={index => filterStore?.clearFilter(index)}
				addFilter={filter => filterStore?.addFilter(filter)}
				editFilter={(index, filter) => filterStore?.editFilter(index, filter)}
			/>
		);
	};

	// const SegmentActions = (props: ISegmentActions) => {
	// 	const { segments, onSegment } = useSegments<CreativeGroupModel>({
	// 		performanceStore,
	// 		disableSegments: [PerformancesColumnsSnakeCase.CreativeGroupId],
	// 	});

	// 	return (
	// 		<Segments<CreativeGroupModel, PerformanceModel, PerformancesColumnsSnakeCase>
	// 			currentSegments={performanceStore.getSegments()}
	// 			availableSegments={segments}
	// 			onClick={segment => onSegment(segment)}
	// 			{...props}
	// 		/>
	// 	);
	// };

	return {
		SelectedActions,
		EntityActions,
		IndexActions,
		FiltersActions,
		// SegmentActions,
	};
}
