import { List, ListItem } from '@monorepo/base/src/components/list/list';
import styles from './http-error-modal.module.scss';

interface IHttpErrorModal<TError> {
	error?: TError;
	message?: string;
}

// TODO - move to adminx components folder
export const HttpErrorModal = <TError extends { getMessages?: () => string[] }>(props: IHttpErrorModal<TError>) => {
	const { error, message } = props;

	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>Error</div>
			<List className={styles.list}>
				{message ? (
					<ListItem key={message}>{message}</ListItem>
				) : (
					error?.getMessages &&
					error.getMessages().map(message => {
						return <ListItem key={message}>{message}</ListItem>;
					})
				)}
			</List>
		</div>
	);
};
