export enum CreativeSelectionModes {
	Format = 'FORMAT',
	Design = 'DESIGN',
	Off = 'OFF',
	Full = 'FULL',
}

export type CreativeSelectionMode =
	| CreativeSelectionModes.Format
	| CreativeSelectionModes.Design
	| CreativeSelectionModes.Off
	| CreativeSelectionModes.Full;
