import { observer } from 'mobx-react';
import styles from './bidder-domain-list.module.scss';
import { IAdminxStore } from '../../../../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { Input } from '@monorepo/base/src/components/form/input/input';

export const BidderDomainList = observer(() => {
	const { bidderStore } = useStores<IAdminxStore>();
	const campaignCrudStore = bidderStore.getCrud();
	const publisherDomainStatusLimits = campaignCrudStore.getData().campaign.campaign_publisher_domain_status_limits;

	return (
		<Card className={styles.wrapper} icon={'list'} id={'bidderDomainLimits'} title={'Publisher Domain Status Limit'}>
			{publisherDomainStatusLimits?.map(publisherDomainStatusLimit => (
				<Card
					collapsible
					key={publisherDomainStatusLimit.pid}
					className={styles.wrapper}
					title={`${publisherDomainStatusLimit.publisher_name} (${publisherDomainStatusLimit.pid})`}>
					<div className={styles.statusLimitsList}>
						{publisherDomainStatusLimit.domain_status_limits.map(dsl => (
							<Input
								key={dsl.id}
								value={`${dsl.limit || 0}`}
								min={0}
								max={100}
								placeholder={`${dsl.domain_status}`}
								title={`Limit for ${dsl.domain_status}`}
								onValue={value => {
									if (value === '') {
										dsl.limit = 0;
									} else if (Number(value) < 0) {
										dsl.limit = 0;
									} else if (Number(value) > 100) {
										dsl.limit = 100;
									} else {
										dsl.limit = Number(value);
									}
								}}
							/>
						))}
					</div>
				</Card>
			))}
		</Card>
	);
});
