import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { ButtonDimensions, PrimaryButton, PrimaryText, SecondaryButton, SecondaryText } from '../buttons/buttons';
import { Input } from '../form/input/input';
import { Icon } from '../icon/icon';
import { List, ListItem } from '../list/list';
import styles from './select.module.scss';
import { route } from '@monorepo/tools/src/lib/types/url';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';

interface ISelect {
	values: (string | undefined)[];
	isSearch?: boolean;
	placeholder?: string;
	onClick?: (value: string | string[], e: React.MouseEvent<HTMLElement>) => void;
	onApply?: (value: string[]) => void;
	onCancel?: () => void;
	disabled?: boolean;
	defaultValue?: string | string[];
	multiple?: boolean;
	actions?: ReactNode;
	listItemClassName?: string;
	listClassName?: string;
	isSelectAll?: boolean;
	fromFilters?: boolean;
	debugProps?: IDebugProps;
}

interface ISelectActions {
	children: ReactNode;
}

interface ISelectAction {
	children: ReactNode;
	to: route;
}

export const SelectActions = (props: ISelectActions) => {
	const { children } = props;
	return <div>{children}</div>;
};

export const SelectAction = (props: ISelectAction) => {
	const { children, to } = props;
	return (
		<LinkWithParams to={to}>
			<SecondaryText
				style={{
					padding: '1px 0px 1px 12px',
					cursor: 'pointer',
					height: '40px',
					borderRadius: '0px 0px 6px 6px',
				}}
				icon={'plus'}
				iconColor={getTheme().primary600}>
				{children}
			</SecondaryText>
		</LinkWithParams>
	);
};

export const Select = (props: ISelect) => {
	const {
		placeholder,
		values,
		onClick = null,
		onApply,
		disabled = false,
		defaultValue,
		multiple,
		isSearch = true,
		actions,
		listItemClassName,
		listClassName,
		onCancel,
		isSelectAll = false,
		fromFilters = false,
		debugProps,
	} = props;
	const inputElement = useRef<HTMLInputElement>(null);
	const [filteredValues, setFilteredValues] = useState<(string | undefined)[]>(values); // typescript is making me to put initValues as array of undefined also so i remove the undefined values (not supposed to happen)
	const [filterCheckbox, setFilterCheckbox] = useState<Set<string>>(
		new Set(typeof defaultValue === 'string' ? defaultValue?.trim() : defaultValue?.map(val => val.trim()))
	);
	const [autocompleteValue, setAutocompleteValue] = useState<string>('');
	const [isFocus, setIsFocus] = useState<boolean>(true);
	const { dataAttrs } = debugProps || {};
	const didMount = useDidMount();

	useEffect(() => {
		if (didMount && inputElement.current) {
			//@TODO find a better way to do this and remove this setTimeout patch
			setTimeout(() => {
				if (inputElement.current) {
					inputElement.current.focus();
				}
			}, 50);
		}
	});

	useEffect(() => {
		setFilteredValues(values);
	}, [values]);

	const onListItemClick = (value: string | string[] | undefined, e: React.MouseEvent<HTMLElement>) => {
		if (!onClick) {
			return null;
		}
		if (!value) {
			return;
		}
		onClick(value, e);
	};

	const renderFilteredValue = () => {
		if (!filteredValues || (filteredValues.length === 0 && autocompleteValue)) {
			if (actions) {
				return null;
			}
			return (
				<div className={styles.empty}>
					<div>No matches found.</div>
					<div> Check the spelling or try different search terms.</div>
				</div>
			);
		}

		if (multiple) {
			return (
				<List
					className={`${styles.multipleSelection} ${listClassName}`}
					{...generateDataAttrs(suffixToDataAttr('_list', dataAttrs))}>
					{filteredValues.map(value => (
						<ListItem
							onClick={e => {
								let newSet = null;
								if (typeof value === 'string') {
									filterCheckbox.has(value) ? filterCheckbox.delete(value) : filterCheckbox.add(value);
									newSet = new Set(filterCheckbox);
								} else {
									newSet = new Set([]);
								}
								setFilterCheckbox(newSet);
								if (fromFilters) {
									onListItemClick(Array.from(newSet), e);
								}
							}}
							className={`${styles.selection} ${listItemClassName || ''} ${
								filterCheckbox.has(value?.toString() || '') ? styles.activeCheckbox : ''
							}`}
							key={value}>
							<span className={styles.selectionText}>{value}</span>
							{filterCheckbox.has(value?.toString() || '') ? (
								<Icon isMFP={true} color={filterCheckbox.has(value?.toString() || '') ? getTheme().primary600 : ''}>
									check
								</Icon>
							) : null}
							{/* <Checkbox
								checked={filterCheckbox.has(value?.toString() || '')}
								onChange={() => {
									let newSet = null;
									if (typeof value === 'string') {
										filterCheckbox.has(value) ? filterCheckbox.delete(value) : filterCheckbox.add(value);
										newSet = new Set(filterCheckbox);
									} else {
										newSet = new Set([]);
									}
									setFilterCheckbox(newSet);
								}}
								id={value}
								className={`${styles.checkbox} ${filterCheckbox.has(value?.toString() || '') ? styles.activeCheckbox : ''}`}
							/>
							<label htmlFor={value} className={styles.label}>
								{value}
							</label> */}
						</ListItem>
					))}
					{!fromFilters && (
						<ListItem className={styles.apply}>
							<div className={styles.formActions}>
								{isSelectAll ? (
									<div className={styles.action}>
										{' '}
										<PrimaryText
											onClick={() => {
												const vals = filteredValues.filter(v => v) as string[];
												setFilterCheckbox(new Set(vals));
											}}>
											Select All
										</PrimaryText>
									</div>
								) : null}
								<div className={styles.action}>
									<SecondaryButton
										dimension={ButtonDimensions.Small}
										className={`${styles.actionButton} ${styles.cancelButton}`}
										onClick={() => {
											onCancel && onCancel();
										}}>
										Cancel
									</SecondaryButton>
								</div>
								<div className={styles.action}>
									<PrimaryButton
										dimension={ButtonDimensions.Small}
										className={styles.actionButton}
										onClick={() => {
											onApply && onApply(Array.from(filterCheckbox));
										}}>
										Apply
									</PrimaryButton>
								</div>
							</div>
						</ListItem>
					)}
				</List>
			);
		}
		return (
			<List className={`${styles.list} ${listClassName}`}>
				{filteredValues.map(value => (
					<ListItem
						debugProps={{
							dataAttrs: [
								new DataAttribute(
									'id',
									'select_li_' + value?.toLowerCase().replaceAll(' ', '_').replaceAll('.', '') || 'unknown'
								),
							],
						}}
						onClick={(e: React.MouseEvent<HTMLElement>) => {
							onListItemClick(value, e);
							setAutocompleteValue('');
						}}
						key={value}
						className={`${styles.listItem} ${onClick && !disabled ? styles.clickable : ''} ${
							defaultValue === value ? styles.defaultValue : ''
						} ${listItemClassName || ''}`}>
						<span className={styles.listText}>{value}</span>
						{defaultValue === value ? (
							<Icon className={styles.check} isMFP={true}>
								check
							</Icon>
						) : null}
					</ListItem>
				))}
			</List>
		);
	};

	return (
		<Fragment>
			{values.length < 5 || !isSearch ? null : (
				<Input
					ref={inputElement}
					inline={true}
					autoFocus={true}
					onFocusEvent={_isFocus => setIsFocus(_isFocus)}
					icon={'search-sm'}
					iconColor={isFocus ? getTheme().primaryColor : getTheme().gray500}
					inputWrapperClassName={styles.inputWrapper}
					value={autocompleteValue}
					placeholder={placeholder}
					onValue={inputValue => {
						setFilteredValues(values.filter(value => (value || '').toLowerCase().includes(inputValue.toLowerCase())));
						setAutocompleteValue(inputValue);
					}}
					disabled={disabled}
					onClick={e => e.stopPropagation()}
					debugProps={{ dataAttrs: suffixToDataAttr('_search', dataAttrs) }}
					animate={false}
					wrapperStyle={{ marginTop: '0px' }}
				/>
			)}
			{renderFilteredValue()}
			{actions ? <div className={`${values.length > 0 ? styles.actions : ''}`}>{actions}</div> : null}
		</Fragment>
	);
};
