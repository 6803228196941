import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import endpoints from './endpoints/index.endpoints';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { IBidderCampaign } from '../models/bidder-campaign/bidder-camapign.model';
import { UpdateCampaignResponse } from '../models/bidder-campaign/bidder-campaign.types';

export const BidderApi = {
	getPublishers: (): Promise<{ success: boolean; publishers: { id: id; name: string }[] }> => {
		return ask.get(endpoints.bidder.getPublishers());
	},

	getBidderCampaign: (id: id): Promise<IBidderCampaign> => {
		return ask.get(endpoints.bidder.getCampaign(id));
	},

	updateCampaign: (body: IBidderCampaign): Promise<UpdateCampaignResponse> => {
		return ask.put(endpoints.bidder.updateCampaign(), { campaign: body });
	},

	enableCampaign: (campaignId: id): Promise<UpdateCampaignResponse> => {
		return ask.put(endpoints.bidder.enableCampaign(campaignId), {});
	},

	pauseCampaign: (campaignId: id): Promise<UpdateCampaignResponse> => {
		return ask.put(endpoints.bidder.pauseCampaign(campaignId), {});
	},
};
