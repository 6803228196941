import { sentenceCase } from 'change-case';
import { createColumnHelper } from '@tanstack/react-table';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { IPerformanceItem, PerformanceModel } from '../../models/performance.model';
import { ITest, TestModel } from '../../models/test.model';
import routes from '../../routes/index.routes';
import { useTestActions } from '../actions/test.actions';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { IEntityHeaders, IMergedHeadersReturn } from './index.headers';
import { mapToTagStatus, Status } from '../../enums/status';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';

const columnHelper = createColumnHelper<ITest & IPerformanceItem>();

export const useTestHeaders = (props: IEntityHeaders): IMergedHeadersReturn<TestModel> => {
	const { summary } = props;
	const { EntityActions } = useTestActions();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;
	const { settingsStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				name: true,
				status: true,
				id: true,
				description: true,
				percentage: true,
				auctions: true,
				wins: true,
				clicks: true,
				conversions: true,
				cost: true,
				conversionValue: true,
				winRate: true,
				roas: true,
				impressions: false,
				optCost: false,
				lpCvr: false,
				optGain: false,
				gain: false,
				ctr: false,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const preference = new TestModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<QuickActionsMenu>
								<EntityActions entity={preference} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('name', {
				header: 'Deal Name',
				cell: info => {
					const { row } = info;
					const name = row.getValue('name') as string;
					if (isSubRow(row)) {
						const performanceModel = new PerformanceModel(row.original);
						if (typeof name === 'string') {
							return (
								<span>
									<div>Group {performanceModel.testGroupName}</div>
									<div style={{ paddingLeft: '10px' }}>{sentenceCase(name)}</div>
								</span>
							);
						} else {
							return (
								<span>
									<div>Group: {performanceModel.testGroupName}</div>
									<div style={{ paddingLeft: '10px' }}>{name}</div>
								</span>
							);
						}
					}
					return <TableLink toEdit={routes.tests.edit(row.getValue('id'))}>{name}</TableLink>;
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string;
					return <Tag type={mapToTagStatus(status.toUpperCase() as Status)}>{row.getValue('status')}</Tag>;
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('description', {
				header: 'Description',
			}),
			columnHelper.accessor('percentage', {
				header: 'Percentage',
			}),
			...mergedPerforamnceHeaders,
		],
	};
};
