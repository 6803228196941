import { IRequestInterceptor, IResponseInterceptor } from '@monorepo/tools/src/lib/tools/ask/ask';
import { generateStorageKey } from './helper';

const _PUV = '_PUV';
const _STORE_PREFIX = generateStorageKey('ilbbaicnl', _PUV);
const PUV = 'PUV';

const getPUV = () => {
	const url = new URL(window.location.href);
	const puv = url.searchParams.get(PUV);
	if (!puv) {
		const sessionStorageKeys = Object.keys(window.sessionStorage);
		const puvKeys = sessionStorageKeys.filter(key => key.includes(_PUV) && key.includes('user'));
		const user = window.sessionStorage.getItem(puvKeys[0]);
		return JSON.parse(user || '{}')?.id;
	}
	return puv;
};

const getToken = (targetId?: string) => {
	const puv = getPUV();
	if (!puv) {
		return;
	}
	const token = window.sessionStorage.getItem(`${_STORE_PREFIX}_${targetId || puv}`);
	return token;
};

const setToken = (token: string, targetId?: string) => {
	const puv = getPUV();
	if (!puv && !targetId) {
		return;
	}
	window.sessionStorage.setItem(`${_STORE_PREFIX}_${targetId || puv}`, token);
};

const setUser = (user: any, targetId?: string) => {
	const puv = getPUV();
	if (!puv && !targetId) {
		return;
	}
	window.sessionStorage.setItem(`${_STORE_PREFIX}_${targetId || puv}_user`, JSON.stringify(user));
};

const deleteUser = (targetId?: string) => {
	const puv = getPUV();
	if (!puv && !targetId) {
		return;
	}
	window.sessionStorage.removeItem(`${_STORE_PREFIX}_${targetId || puv}_user`);
	window.sessionStorage.removeItem(`${_STORE_PREFIX}_${targetId || puv}`);
};

const getUser = (targetId?: string) => {
	const puv = getPUV();
	if (!puv) {
		return;
	}
	const user = window.sessionStorage.getItem(`${_STORE_PREFIX}_${targetId || puv}_user`);
	return JSON.parse(user || '{}');
};
const hasPUV = () => {
	return !!getPUV();
};

const actAsRequestInterceptor: IRequestInterceptor = {
	call: (url: URL, requestOptions: RequestInit) => {
		if (hasPUV()) {
			const token = getToken();
			if (!token) {
				return;
			}
			requestOptions.headers = {
				...requestOptions.headers,
				Authorization: `Bearer ${token}`,
			};
		}
	},
};

const actAsResponseInterceptor: IResponseInterceptor = {
	success: (response: Response) => {
		if (!hasPUV()) {
			return;
		}

		const token = response.headers.get('User-Token');
		if (token) {
			ActAsHelper.setToken(token);
		}
	},
};

const clearAllPUVFromSessionStorage = () => {
	const keys = Object.keys(window.sessionStorage);
	keys.forEach(key => {
		if (key.includes(`${_STORE_PREFIX}_`)) {
			window.sessionStorage.removeItem(key);
		}
	});
};

const removePUVFromUrl = () => {
	const url = new URL(window.location.href);
	url.searchParams.delete('PUV');
	window.history.pushState({}, '', url.toString());
};

export const ActAsHelper = {
	getToken,
	setToken,
	setUser,
	getUser,
	actAsRequestInterceptor,
	actAsResponseInterceptor,
	hasPUV,
	getPUV,
	clearAllPUVFromSessionStorage,
	removePUVFromUrl,
	deleteUser,
};
