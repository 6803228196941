import { sentenceCase } from 'change-case';
import { observer } from 'mobx-react';
import { ChangeEvent, useEffect, useState } from 'react';
import { ResourceTypes } from '../../../enums/creative-resource-types';
import { useRoute } from '../../../hooks/tools/use-route';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { BannerModel } from '../../../models/banner.model';
import { BannerSize, CampaignModel, ICampaign } from '../../../models/campaign.model';
import { CreativeGroupModel, ICreativeGroup } from '../../../models/creative-group.model';
import routes from '../../../routes/index.routes';
import { ButtonGroup } from '@monorepo/base/src/components/buttons/group/button-group';
import { GButton } from '@monorepo/base/src/components/buttons/group/button/g-button';
import { Card } from '@monorepo/base/src/components/card/card';
import { FileSelector } from '@monorepo/base/src/components/file-selector/file-selector';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { Textarea } from '@monorepo/base/src/components/form/textarea/textarea';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Image as Img } from '@monorepo/base/src/components/img/img';
import styles from './creative-form.module.scss';
import { IAdminxStore } from '../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { DropdownNew } from '@monorepo/base/src/components/dropdown-new/dropdown-new';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { PerformancesColumnsSnakeCase, useReportsList } from '../../../hooks/apis/use-reports-list';
import { AdvertiserModel, IAdvertiser } from '../../../models/advertiser.model';

export const CreativeForm = observer(() => {
	const { creativeStore, creativeGroupsStore, campaignStore, advertiserStore } = useStores<IAdminxStore>();
	const { currentRoute } = useRoute();
	const [filename, setFilename] = useState<string>('');

	const creativeCrudStore = creativeStore.getCrud();
	const creativeGroups = creativeGroupsStore.getListStore().getData()?.getData();
	const advertisers = advertiserStore.getListStore().getData();
	const formStore = creativeCrudStore.getFormStore();
	const creative = creativeCrudStore.getData();

	const isCreatePage = currentRoute === routes.creatives.create();
	const resourceType = creative.getResourceType();
	const bannerSizes = campaignStore.getInputOptions().getBannerSizes();
	const advertiserId = creative.getCreativeGroup()?.getAdvertiser()?.getId();
	const [isSizeless, setIsSizeless] = useState<boolean>(false);

	useReportsList(creativeGroupsStore.getListStore(), {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Design, PerformancesColumnsSnakeCase.AdvertiserId],
		reportName: 'adxCreativeGroups',
	});
	useReportsList(campaignStore.getListStore(), {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name, PerformancesColumnsSnakeCase.AdvertiserId],
		reportName: 'adxCampaigns',
	});
	useReportsList(advertiserStore.getListStore(), {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxAdvertisers',
	});

	useEffect(() => {
		if (!isCreatePage) {
			setIsSizeless(!creative.getSize());
		}
	}, [creative.getSize()]);

	const campaigns =
		campaignStore
			.getListStore()
			.getData()
			?.data.map(
				campaign =>
					new CampaignModel({ ...(campaign as ICampaign), advertiser: new AdvertiserModel({ id: campaign.advertiserId }) })
			) || [];

	const creativeGroupOptions = creativeGroups?.map(({ design = '', id = '' }) => ({ label: design, value: id })) || [];

	const bannerSizesOptions = bannerSizes.map(bannerSize => {
		return { label: `${bannerSize.width}x${bannerSize.height}`, value: bannerSize.id as id };
	});

	const campaignOptions = campaigns
		.filter(campaign => campaign.getAdvertiser()?.getId() === advertiserId)
		.map(campaign => ({ label: campaign.getName() || '', value: campaign.getId() || '' }));

	const onBannerSelect = async (bannerFormData: FormData, e: ChangeEvent<HTMLInputElement>) => {
		const fileList = e.target.files;
		if (fileList?.length === 1) {
			setFilename(fileList?.[0].name);
		} else if (fileList?.length) {
			setFilename(`Upload ${fileList?.length} images`);
		}

		const files = Array.from(bannerFormData); //[0][1]
		const genBanner = (fileContent: File, index: number): Promise<BannerModel | undefined> => {
			return new Promise(resolve => {
				if (fileContent instanceof File) {
					let size: string | undefined = '';
					const fileExtension =
						fileContent.name.substring(fileContent.name.lastIndexOf('.') + 1, fileContent.name.length) || fileContent.name;
					const reader = new FileReader();
					reader.readAsDataURL(fileContent);
					reader.onload = function (e) {
						if (creative.getResourceType() === ResourceTypes.IMAGE) {
							const image = new Image();
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							image.src = e.target.result;
							creative.setResourceUrl(index === 0 ? image.src : undefined);
							image.onload = function () {
								size = `${image.width}x${image.height}`;
								const banner = new BannerModel({ size, fileExtension, creative: fileContent, resource_url: '' });
								resolve(banner);
							};
						} else {
							const banner = new BannerModel({ size, fileExtension, creative: fileContent, resource_url: '' });
							resolve(banner);
						}
					};
				} else {
					resolve(undefined);
				}
			});
		};

		const bannersPromisesArr = files.map((file, index) => genBanner(file[1] as File, index));
		const banners = (await Promise.all([...bannersPromisesArr])) as BannerModel[];
		creative.setBanners(banners);
	};

	const onSizeSelect = (sizesId: id[]) => {
		const selectedSizes: BannerSize[] = [];

		sizesId.forEach(sizeId => {
			const selectedSize = bannerSizes.find(bannerSize => bannerSize.id === sizeId);
			if (selectedSize) {
				selectedSizes.push(selectedSize);
			}
		});

		return selectedSizes;
	};

	const handleCreativegroupSelect = ([creativeGroupId]: (string | number)[]) => {
		const creativeGroup = creativeGroups?.find(({ id }) => id === creativeGroupId);
		if (creativeGroup) {
			const advertiser = advertisers?.getData()?.find(({ id }) => id === creativeGroup.advertiserId);
			if (!advertiser) {
				return;
			}
			creative.setCreativeGroup(
				new CreativeGroupModel({ advertiser: new AdvertiserModel(advertiser as IAdvertiser), ...(creativeGroup as ICreativeGroup) })
			);
			creative.setCampaigns([]);
		}
	};

	const ResourceTypesMapper = new Map([
		[ResourceTypes.HTML, '.html'],
		[ResourceTypes.IMAGE, 'image/*'],
		[ResourceTypes.NATIVE, '.json'],
	]);

	return (
		<Card
			className={styles.wrapper}
			title={'Upload Banner'}
			icon={'image-03'}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_form')] }}>
			<Input
				placeholder={'Brand'}
				value={creative.getBrand()}
				onValue={value => {
					creative.setBrand(value);
					formStore.clearError('brand');
				}}
				required={true}
				error={formStore.getErrors().get('brand')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'brand')] }}
			/>
			<Input
				placeholder={'Description'}
				value={creative.getDescription()}
				onValue={value => {
					creative.setDescription(value);
					formStore.clearError('description');
				}}
				error={formStore.getErrors().get('description')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'description')] }}
			/>
			<DropdownNew
				options={creativeGroupOptions}
				classNames={{ dropdownWrapper: styles.dropdownWrapper, parentSelectWrapper: styles.parentSelectWrapper }}
				required={true}
				label={'Select Creative Group'}
				autocomplete={true}
				autocompletePlaceholder={'Search Creative Group'}
				error={formStore.getErrors().get('creative_group')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'creative_group')] }}
				defaultValues={[creative.getCreativeGroup()?.getId() as id]}
				onSelect={handleCreativegroupSelect}
			/>
			<DropdownNew
				label={'Select Campaigns'}
				options={campaignOptions}
				classNames={{ dropdownWrapper: styles.dropdownWrapper, parentSelectWrapper: styles.parentSelectWrapper }}
				autocomplete={true}
				autocompletePlaceholder={'Search Campaigns'}
				defaultValues={creative.getCampaigns()?.map(campaign => campaign.getId() as id) || []}
				onSelect={campaignIds => creative.setCampaigns(campaignIds.map(campaignId => new CampaignModel({ id: campaignId })))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'campaigns')] }}
				multi={true}
			/>
			<Textarea
				value={creative.getDefaultDestUrl()}
				placeholder={'Destination Url'}
				onValue={value => {
					creative.setDefaultDestUrl(value);
					formStore.clearError('default_dest_url');
				}}
				error={formStore.getErrors().get('default_dest_url')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'default_dest_url')] }}
			/>
			<Spacer />
			<ButtonGroup
				required={true}
				title={'Select Resource Type'}
				defaultValues={resourceType ? new Set([resourceType]) : undefined}
				error={formStore.getErrors().get('resource_type')?.getMsg()}
				onChange={setValues => {
					const selectedResourceType = setValues.values().next().value;
					creative.setResourceType(selectedResourceType);
					creative.setResourceUrl(undefined);
					creative.setBanners([]);
					formStore.clearError('resource_type');
				}}>
				<GButton
					disabled={!isCreatePage}
					icon={'image-01'}
					text={sentenceCase(ResourceTypes.IMAGE)}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'resource_type_image')] }}
					value={ResourceTypes.IMAGE}
				/>
				<GButton
					disabled={!isCreatePage}
					icon={'layout-alt-01'}
					text={ResourceTypes.HTML}
					value={ResourceTypes.HTML}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'resource_type_html')] }}
				/>
				<GButton
					disabled={!isCreatePage}
					icon={'design-services'}
					text={ResourceTypes.NATIVE}
					value={ResourceTypes.NATIVE}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'resource_type_native')] }}
				/>
				{/* <GButton icon={'circle'} text={ResourceTypes.VIDEO} value={ResourceTypes.VIDEO}></GButton> */}
			</ButtonGroup>
			{resourceType === ResourceTypes.HTML && isSizeless && (
				<DropdownNew
					required={true}
					classNames={{ dropdownWrapper: styles.dropdownWrapper, parentSelectWrapper: styles.parentSelectWrapper }}
					label={'Select Sizes'}
					options={bannerSizesOptions}
					defaultValues={creative.getBannerSizes()?.map(bannerSize => bannerSize.id as id)}
					onSelect={_bannerSizes => {
						const bannerSizes = onSizeSelect(_bannerSizes);
						creative.setBannerSizes(bannerSizes);
					}}
					error={formStore.getErrors().get('banner_sizes')?.getMsg()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'banner_sizes')] }}
					multi={true}
					autocomplete={true}
					autocompletePlaceholder={'Search banner sizes'}
					disabled={!isSizeless}
				/>
			)}
			{resourceType === ResourceTypes.HTML && !isSizeless && !isCreatePage && (
				<Input
					placeholder={'Size'}
					value={creative.getSize()}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'size')] }}
					disabled
				/>
			)}
			<Spacer y={2} />
			<FileSelector
				onFileSelect={onBannerSelect}
				fileVariableName={'creative'}
				accept={resourceType ? ResourceTypesMapper.get(resourceType) : ''}
				error={formStore.getErrors().get('banner')?.getMsg()}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'banner')] }}
				required={true}
				multiple={isCreatePage}>
				Upload Banner {filename ? `- ${filename}` : ''}
			</FileSelector>
			<Spacer y={2} />
			{resourceType === ResourceTypes.IMAGE && <Img source={creative.getResourceUrl()} />}
			{resourceType === ResourceTypes.HTML && creative.getResourceUrl() && <div>No Preview for html files</div>}
		</Card>
	);
});
