import { id } from '@monorepo/tools/src/lib/types/primitives';
import { CrudEndpoint } from './crud.endpoint';

export class CampaignSegmentationEndpoint extends CrudEndpoint {
	constructor() {
		super('campaigns');
	}

	createWith(id: id) {
		return `${this.rootApi}/campaigns/webapps-cid-creator/${id}`;
	}

	get(id: id) {
		return `${this.rootApi}/campaigns/webapps-cid/${id}`;
	}

	// get() {
	// 	return `${this.rootApi}/input-options`;
	// }
}
