import { FilterTypeKey, FilterType, INumberFilter, IStringFilter, IEnumFilter, IEntityFilter } from '../../components/filters-menu/filters';
import { performanceFilters } from '../../components/filters-menu/filters/perfomance.filter';
import { Statuses } from '../../enums/status';
import { CampaignsApi } from '../../apis/campaigns.api';
import { AdvertisersApi } from '../../apis/advertisers.api';

const parentEntitiesMapper = new Map<string, string>([
	['campaign-groups', 'campaign_group'],
	['advertisers', 'advertiser'],
]);

export const campaignFilters = new Map<FilterTypeKey, FilterType>([
	[
		'entityFilters',
		{
			...performanceFilters.entityFilters,
			campaignEntity: {
				columnName: 'Campaign',
				cellKey: 'nameid',
				autocompleteCallback: async () => {
					const locationPathname = window.location.pathname;
					const parentEntityName = locationPathname.split('/')[1];
					const parentEntityId = locationPathname.split('/')[2]?.trim();
					return await CampaignsApi.list().then(campaigns => {
						return campaigns
							.map(campaign => {
								if (!parentEntityId || !parentEntityName) {
									return {
										label: `${campaign.name} (${campaign.id})`,
										value: campaign.id,
									};
								}

								if ((campaign as any)[parentEntitiesMapper.get(parentEntityName) as string]?.id == parentEntityId) {
									return {
										label: `${campaign.name} (${campaign.id})`,
										value: campaign.id,
									};
								}
							})
							.filter(campaign => campaign);
					});
				},
			},
			advertiserEntity: {
				columnName: 'Advertiser',
				cellKey: 'advertisernameid',
				autocompleteCallback: async () => {
					return await AdvertisersApi.list().then(advertisers => {
						return advertisers.map(advertiser => {
							return {
								label: `${advertiser.name} (${advertiser.id})`,
								value: advertiser.id,
							};
						});
					});
				},
			},
		} as IEntityFilter,
	],
	[
		'numberFilters',
		{
			...performanceFilters.numberFilters,
			dailyBudgetLimit: { columnName: 'Budget', cellKey: 'daily_budget.limit' }, //cellKey: ['daily_budget', 'limit']
		} as INumberFilter,
	],
	[
		'stringFilters',
		{
			...performanceFilters.stringFilters,
			name: { columnName: 'Name', cellKey: 'name' },
			campaignGroupName: { columnName: 'Campaign Group', cellKey: 'campaign_group.name' }, //cellKey: ['campaign_group', 'name']
		} as IStringFilter,
	],
	[
		'enumFilters',
		{
			...performanceFilters.enumFilters,
			status: {
				columnName: 'Status',
				cellKey: 'status',
				enumOptions: [
					{ value: Statuses.Active, label: 'Active' },
					{ value: Statuses.Paused, label: 'Paused' },
					{ value: Statuses.NoBalance, label: 'No Balance' },
					{ value: Statuses.New, label: 'New' },
					{ value: Statuses.Ended, label: 'Ended' },
				],
				deletable: false,
			},
		} as IEnumFilter,
	],
]);
