import styles from './user-menu.module.scss';
import { observer } from 'mobx-react';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { Fragment, useState } from 'react';
import { Spacer } from '@monorepo/base/src/components/spacer/spacer';
import { Avatar } from '@monorepo/base/src/components/avatar/avatar';
import { AvatarActingAs, AvatarDetails, AvatarSubtitle, AvatarTitle } from '@monorepo/base/src/components/avatar-details/avatar-details';
import { Divider } from '@monorepo/base/src/components/divider/divider';
import { SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { ActAsHelper } from '@monorepo/controlled/src/stores/act-as.helper';
import { sendGtagEvent } from '@monorepo/tools/src/lib/tools/tracking';
import { TrackingActions } from '@monorepo/tools/src/lib/consts/tracking/actions';
import { EVENTS } from '@monorepo/tools/src/lib/consts/tracking/events';
import { AuthStore } from '@monorepo/controlled/src/stores/auth.store';

export interface IUserMenuItemProps {
	onClick: () => void;
	icon?: string;
	children: React.ReactNode;
	className?: string;
}

export const UserMenuItem = observer(({ children, className, ...rest }: IUserMenuItemProps) => {
	return (
		<SecondaryText {...rest} className={`${styles.action} ${className}`}>
			{children}
		</SecondaryText>
	);
});

interface IGetUser {
	getPicture: () => string | undefined;
	getFirstName: () => string | undefined;
	getLastName: () => string | undefined;
	getFullName: () => string | undefined;
	getEmail: () => string | undefined;
}

export interface IUserMenuProps {
	authStore: AuthStore;
	userStore: {
		getUser: () => IGetUser | undefined;
		removeUser: () => void;
	};
	loginPageRoute: string;
	children?: React.ReactNode;
}

export const UserMenu = observer(({ authStore, userStore, loginPageRoute, children }: IUserMenuProps) => {
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
	// const navigate = useNavigateParams();
	const user = userStore.getUser();
	const actAsUser = ActAsHelper.getUser();

	const onSignOut = () => {
		if (ActAsHelper.hasPUV()) {
			ActAsHelper.deleteUser(actAsUser?.id);
			window.close();
			return;
		} else {
			ActAsHelper.clearAllPUVFromSessionStorage();
			userStore.removeUser();
			authStore.removeToken(true);
			window.location.href = loginPageRoute;
		}
	};

	if (!user) {
		return null;
	}

	const UserBar = () => {
		return (
			<div
				className={styles.userBar}
				onClick={() => {
					sendGtagEvent({
						action: TrackingActions.Click,
						category: EVENTS.CLICK.NAVBAR.USER.OPEN,
					});
					setIsOpenMenu(!isOpenMenu);
				}}>
				{<Avatar firstName={user.getFirstName()} lastName={user.getLastName()} url={user?.getPicture()} />}
			</div>
		);
	};

	return (
		<div className={styles.wrapper}>
			<UserBar />
			<Menu unstyled={true} className={styles.menu} isOpen={isOpenMenu} onClose={() => setIsOpenMenu(false)}>
				<div className={styles.userMenu}>
					<AvatarDetails
						className={styles.details}
						url={user?.getPicture()}
						firstName={user?.getFirstName()}
						lastName={user?.getLastName()}>
						<AvatarTitle className={styles.fullName}>{user?.getFullName()}</AvatarTitle>
						<AvatarSubtitle className={styles.email}>{user?.getEmail()}</AvatarSubtitle>
						{actAsUser && (
							<Fragment>
								<AvatarActingAs className={styles.actingAs}>
									Acting as {actAsUser?.firstName} {actAsUser?.lastName}
								</AvatarActingAs>
								<AvatarActingAs className={styles.actingAs}>({actAsUser?.email})</AvatarActingAs>
							</Fragment>
						)}
					</AvatarDetails>
				</div>
				<Spacer y={1} />
				<Divider classNames={styles.divider} />
				<div className={styles.actions}>
					{children}
					<SecondaryText
						className={styles.action}
						icon={'log-out-01'}
						onClick={() => {
							sendGtagEvent({
								action: TrackingActions.Click,
								category: EVENTS.CLICK.NAVBAR.USER.MENU.TERMS_AND_CONDITIONS,
								label: 'User Menu',
							});
							onSignOut();
						}}>
						Log out
					</SecondaryText>
				</div>
			</Menu>
		</div>
	);
});
