import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useList } from '../../../../modules/hooks/tools/use-list';
import { CreativeModel } from '../../../../modules/models/creative.model';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../../../modules/routes/index.routes';
import { useCreativeActions } from '../../../../modules/hooks/actions/creatives.actions';
import { IPerformanceOptionsFilterType } from '../../../../modules/hooks/apis/use-performance';
import { PerformancesColumnsSnakeCase } from '../../../../modules/hooks/apis/use-reports-list';
import { useMergeWithPerformance } from '../../../../modules/hooks/tools/use-merge-with-performance';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { usePerformanceEntity } from '../../../../modules/hooks/apis/use-performance-entity';
import { useCreativeHeaders } from '../../../../modules/hooks/theaders/creatives.headers';
import { IAdminxStore } from '../../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';

export const CreativeGroupCreatives = observer(() => {
	const { creativeStore } = useStores<IAdminxStore>();
	const { creativeGroupId } = useParams();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const performanceStore = creativeStore.getPerformance();
	if (creativeGroupId) {
		useList<CreativeModel>(creativeStore.getListStore(), { queryParams: { creativeGroupId } });
		const creativeGroupFilter = {
			column: PerformancesColumnsSnakeCase.CreativeGroupId,
			filterType: IPerformanceOptionsFilterType.Include,
			value: [creativeGroupId],
		};
		usePerformanceEntity(performanceStore, {
			filters: [creativeGroupFilter],
		});
	}

	const { data, summary } = useMergeWithPerformance<CreativeModel>({
		performanceStore,
		data: creativeStore.getListStore().getList(),
		key: PerformancesColumnsSnakeCase.CreativeId,
		attachExtraParams(row) {
			return {
				brand: row.brand,
				name: row.brand,
				description: row.description,
				resource_url: row.resource_url,
				resource_type: row.resource_type,
				adx_creative_status: row.adx_creative_status,
				size: row.size,
				default_dest_url: row.default_dest_url,
				creative_group: row.creative_group,
			};
		},
	});

	const { SelectedActions, FiltersActions, SegmentActions, EntityActions } = useCreativeActions();
	const { columns } = useCreativeHeaders({ EntityActions, includeColumns: { creative_group: false }, summary });

	return (
		<Page>
			<Pagebar>
				<PagebarTitle>Creatives</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
				</PagebarActions>
			</Pagebar>
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				createRoute={creativeGroupId ? routes.creativeGroups.createCreative(creativeGroupId) : undefined}
				columns={columns}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'creatives_creative_group_table')] }}
				data={data}
				isLoading={creativeStore.getListStore().getIsLoading()}
				isLoadingPerformance={performanceStore.getIsLoading()}
				isSummary={true}>
				{() => {
					return {
						FiltersActions,
						SelectedActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
