import { observer } from 'mobx-react';
import { FC } from 'react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Card } from '@monorepo/base/src/components/card/card';
import { IAdminxStore } from '../../../../stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import styles from './max-loss.module.scss';
import { Input } from '@monorepo/base/src/components/form/input/input';

export const DailyMaxLossDisplayInAdvertisers = [11];

export const DailyMaxLoss: FC = observer(() => {
	const { campaignStore } = useStores<IAdminxStore>();

	const campaignCrudStore = campaignStore.getCrud();
	const campaign = campaignCrudStore.getData();
	const maxLossPerDay = campaign.getDailyDomainMaxLoss();

	return (
		<Card
			id={'maxLossPerDay'}
			title={'Daily Domain Maximum Loss'}
			icon={'contrast-01'}
			className={styles.spaceBetween}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'daily_domain_max_loss_section')] }}>
			<Input
				type={'number'}
				icon={'currency-dollar'}
				value={maxLossPerDay?.toString() || ''}
				placeholder={'Daily Max Loss'}
				onValue={value => campaign.setDailyDomainMaxLoss(parseInt(value))}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'daily_domain_max_loss')] }}
			/>
		</Card>
	);
});
