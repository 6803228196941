import { advertiserNameCell, campaignGroupNameCell, campaignNameCell, creativeGroupNameCell, statusCell } from '../cells/adminx.cells';
import {
	AdvertiserEntityFilterDefinition,
	CampaignEntityFilterDefinition,
	CampaignGroupEntityFilterDefinition,
	NumbersFilterDefinition,
	StatusFilterDefinition,
	TextFilterDefinition,
} from '../table-filters/filters.definitions';
import { DimensionsColumnConfig, MetricsCurrencyColumnConfig } from './columns';
import { IColumnConfig } from './columns.types';

export class NameColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'name',
			accessor: 'name',
			header: 'Name',
			linkTo: ['id'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}
export class IdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'id',
			accessor: 'id',
			header: 'ID',
			linkTo: ['name'],
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class StatusColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'status',
			accessor: 'status',
			header: 'Status',
			cell: statusCell,
			availableFilters: new StatusFilterDefinition(),
			...params,
		});
	}
}

export class CampaignNameColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_name',
			accessor: 'campaignName',
			header: 'Campaign',
			linkTo: ['campaign_id'],
			cell: campaignNameCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class CampaignIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_id',
			accessor: 'campaignId',
			header: 'Campaign ID',
			linkTo: ['campaign_name'],
			availableFilters: new CampaignEntityFilterDefinition(),
			...params,
		});
	}
}

export class AdvertiserNameColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'advertiser_name',
			accessor: 'advertiserName',
			header: 'Advertiser',
			linkTo: ['advertiser_id'],
			cell: advertiserNameCell,
			availableFilters: new TextFilterDefinition(),
			...params,
		});
	}
}

export class AdvertiserIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'advertiser_id',
			accessor: 'advertiserId',
			header: 'Advertiser ID',
			linkTo: ['advertiser_name'],
			availableFilters: new AdvertiserEntityFilterDefinition(),
			...params,
		});
	}
}

export class CreativeGroupNameColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'creative_group_name',
			accessor: 'creativeGroupName',
			header: 'Creative Group',
			linkTo: ['creative_group_id'],
			cell: creativeGroupNameCell,
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The brand name of the creative group in our system. Will only be populated if we decided to bid',
			...params,
		});
	}
}

export class CreativeGroupIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'creative_group_id',
			accessor: 'creativeGroupId',
			linkTo: ['creative_group_name'],
			header: 'Creative Group ID',
			availableFilters: new TextFilterDefinition(),
			tooltip: 'The Creative Group ID in our system. Will only be populated if we decided to bid ',
			...params,
		});
	}
}

export class CampaignGroupNameColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_group_name',
			accessor: 'campaignGroupName',
			header: 'Campaign Group',
			linkTo: ['campaign_group_id'],
			availableFilters: new TextFilterDefinition(),
			cell: campaignGroupNameCell,
			tooltip: 'The name of the campaign group as defined in campaign group creation / edit screen',
			...params,
		});
	}
}

export class CampaignGroupIdColumnConfig extends DimensionsColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			name: 'campaign_group_id',
			accessor: 'campaignGroupId',
			header: 'Campaign Group ID',
			linkTo: ['campaign_group_name'],
			availableFilters: new CampaignGroupEntityFilterDefinition(),
			tooltip: 'The Campaign Group ID in our system. Will only be populated if we decided to bid',
			...params,
		});
	}
}

export class BudgetColumnConfig extends MetricsCurrencyColumnConfig {
	constructor(params: Partial<IColumnConfig> = {}) {
		super({
			type: 'dimensions',
			name: 'daily_budget',
			accessor: 'dailyBudget',
			header: 'Budget',
			availableFilters: new NumbersFilterDefinition(),
			...params,
		});
	}
}
