import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { CampaignForm } from '../../../modules/components/forms/campaign/campaign.form';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { useCampaignGet } from '../../../modules/hooks/apis/use-campaign-get';
import { useCampaignInputOptions } from '../../../modules/hooks/apis/use-campaign-input-options';
import { useList } from '../../../modules/hooks/tools/use-list';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CreativeModel } from '../../../modules/models/creative.model';
import styles from './edit-campaign.module.scss';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { IAdminxStore } from '../../../modules/stores';
import { Statuses } from '../../../modules/enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Difference, deepObjectDiff } from '@monorepo/tools/src/lib/utils/object';
import { DataComparisonModal } from '../../../modules/components/modals/data-comparison/data-comparison';
import { IPerformanceOptionsSortBy, PerformancesColumnsSnakeCase, useReportsList } from '../../../modules/hooks/apis/use-reports-list';

export const EditCampaign = observer(() => {
	const { campaignStore, creativeStore, modalsStore, toastsStore } = useStores<IAdminxStore>();
	const { setIsTableOfContents } = useOutletContext<{ setIsTableOfContents: (isSetTableOfContents: boolean) => void }>();
	const { dispatchLog } = useLogs();
	const reportList = campaignStore.getListStore();

	useCampaignInputOptions();
	useReportsList(reportList, {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxCampaigns',
		sortBy: IPerformanceOptionsSortBy.Id,
	});
	useList<CreativeModel>(creativeStore.getListStore());

	const navigate = useNavigateParams();
	const location = useLocation();
	const params = useParams();

	const campaignCrudStore = campaignStore.getCrud();

	useEffect(() => {
		campaignCrudStore.initEditedState();
		setIsTableOfContents(true);
	}, []);

	useEffect(() => {
		campaignCrudStore.reset();
		campaignCrudStore.setIsLoading(true);
	}, [location]);

	const [isFormClicked, setFormClicked] = useState<boolean>(false);

	if (params.campaignId) {
		useCampaignGet({ id: params.campaignId });
		// useGet<CampaignModel>(campaignCrudStore, params.campaignId);
	}

	const campaign = campaignCrudStore.getData();
	const previousCampaign = campaignCrudStore.getOriginData();
	const name = campaign.getName();
	const isLoading = campaignCrudStore.getIsLoading();
	const formStore = campaignCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isSuccess = campaignCrudStore.getIsSuccess();
	const error = campaignCrudStore.getHttpError();

	const targeting = campaign.getTargeting();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(-1);
			dispatchLog({
				msg: `Campaign ${name} was successfully edited`,
				type: DispatchLogsTypes.Success,
			});
			campaignCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onEditCampaign = () => {
		setFormClicked(true);

		const removeUnneccearyFields = (changes: Difference[]) => {
			const removeKeys = [
				'advertiser.id',
				'advertiser.user_cap_enabled',
				'advertiser.status',
				'advertiser.campaign_groups',
				'advertiser.stats',
				'advertiser.total_budget',
				'campaign_group.id',
				'campaign_group.status',
				'campaign_group.advertiser',
				'targeting.isSharedTargetingModified',
			];
			return changes.filter(change => !removeKeys.includes(change.key));
		};

		const changes = removeUnneccearyFields(deepObjectDiff(previousCampaign, campaign));
		if (changes.length === 0) {
			toastsStore.addToast({
				type: DispatchLogsTypes.Info,
				msg: 'No changes were made',
			});
			return;
		}

		modalsStore.addModal({
			children: (
				<ActionModal
					type={targeting?.getIsSharedTargetingModified() ? ModalTypes.Warning : ModalTypes.Info}
					primaryBtnTitle={targeting?.getIsSharedTargetingModified() ? "Yes, I'm sure" : "Yes, I'm aware"}
					onAction={() => {
						campaignStore.getCrud().edit();
					}}>
					<div className={styles.modalWrapper}>
						{targeting?.getIsSharedTargetingModified() && (
							<div className={styles.sharedTargetModificationText}>
								<b>Caution: Altering shared targeting impacts all associated campaigns, are you sure?</b>
							</div>
						)}
						<DataComparisonModal changes={changes} />
					</div>
				</ActionModal>
			),
		});
	};

	return (
		<Fragment>
			<BarLoader is={isLoading} />
			<Disable is={isLoading}>
				<Page>
					<Pagebar>
						<PagebarTitle unstyled={true}>
							<div className={styles.titleWrapper}>
								<div className={styles.title}>Edit Campaign</div>
								<div className={styles.campaignStatus}>
									<span>{isLoading ? '...' : name}</span>
									<SpacerX />
									<Toggle
										label={campaign.getStatus() === Statuses.Active ? Statuses.Active : Statuses.Paused}
										onChange={status => campaign.setStatus(status ? Statuses.Active : Statuses.Paused)}
										defaultValue={campaign.getStatus() === Statuses.Active}
										debugProps={{ dataAttrs: [new DataAttribute('id', 'campaign_status')] }}
									/>
								</div>
							</div>
						</PagebarTitle>
					</Pagebar>
					<CampaignForm />
				</Page>
			</Disable>
			<BottomBar>
				{error ? <ErrorMsg error={error} /> : null}
				<BottomBarActions>
					<PrimaryButton
						disabled={
							isLoading || JSON.stringify(campaignCrudStore.getData()) === JSON.stringify(campaignCrudStore.getOriginData())
						}
						onClick={() => onEditCampaign()}
						debugProps={{ dataAttrs: [new DataAttribute('id', 'save_campaign_button')] }}
						isRotateIcon={isLoading}
						icon={isLoading ? 'loading-01' : 'send-03'}>
						Save
					</PrimaryButton>
				</BottomBarActions>
			</BottomBar>
		</Fragment>
	);
});
