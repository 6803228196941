import { id } from '@monorepo/tools/src/lib/types/primitives';
import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { ReactElement } from 'react';
import { dispatchLogType } from '../hooks/use-logs';
import { generateStorageKey } from './helper';

interface INotificationAdd {
	date: Date;
	onClose?: () => void;
	children?: ReactElement | string;
	msg?: string;
	type?: dispatchLogType;
	extraData: any;
}

export interface INotificationItem extends INotificationAdd {
	id: number;
}

interface INotifications {
	notifications: INotificationAdd[];
}

export class NotificationsStore implements INotifications {
	notifications: INotificationItem[] = [];
	isOpenMenu = false;
	id = 1;

	constructor() {
		makeAutoObservable(this);
		makePersistable(this, {
			name: generateStorageKey('notificationsstore'),
			properties: ['notifications'],
			storage: {
				setItem: (key, data) => {
					window.localStorage.setItem(key, data);
				},
				removeItem: key => window.localStorage.removeItem(key),
				getItem: key => {
					const data = JSON.parse(window.localStorage.getItem(key) || '{}');
					return {
						...data,
						notifications: data.notifications || [],
					};
				},
			},
		});
	}

	getNotifications(): INotificationItem[] {
		return this.notifications;
	}

	getNotification(id: id): INotificationItem | undefined {
		return this.notifications.find(notification => notification.id === id);
	}

	addNotification(notification: INotificationAdd): INotificationItem {
		if (!notification) {
			throw new Error('No notification added to addNotification function');
		}
		const newNotification = {
			id: this.id++,
			...notification,
		};
		// Render notifications in the next render cycle
		setTimeout(() => {
			const notifications = [newNotification, ...this.notifications];
			if (notifications.length > 5) {
				notifications.pop();
			}
			this.setNotifications(notifications);
		});

		return newNotification;
	}

	setNotifications(notifications: INotificationItem[]) {
		this.notifications = notifications;
	}

	removeNotification(id: number) {
		this.notifications = this.notifications.filter(notification => notification.id !== id);
	}

	setIsOpenMenu(isOpenMenu: boolean) {
		this.isOpenMenu = isOpenMenu;
	}

	getIsOpenMenu(): boolean {
		return this.isOpenMenu;
	}

	getId(): number {
		return this.id;
	}
}
