import React, { cloneElement, ReactNode } from 'react';
import { route } from '@monorepo/tools/src/lib/types/url';
import { Accordion, IAccordion, IAccordionClasses } from '../accordion/accordion';
import { List, ListItem } from '../list/list';
import styles from './nav.module.scss';
import { Icon } from '../icon/icon';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';

export interface INavClick {
	to?: route;
}

export interface INavSharedProps {
	onNavClick?: (props: INavClick) => void;
	activeRoute?: route;
	unstyled?: boolean;
	list?: boolean;
	activeNavItemClassName?: string;
}

export interface INav extends INavSharedProps {
	children: JSX.Element | JSX.Element[];
	classes?: {
		nav?: string;
		activeNavItem?: string;
	};
}

export interface INavItem extends INavSharedProps {
	children: ReactNode;
	to?: route;
	className?: string;
	linkClassName?: string;
	onNavItem?: (props: INavClick) => void;
	disabled?: boolean;
	icon?: string | JSX.Element;
	iconFont?: IconFonts;
	debugProps?: IDebugProps;
}

export interface INavGroup extends INavSharedProps {
	title: ReactNode;
	children: JSX.Element | JSX.Element[];
	classes?: {
		navGroup?: string;
	};
	accordionProps?: Partial<IAccordion>;
	togglerIcon?: string;
}

export interface INavPage {
	children: JSX.Element[] | JSX.Element;
	classes?: {
		wrapper?: string;
		navWrapper?: string;
	};
}

export interface INavTitle {
	children: ReactNode;
	classes?: {
		navTitle?: string;
	};
}

/**
 * @param props
 * @returns
 */
export const NavPage = (props: INavPage) => {
	const { children, classes } = props;

	const _children = React.Children.map(children, child => {
		if (child.type.displayName === 'Page') {
			return cloneElement(child, {
				className: `${styles.page} ${child.props.className || ''}`,
			});
		}
		if (child.type.displayName === 'Nav') {
			return (
				<div className={`${styles.navWrapper} ${classes?.navWrapper || ''}`}>
					{cloneElement(child, {
						className: `${styles.navInPage} ${child.props.className || ''}`,
					})}
				</div>
			);
		}
		return child;
	});

	return <div className={`${styles.wrapper} ${classes?.wrapper}`}>{_children}</div>;
};

export const Nav = (props: INav) => {
	const { children, onNavClick, activeRoute, unstyled = false, list = false, classes } = props;

	const childrenWithExtraProp = React.Children.map(children, child => {
		return cloneElement(child, {
			onNavClick,
			activeRoute,
			unstyled,
			list,
			activeNavItemClassName: classes?.activeNavItem || '',
		});
	});

	return (
		<List
			className={`${styles.nav} ${list ? styles.navList : ''} ${unstyled ? styles.unstyled : ''} ${classes?.nav || ''}`}
			debugProps={{ dataAttrs: [new DataAttribute('id', 'context_menu')] }}>
			{childrenWithExtraProp}
		</List>
	);
};
Nav.displayName = 'Nav'; // For use in children.map loop

export const NavTitle = (props: INavTitle) => {
	const { children, classes } = props;
	return <div className={`${styles.navTitle} ${classes?.navTitle || ''}`}>{children}</div>;
};

export const NavGroup = (props: INavGroup) => {
	const {
		children,
		title,
		onNavClick,
		activeRoute,
		unstyled,
		classes = {},
		accordionProps,
		togglerIcon = '',
		activeNavItemClassName = '',
	} = props;

	const childrenWithExtraProp = React.Children.map(children, child => {
		return cloneElement(child, {
			onNavClick,
			activeRoute,
			unstyled,
			activeNavItemClassName,
		});
	});

	const _accordionClasses: IAccordionClasses = {
		toggler: styles.navGroupToggler,
		title: styles.navGroupTitle,
		...(accordionProps?.classes || {}),
	};

	const _accordionProps: Partial<IAccordion> = {
		defaultOpen: true,
		title,
		classes: _accordionClasses,
		togglerIcon,
		...(accordionProps || {}),
	};

	return (
		<ListItem className={`${styles.navGroup} ${unstyled ? styles.unstyled : ''} ${classes?.navGroup || ''}`}>
			<List>
				<Accordion
					defaultOpen={_accordionProps.defaultOpen}
					title={_accordionProps.title}
					classes={_accordionClasses}
					{...accordionProps}>
					{childrenWithExtraProp}
				</Accordion>
			</List>
		</ListItem>
	);
};

export const NavItem = (props: INavItem) => {
	const {
		children,
		to,
		onNavClick,
		activeRoute,
		className,
		linkClassName,
		onNavItem,
		list,
		disabled,
		unstyled,
		activeNavItemClassName,
		icon,
		iconFont,
		debugProps,
	} = props;
	const { dataAttrs } = debugProps || {};

	const onNav = () => {
		if (onNavClick) {
			onNavClick({ to });
		}
		if (onNavItem) {
			onNavItem({ to });
		}
	};

	const renderNavLinkIcon = () => {
		if (!icon) {
			return null;
		}
		if (typeof icon === 'string') {
			return (
				<Icon isMFP={true} size={'18px'} font={iconFont || IconFonts.Outlined}>
					{icon}
				</Icon>
			);
		}
		return icon;
	};

	const renderNavLink = () => {
		if (to && !disabled) {
			return (
				<LinkWithParams to={to} className={`${styles.navLink} ${linkClassName}`} onClick={onNav}>
					{renderNavLinkIcon()}
					{children}
				</LinkWithParams>
			);
		}
		if (!disabled) {
			// TODO - need this check if not disabled??
			return (
				<div
					className={`${styles.navLink} ${linkClassName} ${list ? styles.children : ''}`}
					onClick={onNav}
					{...generateDataAttrs(dataAttrs)}>
					{renderNavLinkIcon()}
					{children}
				</div>
			);
		}
	};

	const styledClasses = unstyled
		? [styles.unstyled, activeRoute === to ? activeNavItemClassName : '']
		: [
				styles.navItem,
				list ? styles.item : '',
				activeRoute && activeRoute === to ? `${styles.activeRoute} ${activeNavItemClassName}` : '',
		  ];
	return <ListItem className={`${className || ''} ${styledClasses.join(' ')}`}>{renderNavLink()}</ListItem>;
};
