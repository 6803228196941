import { httpStatus } from '@monorepo/tools/src/lib/enums/http-statuses';

export interface IHttpError {
	name?: string;
	httpStatus: httpStatus;
	errorPayload: IErrorPayload;
	message: string;
}

export enum ErrorPayloadType {
	Message = 'message',
	Properties = 'properties',
}

type ErrorPayloadTypes = ErrorPayloadType.Message | ErrorPayloadType.Properties;

export interface IErrorPayload {
	type: ErrorPayloadTypes;
	data: { [key: string]: string };
}
export class HttpError implements IHttpError {
	name?: string;
	message: string;
	httpStatus: httpStatus;
	errorPayload: IErrorPayload;

	constructor(props: IHttpError) {
		this.name = props?.name;
		this.httpStatus = props?.httpStatus;
		this.message = props?.message;
		this.errorPayload = props?.errorPayload;
	}

	getMessage(): string {
		return this.message;
	}
}
