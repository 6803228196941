import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { observer } from 'mobx-react';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IAdminxStore } from '../../../modules/stores';
import styles from './view-campaign-segmentations.module.scss';
import { Card } from '@monorepo/base/src/components/card/card';
import { CampaignModel, ICampaign } from '../../../modules/models/campaign.model';
import { SelectOptions } from '@monorepo/base/src/components/select-new/select-new';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { DropdownNew } from '@monorepo/base/src/components/dropdown-new/dropdown-new';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { useEffect } from 'react';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { IPerformanceOptionsSortBy, PerformancesColumnsSnakeCase, useReportsList } from '../../../modules/hooks/apis/use-reports-list';

export const ToolsViewCampaignSegmentations = observer(() => {
	const { campaignSegmentsStore, campaignStore } = useStores<IAdminxStore>();
	const campaignSegmentsCrudStore = campaignSegmentsStore.getCrud();
	const isLoading = campaignSegmentsCrudStore.getIsLoading();
	const error = campaignSegmentsCrudStore.getHttpError();
	const webAppsCidSegments: SelectOptions[] = campaignStore
		.getInputOptions()
		.getWebappsCidSegments()
		.map(segment => ({
			label: segment.auction_package_name || '',
			value: segment.auction_package_id || '',
		}));
	const reportList = campaignStore.getListStore();
	const campaignOptions: SelectOptions[] =
		reportList
			.getData()
			?.getData()
			?.map(campaign => {
				const _campaign = new CampaignModel(campaign as ICampaign);
				return {
					label: _campaign.getName() as string,
					value: _campaign.getId() as string,
				};
			}) || [];
	const selectedCampaignId = campaignSegmentsCrudStore.getData().getCampaignId();
	const segmentations = campaignSegmentsCrudStore.getData().getSegmentations();
	const { dispatchLog } = useLogs();

	useEffect(() => {
		campaignSegmentsCrudStore.reset();
		if (error) {
			dispatchLog({
				msg: `Couldn't fetch campaign segments`,
				type: DispatchLogsTypes.Error,
			});
		}
	}, [error]);

	useReportsList(reportList, {
		groupBys: [PerformancesColumnsSnakeCase.Id, PerformancesColumnsSnakeCase.Name],
		reportName: 'adxCampaigns',
		sortBy: IPerformanceOptionsSortBy.Id,
	});

	const onListItemCampaign = (campaignId: id[]) => {
		campaignSegmentsCrudStore.getData().setCampaignId(campaignId[0]);
		campaignSegmentsCrudStore.getData().setSegmentations([]);
		if (campaignId[0]) {
			campaignSegmentsCrudStore.getById(campaignId[0]);
		}
	};

	const renderSegments = () => {
		if (!selectedCampaignId) {
			return null;
		}

		if (segmentations?.length === 0 && !isLoading) {
			return <Card title={'No Segmentations Found'}> </Card>;
		}

		return segmentations?.map((segment, index) => {
			return (
				<Card key={index} title={'View Webapps CID Segment'} icon={'announcement-01'}>
					<DropdownNew
						label={'Select Segment'}
						classNames={{
							dropdownWrapper: styles.dropdownWrapper,
							parentSelectWrapper: styles.parentSelectWrapper,
							dropdownInput: styles.dropdownInput,
						}}
						options={webAppsCidSegments}
						defaultValues={segment.auction_package_id ? [segment.auction_package_id] : ([] as id[])}
						multi={false}
						disabled
					/>
					<div>
						<Input
							className={styles.inputStyle}
							type={'number'}
							disabled
							value={segment.getSourceCid()?.toString() || ''}
							placeholder={'Source Cid'}
							required={true}
							onValue={value => segment.setSourceCid(value)}
							isId={true}
						/>
						<Input
							className={styles.inputStyle}
							type={'number'}
							disabled
							value={segment.getTargetCid()?.toString() || ''}
							placeholder={'Target Cid'}
							required={true}
							onValue={value => segment.setTargetCid(value)}
							isId={true}
						/>
					</div>
				</Card>
			);
		});
	};

	return (
		<Page>
			<div className={styles.formWrapper}>
				<div className={styles.pagebar}>
					<Pagebar>
						<PagebarTitle>View Campaign Segmentation</PagebarTitle>
					</Pagebar>
				</div>

				<div className={styles.segmentation}>
					<Card title={'Select Campaign'} icon={'announcement-01'}>
						<DropdownNew
							classNames={{ dropdownWrapper: styles.dropdownWrapper, parentSelectWrapper: styles.parentSelectWrapper }}
							options={campaignOptions}
							defaultValues={selectedCampaignId ? [selectedCampaignId] : ([] as id[])}
							autocomplete={true}
							autocompletePlaceholder={'Search Campaign'}
							required
							onSelect={onListItemCampaign}
							disabled={reportList.getIsLoading() || isLoading}
						/>
						<div className={styles.limitLoaderSize}>
							<BarLoader is={reportList.getIsLoading() || isLoading} />
						</div>
					</Card>
					{renderSegments()}
				</div>
			</div>
		</Page>
	);
});
