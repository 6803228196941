import styles from './sidebar.module.scss';
import sidebarButtonStyles from './sidebar-button.module.scss';
import { useHover } from '@monorepo/tools/src/lib/hooks/utils/use-hover';
import { useRef } from 'react';
import { useDebounce } from '@monorepo/tools/src/lib/hooks/utils/use-debounce';
import { DataAttribute, generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { Icon } from '../icon/icon';

export enum SidebarMode {
	HOVER = 'hover',
	LOCK = 'lock',
}

interface InjectedSidebarProps {
	isHover: boolean;
}

interface ISidebar {
	children(props: InjectedSidebarProps): JSX.Element;
	isOpen: boolean;
	isAnimate?: boolean;
	mode?: SidebarMode;
	debugProps?: IDebugProps;
}

interface ISidebarButton {
	onClick?: () => void;
	isOpen: boolean;
	classes?: {
		button: string;
	};
	icon?: string;
}

export const SidebarButton = (props: ISidebarButton) => {
	const { onClick, isOpen, classes, icon } = props;
	if (icon) {
		return (
			<div
				className={`${sidebarButtonStyles.button} ${classes?.button || ''} ${isOpen ? sidebarButtonStyles.lock : ''}`}
				onClick={onClick}>
				<Icon className={styles.icon} isMFP={true} size={'18px'}>
					{icon}
				</Icon>
			</div>
		);
	}
	return (
		<div
			className={`${sidebarButtonStyles.button} ${classes?.button || ''} ${isOpen ? sidebarButtonStyles.lock : ''}`}
			onClick={onClick}>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export const Sidebar = (props: ISidebar) => {
	const { children, isOpen, isAnimate, mode, debugProps } = props;
	const hoverRef = useRef(null);
	const isHover = useHover(hoverRef);
	const debouncedIsHover = useDebounce<boolean>(isHover, 100);
	const renderCounter = useRef(0); //TODO - maybe change it later?
	const { dataAttrs } = debugProps || {};
	renderCounter.current = renderCounter.current + 1;

	return (
		<aside
			{...generateDataAttrs(dataAttrs || [new DataAttribute('id', 'sidebar')])}
			ref={hoverRef}
			className={`${styles.wrapper} ${isOpen || (debouncedIsHover && mode === SidebarMode.HOVER) ? styles.lock : ''} 
			${isOpen || (debouncedIsHover && mode === SidebarMode.HOVER) ? styles.open : styles.close} 
			${renderCounter.current > 3 ? styles.triggerAnimation : styles.noAnmiation} 
			${isAnimate || (debouncedIsHover && mode === SidebarMode.HOVER) ? styles.animate : styles.static}`}>
			{children({ isHover: debouncedIsHover })}
		</aside>
	);
};
