import { IDealEditForm } from '../models/deal.model';
import { IDealCreateForm, DealModel } from '../models/deal.model';
import { IRequestOptions } from '@monorepo/tools/src/lib/interfaces/url';
import endpoints from './endpoints/index.endpoints';
import { URLSearchParams } from 'url';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';

export const DealsApi = {
	list: (params?: URLSearchParams): Promise<DealModel[]> => {
		return ask.get(endpoints.deals.list(), params);
	},
	create: (deal: IDealCreateForm, options?: IRequestOptions): Promise<{ id: id }> => {
		return ask.post<IDealCreateForm>(endpoints.deals.create(), deal, options);
	},
	get: (id: id, options?: URLSearchParams): Promise<DealModel> => {
		return ask.get(endpoints.deals.get(id), options);
	},
	edit: (id: id, deal: IDealEditForm, options?: IRequestOptions): Promise<null> => {
		return ask.patch<IDealEditForm>(endpoints.deals.edit(id), deal, options);
	},
	delete: (id: id) => {
		return ask.delete(endpoints.deals.delete(id));
	},
	attachCampaigns(creativeId: id, campaignIds: id[]): Promise<null> {
		return ask.put(endpoints.deals.attachCampaigns(creativeId), campaignIds);
	},
};
