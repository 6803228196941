import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { PerformanceStore } from '../../stores/performance.store';
import { preparePerformanceRequest } from './use-performance';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { keys } from 'mobx';
import { IPerformanceOptions } from './use-reports-list';

export function usePerformanceEntity<TModel>(
	performanceStore: PerformanceStore<TModel>,
	options?: IPerformanceOptions,
	hookOptions: BetweenPagesStateProps = {}
) {
	const isBlockRequest = hookOptions.isBlockRequest || false;
	const location = useLocation();
	const { startDate, endDate } = useDates();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const segments = performanceStore.getSegments();
	const groupBys = performanceStore.getGroupBys();
	const didMount = useDidMount(); // fetch data after did mount, solve problem of navigating between performance pages before fetch is resolved
	const [fetchParams, setFetchParams] = useState<IPerformanceOptions>({ ...options });

	useEffect(() => {
		const { periodStart, periodEnd } = options || {};
		if (isBlockRequest || !didMount) {
			return;
		}

		const params = preparePerformanceRequest({
			...options,
			periodStart: periodStart || primaryFromDate,
			periodEnd: periodEnd || primaryToDate,
			groupBys: [
				...groupBys,
				...(segments ? Array.from(segments).map(s => s.performanceGroupBy) : []),
				...(options?.groupBys ? options.groupBys : []),
			],
			submit: options?.submit || false,
			reportName: options?.reportName || 'adx',
		});

		performanceStore.reset();
		setFetchParams(params);
		performanceStore.fetch(params);

		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				performanceStore.abort();
			}
		};
	}, [location, primaryFromDate, primaryToDate, keys, segments, didMount]);

	return { error: performanceStore.getHttpError(), isLoading: performanceStore.getIsLoading(), fetchParams };
}
