import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './create-test.module.scss';
import { Fragment, useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { TestForm } from '../../../modules/components/forms/test/test.form';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { Toggle } from '@monorepo/base/src/components/form/toggle/toggle';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { IAdminxStore } from '../../../modules/stores';
import { Statuses } from '../../../modules/enums/status';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

export const CreateTest = observer(() => {
	const { testStore } = useStores<IAdminxStore>();
	const [isFormClicked, setFormClicked] = useState<boolean>(false);
	const { dispatchLog } = useLogs();

	const testCrudStore = testStore.getCrud();
	const test = testCrudStore.getData();

	const navigate = useNavigateParams();
	const location = useLocation();

	useEffect(() => {
		testCrudStore.reset();
	}, [location]);

	const name = testCrudStore.getData().getName();
	const formStore = testCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isLoading = testCrudStore.getIsLoading();
	const isSuccess = testCrudStore.getIsSuccess();
	const error = testCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			navigate(-1);
			dispatchLog({
				msg: `Test ${name} was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			testCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onCreateTest = () => {
		setFormClicked(true);
		testStore.getCrud().create();
	};

	return (
		<Fragment>
			<Disable is={isLoading}>
				<Page className={isLoading ? styles.loadingPage : ''}>
					<Pagebar>
						<PagebarTitle>
							<div className={styles.title}>Create test</div>
							<SpacerX />
							<Toggle
								label={test.getStatus() === Statuses.Active ? Statuses.Active : Statuses.Paused}
								onChange={status => test.setStatus(status ? Statuses.Active : Statuses.Paused)}
								defaultValue={test.getStatus() === Statuses.Active}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'test_status')] }}
							/>
						</PagebarTitle>
					</Pagebar>
					<TestForm />
				</Page>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					<BottomBarActions>
						<div className={styles.bottomBarActions}>
							<SecondaryButton
								className={styles.addTestGroupBtn}
								onClick={() => test.addTestGroup()}
								isRotateIcon={isLoading}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'add_test_group_button')] }}
								icon={'plus'}>
								Add Test Group
							</SecondaryButton>
							<PrimaryButton
								onClick={() => onCreateTest()}
								isRotateIcon={isLoading}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'create_test_button')] }}
								icon={isLoading ? 'loading-01' : 'send-03'}>
								Create
							</PrimaryButton>
						</div>
					</BottomBarActions>
				</BottomBar>
			</Disable>
		</Fragment>
	);
});
