import { generateAdxReprPerformanceColumns } from '../../../columns/metrics.columns';
import { IAdminxReportTableStoreParams } from '../../../stores/table-stores/adx-repr-table.store';
import { SortOrderEnum } from '../../../stores/data-stores/adx-repr.types';
import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import { DimensionsColumnConfig } from '../../../columns/columns';
import { TextFilterDefinition } from '../../../table-filters/filters.definitions';
import { domainLinkCell } from '../../../cells/old-adx-report.cells';

// Notes:
// - This configuration is used to generate a table report for campaigns domains
// - if the filters object or sorting object contains a column that is not in the columns array, it will cause an error !!!!!
// -

export const domainsPerformanceTableConfig: IAdminxReportTableStoreParams = {
	reportParams: {
		reportName: 'AdxCampaignsReport',
		sort: [
			{
				column: 'auctions_all',
				sortOrder: SortOrderEnum.DESC,
			},
		],
		bypassCache: true,
		filters: [],
	},
	columns: [
		new DimensionsColumnConfig({
			name: 'domain',
			accessor: 'domain',
			header: 'Domain',
			visibility: ColumnVisibilityOptions.AlwaysVisible,
			availableFilters: new TextFilterDefinition(),
			cell: domainLinkCell,
		}),
		...generateAdxReprPerformanceColumns(),
	],
	includeChart: true,
	tableName: 'adxReprDomainPerformance', // must be uniq for each table report, it is the key for the local storage and export file
};
