import { observer } from 'mobx-react';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { usePreferenceActions } from '../../../modules/hooks/actions/preference.actions';
import { usePreferenceHeaders } from '../../../modules/hooks/theaders/preferences.headers';
import { useList } from '../../../modules/hooks/tools/use-list';
import { useStateBetweenPages } from '../../../modules/hooks/tools/use-state-between-pages';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { PreferenceModel } from '../../../modules/models/preference.model';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useRoute } from '../../../modules/hooks/tools/use-route';

const actionsDebugProps = { dataAttrs: [new DataAttribute('id', 'preferences_actions')] };
const pageBarDebugProps = { dataAttrs: [new DataAttribute('id', 'preferences_pagebar')] };
const tableDebugProps = { dataAttrs: [new DataAttribute('id', 'preferences_table')] };

export const Preferences: FC = observer(() => {
	const { preferenceStore } = useStores<IAdminxStore>();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const preferenceListStore = preferenceStore.getListStore();
	const stateBetweenPages = useStateBetweenPages(() => preferenceListStore.getList().length !== 0);

	useList<PreferenceModel>(preferenceStore.getListStore(), undefined, stateBetweenPages);

	const { columns } = usePreferenceHeaders();
	const { SelectedActions, IndexActions, FiltersActions } = usePreferenceActions({ debugProps: actionsDebugProps });

	return (
		<Page>
			<Pagebar debugProps={pageBarDebugProps}>
				<PagebarTitle>Preferences</PagebarTitle>
			</Pagebar>
			<ControlledTable
				currentRoute={
					currentRouteWithoutSpecialChars === 'preferencescreate' || currentRouteWithoutSpecialChars === 'preferencesedit'
						? 'preferences'
						: currentRouteWithoutSpecialChars
				}
				columns={columns}
				debugProps={tableDebugProps}
				data={preferenceStore.getListStore().getList()}
				isLoading={preferenceStore.getListStore().getIsLoading()}>
				{() => {
					return {
						SelectedActions,
						IndexActions,
						FiltersActions,
					};
				}}
			</ControlledTable>
			<Outlet />
		</Page>
	);
});
