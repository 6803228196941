import { Fragment } from 'react';
import styles from './skeleton.module.scss';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';

interface ISkeleton {
	is?: boolean;
	rectanglesAmount?: number;
	fullWidth?: boolean;
	debugProps?: IDebugProps;
}
export const Skeleton = (props: ISkeleton) => {
	const { is = false, rectanglesAmount = 1, fullWidth, debugProps } = props;
	const { dataAttrs } = debugProps || {};

	if (!is) {
		return null;
	}

	const rectangles = Array.from({ length: rectanglesAmount });

	return (
		<Fragment>
			{rectangles.map((rectangle, recatngleIndex) => {
				return (
					<div
						key={recatngleIndex}
						className={`${styles.rectangle} ${fullWidth ? styles.full : ''}`}
						{...generateDataAttrs(dataAttrs)}></div>
				);
			})}
		</Fragment>
	);
};
