import { ElementType } from 'react';
import { IButton, SecondaryLink, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { route } from '@monorepo/tools/src/lib/types/url';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { Row } from '@tanstack/react-table';
import { ActionModal, ModalTypes } from '@monorepo/controlled/src/components/modal/modals/action-modal/action-modal';
import { DeleteModal } from '../../components/modals/delete-modal';
import { IAdminxStore } from '../../stores';
import { Id } from '@monorepo/controlled/src/stores/base-crud.store';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { HttpError } from '../../models/http-error.model';
import { AllowMultipleDownloadsModal } from '../../components/modals/allow-multiple-downloads-model';

export interface IUseEntityActions {
	onEdit?: (id: id) => string;
	createRoute?: route;
	debugProps?: IDebugProps;
}

export interface ITableActions<T> {
	rows: Row<T>[];
	debugProps: IDebugProps;
}

export interface IEntityActions<T> {
	entity: T;
}

interface IDefaultActionsProps<T> {
	onDelete?: (ids: id[]) => Promise<void | HttpError>;
	onDownload?: (ids: id[]) => void;
	onEditRoute?: (id: id) => string;
	entities: T[];
	debugProps?: IDebugProps;
}

interface IDefaultActionsReturnProps {
	EditAction: ElementType;
	DeleteAction: ElementType;
	DownloadAction: ElementType;
}

export function useDefaultActions<T extends Id>(props: IDefaultActionsProps<T>): IDefaultActionsReturnProps {
	const { onDelete, onEditRoute, onDownload, entities, debugProps } = props;
	const { modalsStore } = useStores<IAdminxStore>();
	const { dispatchLog } = useLogs();
	const navigate = useNavigateParams();
	const { dataAttrs } = debugProps || {};

	const _onDelete = () => {
		modalsStore.addModal({
			children: (
				<ActionModal
					type={ModalTypes.Info}
					primaryBtnTitle={'Delete'}
					onAction={() => {
						const deleteIds = entities.map(entity => entity?.getId()).filter(id => id);
						if (!onDelete) {
							return;
						}
						// as id[] because of filter id => id in line 57
						onDelete(deleteIds as id[]).then((error?: HttpError | void) => {
							if (error) {
								dispatchLog({
									msg: `Delete ${deleteIds} Failed`,
									type: DispatchLogsTypes.Error,
								});
								return;
							}
							dispatchLog({
								msg: `${deleteIds} ${'Deleted'}`,
								type: DispatchLogsTypes.Success,
							});
							setTimeout(() => {
								navigate(0);
							}, 500);
						});
					}}>
					<DeleteModal label={entities.length > 1 ? 'Are you sure you want to delete these rows?' : undefined} />
				</ActionModal>
			),
		});
	};
	const _onDownload = () => {
		if (!onDownload) {
			return;
		}
		const toDownloadIds = entities.map(entity => entity?.getId()).filter(id => id);
		if (toDownloadIds.length === 1) {
			onDownload(toDownloadIds as id[]);
		}
		if (!modalsStore.getIsAllowMultipleDownloadsModalDisplayed()) {
			modalsStore.addModal({
				children: (
					<ActionModal
						type={ModalTypes.Info}
						primaryBtnTitle={'Got it!'}
						onAction={() => {
							onDownload(toDownloadIds as id[]);
							modalsStore.setIsAllowMultipleDownloadsModalDisplayed(true);
						}}>
						<AllowMultipleDownloadsModal />
					</ActionModal>
				),
			});
		} else {
			onDownload(toDownloadIds as id[]);
		}
	};

	const EditAction = (props: IButton<HTMLAnchorElement>) => {
		const { textColor = 'white', iconColor = 'white' } = props;
		const editId = entities[0]?.getId();
		if (!onEditRoute || !editId) {
			return null;
		}
		return (
			<SecondaryLink
				disabled={entities.length !== 1}
				text={true}
				icon={'edit-02'}
				to={onEditRoute(editId)}
				textColor={textColor}
				iconColor={iconColor}
				{...props}
				debugProps={{ dataAttrs: suffixToDataAttr('_selected_edit', dataAttrs) }}>
				Edit
			</SecondaryLink>
		);
	};

	const DeleteAction = (props: IButton<HTMLButtonElement>) => {
		const { textColor = 'white', iconColor = 'white' } = props;
		if (!onDelete) {
			return null;
		}
		return (
			<SecondaryText
				icon={'trash-02'}
				onClick={_onDelete}
				textColor={textColor}
				iconColor={iconColor}
				debugProps={{ dataAttrs: suffixToDataAttr('_selected_delete', dataAttrs) }}
				{...props}>
				Delete
			</SecondaryText>
		);
	};

	const DownloadAction = (props: IButton<HTMLButtonElement>) => {
		const { textColor = 'white', iconColor = 'white' } = props;
		if (!onDownload) {
			return null;
		}
		return (
			<SecondaryText
				icon={'chevron-down-double'}
				onClick={_onDownload}
				textColor={textColor}
				iconColor={iconColor}
				debugProps={{ dataAttrs: suffixToDataAttr('_selected_download_creative', dataAttrs) }}
				{...props}>
				Download
			</SecondaryText>
		);
	};

	return {
		EditAction,
		DeleteAction,
		DownloadAction,
	};
}
