import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { CrudEndpoint } from './crud.endpoint';
import { url } from '@monorepo/tools/src/lib/types/url';

export class UsersEndpoint extends CrudEndpoint {
	sherrifRoute: string;
	bouncerRoute: url;

	constructor() {
		super('users');
		this.sherrifRoute = getConfig().env.sheriffRoot;
		this.bouncerRoute = `${this.sherrifRoute}/bouncer/${this.component}`;
	}

	// get(id: id) {
	// 	return `${this.bouncerRoute}/${id}`;
	// }
	//
	// list() {
	// 	return `${this.bouncerRoute}`;
	// }
	//
	profile() {
		// Token necessary
		return `${this.sherrifRoute}/bouncer/profile/me`;
	}

	// edit(id: id) {
	// 	// Token necessary
	// 	return `${this.bouncerRoute}/${id}`;
	// }
	//
	// create() {
	// 	return `${this.bouncerRoute}`;
	// }
	//
	// signUp() {
	// 	return `${this.bouncerRoute}/signup`;
	// }

	signIn() {
		return `${this.bouncerRoute}/signin`;
	}

	// resetPassword() {
	// 	return `${this.bouncerRoute}/reset-password`;
	// }
	//
	// forgotPassword() {
	// 	return `${this.bouncerRoute}/forget-password`;
	// }
	//
	// verify(token: string) {
	// 	return `${this.bouncerRoute}/verify/${token}`;
	// }
	//
	// sendVerifyEmail() {
	// 	return `${this.bouncerRoute}/send-verify-email`;
	// }

	validate2fa() {
		return `${this.bouncerRoute}/validate2fa`;
	}

	getRoles() {
		return `${this.bouncerRoute}/roles`;
	}
}
