import { BasicInfo } from './basic-info/basic-info';
import { Budget } from './budget/budget';
import { DefaultPayout } from './default-payout/default-payout';
import { Pretargeting } from './pretargeting/pretargeting';
import { Rules } from './rules/rules';
import { Targeting } from './targeting/targeting';
import { CampaignCreative } from './campaign-creative/campaign-creative';
import styles from './campaign-form.module.scss';

import { KeysInTarget } from './sites-in-target/sites-in-target';
import { BidKeysAndSitesInfo } from './bidkeys-and-sites-info/bidkeys-and-sites-info';
import { DailyMaxLoss, DailyMaxLossDisplayInAdvertisers } from './max-loss/max-loss';
import { IAdminxStore } from '../../../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';

export const CampaignForm = ({ isDuplicate }: { isDuplicate?: boolean }) => {
	const { campaignStore } = useStores<IAdminxStore>();

	const advertiserId = campaignStore.getCrud().getData().getAdvertiser()?.getId();
	return (
		<div className={styles.wrapper}>
			<BasicInfo />
			<Pretargeting />
			<Budget />
			<DefaultPayout />
			<Targeting isDuplicate={isDuplicate} />
			{DailyMaxLossDisplayInAdvertisers.includes(Number(advertiserId)) && <DailyMaxLoss />}
			<CampaignCreative />
			<Rules />
			<BidKeysAndSitesInfo />
			<KeysInTarget />
		</div>
	);
};
