import { createColumnHelper } from '@tanstack/react-table';
import { OverflowColumn } from '@monorepo/base/src/components/table/overflow-column/overflow-column';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { Decisions } from '../../enums/decisions';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { JobModel } from '../../models/job.model';
import routes from '../../routes/index.routes';
import { useJobActions } from '../actions/job.actions';
import { IMergedHeadersReturn } from './index.headers';
import { Statuses, mapToTagStatus, Status } from '../../enums/status';
import { SpacerX } from '@monorepo/base/src/components/spacer/spacer';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { IAdminxStore } from '../../stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useEffect } from 'react';

const columnHelper = createColumnHelper<JobModel>();

export const useJobHeaders = (): IMergedHeadersReturn<JobModel> => {
	const { settingsStore } = useStores<IAdminxStore>();
	const { EntityActions } = useJobActions();
	const { currentRouteWithoutSpecialChars } = useRoute();
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore && Object.keys(tableStore.getColumnVisibility()).length === 0) {
			tableStore.setDefaultColumnVisibility({
				id: true,
				name: true,
				task_classes: true,
				cron_expression: true,
				is_active: true,
				run_on_init: true,
				max_retries: true,
				retry_timeout: true,
				refresh_rate: true,
			});
			tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const job = new JobModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<SpacerX x={0.7} />
							<QuickActionsMenu>
								<EntityActions entity={job} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				cell: info => {
					const { row } = info;
					return (
						<TableLink
							toEdit={routes.jobs.edit(row.getValue('id'))}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('task_classes', {
				header: 'Task',
				cell: info => {
					const { row } = info;
					return <OverflowColumn data={row.getValue('task_classes')} />;
				},
			}),
			columnHelper.accessor('cron_expression', {
				header: 'Cron Expression',
			}),
			columnHelper.accessor('is_active', {
				header: 'Active',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('is_active') ? Statuses.Active : Statuses.Paused;
					return (
						<Tag type={mapToTagStatus(status.toUpperCase() as Status)}>
							{row.getValue('is_active') ? Statuses.Active : Statuses.Paused}
						</Tag>
					);
				},
			}),
			columnHelper.accessor('run_on_init', {
				header: 'Run On Init',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('run_on_init') ? Decisions.Yes : Decisions.No;

					return <span>{status}</span>;
				},
			}),
			columnHelper.accessor('max_retries', {
				header: 'Max Retries',
			}),
			columnHelper.accessor('retry_timeout', {
				header: 'Retry Timeout',
			}),
			columnHelper.accessor('refresh_rate', {
				header: 'Refresh Rate',
			}),
		],
	};
};
