import { observer } from 'mobx-react';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IModalItem } from '../../../stores/modals.store';
import { Modal } from '../modal/modal';

export const ModalsContainer = observer(() => {
	const { modalsStore } = useStores<IControlledStore>();
	const modals = modalsStore.getModals();

	return (
		<div>
			{modals.map((modal: IModalItem) => {
				const { children, id } = modal;

				return (
					<Modal key={id} {...modal}>
						{children}
					</Modal>
				);
			})}
		</div>
	);
});
