import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { PreferenceForm } from '../../../modules/components/forms/preference/preference.form';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Page, Pagebar, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { usePreferenceGet } from '../../../modules/hooks/apis/use-preference-get';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './edit-preference.module.scss';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import routes from '../../../modules/routes/index.routes';
import { Disable } from '@monorepo/base/src/components/disable/disable';
import { BottomBar, BottomBarActions } from '@monorepo/base/src/components/bottom-bar/bottom-bar';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { SiderSlider } from '@monorepo/base/src/components/sider-slider/sider-slider';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { IAdminxStore } from '../../../modules/stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

export const EditPreference = observer(() => {
	const { preferenceStore, toastsStore } = useStores<IAdminxStore>();
	const { dispatchLog } = useLogs();
	const location = useLocation();
	const navigate = useNavigateParams();
	const params = useParams();

	const preferenceCrudStore = preferenceStore.getCrud();

	useEffect(() => {
		preferenceCrudStore.reset();
		preferenceCrudStore.setIsLoading(true);
	}, [location]);

	const [isFormClicked, setFormClicked] = useState<boolean>(false);

	if (params.preferenceId) {
		usePreferenceGet({ id: params.preferenceId });
	}

	const preference = preferenceCrudStore.getData();
	const name = preference.getName();
	const isLoading = preferenceCrudStore.getIsLoading();
	const formStore = preferenceCrudStore.getFormStore();
	const isValidForm = formStore.getIsValid();
	const isSuccess = preferenceCrudStore.getIsSuccess();
	const error = preferenceCrudStore.getHttpError();

	useEffect(() => {
		if (!isValidForm && isFormClicked) {
			dispatchLog({
				msg: 'Please complete all mandatory fields',
				type: DispatchLogsTypes.Error,
			});
		}
	}, [isFormClicked]);

	useEffect(() => {
		if (isSuccess) {
			toastsStore.addToastOnNav({ msg: `Preference ${name} was successfully edited`, type: DispatchLogsTypes.Success });
			navigate(`${routes.preferences.index()}`, { state: { isBlockRequset: false } as BetweenPagesStateProps });
			preferenceCrudStore.setIsSuccess(false);
		}
	}, [isSuccess]);

	const onEditPreference = () => {
		setFormClicked(true);
		preferenceStore.getCrud().edit();
	};

	return (
		<SiderSlider
			onClose={() => navigate(routes.preferences.index(), { state: { isBlockRequest: true } as BetweenPagesStateProps })}
			isOpen={true}>
			<BarLoader is={isLoading} />
			<Disable is={isLoading}>
				<Page>
					<Pagebar>
						<PagebarTitle>
							<div className={styles.title}>Edit preference</div>
						</PagebarTitle>
					</Pagebar>
					<PreferenceForm />
				</Page>
				<BottomBar>
					{error ? <ErrorMsg error={error} /> : null}
					<BottomBarActions>
						<PrimaryButton
							className={styles.editBtn}
							onClick={() => onEditPreference()}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'save_preference_button')] }}
							isRotateIcon={isLoading}
							icon={isLoading ? 'loading-01' : 'send-03'}>
							Save
						</PrimaryButton>
					</BottomBarActions>
				</BottomBar>
			</Disable>
		</SiderSlider>
	);
});
