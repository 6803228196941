import { makeAutoObservable } from 'mobx';

export interface IPretargeting {
	billing_id: number;
	name: string;
}

/**
 * Not observable for now
 */
export class PretargetingModel implements IPretargeting {
	billing_id: number;
	name: string;

	constructor(pretargeting: IPretargeting) {
		const { billing_id, name } = pretargeting;

		this.billing_id = billing_id;
		this.name = name;
		makeAutoObservable(this);
	}

	getBillingId(): number {
		return this.billing_id;
	}

	getName(): string {
		return this.name;
	}
}
