import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDates } from '@monorepo/controlled/src/hooks/use-dates';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { useDidMount } from '@monorepo/tools/src/lib/hooks/utils/use-didmount';
import { HttpStore, IRequestFlags } from '@monorepo/controlled/src/stores/http.store';
import { IQueryParams } from '@monorepo/tools/src/lib/interfaces/url';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { FilterModel } from '@monorepo/controlled/src/models/filter.model';
import { PerformanceResponse, availablePerformanceToMetrics } from '../../models/performance.model';
import { StringActions, stringAction } from '@monorepo/tools/src/lib/utils/string';
import { NumberActions, numberAction } from '@monorepo/tools/src/lib/utils/number';
import { isDebug } from '../tools/use-debug';

export enum PerformancesColumnsSnakeCase {
	Id = 'id',
	Name = 'name',
	Domain = 'domain',
	Page = 'page',
	BidKeys = 'bid_key',
	CampaignId = 'campaign_id',
	Campaign = 'campaign',
	CampaignName = 'campaign_name',
	CampaignGroupId = 'campaign_group_id',
	AdvertiserName = 'advertiser_name',
	AdvertiserId = 'advertiser_id',
	Advertiser = 'advertiser',
	CreativeId = 'creative_id',
	CreativeGroupId = 'creative_group_id',
	TestId = 'test_id',
	Date = 'date',
	PublisherId = 'publisher_id',
	BrowserName = 'browser_name',
	OsName = 'os_name',
	Geo = 'geo',
	State = 'state',
	City = 'city',
	AuctionType = 'auction_type',
	PreferredDealId = 'preferred_deal_id',
	CreativeSize = 'creative_size',
	TestGroupName = 'test_group_name',
	PreferredDeaName = 'preferred_deal_name',
	BidIsEditable = 'bid_is_editable',
	CreativeBrandName = 'creative_brand_name',
	CreativeGroupDesign = 'creative_group_design',
	StrategyId = 'strategy_id',
	CreativeImageUrl = 'creative_image_url',
	TestName = 'test_name',
	Bid = 'bid',

	Design = 'design',
	PreferredDealName = 'preferred_deal_name',
	CampaignGroupName = 'campaign_group_name',
	BidKey = 'bid_key',
	Status = 'status',
	DailyBudget = 'daily_budget',
	TotalBudget = 'total_budget',
	TotalMoneySpent = 'total_money_spent',
	TotalMoneyEarn = 'total_money_earn',
	DailyMoneySpent = 'daily_money_spent',
	DailyMoneyEarn = 'daily_money_earn',
	UserCapEnabled = 'user_cap_enabled',
	Dt = 'dt',
	Roas = 'roas',
	AvgBidFloor = 'avg_bid_floor',
	AvgBidToWin = 'avg_bid_to_win',
	MinBidFloor = 'min_bid_floor',
	MaxBidFloor = 'max_bid_floor',
	MaxBidToWin = 'max_bid_to_win',
	OptimalGain = 'optimal_gain',
	Gain = 'gain',
	OptimalCost = 'optimal_cost',
	MaxLossPerDomain = 'max_loss_per_domain',
	maxClicksToFirstConv = 'max_clicks_to_first_conv',
	RealAdvertiserId = 'real_advertiser_id',
	DailySales = 'daily_sales',
	StateMachineId = 'state_machine_id',
	UniqSale = 'uniq_sale',
	CachePixelType = 'cache_pixel_type',
	EncodeUrl = 'encode_url',
	RevenueCoeff = 'revenue_coeff',
	Hour = 'hour',
	QmaxClicksToFirstConv = 'qmax_clicks_to_first_conv',
	QmaxLossPerDomain = 'qmax_loss_per_domain',
	Roi = 'roi',
}

export const columnsTitlesToColumnsNames: Record<string, string> = {
	Name: 'name',
	'Campaign ID': PerformancesColumnsSnakeCase.CampaignId,
	'Campaign Name': PerformancesColumnsSnakeCase.CampaignName,
	'Strategy ID': PerformancesColumnsSnakeCase.StrategyId,
	'Campaign Group ID': PerformancesColumnsSnakeCase.CampaignGroupId,
	'Campaign Group Name': PerformancesColumnsSnakeCase.CampaignGroupName,
	'Creative ID': PerformancesColumnsSnakeCase.CreativeId,
	'Creative Brand Name': PerformancesColumnsSnakeCase.CreativeBrandName,
	'Creative Size': PerformancesColumnsSnakeCase.CreativeSize,
	'Creative Group Design': PerformancesColumnsSnakeCase.CreativeGroupDesign,
	'Advertiser ID': PerformancesColumnsSnakeCase.AdvertiserId,
	'Test ID': PerformancesColumnsSnakeCase.TestId,
	'Test Name': PerformancesColumnsSnakeCase.TestName,
	'Test Group Name': PerformancesColumnsSnakeCase.TestGroupName,
	Page: PerformancesColumnsSnakeCase.Page,
	Domain: PerformancesColumnsSnakeCase.Domain,
	'Bid Key': PerformancesColumnsSnakeCase.BidKey,
	'Publisher ID': PerformancesColumnsSnakeCase.PublisherId,
	'Browser Name': PerformancesColumnsSnakeCase.BrowserName,
	'OS Name': PerformancesColumnsSnakeCase.OsName,
	Geo: PerformancesColumnsSnakeCase.Geo,
	State: PerformancesColumnsSnakeCase.State,
	City: PerformancesColumnsSnakeCase.City,
	'Auction Type': PerformancesColumnsSnakeCase.AuctionType,
	'Preferred Deal ID': PerformancesColumnsSnakeCase.PreferredDealId,
	'Preferred Deal Name': PerformancesColumnsSnakeCase.PreferredDealName,
	Status: PerformancesColumnsSnakeCase.Status,
	Campaign: PerformancesColumnsSnakeCase.Campaign,
	Advertiser: PerformancesColumnsSnakeCase.Advertiser,
	Date: PerformancesColumnsSnakeCase.Dt,
	Roas: PerformancesColumnsSnakeCase.Roas,
	'Total Spent': PerformancesColumnsSnakeCase.TotalBudget,
	'Total Money Earn': PerformancesColumnsSnakeCase.TotalMoneyEarn,
	'Total Daily Spent': PerformancesColumnsSnakeCase.DailyMoneySpent,
	'Total Daily Earn': PerformancesColumnsSnakeCase.DailyMoneyEarn,
	'User Cap Enabled': PerformancesColumnsSnakeCase.UserCapEnabled,
	Budget: PerformancesColumnsSnakeCase.DailyBudget,
	...availablePerformanceToMetrics,
	'Avg. Bid Floor': PerformancesColumnsSnakeCase.AvgBidFloor,
	'Avg. Bid To Win': PerformancesColumnsSnakeCase.AvgBidToWin,
	'Min Bid Floor': PerformancesColumnsSnakeCase.MinBidFloor,
	'Max Bid Floor': PerformancesColumnsSnakeCase.MaxBidFloor,
	'Max Bid To Win': PerformancesColumnsSnakeCase.MaxBidToWin,
	Gain: PerformancesColumnsSnakeCase.Gain,
	'Optimal Gain': PerformancesColumnsSnakeCase.OptimalGain,
	'Optimal Cost': PerformancesColumnsSnakeCase.OptimalCost,
	Design: PerformancesColumnsSnakeCase.Design,
	// Entity filters with prefixes to be used in the filter object for the API
	adxAdvertisers__Advertiser: PerformancesColumnsSnakeCase.Id,
	'adxCreativeGroups__Creative Group': PerformancesColumnsSnakeCase.Id,
	adxCampaigns__Advertiser: PerformancesColumnsSnakeCase.AdvertiserId,
	adxCreativeGroups__Advertiser: PerformancesColumnsSnakeCase.AdvertiserId,
	adxCampaigns__Campaign: PerformancesColumnsSnakeCase.Id,
	'adx__Advertiser ID': PerformancesColumnsSnakeCase.AdvertiserId,
	adx__Advertiser: PerformancesColumnsSnakeCase.AdvertiserId,
	adx__Campaign: PerformancesColumnsSnakeCase.CampaignId,
	adxCampaignGroup__Advertiser: PerformancesColumnsSnakeCase.AdvertiserId,
};

export const groupByMapper: Record<string, string> = {
	id: PerformancesColumnsSnakeCase.Id,
	name: PerformancesColumnsSnakeCase.Name,
	campaignId: PerformancesColumnsSnakeCase.CampaignId,
	campaign: 'campaign',
	campaignName: 'campaign_name',
	strategyId: PerformancesColumnsSnakeCase.StrategyId,
	campaignGroupId: PerformancesColumnsSnakeCase.CampaignGroupId,
	campaignGroup: 'campaign_group',
	campaignGroupName: 'campaign_group_name',
	creativeId: PerformancesColumnsSnakeCase.CreativeId,
	creativeBrandName: PerformancesColumnsSnakeCase.CreativeBrandName,
	creativeSize: PerformancesColumnsSnakeCase.CreativeSize,
	creativeGroupDesign: PerformancesColumnsSnakeCase.CreativeGroupDesign,
	advertiserId: PerformancesColumnsSnakeCase.AdvertiserId,
	advertiser: 'advertiser',
	advertiserName: PerformancesColumnsSnakeCase.AdvertiserName,
	testId: PerformancesColumnsSnakeCase.TestId,
	testName: PerformancesColumnsSnakeCase.TestName,
	testGroupName: PerformancesColumnsSnakeCase.TestGroupName,
	page: PerformancesColumnsSnakeCase.Page,
	domain: PerformancesColumnsSnakeCase.Domain,
	bidKey: PerformancesColumnsSnakeCase.BidKey,
	publisherId: PerformancesColumnsSnakeCase.PublisherId,
	browserName: PerformancesColumnsSnakeCase.BrowserName,
	osName: PerformancesColumnsSnakeCase.OsName,
	geo: PerformancesColumnsSnakeCase.Geo,
	state: PerformancesColumnsSnakeCase.State,
	city: PerformancesColumnsSnakeCase.City,
	auctionType: PerformancesColumnsSnakeCase.AuctionType,
	preferredDealId: PerformancesColumnsSnakeCase.PreferredDealId,
	preferredDealName: PerformancesColumnsSnakeCase.PreferredDealName,
	totalBudget: PerformancesColumnsSnakeCase.TotalBudget,
	totalMoneySpent: PerformancesColumnsSnakeCase.TotalMoneySpent,
	totalMoneyEarn: PerformancesColumnsSnakeCase.TotalMoneyEarn,
	dailyMoneySpent: PerformancesColumnsSnakeCase.DailyMoneySpent,
	dailyMoneyEarn: PerformancesColumnsSnakeCase.DailyMoneyEarn,
	status: PerformancesColumnsSnakeCase.Status,
	userCapEnabled: PerformancesColumnsSnakeCase.UserCapEnabled,
	date: PerformancesColumnsSnakeCase.Dt,
	dailyBudget: PerformancesColumnsSnakeCase.DailyBudget,
	design: PerformancesColumnsSnakeCase.Design,
};

enum ReportFilterActionEnum {
	CONTAINS = 'CONTAINS',
	IN = 'IN',
	NOT_IN = 'NOT_IN',
	EQUALS = 'EQUALS',
	NOT_EQUALS = 'EQUALS',
	GREATER = 'GREATER',
	GREATER_AND_EQUALS = 'GREATER_EQUALS',
	LESS = 'LESSER',
	LESS_AND_EQUALS = 'LESSER_EQUALS',
	IS_NULL = 'IS_NULL',
	IS_NOT_NULL = 'IS_NULL',
	NOT_CONTAINS = 'CONTAINS',
	INCLUDE = 'INCLUDE',
}

const isInvertedActions: string[] = [StringActions.DoesNotEqual, StringActions.DoesNotContain];

const FilterActionLabelToActionEnum = {
	[NumberActions.Equals]: ReportFilterActionEnum.EQUALS,
	[NumberActions.LessThan]: ReportFilterActionEnum.LESS,
	[NumberActions.LessThanEquals]: ReportFilterActionEnum.LESS_AND_EQUALS,
	[NumberActions.GreaterThan]: ReportFilterActionEnum.GREATER,
	[NumberActions.GreaterThanEquals]: ReportFilterActionEnum.GREATER_AND_EQUALS,
	[StringActions.Contains]: ReportFilterActionEnum.CONTAINS,
	[StringActions.DoesNotEqual]: ReportFilterActionEnum.NOT_EQUALS,
	[StringActions.DoesNotContain]: ReportFilterActionEnum.NOT_CONTAINS,
	[StringActions.Include]: ReportFilterActionEnum.INCLUDE,
};

export enum IPerformanceOptionsSortOrder {
	Asc = 0,
	Desc = 1,
}

interface IPerformanceOptionsPeriod {
	periodStart: string; // YYYY-MM-DD
	periodEnd: string; // YYYY-MM-DD
}

export enum IPerformanceOptionsFilterType {
	Equals = 'EQUALS',
	Include = 'INCLUDE',
	In = 'IN',
}

interface IPerformanceOptionsFilter {
	column: string;
	filterType: string;
	value: string[];
	inverted?: boolean;
}

export enum IPerformanceOptionsDateSegments {
	Daily = 'DAILY',
	None = 'NONE',
}

export enum IPerformanceOptionsSortBy {
	Date = 'date',
	Wins = 'wins',
	Id = 'id',
}

export interface IPerformanceOptions {
	start?: number;
	limit?: number;
	periodStart?: string; // YYYY-MM-DD
	periodEnd?: string; // YYYY-MM-DD
	multipleTimePeriods?: IPerformanceOptionsPeriod[];
	groupBys?: (PerformancesColumnsSnakeCase | null)[];
	filters?: IPerformanceOptionsFilter[];
	dateSegment?: IPerformanceOptionsDateSegments;
	sortBy?: string;
	sortOrder?: IPerformanceOptionsSortOrder;
	inResultsSort?: string | null;
	inResultsSortOrder?: IPerformanceOptionsSortOrder;
	inResultsFilters?: string[][];
	graphYaxis?: string[];
	graphLines?: string[]; // ?
	additionalColumns?: string[]; // ?
	reportName?: string;
	submit?: boolean;
	type?: string;
}

export type PerformanceOptionsSortByStrings = keyof typeof IPerformanceOptionsSortBy;

export const getFilterObject = (filters: Map<number, FilterModel>, reportName = '') => {
	const filtersObject: IPerformanceOptionsFilter[] = [];
	Array.from(filters).forEach(([, filter]) => {
		filtersObject.push({
			column:
				columnsTitlesToColumnsNames[`${reportName}__${filter.prototype.MenuComponent}`] ||
				columnsTitlesToColumnsNames[filter.prototype.MenuComponent] ||
				filter.prototype.MenuComponent,
			filterType:
				filter.prototype.MenuComponent === 'Status'
					? IPerformanceOptionsFilterType.Include
					: FilterActionLabelToActionEnum[(filter.action as stringAction | numberAction) || 'contains'] || '',
			value: Array.isArray(filter.value) ? filter.value : [filter?.value.toString() || ''],
			inverted: isInvertedActions.includes(filter.action as string),
		});
	});

	return filtersObject;
};

export const preparePerformanceRequest = (options: IPerformanceOptions): IPerformanceOptions => {
	const {
		limit,
		periodStart,
		periodEnd,
		multipleTimePeriods,
		groupBys,
		filters,
		sortBy,
		start,
		dateSegment,
		inResultsSort,
		inResultsSortOrder,
		sortOrder,
		inResultsFilters,
		graphYaxis,
		graphLines,
		additionalColumns,
		reportName,
		submit = false,
	} = options;

	const externalOptions: IPerformanceOptions = {
		type: 'STATISTICS',
		submit,
		start: start || 0,
		limit: limit || 100,
		periodStart,
		periodEnd,
		multipleTimePeriods: multipleTimePeriods || [],
		groupBys: (() => {
			if (!groupBys) {
				return [PerformancesColumnsSnakeCase.CampaignId];
			}
			// Default is by date, if we add date it ruins the results so remove it for now until fixed in reporting service (probably never blat)
			return groupBys
				?.filter(groupBy => groupBy !== PerformancesColumnsSnakeCase.Date)
				.filter((value, index, self) => self.indexOf(value) === index); // remove duplicates
		})(),
		filters: filters || [],
		dateSegment: (() => {
			if (dateSegment) {
				return dateSegment;
			}

			if (!groupBys?.includes(PerformancesColumnsSnakeCase.Date)) {
				return IPerformanceOptionsDateSegments.None;
			}
			return IPerformanceOptionsDateSegments.Daily;
		})(),
		sortBy: sortBy || IPerformanceOptionsSortBy.Date,
		inResultsSort: inResultsSort || null,
		inResultsSortOrder: inResultsSortOrder || IPerformanceOptionsSortOrder.Desc,
		sortOrder: sortOrder || IPerformanceOptionsSortOrder.Desc,
		inResultsFilters: inResultsFilters || [],
		graphYaxis: graphYaxis || [],
		graphLines: graphLines || [],
		additionalColumns: additionalColumns || [],
		reportName,
	};

	return externalOptions;
};

//TODO - add aggergateDataBySegment from use-merge-with-performance
export const useReportsList = (
	httpStore: HttpStore<IPerformanceOptions, PerformanceResponse>,
	options: IPerformanceOptions = {},
	hookOptions: BetweenPagesStateProps = {}
) => {
	const isBlockRequest = hookOptions.isBlockRequest || false;
	const location = useLocation();
	const { startDate, endDate } = useDates();
	const { tableStore } = usePageSettings();
	const primaryFromDate = dayjs(startDate).format('YYYY-MM-DD');
	const primaryToDate = dayjs(endDate).format('YYYY-MM-DD');
	const didMount = useDidMount(); // fetch data after did mount, solve problem of navigating between performance pages before fetch is resolved
	const groupByColumns = options.groupBys || [];
	const sortBy = options.sortBy;
	const sortOrder = options.sortOrder;
	const limit = options?.limit;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const filters = tableStore?.getFiltersStore(currentRouteWithoutSpecialChars)?.getCurrentFilters();
	const [fetchParams, setFetchParams] = useState<IPerformanceOptions>({ ...options, groupBys: groupByColumns, limit: 100000 });

	useEffect(() => {
		httpStore.reset();
		tableStore?.setPageIndex(0);
		fetchReports({ appendData: false, resetOffset: true });
		return () => {
			if (!isBlockRequest) {
				// in case of block request no request happend so no need to abort
				httpStore.abort();
			}
		};
	}, [
		location,
		primaryFromDate,
		primaryToDate,
		didMount,
		JSON.stringify(groupByColumns),
		sortBy,
		sortOrder,
		JSON.stringify(Array.from(filters || new Map())),
	]);

	const fetchReports = (flags?: IRequestFlags) => {
		const { periodStart, periodEnd } = options;
		if (isBlockRequest || !didMount) {
			return;
		}

		const _LIMIT = limit || 10000;
		const filters = tableStore?.getFiltersStore(currentRouteWithoutSpecialChars)?.getCurrentFilters();
		const filtersArray = getFilterObject(filters || new Map(), options?.reportName);

		const params = preparePerformanceRequest({
			...options,
			periodStart: periodStart || primaryFromDate,
			periodEnd: periodEnd || primaryToDate,
			limit: _LIMIT,
			filters: [...filtersArray, ...(options.filters || [])],
		});

		const extraQueryParams: IQueryParams = {};
		if (isDebug()) {
			extraQueryParams.m = JSON.stringify(params);
		}
		setFetchParams(params);

		httpStore.fetch(params, { queryParams: extraQueryParams }, flags || {});
	};

	return {
		reportsError: httpStore.getHttpError(),
		isLoading: httpStore.getIsLoading(),
		data: httpStore.getData(),
		fetchReports,
		fetchParams,
	};
};
