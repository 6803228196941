import styles from './creative-preview.module.scss';
import { useEffect, useState } from 'react';
import { Icon, IconFonts } from '../../icon/icon';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';

interface ICreativePreview {
	data: string;
	type: string;
	maxWidth?: string;
}

export const CreativePreview = (props: ICreativePreview) => {
	const { data, maxWidth, type } = props;
	const [clicked, setClicked] = useState(false);

	useEffect(() => {
		if (clicked) {
			setTimeout(() => setClicked(false), 2000);
		}
	}, [clicked]);

	if (type === 'HTML') {
		return (
			<div className={styles.creativePreview} style={{ maxWidth }}>
				<iframe
					sandbox=""
					allow=""
					referrerPolicy="no-referrer"
					style={{ maxWidth: '300px', maxHeight: '50px', borderWidth: '0' }}
					src={data}
				/>
				<div>
					<Tooltip content={'Copy to clipboard'}>
						<Icon
							isMFP={true}
							size={'20px'}
							font={IconFonts.Filled}
							onClick={() => {
								setClicked(true);
								navigator.clipboard.writeText(data);
							}}
							className={`${styles.copyIcon} ${clicked ? styles.clicked : ''}`}
							debugProps={{ dataAttrs: [new DataAttribute('id', 'table_copy_resource_url_to_clipboard')] }}>
							file-attachment-02
						</Icon>
					</Tooltip>
				</div>
			</div>
		);
	}
	return <img style={{ maxHeight: '60px' }} src={data} />;
};
