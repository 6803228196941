import { ColumnVisibilityOptions } from '../../../columns/columns.types';
import {
	DomainBillingIdColumnConfig,
	DomainPretargetingModeColumnConfig,
	DomainNameColumnConfig,
	DomainPretargetingIdColumnConfig,
	DomainTypeColumnConfig,
} from '../../../columns/domains.columns';
import { ReportSortOrder } from '../../../stores/data-stores/old-report.types';

export const domainPretargetingStatusTableConfig = {
	reportParams: {
		reportName: 'adxDomainPretargetingStatus',
		sortBy: 'name',
		sortOrder: ReportSortOrder.Desc,
		submit: false,
	},
	columns: [
		new DomainPretargetingIdColumnConfig({ visibility: ColumnVisibilityOptions.AlwaysVisible }),
		new DomainBillingIdColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DomainTypeColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DomainPretargetingModeColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
		new DomainNameColumnConfig({ visibility: ColumnVisibilityOptions.Visible }),
	],
	tableState: { pagination: { pageIndex: 0, pageSize: 5 } },
	isGraphAvailable: false,
	tableName: 'domainsPretargetingStatusTable',
};
