import { makeAutoObservable } from 'mobx';
import { id } from '@monorepo/tools/src/lib/types/primitives';
import { Statuses } from '../enums/status';

/**
 * The props the preference crud page uses
 */
export interface IPreference {
	id?: id;
	name?: string;
	value?: string;
	comment?: string;
}

/**
 * The props the preference edit page uses
 */
export interface IPreferenceEdit {
	id?: id;
	name?: string;
	value?: string;
	comment?: string;
}

/**
 * The props to send to server
 */
export interface IPreferenceCreateForm {
	name?: string;
	value?: string;
	comment?: string;
}

export interface IPreferenceEditForm {
	id?: id;
	name?: string;
	value?: string;
	comment?: string;
}

export class PreferenceModel implements IPreference {
	id?: id;
	name?: string;
	value?: string;
	comment?: string;
	status = Statuses.Active;

	constructor(props?: IPreference) {
		this.id = props?.id;
		this.name = props?.name;
		this.value = props?.value;
		this.comment = props?.comment;

		makeAutoObservable(this);
	}

	public getId(): id | undefined {
		return this.id;
	}

	public setId(id: id) {
		this.id = id;
	}

	public getName() {
		return this.name;
	}

	public setName(name: string) {
		this.name = name;
	}

	public getValue() {
		return this.value;
	}

	public setValue(value: string) {
		this.value = value;
	}

	public getComment() {
		return this.comment;
	}

	public setComment(comment: string) {
		this.comment = comment;
	}
}
