import { ReactNode } from 'react';
import styles from './action-liner.module.scss';

interface ISelectedLinerButton {
	children: ReactNode;
}
export const SelectedLinerButton = (props: ISelectedLinerButton) => {
	const { children } = props;
	return <div className={styles.selectedLinerButton}>{children}</div>;
};

export const IndexLinerButton = (props: ISelectedLinerButton) => {
	const { children } = props;
	return <div className={styles.indexLinerButton}>{children}</div>;
};
