import { randomString } from '@monorepo/tools/src/lib/utils/string';
import { Checkbox } from '../checkbox/checkbox';
import styles from './toggle.module.scss';
import { Directions } from '../../buttons/buttons';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { generateDataAttrs } from '@monorepo/tools/src/lib/models/data-attr.model';
import { Icon } from '../../icon/icon';

interface IToggle {
	onChange?: (value: boolean) => void;
	label: string;
	required?: boolean; // TODO - Not Implemented
	defaultValue?: boolean;
	title?: string;
	classNames?: string;
	id?: string;
	labelDirection?: Directions;
	debugProps?: IDebugProps;
	icon?: string | JSX.Element;
	iconColor?: string;
	iconSize?: string;
	disabled?: boolean;
}

export const Toggle = (props: IToggle) => {
	const {
		onChange,
		defaultValue = false,
		label,
		title = '',
		classNames,
		id = randomString(10),
		labelDirection = Directions.FromLeft,
		debugProps,
		icon,
		iconColor,
		iconSize,
		disabled = false,
	} = props;

	const { dataAttrs } = debugProps || {};
	const onToggle = () => {
		if (onChange) {
			onChange(!defaultValue);
		}
	};

	const renderLabel = () => {
		return (
			<label htmlFor={id} className={styles.switch}>
				<Checkbox disabled={disabled} checked={defaultValue} onChange={onToggle} id={id} />
				<span className={styles.slider}></span>
			</label>
		);
	};

	return (
		<div className={`${styles.wrapper} ${classNames} ${disabled ? styles.disabled : ''}`} {...generateDataAttrs(dataAttrs)}>
			{title !== '' ? <span className={styles.title}>{title}</span> : null}
			<div className={styles.switchWrapper}>
				{labelDirection === Directions.FromLeft ? renderLabel() : null}
				<span className={styles.label}>{label}</span>
				{labelDirection === Directions.FromRight ? renderLabel() : null}
			</div>
			{icon ? (
				<Icon isMFP={typeof icon === 'string'} className={styles.icon} color={iconColor} size={iconSize}>
					{icon}
				</Icon>
			) : null}
		</div>
	);
};
