import { FileSelector } from '@monorepo/base/src/components/file-selector/file-selector';
import { DispatchLogsTypes, useLogs } from '@monorepo/controlled/src/hooks/use-logs';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Tooltip, TooltipThemes } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { IAdminxStore } from '../../stores';
import styles from './creatives-bulk-create-csv.module.scss';
// import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';

export const CreativesBulkCSVCreate = observer(() => {
	const { creativeStore } = useStores<IAdminxStore>();
	const bulkCreateCSVStore = creativeStore.getBulkCreateCSVStore();
	const isSuccess = bulkCreateCSVStore.getIsSuccess();
	const httpError = bulkCreateCSVStore.getHttpError();
	const isLoading = bulkCreateCSVStore.getIsLoading();
	const navigate = useNavigateParams();

	const { dispatchLog } = useLogs();

	useEffect(() => {
		bulkCreateCSVStore.setIsLoading(false);
	}, []);

	const onCreativeCSVSelect = async (creativesCSVData: FormData) => {
		bulkCreateCSVStore.fetch(creativesCSVData);
	};

	useEffect(() => {
		if (isSuccess && !httpError) {
			dispatchLog({
				msg: `Creatives was successfully added`,
				type: DispatchLogsTypes.Success,
			});
			bulkCreateCSVStore.setIsSuccess(false);
			navigate(0);
		}
		if (!isSuccess && httpError) {
			dispatchLog({
				msg: httpError.getMessage(),
				type: DispatchLogsTypes.Error,
			});
			bulkCreateCSVStore.setHttpError(null);
		}
	}, [isSuccess, httpError]);

	return (
		<Tooltip
			theme={TooltipThemes.Light}
			content={
				<div className={styles.tooltip}>
					<div className={styles.title}>
						Download csv for example:{' '}
						<a className={styles.link} href={'/assets/bulk-creatives-create-template.csv'} download>
							Template
						</a>
					</div>
					<div>*All fields must be written</div>
				</div>
			}>
			<FileSelector
				icon={isLoading ? 'loading-01' : 'folder-plus'}
				rotateIcon={isLoading}
				onFileSelect={onCreativeCSVSelect}
				fileVariableName={'bulk_creatives'}
				accept={'.csv'}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'banner')] }}
				required={false}>
				Bulk
			</FileSelector>
		</Tooltip>
	);
});
